interface OrdersListOrders {
  storeLogo: string
  storeName: string
  modalityId: number
  id: number
  type: string
  status: number
  time: string
}

interface OrderData {
  
}

interface OrderHistoryListOrder {
  id: number
  cpf: string
  modalityId: number
  serviceFee: number
  discount: number
  deliveryPlace: string
  date: string
  shortDate: string
  type: string
  status: number
  time: string
}

interface OrderHistoryListOrderStore {
  id: number
  name: string
  imageUrl: string
  logoUrl: string
  address: string
  menuType: number
  phone: string
}

interface OrderHistoryListOrderItem {
  productId: number
  product: string
  quantity: number
  description: string
  price: number
  orderId: number
  additionalText: string
  notes: string
  additionals: []
  fractionalQuantity: boolean
}

interface OrdersList {
  retry: boolean
  orders: OrdersListOrders[]
  success: boolean
}

interface OrderHistoryList {
  message: string
  orders: OrderHistoryListOrder[]
  success: boolean
}

export const mapOrdersList = (data: any) : OrdersList => {
  const orders = (data['StatusPedidos'] && data['StatusPedidos'].length && data['StatusPedidos'].map((mappedItem: any) => {
    return {
      storeLogo: mappedItem['Estabelecimento'] && mappedItem['Estabelecimento']['Logo'],
      storeName: mappedItem['Estabelecimento'] && mappedItem['Estabelecimento']['Nome'],
      id: mappedItem['Pedido'] && mappedItem['Pedido']['ComandaId'],
      modalityId: (
        mappedItem['Pedido'] && mappedItem['Pedido']['Tipo'] ? 
        (mappedItem['Pedido'] && mappedItem['Pedido']['Tipo'] && mappedItem['Pedido']['Tipo'] === 'Delivery' ? 4 : 2) : null
      ),
      schedule: mappedItem['Pedido'] && mappedItem['Pedido']['DataAgendamentoSeara'],
      status: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Descricao'],
      currentStep: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Atual'],
      stepsTotal: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Quantidade'],
      time: mappedItem['Pedido'] && mappedItem['Pedido']['Status'] && mappedItem['Pedido']['Status']['Horario']
    }
 })) || []

	return {
    retry: data['RefazerChamada'],
    success: data['Sucesso'],
    orders
  }
}

export const mapOrderData = (data: any) : OrderData => {
  const statuses = data['NotificacaoPedido'] && data['NotificacaoPedido']['ListaStatusDelivery'].map((mappedItem: any) => {
    return {
      time: mappedItem['Horario'],
      status: mappedItem['Status'],
      active: mappedItem['Ativo']
    }
 })

  const notification = data['NotificacaoPedido'] && {
    status: data['NotificacaoPedido']['StatusDelivery'],
    statuses,
    deliveryTime: data['NotificacaoPedido']['PrevisaoEntregaTexto']
  }

  const store = data['Estabelecimento'] && {
    id: data['Estabelecimento']['EstabelecimentoId'],
    name: data['Estabelecimento']['Nome'],
    address: data['Estabelecimento']['Endereco'],
    logoUrl: data['Estabelecimento']['UrlLogo'],
    whatsapp: data['Estabelecimento']['NumeroWhatsapp'],
    phone: data['Estabelecimento']['Telefone']
  }

  const scheduling = data['AgendamentoSeara'] && {
    date: data['AgendamentoSeara']['Data'],
    schedule: data['AgendamentoSeara']['Hora']
  }

  const items: OrderHistoryListOrderItem[] = data['PedidoItems'] && data['PedidoItems'].length && data['PedidoItems'].map((mappedItem: any) => {
    return {
      productId: mappedItem['ProdutoId'],
      product: mappedItem['Produto'],
      quantity: mappedItem['Quantidade'],
      description: mappedItem['Descricao'],
      price: mappedItem['Valor'],
      orderId: mappedItem['PedidoId'],
      additionalText: mappedItem['TextoAcompanhamento'],
      notes: mappedItem['Observacao'],
      additionals: mappedItem['Acompanhamentos'],
      fractionalQuantity: mappedItem['QuantidadeFracionada']
    }
  })

	return {
    id: data['ComandaBalcaoId'],
    list: items,
    store,
    cpf: data['Cpf'],
    inProgress: data['EmAndamento'],
    brandCard: data['BandeiraCartao'],
    numberCard: data['NumeroCartao'],
    requestNumber: data['NumeroChamada'],
    scheduling,
    status: data['NumeroChamada'] > -1 ? 2 : 1,
    paymentStatus: data['StatusPagamento'],
    orderDate: data['DataComprovante'],
    orderId: data['ComandaBalcaoId'],
    deliveryAddress: data['LocalEntrega'],
    notification,
    inprogress: data['EmAndamento'],
    modalityId: data['ModalidadeRetiradaId'],
    userName: data['Nome'],
    summary: {
      total: data['ValorPago'],
      deliveryFee: data['ModalidadeRetiradaValor'],
      discount: data['ValorDesconto'],
      subtotal: data['ValorPago'] - data['ModalidadeRetiradaValor']
    }
	}
}

export const mapOrderHistoryList = (data: any) : OrderHistoryList => {
  const orders = (data['MeusPedidos'] && data['MeusPedidos'].length && data['MeusPedidos'].map((mappedItem: any) => {
    const store: OrderHistoryListOrderStore = mappedItem['Estabelecimento'] && {
      id: mappedItem['Estabelecimento']['EstabelecimentoId'],
      name: mappedItem['Estabelecimento']['Nome'],
      imageUrl: mappedItem['Estabelecimento']['UrlImagem'],
      logoUrl: mappedItem['Estabelecimento']['UrlLogo'],
      address: mappedItem['Estabelecimento']['Endereco'],
      menuType: mappedItem['Estabelecimento']['TipoCardapio'],
      phone: mappedItem['Estabelecimento']['Telefone']
    }

    const items: OrderHistoryListOrderItem[] = mappedItem['PedidoItems'] && mappedItem['PedidoItems'].length && mappedItem['PedidoItems'].map((mappedItem: any) => {
      return {
        productId: mappedItem['ProdutoId'],
        product: mappedItem['Produto'],
        quantity: mappedItem['Quantidade'],
        description: mappedItem['Descricao'],
        price: mappedItem['Valor'],
        orderId: mappedItem['PedidoId'],
        additionalText: mappedItem['TextoAcompanhamento'],
        notes: mappedItem['Observacao'],
        additionals: mappedItem['Acompanhamentos'],
        fractionalQuantity: mappedItem['QuantidadeFracionada']
      }
    })

    return {
      id: mappedItem['ComandaBalcaoId'],
      cpf: mappedItem['Cpf'],
      modalityId: mappedItem['ModalidadeRetiradaId'],
      serviceFee: mappedItem['ModalidadeRetiradaValor'],
      modalityDiscount: mappedItem['DescontoValorModalidadeRetirada'],
      deliveryPlace: mappedItem['LocalEntrega'],
      date: mappedItem['Data'],
      shortDate: mappedItem['DataComprovante'],
      cardBrand: mappedItem['BandeiraCartao'],
      cardNumber: mappedItem['NumeroCartao'],
      paymentStatus: mappedItem['StatusPagamento'],
      cardImage: mappedItem['ImagemCartao'],
      cardImageVisa: mappedItem['ImagemCartaoVisa'],
      cardBrandId: mappedItem['ImagemBandeiraId'],
      rating: mappedItem['Avaliacao'],
      inProgress: mappedItem['EmAndamento'],
      requestNumber: mappedItem['NumeroChamada'],
      amountPaid: mappedItem['ValorPago'],
      discount: mappedItem['ValorDesconto'],
      reversal: mappedItem['Estorno'],
      store,
      items
    }
 })) || []

	return {
    message: data['Mensagem'],
    success: data['Sucesso'],
    orders
  }
}
