import {
  actionTypes
} from '..'

import {
  loading
} from '../loading'

export const fetchUserAddresses = (callback) => (dispatch, getState, container) => {
  try {
    const latitude = getState().main.latitude
    const longitude = getState().main.longitude
    const modalityId = getState().main.modality && getState().main.modality.id
    const userAddress = getState().main.userAddress

    const data = {
      latitude,
      longitude,
      modalityId,
      userAddress
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchUserAddresses({
      onSuccess: ({ userAddresses}) => {
        dispatch(setUserAddresses(userAddresses))
      },
      onError: () => {
        dispatch(setUserAddresses([]))
      },
      onEmpty: () => {
        dispatch(setUserAddresses([]))
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setUserAddresses([]))
  } finally {
    callback && callback()
  }
}

export const setUserAddresses = (userAddresses) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESSES,
    payload: userAddresses
 })
}

export const setUserAddress = (userAddress) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESS,
    payload: userAddress
 })
}

export const removeUserAddressesItem = (userAddress) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_USER_ADDRESSES_ITEM,
    payload: userAddress
  })
}
