import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
  handleGTM,
  logout,
  setModality
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import {
  cart,
  coupon
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  containerMode,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Skeleton,
  Navigation,
} from '..'

import { NewCoupon } from './new-coupon'

import { Content } from './content'
import { EmptyContent } from './empty-content'

import {
  H1,
  H2,
  Wrapper,
  LoadingWrapper,
  BackButton
} from './styles'

export const Context = React.createContext({})

export function CouponsPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    fetchCoupons,
    loading,
    coupon,
    couponCpf,
    setCoupon,
    theme,
    userAddress,
    userName,
    match,
    registeredCoupons,
    storeName,
    storeImg,
    modalityId,
    handleGTM,
    userId,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

  const context = useContext(Context)

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const stateCouponId = coupon && coupon.id
  const couponId = match.params && Number(match.params.couponId)
  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [sideMode, setSideMode] = useState({
    isOpen: false,
    mode: containerMode.default
  })
  const [viewCards, setViewCards] = useState(false)
  const { coupons = [] } = registeredCoupons

  const registeredCouponsSection = sections && sections.filter(item => item.id === 'registered-coupons')[0]
  const newCouponSection = sections && sections.filter(item => item.id === 'new-coupon')[0]

  const registeredCouponsSectionTitle = registeredCouponsSection.title
  const registeredCouponsSelectedSectionName = registeredCoupons && registeredCoupons.length ? 'main-content' : 'empty-content'
  const registeredCouponsSelectedSection = registeredCouponsSection.content.filter(item => item.id === registeredCouponsSelectedSectionName)[0]

  const newCouponSectionTitle = newCouponSection.title

  useEffect(() => {
    setTimeout(() => fetchCoupons(), 500)
  }, [
    fetchCoupons
  ])

  useEffect(() => {
    if (couponId && couponId !== stateCouponId) {
      const selectedCoupon = registeredCoupons && registeredCoupons.coupons && registeredCoupons.coupons.filter(item => item.id === couponId)[0]

      setCoupon({
        id: couponId,
        ...selectedCoupon
      })
    }

    if (!couponId && couponCpf) {
      setCoupon({})
    }
  }, [
    couponCpf,
    couponId,
    registeredCoupons,
    setCoupon,
    stateCouponId
  ])

  useLayoutEffect(() => {
    const url = window.location.href;

    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Cupons'
    }
    handleGTM(access)

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId,
    handleGTM
  ])

  async function handleModality(id) {
    setModality({ id: id })

    await fetchCoupons()
  }

  const handleSideModeChange = (mode) => {
    const _isOpen = sideMode.mode !== mode

    setSideMode({
      isOpen: _isOpen,
      mode: _isOpen ? mode : sideMode.default
    })
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleCoupon(id) {
    history.push(`/coupons/${id}${URLParameters}`)

    if (sideMode.mode !== containerMode.sideFullContent) {
      handleSideModeChange(containerMode.sideFullContent)
    }
  }

  return (
    <Wrapper id={'coupons'}>
			<Helmet>
				<title>{`${title} - Meus cupons`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/coupons`} />
			</Helmet>

      {loading.includes('coupons-history') && <LoadingWrapper id='coupons-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('coupons-history') && <PageContainer
        isSideOpen={sideMode.isOpen}
        mode={sideMode.mode}
      >
        <PageContainerHeader>
          <MainHeader
            currentRoute='coupons'
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={userAddress ? userAddress : addressDelivery}
            isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
            cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
            opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sideMode.isOpen}>
          <>
            <Image src={storeImage} alt="" />

            <H1>{context.title}</H1>

            <BackButton className='back-button' onClick={() => {
              window.history.back()
            }}>
              <CustomIcon name='AngleLeft' />
            </BackButton>

            <H2>{newCouponSectionTitle}</H2>

            <NewCoupon fetchCoupons={fetchCoupons} />

            {coupons && coupons.length ? <H2>{registeredCouponsSectionTitle}</H2> : null}

            {coupons && coupons.length ?
              <Content data={coupons} couponId={couponId} handleCoupon={handleCoupon} /> :
              <EmptyContent data={{ ...registeredCouponsSelectedSection }} />}
          </>
        </PageContainerMain>
        <PageContainerSide>
          {!isMobile && sideMode.mode === containerMode.sideFullContent && !couponId &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

          {sideMode.mode === containerMode.headerFullContent ?
            <Navigation
              contactUs={() => handleContactUs()}
              userName={userName}
              currentRoute='coupons'
            /> : null}
        </PageContainerSide>
      </PageContainer>}
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    addressDelivery: state.main.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.main.loading || [],
    coupon: state.main.coupon || {},
    registeredCoupons: state.main.coupons || { coupons: [] },
    userAddress: state.main.userAddress || '',
    userAddresses: state.main.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.main.userName,
    storeImg: (state.main.store && state.main.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.main.store && state.main.store.name,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  fetchCoupons: coupon.fetchCoupons,
  logout,
  setCoupon: coupon.setCoupon,
  setModality,
  handleGTM
})

export const Coupons = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'coupons' })
)(CouponsPage))
