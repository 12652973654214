import {
  useEffect
} from 'react'
import {
  useDispatch,
	useStore
} from 'react-redux'

import {
	storeSettings
} from '../../state/store-settings'

export function useStoreSettings() {
	const dispatch = useDispatch()
	const store = useStore()

	useEffect(() => {
		dispatch(storeSettings.fetchStoreSettings())
  }, [
		dispatch
	])

	const {
		title,
		url,
		description
	} = store.getState().main.storeSettings

	return {
		title,
		url,
		description
	}
}
