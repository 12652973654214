import {
  actionTypes
} from '..'

import {
  loading
} from '../loading'

export const setContactUs = (contactUs) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CONTACT_US,
    payload: contactUs
  })
}

export const sendContactUs = (callback) => (dispatch, getState, container) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const successCallback = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  const done = () => {
    dispatch(loading.setLoading({ item: 'send-contact-us', delete: true}))
  }

  try {
    const contactUsMessage = (getState().main.contactUs && getState().main.contactUs.message) || ''

    const data = { message: contactUsMessage }
    const actions = {
      setLoading: loading.setLoading
    }

    return container.sendContactUs({
      onSuccess: ({ contactUs }) => {
        if (contactUs && contactUs.success) {
          dispatch(setContactUs({
            message: contactUsMessage,
            notifications: contactUs.notifications
          }))

          successCallback && successCallback({
            ...contactUs
          })

          done()

          return
        }

        dispatch(setContactUs({
          notifications: []
        }))

        done()
      },
      onError: () => {
        errorCallback && errorCallback()

        dispatch(setContactUs({
          notifications: []
        }))

        done()
      },
      onEmpty: () => {
        errorCallback && errorCallback()

        dispatch(setContactUs({
          notifications: []
        }))

        done()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    errorCallback && errorCallback()

    dispatch(setContactUs({
      notifications: []
    }))
  } finally {
    
  }
}
