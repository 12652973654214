import styled from 'styled-components'
import { Button } from '../../../components'

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    grid-area: details;
    width: 100%;
    box-sizing: border-box;
    padding: 0 1.5rem;

    @media (min-width: 60rem) {    
        margin: 0 0 1.5rem 0;
        padding: 0 1.5rem 0 0;
        border-right: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
        margin: 0;
    }
`

export const CustomerMessage = styled.span`
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 1.688rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};

    >span{
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};

    }
    
    @media (max-width: 60rem){
        font-size: 1.15rem;
        margin: 0;
    }
`

export const OrderDate = styled.span`
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
    margin: 0;

    @media(max-width: 60rem){
        margin: 0;
    }
`

export const DeliveryEstimate = styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
    background: #F7F7F7;
    border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    height: 3.5rem;
    border-radius: 12px;
    padding: 0 1.5rem;
    box-sizing: border-box;

    svg {
        margin: 0 .75rem 0 0;

        path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
        }
        g {
            stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
        }
        path {
            stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
        }
    }

    @media (max-width: 60rem) {
        font-size: .9rem;
    }
`

export const DetailsItem = styled.div`
    background: #fff;
    border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
    border-radius: 15px;
    height: 5rem;
    width: 100%;
    padding: .85rem;
    display: none;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    margin: 1.5rem 0 0 0;

    @media (min-width: 60rem) {
        display: flex;
    }
`

export const ItemLabel = styled.label`
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`

export const ItemContent = styled.span`
    font-size: 1.25rem;
    line-height: 1.688rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
    display: flex;
    align-items: start;

    svg {
        margin: 0 .5rem 0 0;
        width: ${props => props.iconWidth || '.75rem'};

        path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
        }
    }
`

export const CardImage = styled.img`
    width: 50px;
    height: 30px;
    padding: 10px;
`

export const ActionButton = styled(Button)`
  flex: 0 0 3rem;
  margin-top: 1.25rem;
  cursor: pointer;

  >a{
    font-weight: bold;
    font-size: .875rem;
    line-height: 1.188rem;
    color: #fff;
    text-decoration: none;
  }

  svg {
    margin: 0 0 -.2rem .4rem;
    width: 1.25em

    path {
        fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#333'};
    }
}
`
