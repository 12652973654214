import React, {
  useContext
} from 'react'
import { connect } from 'react-redux'

import { ReactComponent as CouponIcon } from '../../assets/icons/icon_coupon.svg'

import { InputRounded } from '../../components'

import {
  coupon
} from '../../../state'

import {
  pipe
} from '../../../domain/helpers'

import {
  CouponInputContainer,
  Wrapper,
  RegisterButton
} from './styles'

import {
  Context
} from '../'

export const NewCouponComponent = (props) => {
  const {
    addCoupon,
    setCoupon,
    fetchCoupons,
    coupon
  } = props

  const context = useContext(Context)

  const {sections} = context
  const section = sections.filter(item => item.id === 'new-coupon')[0]
  const sectionContent = section.content.filter(item => item.id === 'main-content')[0]
  const sectionActions = sectionContent.actions
  const sectionFields = sectionContent.fields
  const registerButton = sectionActions.filter(item => item.id === 'register-button')[0]
  const registerButtonLabel = registerButton.label
  const couponInput = sectionFields.filter(item => item.id === 'coupon-input')[0]
  const couponInputLabel = couponInput.label

  function handleAdd() {
    addCoupon([() => {}, () => {
      setCoupon('')

      fetchCoupons()
    }])
  }

  return (
    <Wrapper>
      <CouponInputContainer>
        <InputRounded placeholder={couponInputLabel} value={coupon} onChange={(event) => {
          setCoupon(event.target.value)
        }} />
        <CouponIcon />
      </CouponInputContainer>
      <RegisterButton onClick={() => {
        handleAdd()
      }}>{registerButtonLabel}</RegisterButton>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    coupon: (state.main && state.main.coupon) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  addCoupon: coupon.addCoupon,
  setCoupon: coupon.setCoupon
})

export const NewCoupon = React.memo(pipe(
  GetConnection
)(NewCouponComponent))
