import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  flex: none;
  opacity: 0;

  &.motion {
    opacity: 1;
  }
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  flex: 1;
  max-height: 4.063rem;
  min-height: 4.063rem;
  margin-bottom: 1.25rem;

  &::last-child {
    margin-bottom: 0;
  }
`

export const PickupButton = styled.button`
  ${ props => props.backgroundImage ? `
    background: url(${ props.backgroundImage}) no-repeat;
    background-size: 100%;
  ` : '' }
  box-sizing: border-box;
  flex: 1;
  padding: .625rem;
  margin: 0;
  margin-right: .625rem;
  background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  max-height: 5rem;
  min-height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: .938rem;
  position: relative;
  overflow: hidden;

  > label {
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: .75rem;
    line-height: 1rem;
    cursor: inherit;

    > span {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      display: block;
      font-size: .875rem;
      text-transform: uppercase;
    }
  }

  > img {
    position: absolute;
    right: -2.188rem;
    top: 50%;
    width: auto;
    height: 4.375rem;
    transform: translate(0, -50%);
  }
`

export const DeliveryButton = styled.button`
  ${ props => props.backgroundImage ? `
    background: url(${ props.backgroundImage}) no-repeat;
    background-size: 100%;
  ` : '' }
  
  box-sizing: border-box;
  flex: 1;
  padding: .625rem;
  margin: 0;
  background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  max-height: 5rem;
  min-height: 5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  border-radius: .938rem;
  position: relative;
  overflow: hidden;

  > label {
    text-align: left;
    color: #fff;
    font-weight: bold;
    font-size: .75rem;
    line-height: 1rem;
    cursor: inherit;

    > span {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      display: block;
      font-size: .875rem;
      text-transform: uppercase;
    }
  }

  > img {
    position: absolute;
    right: -.625rem;
    top: 50%;
    width: auto;
    height: 5rem;
    transform: translate(0, -50%);
  }
`

export const OrderHistoryButton = styled.button`
  ${ props => props.backgroundImage ? `
    background: url(${ props.backgroundImage}) no-repeat;
    background-size: 100%;
  ` : '' }

  box-sizing: border-box;
  flex: 1;
  padding: .625rem;
  margin: 0;
  background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  max-height: 4.375rem;
  min-height: 4.375rem;
  max-width: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: .938rem;
  flex-direction: row;

  > label {
    text-align: left;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    font-weight: bold;
    font-size: .875rem;
    line-height: 1rem;
    flex: 1;
    min-width: 12.5rem;
    cursor: inherit;

    > span {
      color: #fff;
      display: block;
      font-size: .75rem;
      text-transform: none;
      font-weight: normal;
    }
  }

  > img {
    max-width: 2.5rem;
    min-width: 2.5rem;
    min-height: auto;
    max-height: auto;
    flex: 1;
  }

  > span {
    flex: 0;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    text-align: right;
    padding-left: .625rem;
  }
`

export const Image = styled.img``

export const Label = styled.label`
  margin: 0;
`
