import React, {
	useEffect,
	useState
} from 'react'

import {
	CustomIcon
} from '../../components'
import history from '../../../history'

import {
	calculateCartTotal
} from '../../../domain/helpers'

//import { PWAAnalytics } from '../../../domain/helpers/ipiranga/PWA'
import { ReactComponent as EmptyBasketIcon } from '../../assets/icons/icon_empty_basket.svg';

import {
	Wrapper,
	ListWrapper,
	ListItemWrapper,
	H2,
	ProductWrapper,
	ActionsWrapper,
	SummaryWrapper,
	Paragraph,
	Span,
	Button,
	QuantityControlWrapper,
	EmptyBasketContainer,
	GreetingsTitle,
	SimpleQuantity
} from './styles'

const calculateQuantityTotal = (items) => {
	const total = items && items.reduce((acc, item) => acc + item.quantity, 0)

	return total
}

const QuantityControl = ({
	quantity,
	id,
	name,
	price,
	handleQuantity,
	dataTotal,
	URLParameters
}) => {
	return <QuantityControlWrapper>
		<Button onClick={() => {
			if (quantity === 1) {
				handleQuantity({
					id,
					quantity: 0,
					name,
					price
				})

				if (dataTotal && dataTotal.length === 1) {
					history.push(`/store${URLParameters}`)
				}

				return
			}

			handleQuantity({
				id,
				quantity: -1
			})
		}}>
			<CustomIcon name={quantity === 1 ? 'Trash' : 'Minus'} width='11px' />
		</Button>
		<Span>{quantity}</Span>
		<Button onClick={() => {
			if (quantity === 999) {
				return
			}

			handleQuantity({
				id,
				quantity: 1
			})
		}}>
			<CustomIcon name='Plus' width='11px' />
		</Button>
	</QuantityControlWrapper>
}

export function List(props) {
	const {
		data,
		deliveryFee,
		storeName,
		handleQuantity: propsHandleQuantity,
		modalityId,
		handleGTM,
		coupon,
		isQuantityEditable = true,
		userName,
		mode,
		setProductsRemoved,
		URLParameters
	} = props

	const {
		value: couponValue,
		minValue: couponMinimumValue,
		type,
		typeValueModalityDelivery,
		valueModalityDelivery,
		valueMaxModalityDelivery,
		valueMinModalityDelivery
	} = coupon

	const [loaded, setLoaded] = useState(false)
	const [cartSubtotalTotal, setCartSubtotalTotal] = useState(0)
	const [cartTotal, setCartTotal] = useState(0)
	const [itemsQuantity, setItemsQuantity] = useState(1)
	const [finishDelivery, setFinishDelivery] = useState(0)
	const [percentual, setPercentual] = useState(0)

	useEffect(() => {
		if (!loaded) {
			setLoaded(true)
		}

		setCartSubtotalTotal(calculateCartTotal(data))

		setItemsQuantity(calculateQuantityTotal(data))

		if (coupon && couponValue) {
			if (type === 2) {
				const percentualCalculate = (calculateCartTotal(data) * couponValue) / 100

				setPercentual(percentualCalculate)
				setCartTotal(calculateCartTotal(data) - percentualCalculate + deliveryFee)
				
				return () => ({})
			}

			setCartTotal(calculateCartTotal(data) - couponValue + deliveryFee)

			return () => ({})
		}

		setCartTotal(calculateCartTotal(data) + deliveryFee)
		setFinishDelivery(deliveryFee)

		return () => { }
	}, [
		data,
		itemsQuantity,
		storeName,
		loaded,
		couponValue,
		couponMinimumValue,
		cartSubtotalTotal,
		coupon,
		deliveryFee,
		typeValueModalityDelivery,
		valueMaxModalityDelivery,
		valueMinModalityDelivery,
		valueModalityDelivery,
		type
	])

	function handleQuantity(args) {
		propsHandleQuantity && propsHandleQuantity(args)

		if (args.quantity === -1) {
			const quantityData = {
				'event': 'removeToCart',
				'ecommerce': {
					'add': {
						'products': {
							'name': args.name,
							'id': args.id,
							'price': args.valorPromocional ? args.valorPromocional : args.price,
							'brand': 'Seara',
							'category': args.category && args.category.name,
							'variant': '',
							'quantity': args.quantity
						}
					}
				}
			}

			handleGTM(quantityData)
		} else if (args.quantity === 1) {
			const quantityData = {
				'event': 'addToCart',
				'ecommerce': {
					'add': {
						'products': {
							'name': args.name,
							'id': args.id,
							'price': args.valorPromocional ? args.valorPromocional : args.price,
							'brand': 'Seara',
							'category': args.category && args.category.name,
							'variant': '',
							'quantity': args.quantity
						}
					}
				}
			}

			handleGTM(quantityData)
		} else if (args.quantity === 0) {
			const product = {
				'name': args.name,
				'id': args.id,
				'price': args.valorPromocional ? args.valorPromocional : args.price,
				'brand': 'Seara',
				'category': args.category && args.category.name,
				'variant': '',
				'quantity': args.quantity
			}

			setProductsRemoved(product)
		}
		// if (args.quantity === 0) {
		// 	const quantityData = {
		// 		action: 'analytics',
		// 		data: {
		// 			eventType: 'remove_product_to_cart',
		// 			parameters: {
		// 				store_name: storeName,
		// 				modality: modalityId,
		// 				product_name: args.name,
		// 				product_value: args.valorPromocional ? args.valorPromocional : args.price,
		// 				quantity: args.quantity
		// 			}
		// 		}
		// 	}

		// 	handleGTM(quantityData)
		// 	PWAAnalytics(quantityData)
		// }
	}

	return <Wrapper id='cart-list'>
		{mode === 'simple' ? null : <GreetingsTitle>
			Olá, <span>{userName}</span><br /> pronto para concluir<br /> seu pedido?
		</GreetingsTitle>}

		{data && data.length ? <H2>Resumo do Pedido</H2> : null}

		{data && data.length ? <ListWrapper>
			{data.map((item, index) => {
				const key = `cart-list-item-${index}`

				const {
					id,
					quantity,
					name,
					price,
					selectedAdditionals,
					valorPromocional,
					description
				} = item
				const priceFinalProduct = !valorPromocional ? price : valorPromocional
				const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
					const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 3)
					const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 2)
					const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 1)

					const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.price))) || 0
					const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.price), [])) / selectedAdditionalsAverage.length : 0
					const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
						if (current.quantity > 1) {
							return Number(acc + (current.price * current.quantity))
						}

						return Number(acc + current.price)
					}, []) : 0

					const description = (mappedItem.items && mappedItem.items.map((descriptionMappedItem) => {
						const {
							name
						} = descriptionMappedItem

						return name
					})).filter(filteredItem => filteredItem.trim())

					return {
						price: additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal,
						description: description.join(', ')
					}
				})

				const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current.price), [])) || 0
				const selectedAdditionalsGroupsDescription = (selectedAdditionalsGroups && selectedAdditionalsGroups.length ? selectedAdditionalsGroups.reduce((acc, current) => `${acc ? `${acc}, ` : ''}${current.description}`, '') : '') || 0
				const total = quantity * (priceFinalProduct + (selectedAdditionalsGroupsTotal && selectedAdditionalsGroupsTotal))
				const finalDescription = selectedAdditionals && selectedAdditionals.length ? selectedAdditionalsGroupsDescription : ''

				return (
					<ListItemWrapper key={key}>
						<ProductWrapper>
							<Paragraph className='name'>{name}</Paragraph>
							{finalDescription || description ? <Paragraph className='description'>{finalDescription || description}</Paragraph> : null}
							<Paragraph className='price'>{`R$ ${total && total.toFixed(2).replace('.', ',')}`}</Paragraph>
						</ProductWrapper>
						<ActionsWrapper>
							{isQuantityEditable ?
								<QuantityControl
									id={id}
									name={name}
									price={price}
									quantity={quantity}
									handleQuantity={handleQuantity}
									dataTotal={data}
									URLParameters={URLParameters}
								/> :
								<SimpleQuantity>{quantity}</SimpleQuantity>
							}
						</ActionsWrapper>
					</ListItemWrapper>
				)
			})}
		</ListWrapper> : null}

		{!(data && data.length) ?
			<EmptyBasketContainer>
				<EmptyBasketIcon />
				<h4>Sua cesta está vazia!</h4>
				<p>Adicione seus itens favoritos aqui.</p>
			</EmptyBasketContainer> : null
		}

		{data && data.length ?
			<SummaryWrapper>
				<Paragraph className='subtotal'><Span className='label'>Subtotal:</Span> <Span className='price'>{`R$ ${cartSubtotalTotal.toFixed(2).replace('.', ',')}`}</Span></Paragraph>
				{/* {cartSubtotalTotal >= couponMinimumValue && couponValue && !typeValueModalityDelivery ? <Paragraph className='discount'><Span className='label'>Desconto:</Span> <Span className='price'>{`R$ ${couponValue.toFixed(2).replace('.', ',')}`}</Span></Paragraph> : null} */}
				{cartSubtotalTotal && couponValue ? <Paragraph className='discount'><Span className='label'>Desconto:</Span> <Span className='price'>{`-R$ ${type === 2 ? percentual.toFixed(2).replace('.', ',') : couponValue.toFixed(2).replace('.', ',')}`}</Span></Paragraph> : null}
				<Paragraph className='fee'><Span className='label'>{modalityId === 4 ? 'Taxa de entrega' : 'Taxa de retirada'}:</Span> <Span className='price'>{modalityId === 4 && deliveryFee ? `R$ ${(deliveryFee.toFixed(2).replace('.', ','))}` : 'Grátis'}</Span></Paragraph>
				<Paragraph className='total'><Span className='label'>TOTAL:</Span> <Span className='price total'>{`R$ ${(cartTotal).toFixed(2).replace('.', ',')}`}</Span></Paragraph>
			</SummaryWrapper>
			: null}
	</Wrapper>
}
