import styled from 'styled-components'

import { fadeIn } from '../../components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  position: relative;
  background: #fff;

  ${fadeIn}

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    &#stores-list-loading {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      min-width: calc(100% - 2.5rem);
      max-width: calc(100% - 2.5rem);
      flex: 1;
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  padding: 1.25rem;
  box-sizing: border-box;
`

export const ContentWrapper = styled.div`
  flex: 1;
  flex-direction: column;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1.25rem;
  position: relative;
  box-sizing: border-box;
  max-width: 100%;
  position: relative;

  ${fadeIn}

  > div {
    display: flex;
    flex-direction: column;

    padding: 0;

    &#stores-list-search {
      margin-bottom: 1.875rem;
    }
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0 0 1.25rem;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: uppercase;
`

export const H2 = styled.h2`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
`

export const Paragraph = styled.p`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  padding-bottom: 1.25rem;
`
