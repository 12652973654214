import {
  API_URL,
  APP_ID,
	URL,
	TITLE,
	DESCRIPTION,
  PLATAFORM_ID,
  GOOGLE_API_KEY
} from './config'

import * as api from './infra/api'

import {
  mapAddressByGeolocationData
} from './domain/address'
import {
  mapAgglomerationsList
} from './domain/agglomeration'
import {
  mapCartList
} from './domain/cart'
import {
  mapContactUsData,
  mapContactUsRequest
} from './domain/contact-us'
import {
  mapCouponsData,
  mapCouponData,
  mapCouponRequest
} from './domain/coupon'
import {
  mapHighlightsList
} from './domain/highlight'
import {
  mapOrderData,
  mapOrdersList,
  mapOrderHistoryList
} from './domain/order'
import {
  mapSignUpData,
  mapSignUpRequest,
  mapSignUpFacebookData,
  mapSignUpFacebookRequest,
  mapSignUpGoogleData,
  mapSignUpGoogleRequest,
} from './domain/sign-up'
import {
  mapStoresList,
  mapPickupStoresList,
  mapDeliveryStoresList,
  mapStoresRequest,
  mapAddressValidationRequest,
  mapAddressValidationData,
  mapStoreData
} from './domain/store'
import {
  mapUserAddressesList
} from './domain/user-address'

import makeAddressByGeolocationRepository from './infra/address'
import makeAgglomerationRepository from './infra/agglomeration'
import makeCartRepository from './infra/cart'
import makeCouponRepository from './infra/coupon'
import makeContactUsRepository from './infra/contact-us'
import makeHighlightRepository from './infra/highlight'
import makeOrderRepository from './infra/order'
import makeStoreRepository from './infra/store'
import makeStoreSettingsRepository from './infra/store-settings'
import makeUserAddressRepository from './infra/user-address'
import makeSignUpRepository from './infra/sign-up'

import makeFetchAddressByGeolocation from './app/address/fetchAddressByGeolocation'
import makeFetchAgglomerations from './app/agglomeration/fetchAgglomerations'
import makeFetchCart from './app/cart/fetchCart'
import makeFetchHighlights from './app/highlight/fetchHighlights'
import makeSendContactUs from './app/contact-us/sendContactUs'
import makeAddCoupon from './app/coupon/addCoupon'
import makeFetchCoupons from './app/coupon/fetchCoupons'
import makeFetchOrders from './app/order/fetchOrders'
import makeFetchOrder from './app/order/fetchOrder'
import makeFetchOrderHistory from './app/order/fetchOrderHistory'
import makeFetchStore from './app/store/fetchStore'
import makeFetchStores from './app/store/fetchStores'
import makeFetchStoreSettings from './app/store/fetchStoreSettings'
import makeFetchStoreAddressValidation from './app/store/fetchStoreAddressValidation'
import makeFetchUserAddresses from './app/user-address/fetchUserAddresses'
import makeSignUp from './app/sign-up/signUp'
import makeSignUpFacebook from './app/sign-up/signUpFacebook'
import makeSignUpGoogle from './app/sign-up/signUpGoogle'

/* ApiService */
const apiService = api.apiService({
  localStorage: window.localStorage,
  apiUrl: API_URL,
  appId: APP_ID,
  platformId: PLATAFORM_ID
})

/* ExternalApiService */
const externalApiService = api.externalApiService()

const auth = () => api.withAuthHeader('main')

/* AddressByGeolocation */
const addressByGeolocationRepository = makeAddressByGeolocationRepository({
  externalApiService
})

const fetchAddressByGeolocation = makeFetchAddressByGeolocation({
  addressByGeolocationRepository,
  maps: {
    mapAddressByGeolocationData
  },
  data: {
    key: GOOGLE_API_KEY
  }
})

/* Agglomeration */
const agglomerationRepository = makeAgglomerationRepository({
  apiService
})

const fetchAgglomerations = makeFetchAgglomerations({
  agglomerationRepository,
  auth,
  maps: {
    mapAgglomerationsList
  }
})

/* Cart */
const cartRepository = makeCartRepository({
  apiService
})

const fetchCart = makeFetchCart({
  cartRepository,
  auth,
  maps: {
    mapCartList
  }
})

/* Highlight */
const highlightRepository = makeHighlightRepository({
  apiService,
})

const fetchHighlights = makeFetchHighlights({
  highlightRepository,  
  auth,
  maps: {
    mapHighlightsList
  }
})

/* Order */
const orderRepository = makeOrderRepository({
  apiService
})

const fetchOrders = makeFetchOrders({
  orderRepository,
  auth,
  maps: {
    mapOrdersList
  }
})

const fetchOrder = makeFetchOrder({
  orderRepository,
  auth,
  maps: {
    mapOrderData
  }
})

const fetchOrderHistory = makeFetchOrderHistory({
  orderRepository,
  auth,
  maps: {
    mapOrderHistoryList
  }
})

/* SignUp */
const signUpRepository = makeSignUpRepository({
  apiService
})

const signUp = makeSignUp({
  signUpRepository,
  auth,
  maps: {
    mapSignUpData,
    mapSignUpRequest
  }
})

const signUpFacebook = makeSignUpFacebook({
  signUpRepository,
  auth,
  maps: {
    mapSignUpFacebookData,
    mapSignUpFacebookRequest
  }
})

const signUpGoogle = makeSignUpGoogle({
  signUpRepository,
  auth,
  maps: {
    mapSignUpGoogleData,
    mapSignUpGoogleRequest
  }
})

/* Coupon */
const couponRepository = makeCouponRepository({
  apiService
})

const fetchCoupons = makeFetchCoupons({
  couponRepository,
  auth,
  maps: {
    mapCouponsData
  }
})

const addCoupon = makeAddCoupon({
  couponRepository,
  auth,
  maps: {
    mapCouponData,
    mapCouponRequest
  }
})

/* ContactUs */
const contactUsRepository = makeContactUsRepository({
  apiService
})

const sendContactUs = makeSendContactUs({
  contactUsRepository,
  auth,
  maps: {
    mapContactUsData,
    mapContactUsRequest
  }
})

/* UserAddress */
const userAddressRepository = makeUserAddressRepository({
  apiService
})

const fetchUserAddresses = makeFetchUserAddresses({
  userAddressRepository,
  auth,
  maps: {
    mapUserAddressesList
  }
})

/* Store */
const storeRepository = makeStoreRepository({
  apiService
})

const fetchStores = makeFetchStores({
  storeRepository,
  auth,
  maps: {
    mapPickupStoresList,
    mapDeliveryStoresList,
    mapStoresList,
    mapStoresRequest
  }
})

const fetchStore = makeFetchStore({
  storeRepository,
  auth,
  maps: {
    mapStoreData
  }
})

const fetchStoreAddressValidation = makeFetchStoreAddressValidation({
  storeRepository,
  auth,
  maps: {
    mapAddressValidationData,
    mapAddressValidationRequest
  }
})

/* Store Settings */
const storeSettingsRepository = makeStoreSettingsRepository({
	URL,
	TITLE,
	DESCRIPTION
})

const fetchStoreSettings = makeFetchStoreSettings({
  storeSettingsRepository
})

export {
  fetchAddressByGeolocation,
  fetchAgglomerations,
  fetchCart,
  addCoupon,
  fetchCoupons,
  fetchHighlights,
  fetchOrders,
  fetchOrder,
  fetchOrderHistory,
  fetchStore,
  fetchStores,
  fetchStoreAddressValidation,
  fetchUserAddresses,
	fetchStoreSettings,
  sendContactUs,
  signUp,
  signUpFacebook,
  signUpGoogle
}
