import React from 'react'
import { Tooltip } from '@material-ui/core'
import { connect } from 'react-redux'

import {
  pipe
} from '../../../domain/helpers'
import {
  DefaultImage as Image
} from '../../components'

import NoProduct from '../../assets/no-product-card.png'

import {
  setQuantity
} from '../../../redux/actions/main'

import {
  VerticalProductContainer,
  VerticalProductDescription,
  VerticalProductName,
  VerticalProductPrice,
  VerticalProductPriceContainer,
  VerticalProductPromotionalPrice,
  VerticalProductText,
  IncludeButton,
  ProductImage
} from './styles'

export function CardProductComponent(props) {
  const {
    id,
    handleView,
    handleAdd,
    name,
    description,
    price,
    image,
    valorPromocional,
    categories,
    status,
    setQuantity,
    quantity,
    opened,
    className
  } = props

  function handleQtd(args) {
    setQuantity(args)
  }

  return (
    <VerticalProductContainer className={className} opened={opened} categories={categories}>
      {image ? <ProductImage><Image src={image} alt={name} onClick={handleView} /></ProductImage> : <ProductImage>
        {!image && <Image className='noimg' src={NoProduct} alt={name} onClick={handleView} />}
      </ProductImage>}
      {name && name.length >= 50 ? <Tooltip title={name} placement="top" arrow>
        <VerticalProductName onClick={handleView}>{name.substring(0, 46) + '...'}</VerticalProductName>
      </Tooltip > : <VerticalProductName onClick={handleView}>{name}</VerticalProductName>}
      <VerticalProductDescription onClick={handleView}>{description && description.length > 50 ? description.substring(0, 60) + '...' : description}</VerticalProductDescription>
      <VerticalProductPriceContainer>
        <VerticalProductText>A partir de:</VerticalProductText>
        <VerticalProductPrice className={valorPromocional ? 'promo' : 'price'} ><span>R$</span> {valorPromocional ? valorPromocional : price}</VerticalProductPrice>
        {valorPromocional && <VerticalProductPromotionalPrice>{price}</VerticalProductPromotionalPrice>}
        {status && status === 1 ? (!quantity ? <IncludeButton onClick={handleAdd}>Incluir</IncludeButton> : <IncludeButton onClick={() => {
          if (quantity === 999) {
            return
          }

          handleQtd({
            id,
            quantity: 1
          })
        }}>Incluir</IncludeButton>) :
          <IncludeButton className='disabled' >Incluir</IncludeButton>}
      </VerticalProductPriceContainer>
    </VerticalProductContainer>
  )
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.main.store && state.main.store.selectedCategory
  }
}

const GetConnection = connect(mapStateToProps, {
  setQuantity
})


export function CardProduct(props) {
  const MemoizedComponent = React.memo(CardProductComponent)
  const Component = pipe(
    GetConnection
  )(MemoizedComponent)

  return <Component {...props} />
}
