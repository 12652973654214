import styled from 'styled-components'

export const Wrapper = styled.header`
  display: none;
  background-color: #fff;
  max-width: 100%;
  min-width: 100%;
  
  ${ props => props.itemId ? 'display: none;' : ''}

  @media (min-width: 60rem) {
    display: unset;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    max-height: 5.625rem;
    min-height: 5.625rem;
    height: 5.625rem;
  }
`
