import React from 'react'

import { OrderSummary } from '../../'

import {
  ReceiptSumaryContainer,
  ReceiptSummaryStore,
  ReceiptSummaryContent,
  StoreContainer,
  StoreLabel,
  StoreLogo,
  StoreName,
  StatusBox,
  HeaderBox
} from './styles'

import {
  Strong,
  Span,
  DefaultParagraph as Paragraph
} from '../../../components'

export function Summary({
  storeLogo,
  storeAddress,
  products,
  storeName,
  deliveryAddress,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
  status,
  requestNumber,
  phone,
  whattsapp
}) {
  return (
    <ReceiptSumaryContainer>
      <ReceiptSummaryStore>
        <StoreContainer>
          <StoreLabel>Seu pedido em:</StoreLabel>
          <StoreName>{storeName}</StoreName>
        </StoreContainer>
        <StoreLogo src={storeLogo} />
      </ReceiptSummaryStore>
      <ReceiptSummaryContent>
        {status === 1 && <>
          <HeaderBox>
            <h3>Seu pedido está <span className='status_0'>em andamento!</span></h3>
          </HeaderBox>

          <StatusBox className='status_0'>
            <Paragraph className='only-desktop'>Para retirar, fique atento à mudança de status nesta tela ou à chamada pela senha:</Paragraph>

            <Paragraph><Strong>PEDIDO CONFIRMADO!</Strong></Paragraph>

            <Paragraph className='only-mobile'>Para retirar, fique atento à mudança de status nesta tela ou à chamada pela senha:</Paragraph>

            <Paragraph className='requestNumber'><Span>Senha:</Span> <Strong>{(requestNumber && requestNumber >= 1) ? requestNumber : '…'}</Strong></Paragraph>
          </StatusBox>
        </>}

        {status === 2 && requestNumber && <>
          {/* <H2>
            <div>{userName},</div>
            seu pedido está <Span className='status_1'>pronto!</Span>
          </H2> */}

          <StatusBox className='status_1'>
            <Paragraph className='only-desktop'>Uhuul, chegou a hora! Já pode vir retirar o seu pedido aqui no balcão</Paragraph>

            <Paragraph><Strong>VENHA AQUI RETIRAR!</Strong></Paragraph>

            <Paragraph className='only-mobile'>Hmmm, chegou a hora. Já pode vir retirar o seu pedido aqui no balcão.</Paragraph>

            <Paragraph className='requestNumber'><Span>Senha: </Span><Strong>{requestNumber}</Strong></Paragraph>
          </StatusBox>
        </>}
        <OrderSummary
          storeAddress={storeAddress}
          deliveryAddress={deliveryAddress}
          cardBrand={cardBrand}
          cardNumber={cardNumber}
          cpf={cpf}
          hasCpf={hasCpf}
          products={products}
          buttonName='Recebi meu pedido'
          phone={phone}
          whattsapp={whattsapp}
        />
      </ReceiptSummaryContent>
    </ReceiptSumaryContainer>
  )
}
