import {
  actionTypes
} from '..'

import {
  loading
} from '../loading'

export const setStore = (store) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE,
    payload: store
 })
}

export const setStoreFees = (storeFees) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORE,
    payload: storeFees
 })
}

export const setStores = (stores) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_STORES,
    payload: stores
 })
}

export const setStoresPagination = (pagination) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_PAGINATION,
    payload: pagination
 })
}

export const fetchStores = (callback) => (dispatch, getState, container) => {
  try {
    const latitude = (getState().main.userAddress && getState().main.userAddress.latitude) || (getState().main && getState().main.latitude)
    const longitude = (getState().main.userAddress && getState().main.userAddress.longitude) || (getState().main && getState().main.longitude)
    const modalityId = getState().main.modality && getState().main.modality.id
    const searchText = getState().main.search && getState().main.search.text
    const paginationOffset = getState().main.pagination && getState().main.pagination.offset
    const storesList = (getState().main.stores && getState().main.stores.list) || []

    const data = {
      latitude,
      longitude,
      modalityId,
      searchText,
      paginationOffset
    }

    const actions = {
      setStoresPagination,
      setLoading: loading.setLoading
    }

    const done = () => {
      dispatch(loading.setLoading({item: 'stores-list', delete: true}))
      dispatch(loading.setLoading({item: 'welcome', delete: true}))
      callback && callback()
    }

    return container.fetchStores({
      onSuccess: ({ stores }) => {
        dispatch(setStoresPagination({
          offset: paginationOffset,
          total: (stores && stores.remaining) || 0
        }))

        if (!paginationOffset && stores.list && stores.list.length) {
          dispatch(setStores([...stores.list]))

          done()

          return
        }
        
        if (paginationOffset && stores && stores.list && stores.list.length && storesList && storesList.length) {
          dispatch(setStores([
            ...storesList,
            ...stores.list
          ]))

          done()

          return
        }

        dispatch(setStores([]))

        done()

        return
      },
      onError: () => {
        dispatch(setStoresPagination({
          offset: 0,
          total: 0
        }))

        storesList && storesList.length && dispatch(setStores([...storesList]))

        done()
      },
      onEmpty: () => {
        dispatch(setStoresPagination({
          offset: 0,
          total: 0
        }))

        storesList && storesList.length && dispatch(setStores([...storesList]))

        done()
      },
      dispatch,
      data,
      actions,
      getState
   })
  } catch (e) {
    console.log(e)

    dispatch(setStores([]))
  } finally {
    
  }
}

export const fetchStore = (callback) => (dispatch, getState, container) => {
  try {
    const modalityId = getState().main.modality && getState().main.modality.id
    const latitude = getState().main.latitude
    const longitude = getState().main.longitude
    const accessToken = getState().main.accessToken
    const userAddressId = getState().main.userAddress && getState().main.userAddress.id
    const storeId = (getState().main.store && getState().main.store.id) || (getState().main.receipt && getState().main.receipt.store && getState().main.receipt.store.id)
    const selectedCategoryIndex = (getState().main.store && getState().main.store.selectedCategory && getState().main.store.selectedCategory.index) || 0

    const data = {
      latitude,
      longitude,
      modalityId,
      userAddressId,
      accessToken,
      storeId,
      selectedCategoryIndex
    }

    const actions = {
      setLoading: loading.setLoading
    }

    const done = () => {
      dispatch(loading.setLoading({item: 'store', delete: true}))
      callback && callback()
    }

    return container.fetchStore({
      onSuccess: ({ store }) => {
        if (modalityId === 2 && store && store.id && store.name) {
          const {
            pickupMinimumValue,
            deliveryMinimumValue,
            deliveryFee,
            pickupFee,
            categories,
            ...newMappedResult
          } = store

          const selectedCategory = {
            id: categories[0] && categories[0].id,
            name: categories[0] && categories[0].name,
            index: 0,
            selectedProductsCategories: false,
            openModalCategory: false
          }

          dispatch(setStore({
            ...newMappedResult,
            categories,
            selectedCategory,
            pickupMinimumValue,
            deliveryMinimumValue,
            pickupFee,
            deliveryFee
          }))

          done()

          return
        }

        if (modalityId === 4 && store && store.id && store.name) {
          const {
            categories
          } = store
    
          const selectedCategory = {
            id: categories[0] && categories[0].id,
            name: categories[0] && categories[0].name,
            index: 0,
            selectedProductsCategories: false,
            openModalCategory: false
          }
    
          const {
            pickupMinimumValue,
            deliveryMinimumValue,
            deliveryFee,
            ...newMappedResult
          } = store
    
          dispatch(setStore({
            ...newMappedResult,
            selectedCategory
          }))

          done()

          return
        }

        dispatch(setStore({}))

        done()

        return
      },
      onError: () => {
        dispatch(setStore(null))

        done()
      },
      onEmpty: () => {
        dispatch(setStore(null))

        done()
      },
      dispatch,
      data,
      actions,
      getState
   })
  } catch (e) {
    console.log(e)

    dispatch(setStore({}))
  } finally {
    
  }
}
