import React from 'react'
import { ReactComponent as Arrow } from './../../assets/icon_fechar.svg'

import {
    CartContainer as Container,
    CartContainerHeader,
    CartStoreDescription,
    CartStoreName,
    CartStoreIconContainer,
    CartContainerContent,
    CartContainerIconArrow
} from './styles'

export function CartContainer({ 
    logo, 
    children, 
    name, 
    storeImg, 
    cartClicked,
    openCart,
    setOpenCart
}) {
    return (
        <Container>
            <CartContainerHeader>
                <CartContainerIconArrow onClick={() => {
                    cartClicked()
                    setOpenCart(openCart ? false : true)
                }}>
                    <Arrow />
                </CartContainerIconArrow>
                <CartStoreDescription>
                    Seu pedido em: <CartStoreName>{name}</CartStoreName>
                </CartStoreDescription>
                <CartStoreIconContainer>
                    <img src={storeImg ? storeImg : logo} alt="logo" />
                </CartStoreIconContainer>
            </CartContainerHeader>
            <CartContainerContent>
                {children}
            </CartContainerContent>
        </Container>
    )
}
