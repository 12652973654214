import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  min-width: 100%;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 17px;
    }
  }

  > div {
    min-width: 100%;

    > .overlay {
      min-width: 100%;

      > .receipt-is-not-paid {
        min-width: 100%;
        top: calc(100% - 4.375rem);
      }
    }
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const ContentWrapper = styled.div`
  transition: all .25s ease-in;
  flex: 1;
  min-width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;

  &#receipt-waiting {
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    display: flex;
    align-items: center;
    justify-content: center;

    > p {
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    }

    > img {
      margin-top: 2.5rem;
    }
  }
`

export const CloseButton = styled.button`
  background: none;
  border: 0 none;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  cursor: pointer;
  z-index: 1;

  &.white {
    color: #fff;
  }
`

export const ReceiptPageContainer = styled.div`
    flex: 1;
    box-sizing: border-box;
    width: 100%;
    height: 0;
    background-color: #fff;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: 4rem auto;
    grid-template-areas: 
        "header"
        "main";
`

export const ReceiptHeader = styled.header`
	grid-area: header;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: space-between;

	button {
		max-width: 11rem;
		height: 2.5rem;
		border-radius: 8px;
		font-size: .875rem;
	}
`

export const ReceiptMain = styled.div`
	grid-area: main;
	display: grid;
	grid-template-columns: auto;
	grid-template-rows: auto auto;
	grid-template-areas: 'details'
												'summary';
	width: 100%;
	justify-self: center;
	margin: 2.5rem 0 0;

	@media (min-width: 60rem) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas: 'details summary';
		max-width: 48rem;
		margin-bottom: 2.5rem;
		padding: 0;
	}
`

export const LoadingContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0;
  box-sizing: border-box;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  position: relative;
`

export const MobileHeader = styled.div`

`
export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;

  @media (min-width: 40rem) {
    display: none;
  }
`

export const WhattsAppImg = styled.div`
  width: 4rem;
  height: 4rem;
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  cursor: pointer;
  
  img, svg {
    width: 4rem;
    height: 4rem;
    display: block;
  }
`
