import React, {
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
  logout,
  setUserHistory,
  setModality,
  setReceipt,
  handleGTM
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import {
  cart,
  order
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  containerMode,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Skeleton,
  Navigation,
} from '..'

import { Content } from './content'
import { EmptyContent } from './empty-content'
import { Summary } from './summary'

import {
  LoadingWrapper,
  H2,
  Wrapper,
  H1,
  BackButton
} from './styles'
import { Dialog } from '@material-ui/core'

const Context = React.createContext({})

export function PlacedOrdersPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    fetchOrderHistory,
    loading,
    order,
    orderCpf,
    orderHistory,
    setOrder,
    storeName,
    theme,
    userAddress,
    userHistory,
    userName,
    match,
    setUserHistory,
    storeImg,
    modalityId,
    setModality,
    handleGTM,
    userId,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

  const context = useContext(Context)

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const stateOrderId = order && order.id
  const orderId = match.params && Number(match.params.orderId)
  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [sideMode, setSideMode] = useState({
    isOpen: false,
    mode: containerMode.default
  })
  const [viewCards, setViewCards] = useState(false)
  const [visiblePlaceOrder, setVisiblePlaceOrder] = useState(false)

  const { back } = userHistory
  const { orders = [] } = orderHistory

  const inProgressOrders = orders && orders.filter(filteredItem => filteredItem.inProgress === true)
  const finishedOrders = orders && orders.filter(filteredItem => filteredItem.inProgress === false)

  const inProgressOrdersSection = sections && sections.filter(item => item.id === 'in-progress-orders')[0]
  const finishedOrdersSection = sections && sections.filter(item => item.id === 'finished-orders')[0]

  const inProgressOrdersSelectedSectionTitle = inProgressOrdersSection.title

  const finishedOrdersSelectedSectionName = finishedOrders && finishedOrders.length ? 'main-content' : 'empty-content'
  const finishedOrdersSelectedSectionTitle = finishedOrdersSection.title
  const finishedOrdersSelectedSection = finishedOrdersSection.content.filter(item => item.id === finishedOrdersSelectedSectionName)[0]

  const handleSideModeChange = useCallback((mode) => {
    const _isOpen = sideMode.mode !== mode

    setSideMode({
      isOpen: _isOpen,
      mode: _isOpen ? mode : sideMode.default
    })
  }, [
    sideMode
  ])

  useEffect(() => {
    setTimeout(() => fetchOrderHistory(), 500)
  }, [
    fetchOrderHistory
  ])

  useEffect(() => {
    const url = window.location.href;
    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Meus Pedidos'
    }
    handleGTM(access)

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId,
    handleGTM
  ])

  useEffect(() => {
    if (window.location.hash === '#cart' && sideMode.mode !== containerMode.sideFullContent) {
      handleSideModeChange(containerMode.sideFullContent)

      setOrder({})
    } else if (window.location.hash === '#login' && sideMode.mode !== containerMode.headerFullContent) {
      handleSideModeChange(containerMode.headerFullContent)

      setOrder({})
    } else if (orderId && orderId !== stateOrderId) {
      const selectedOrder = orderHistory && orderHistory.orders && orderHistory.orders.filter(item => item.id === orderId)[0]

      setOrder({
        id: orderId,
        ...selectedOrder
      })

      if (isMobile) {
        setVisiblePlaceOrder(true)

        return
      }

      if (sideMode.mode !== containerMode.headerFullContent) {
        handleSideModeChange(containerMode.headerFullContent)
      }
    }
  }, [
    isMobile,
    handleSideModeChange,
    orderCpf,
    orderId,
    orderHistory,
    setOrder,
    stateOrderId,
    sideMode,
    setSideMode
  ])

  async function handleModality(id) {
    setModality({ id: id })
  }

  function handleCloseDialogPlace() {
    setVisiblePlaceOrder(false)
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleOrder(id) {
    setUserHistory({
      back: 'placed-orders'
    })
    history.push(`/placed-orders/${id}${URLParameters}`)
  }

  const summary = () => {
    return <Summary
      modalityId={order.modalityId}
      inProgress={order.inProgress}
      storeLogo={order.store && order.store.logoUrl}
      storeName={order.store && order.store.name}
      deliveryAddress={order.deliveryPlace}
      pickupAddress={order.store && order.store.address}
      date={order.store && order.store.name}
      whatsapp={order.store && order.store.name}
      phone={order.store && order.store.phone}
      products={{
        list: order.items && order.items.map(item => ({
          product: item.product,
          description: item.description,
          price: item.price,
          quantity: item.quantity
        })),
        summary: {
          total: order.amountPaid,
          deliveryFee: order.serviceFee,
          discount: order.discount,
          subtotal: order.amountPaid - order.serviceFee
        }
      }}
      cpf={orderCpf}
      hasCpf={order.hasCpf ?? true}
      cardBrand={order.cardBrand}
      cardNumber={order.cardNumber}
      handleTrackOrder={() => {
        setOrder({
          id: orderId
        })

        history.push(`/receipt${URLParameters}`)
      }}
    />
  }

  return (
    <Wrapper id={'placed-orders'}>
			<Helmet>
				<title>{`${title} - Meus pedidos`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/placed-orders`} />
			</Helmet>

      {loading.includes('orders-history') && <LoadingWrapper id='placed-orders-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('orders-history') && <PageContainer
        isSideOpen={sideMode.isOpen}
        mode={sideMode.mode}
      >
        <H1>{context.title}</H1>

        <BackButton className='back-button' onClick={() => {
          if (back === 'placed-orders' && !orderId) {
            const back = userHistory.navigation && userHistory.navigation[userHistory.navigation.length - 1]
            history.push(`/${back}${URLParameters}`)

            return
          }

          if (back) {
            history.push(`/${back}${URLParameters}`)

            return
          }

          window.history.back()
        }}>
          <CustomIcon name='AngleLeft' />
        </BackButton>

        <PageContainerHeader itemId={orderId}>
          <MainHeader
            currentRoute='placed-orders'
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            modality={modalityId}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            handleModality={handleModality}
            visibleSignIn={visibleSignIn}
            addressDelivery={userAddress ? userAddress : addressDelivery}
            isCartOpen={sideMode.mode === containerMode.sideFullContent && orderId}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            userClicked={() => {
              history.push('/placed-orders#login')
            }}
            cartClicked={() => {
              history.push('/placed-orders#cart')
            }}
            opened={sideMode.mode === containerMode.sideFullContent}
          />
        </PageContainerHeader>
        <PageContainerMain itemId={orderId} opened={sideMode.isOpen}>
          <>
            <Image src={storeImage} alt="" />

            {inProgressOrders && inProgressOrders.length ? <>
              <H2>{inProgressOrdersSelectedSectionTitle}</H2>
              <Content data={inProgressOrders} orderId={orderId} handleOrder={handleOrder} />
            </> : null}

            {finishedOrders && finishedOrders.length ? <>
              <H2>{finishedOrdersSelectedSectionTitle}</H2>

              <Content data={finishedOrders} orderId={orderId} handleOrder={handleOrder} />
            </> : null}

            {inProgressOrders && finishedOrders && !inProgressOrders.length && !finishedOrders.length ?
              <EmptyContent URLParameters={URLParameters} data={{ ...finishedOrdersSelectedSection }} /> :
              null}
          </>
        </PageContainerMain>
        <PageContainerSide className={orderId ? 'placed-order' : ''}>
          {!isMobile && sideMode.mode === containerMode.sideFullContent && !orderId && window.location.hash === '#cart' &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

          {sideMode.mode === containerMode.headerFullContent && !orderId && window.location.hash === '#login' ?
            <Navigation
              contactUs={() => handleContactUs()}
              userName={userName}
              currentRoute='placed-orders'
            /> : null}

          {(sideMode.mode === containerMode.headerFullContent && orderId) && !isMobile ? summary() : <Dialog
            onClose={() => handleCloseDialogPlace()}
            aria-labelledby="place-order-dialog"
            open={visiblePlaceOrder}
            maxWidth={'sm'}
            fullWidth={true}
            fullScreen={true}
          >
            {summary()}
          </Dialog>
          }
        </PageContainerSide>
      </PageContainer>}
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    addressDelivery: state.main.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.main.loading || [],
    order: state.main.order || {},
    orderCpf: state.main.order && state.main.order.cpf,
    orderHistory: state.main.orderHistory || { orders: [] },
    storeName: (state.main.store && state.main.store.name) || '',
    userAddress: state.main.userAddress || '',
    userAddresses: state.main.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.main.userName,
    storeImg: (state.main.store && state.main.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  fetchOrderHistory: order.fetchOrderHistory,
  logout,
  setOrder: order.setOrder,
  setUserHistory,
  setModality,
  setReceipt,
  handleGTM
})

export const PlacedOrders = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'placed-orders' })
)(PlacedOrdersPage))
