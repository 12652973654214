import React from 'react'

import {
  VerticalProduct,
  HorizontalProduct,
  CardProduct
} from '..'

export function Product(props) {
  const {
    direction,
    index,
    id,
    name,
    description,
    image,
    storeLogo,
    price,
    rawPrice,
    rawPromocional,
    handleQuantity,
    handleView: propsHandleView,
    handleAdd: propsHandleAdd,
    additionals,
    selectedAdditionals,
    selectedCategory,
    quantity,
    valorPromocional,
    status,
    className,
    storeImg,
    categories,
    opened
  } = props

  function handleView(event) {
    event.preventDefault()

    propsHandleView({
      index,
      id,
      name,
      description,
      image,
      price: rawPrice,
      quantity: 1,
      additionals,
      selectedAdditionals,
      category: selectedCategory,
      valorPromocional: rawPromocional
    })
  }

  function handleAdd(event) {
    event.preventDefault()

    propsHandleAdd({
      index,
      id,
      name,
      description,
      image,
      price: rawPrice,
      quantity: 1,
      additionals,
      selectedAdditionals,
      category: selectedCategory,
      valorPromocional: rawPromocional
    })
  }

  const newProps = {
    id,
    name,
    description,
    image,
    price,
    quantity,
    handleQuantity,
    valorPromocional,
    handleView,
    handleAdd,
    storeLogo,
    status,
    className,
    storeImg,
    categories: categories ? categories : false,
    opened
  }

  return <>
    {direction === 'vertical' ? <VerticalProduct {...newProps} /> : null}
    {direction === 'horizontal' ? <HorizontalProduct {...newProps} /> : null}
    {direction === 'card' ? <CardProduct {...newProps} /> : null}
  </>
}
