import styled from 'styled-components'

import {
  fadeIn
} from '../components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  flex: 1;
  position: relative;

  ${fadeIn}

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > div {
    min-width: 100%;

    &.fixed {
      background: #fff;
      padding: 1.25rem;
      position: fixed;
      top: calc(100% - 6.875rem);
      left: 0;
      width: 100%;
      box-shadow: 0 0 .625rem #e1e1e1;
      transform: translate(0, 0);
    }

    &#home-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      max-width: 100%;
      min-width: 100%;

      > div {
        &#home-order-tracking {
          margin-bottom: 1.25rem;
          flex: none;
        }

        &#home-highlights {
          margin-bottom: 3.438rem;
          flex: none;
        }

        &#home-actions-panel {
          flex: 1;
        }

        &#home-modalities {
          margin-bottom: 1.563rem;
          max-height: 1.875rem;
          max-width: 10rem;
          min-width: 10rem;
        }
      }
    }

    &#home-loading {
      justify-content: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      min-width: 100%;
      min-width: 100%;
      flex: 1;
    }

    > div {
      max-width: 100%;
      min-width: 100%;
      flex: 1;
    }
  }
`

export const Title = styled.h1`
  font-family: ${props => props.theme.newFontFamily};
  color: ${ props => props.theme.main.general.primaryColor || '#000'};
  font-size: 1.5rem;
  margin: .625rem 0 .313rem 0;
  padding: 0;
  line-height: 1.5rem;
`

export const AddressButton = styled.button`
  background: none;
  border: 0 none;
  color: ${ props => props.theme.main.general.primaryColor || '#000'};
  font-size: .938rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;
  min-height: 2.5rem;

  > span {
    margin: 0 .313rem;
    text-align: left;
  }

  > div {
    &.icon {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      > svg {
        flex: 1;
      }
    }
  }
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const LogoImage = styled.img`
  width: 3.125rem;
  height: auto;
  max-height: 3.125rem;
  margin-bottom: 1.25rem;
`
export const Span = styled.span``

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LogoWrapper = styled.span``

export const ContentWrapper = styled.div`
  ${fadeIn}
`

export const ExitButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: #FFF;
  font-size: 2rem;
  position: absolute;
  cursor: pointer;
  right: 1.25rem;
  top: 1.25rem;
  z-index: 1;
  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSixthColor) || '#000' } .063rem solid;
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    flex: 1;

    > svg {
      width: 1.25rem;
      margin-top: -4px;
      position: relative;

      > * {
        fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
        stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }
    }
  }
`

export const Div = styled.div``

export const Subtitle = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1.313rem;
  margin: 0;
  margin-bottom: 1.25rem;
  padding: 0;
  min-width: 100%;
  flex: none;
  font-weight: 600;
`

export const AddressIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 7.5rem;
  margin: 2.5rem auto;

  > div {
    min-width: 100%;

    > svg {
      width: 100%;
      height: auto;
    }
  }
`

export const NoAddressTitle = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: .625rem;
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: .875rem;
`
export const ReturnToHome = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 4.375rem;
  min-height: 4.375rem;
  line-height: 4.375rem;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 1.25rem 0 0 0;
  font-size: 1rem;
  box-sizing: border-box;
`
