import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  flex: none;
  overflow: hidden;
  padding: 0 1rem;

  @media (min-width: 60rem) {
    min-width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
    padding-right: 0;
		padding: 0 0 0 2rem;
  }
`

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
  flex: none;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: scroll;
  max-height: 7rem;
  min-height: 7rem;

  @media(min-width: 60rem){
    overflow-x: scroll;
    ::-webkit-scrollbar {
		  height: 4px;
	  }
	  
	  ::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC'}; 
      border-radius: 10px;
	  }
	  
	  ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#000'}; 
      -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#CCC'}; 
	  }
  }

  @media(max-width: 60rem){
    ::-webkit-scrollbar {
      display:none;
    }
  }
 
`

export const ItemWrapper = styled.div`
  flex: 1;
  min-width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem;
  min-height: 5rem;
  max-height: 5rem;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .125rem solid;
  border-radius: .938rem;
  flex-wrap: nowrap;
  overflow: hidden;

  &.ready {
    border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000'} .125rem solid;
  }

  > div {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &.first {
      min-width: 3.125rem;
      max-width: 3.125rem;
      max-height: 3.125rem;
      min-height: 3.125rem;
      margin-right: .313rem;

      > img {
        width: 3.125rem;
        height: auto;
        max-height: 3.125rem;
      }
    }

    &.second {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      > p {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        margin-bottom: .188rem;
        font-size: .75rem;
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;

        > span {
          color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000'};
        }
      }

      > div {
        &.steps {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          min-width: 100%;
          max-width: 100%;
          margin-top: .188rem;

          > div {
            flex: 1;
            margin-right: .125rem;
            min-height: .188rem;
            max-height: .188rem;
            border-radius: 1.5px;
            background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#000'};

            &.active {
              background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.third {
      min-width: 1.25rem;
      max-width: 1.25rem;
      text-align: right;

      > button {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      }
    }
  }

  @media (min-width: 60rem) {
    padding: 1rem;
  }
`

export const H2 = styled.h2`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'} .1rem solid;
  padding-bottom: 1.25rem;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  font-size: 1.125rem;
`
