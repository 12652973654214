
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { List } from './List'

import {
  store
} from '../../state'

import { useDeviceLayout } from '../hooks'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  setCart,
  fetchStoreFees
} from '../../redux/actions/main'
import axios from 'axios'

import {
  Skeleton
} from '../'

import {
  ContentWrapper,
  LoadingWrapper,
  Wrapper,
  MobileHeaderWrapper,
  CloseButton
} from './styles'
import { CustomTitle } from '../components/CustomTitle'
import { CustomIcon } from '../components'

export const Context = React.createContext({})

function StoresListPage(props) {
  const {
    loading,
    modalityId,
    store,
    stores,

    close,

    fetchStore,
    fetchStoreFees,

    setCart,
    setStore
  } = props

  const storesData = stores.list || []
  const isMobile = useDeviceLayout({
    isMobile: true
  })

  async function handleStore(args) {
    if (isMobile) {
      postStore(args)
      postCart({
        products: null
      })

      handleFetchStore()
      close()

      return
    }

    postStore(args)
    postCart({
      products: null
    })

    handleFetchStore()
    close()
  }

  async function postStore(args) {
    await setStore(args)
  }

  async function postCart(args) {
    await setCart(args)
  }

  async function handleFetchStore() {
    await fetchStore()

    if(modalityId === 4){
      const storeFeesRequest = axios.CancelToken
      const storeFeesRequestSource = storeFeesRequest.source()
  
      await fetchStoreFees(() => { }, {
        storeFeesRequestSource
      })
    }
  }

  return <Wrapper>
    {!!loading.includes('stores-list') && <LoadingWrapper id='stores-list-loading'>
      <Skeleton module='stores-list' />
    </LoadingWrapper>}

    {!loading.includes('stores-list') && <><ContentWrapper id='stores-list-content'>
      <MobileHeaderWrapper>
        <CloseButton className='close' onClick={() => {
          close()
        }}>
          <CustomIcon name='Times' />
        </CloseButton>
      </MobileHeaderWrapper>
      <CustomTitle title="Selecione onde você gostaria de fazer o seu pedido:"></CustomTitle>

      <List
        data={storesData}
        store={store}
        handleStore={handleStore}
        modalityId={modalityId}
      />
    </ContentWrapper></>}
  </Wrapper>
}

StoresListPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.shape({})),
  modalityId: PropTypes.number,
  store: PropTypes.shape({}),
  stores: PropTypes.shape({}),

  close: PropTypes.func,
  fetchStore: PropTypes.func,
  fetchStoreFees: PropTypes.func,
  setStore: PropTypes.func,
  setCart: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    loading: state.main.loading || [],
    modalityId: state.main.modality && state.main.modality.id,
    store: state.main.store || {},
    stores: state.main.stores || []
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStore: store.fetchStore,
  fetchStoreFees,
  setCart,
  setStore: store.setStore
})

export const StoresList = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'stores-list' })
)(StoresListPage))
