import React from 'react'

import {
  CustomLoading,
  Div
} from '../../components'

import {
  Wrapper,
  H3,
  Button,
  ButtonLabel
} from './styles'

import {
  SelectedItems,
  Coupon
} from './'

export function Details(props) {
  const {
    userCard,
    addressDelivery,
    coupon,
    setCoupon,
    handleAddCoupon,
    handleRemoveCoupon,
    handleViewCoupons,
    handleHasCpf,
    handleFinish,
    hasCpf,
    cpf,
    loading,
    cartCoupon,
    theme,
    modalityId,
    storeAddress,
    scheduling,
    setVisibleScheduling,
    userScheduling,
    URLParameters
  } = props

  return (
    <Wrapper>
      <H3>Confira os detalhes do seu pedido!</H3>

      <Div>
        <SelectedItems
          cpf={cpf}
          hasCpf={hasCpf}
          handleHasCpf={handleHasCpf}
          userCard={userCard}
          addressDelivery={addressDelivery}
          theme={theme}
          modalityId={modalityId}
          storeAddress={storeAddress}
          scheduling={scheduling}
          setVisibleScheduling={setVisibleScheduling}
          userScheduling={userScheduling}
          URLParameters={URLParameters}
        />

        <Coupon
          cartCoupon={cartCoupon}
          coupon={coupon}
          setCoupon={setCoupon}
          handleAddCoupon={handleAddCoupon}
          handleRemoveCoupon={handleRemoveCoupon}
          handleViewCoupons={handleViewCoupons}
        />
      </Div>

      <Button id='finish-purchase-button' onClick={() => {
        handleFinish()
      }}>
        <ButtonLabel >{loading.includes('payment') ?
          <CustomLoading
            color={'#fff'}
            type={'spin'}
            id='default-loading'
            height={30}
            width={30} /> :
          'Finalizar Compra'}</ButtonLabel>
      </Button>
    </Wrapper>
  )
}
