import { actionTypes } from '../actionTypes'

import { setUserCpf } from '../../redux/actions/main'

import {
  loading
} from '../loading'

export const postSignUp = (callback) => (dispatch, getState, container) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const successCallback = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const signUp = getState().main && getState().main.signUp

    const data = {signUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUp({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          errorCallback && errorCallback()

          return
        }

        if (!!signUp.success) {
          successCallback && successCallback()

          const {
            CPF: cpf
          } = signUp

          dispatch({
            type: actionTypes.SET_SIGN_UP,
            payload: {
              ...signUp,
              success: true,
              confirmationType: 'sms',
              resend: false,
              userData: {
                ...signUp
              }
            }
          })
    
          dispatch({
            type: actionTypes.SET_USER_NAME,
            payload: signUp.name
          })
    
          dispatch(setUserCpf(cpf))

          return
        }
      },
      onError: () => {
        errorCallback && errorCallback()
      },
      onEmpty: () => {
        errorCallback && errorCallback()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    errorCallback && errorCallback()
  } finally {
    
  }
}

export const postSignUpFacebook = (callback) => (dispatch, getState, container) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const successCallback = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const signUp = getState().main && getState().main.signUp

    const data = {signUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUpFacebook({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          errorCallback && errorCallback()

          return
        }

        if (!!signUp.success) {
          successCallback && successCallback()

          const {
            CPF: cpf
          } = signUp

          dispatch({
            type: actionTypes.SET_SIGN_UP,
            payload: {
              ...signUp,
              ddi: 55,
              success: true,
              confirmationType: 'sms',
              resend: false,
              userData: {
                ...signUp
              }
            }
          })
    
          dispatch({
            type: actionTypes.SET_USER_NAME,
            payload: signUp.name
          })
    
          dispatch(setUserCpf(cpf))

          return
        }
      },
      onError: () => {
        errorCallback && errorCallback()
      },
      onEmpty: () => {
        errorCallback && errorCallback()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    errorCallback && errorCallback()
  } finally {
    
  }
}

export const postSignUpGoogle = (callback) => (dispatch, getState, container) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : () => {}
  const successCallback = callback && callback[0] && typeof callback[1] === 'function' ? callback[1] : () => {}

  try {
    const signUp = getState().main && getState().main.signUp

    const data = {signUp}
    const actions = {
      setLoading: loading.setLoading
    }

    return container.signUpGoogle({
      onSuccess: ({ signUp }) => {
        if (!signUp.success) {
          errorCallback && errorCallback()

          return
        }

        if (!!signUp.success) {
          successCallback && successCallback()

          const {
            CPF: cpf
          } = signUp

          dispatch({
            type: actionTypes.SET_SIGN_UP,
            payload: {
              ...signUp,
              ddi: 55,
              success: true,
              confirmationType: 'sms',
              resend: false,
              userData: {
                ...signUp
              }
            }
          })
    
          dispatch({
            type: actionTypes.SET_USER_NAME,
            payload: signUp.name
          })
    
          dispatch(setUserCpf(cpf))

          return
        }
      },
      onError: () => {
        errorCallback && errorCallback()
      },
      onEmpty: () => {
        errorCallback && errorCallback()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    errorCallback && errorCallback()
  } finally {
    
  }
}
