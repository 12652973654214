import styled from 'styled-components'

export const Image = styled.img`
  border-radius: .625rem;
`

export const Wrapper = styled.div`
  display: flex; 
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#e2e2e2'} .063rem solid;
  min-width: 100%;
  max-width: 100%;

  &:last-child {
    border-bottom: 0 none;
  }

  * {
    pointer-events: none;
  }

  > .text {
    min-width: 55%;
    max-width: 55%;
  }

  > .full-text {
    min-width: 100%;
    max-width: 100%;
  }

  > .image {
    flex: 0;
    max-height: 6.25rem;
    min-height: 6.25rem;
    max-width: 6.25rem;
    min-width: 6.25rem;
    border-radius: .625rem;
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: touch;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      min-height: auto;
      max-height: unset;
      min-width: 100%;
      max-width: 100%;
      flex: 1;
    }
  }
`

export const H2 = styled.h2`
  margin: 0;
  margin-bottom: .625rem;
  padding: 0;
  font-size: .875rem;
  line-height: .875rem;
  font-weight: 600;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
`

export const Div = styled.div``

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  font-size: .75rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};

  &.description {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  }

  &.price {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
  }

  &.promo {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: 1rem;
  }

  > span{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    margin-bottom: 0;
    font-size: .75rem;
    text-decoration: line-through;
    margin-left: .313rem;
  }
`

export const Span = styled.span``

export const VerticalProductContainer = styled.div`
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  border-radius: .5rem;
  width: 100%;
  cursor: pointer;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundProductCard) || '#000'};
  box-shadow: 2px 2px 9px 1px rgba(0, 0, 0, .1);
  max-width: 15rem;
  min-width: 15rem;
  max-height: 20rem;
  min-height: 20rem;
  position: relative;
  text-align: center;

  @media (min-width: 60rem) {
    ${props => {
      if (props.opened) {
        return `max-width: calc(50% - .85rem);
        min-width: calc(50% - .85rem);`
      } else {
        return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`
      }
    }}
  }@media (min-width: 70rem) {
    ${props => {
      if (props.opened) {
        return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`
      } else {
        return `max-width: calc(33% - .85rem);
        min-width: calc(33% - .85rem);`
      }
    }}
  }

  &:hover {
    background-color: #f7f7f7;
  }

  * {
    box-sizing: border-box;
  }
`

export const ProductImage = styled.div`
  min-height: 10.5rem;
  max-height: 10.5rem;
  min-width: 100%;
  max-width: 100%;
  position: relative;

  img{
    min-height: 10.5rem;
    max-height: 10.5rem;
    max-width: 100%;
    min-width: auto;

    &.noimg{
      max-width: 50%;
      max-height: unset;
      min-height: auto;
      min-width: 50%;
      height: auto;
      box-sizing: unset;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }

  @media (min-width: 60rem) {
    min-height: 10.5rem;
    max-height: 10.5rem;
    min-width: 100%;
    max-width: 100%;
  }
`

export const VerticalProductName = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin-bottom: .5rem;
  min-height: 1rem;
  max-height: 1rem;
  line-height: 1rem;
  min-width: 100%;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 100%;
  max-width: 100%;

  @media (min-width: 60rem) {
    margin-bottom: .25rem;
    font-size: 1.3rem;
    min-height: 1.3rem;
    max-height: 1.3rem;
    line-height: 1.3rem;
  }
`

export const VerticalProductDescription = styled.p`
  margin: 0;
  font-family: Nunito;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  max-height: 4rem;

  @media (min-width: 60rem) {
    font-size: .9rem;
  }
`

export const VerticalProductPriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: calc(100% - 2rem);
  position: absolute;
  left: 1rem;
  bottom: 1rem;
  
  @media (min-width: 60rem) {
    margin: auto 0 0 0;
  }
`

export const VerticalProductText = styled.span`
  display: flex;
  align-items: start;
  font-size: .6rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  position: absolute;
  left: 0;
  top: -.4rem;
  
  @media (min-width: 60rem) {
    width:100%;
  }
`

export const VerticalProductPrice = styled.span`
  font-weight: 700;
  font-size: 1.25rem;
  display: flex;
  align-items: center;

  &.promo {
    color: #19BF2B;
    font-size: 1rem;
  }

  &.price{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  }

  > span {
    font-size: .8rem;
    margin-right: .4rem;
  }
`

export const VerticalProductPromotionalPrice = styled.span`
  font-weight: 600;
  font-size: .8rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC'};
  margin: 0 0 0 .5rem;
  text-decoration: line-through;
`

export const IncludeButton = styled.button`
  padding: .3rem 2rem;
  border-radius: 8px;
  font-weight: 500;
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.secondaryColor) || '#333'};
  border: 1px solid #d9d8d9;
  cursor: pointer;
  font-size: 1rem;

  &.disabled{
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.greyMedium) || '#333'};
  }
`
