import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { ReactComponent as FavoriteAddressIcon } from '../../assets/icons/icon_favorite_address.svg'
import { ReactComponent as MoreOptionsIcon } from '../../assets/icons/icon_more_options.svg'
import { ReactComponent as EditIcon } from '../../assets/icons/icon_edit.svg'
import { ReactComponent as DeleteIcon } from '../../assets/icons/icon_delete.svg'
import { ReactComponent as HomeCategoryIcon } from '../../assets/icons/icon_address_home_category.svg'
import { ReactComponent as WorkCategoryIcon } from '../../assets/icons/icon_address_work_category.svg'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  useCustomLayout, useDeviceLayout
} from '../../hooks'

import {
  CustomIcon,
  CustomLoading
} from '../../components'

import {
  deleteAddress,
  fetchUserAddresses,
  handleGTM,
  setAddress,
  setCEP,
  setNotification,
  setUserAddress,
  setUserHistory,
  setCityLocation,
  fetchStoreFees,
} from '../../../redux/actions/main'

import {
  Wrapper,
  ContentWrapper,
  LoadingContainer,
} from './styles'

import {
  AddressDialogContent,
  AddressList,
  AddressCard,
  AddressCardSideIcon,
  AddressCardContent,
  AddressCardActions,
  AddressAdditional,
  AddressState,
  AddressValue,
  NumberAddress,
  StreetAddress,
  AddressCardEditionActions,
  MobileHeaderWrapper,
  CloseButton
} from './../../address/styles'

import { CustomTitle } from './../../components/CustomTitle'
import { NewAddress } from '../NewAddress'
import { CustomModalNotification } from '../../components/CustomModalNotification'
import {
  userAddress,
  loading,
  store
} from '../../../state'
import history from '../../../history'
import axios from 'axios'

export const Context = React.createContext({})

export function AddressesPage(props) {
  const {
    loading,
    setLoading,
    device,
    userAddress,
    userAddresses,
    deleteAddress,
    fetchUserAddresses,
    setAddress,
    setCEP,
    setUserAddress,
    setCityLocation,
    setUserHistory,
    handleCloseDialogAddress,
    removeUserAddressesItem,
    pathName,
    fetchStore,
    fetchStoreFees,
    modalityId,
		currentRoute,
    userId,
    URLParameters
  } = props

  const [selectedItem, setSelectedItem] = useState('')
  const [deleteAddressModal, setDeleteAddressModal] = useState(false)
  const [deleteAddressModalEgual, setDeleteAddressModalEqual] = useState(false)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  function handleCloseDialogDeleteAddress() {
    setDeleteAddressModal(false)
  }

  function handleCloseDialogDeleteEqual() {
    setDeleteAddressModalEqual(false)
  }

  const handleAddressEdit = ({
    id,
    number,
    address,
    neighborhood,
    complement,
    reference,
    cep,
    city,
    state,
    category
  }) => {
    setUserHistory({
      back: 'addresses',
      nex: 'home'
    })

    setAddress(null)
    setAddress({
      id,
      main: address,
      number,
      neighborhood,
      complement,
      reference,
      cep,
      city,
      state,
      type: category
    })
  }

  const handleAddressDelete = ({
    id
  }) => {
    setLoading('addresses')
    setAddress({
      id
    })

    setSelectedItem(null)

    deleteAddress([() => {
      window.alert('Ocorreu um erro ao excluir o endereço selecionado.')

      fetchUserAddresses()
      setLoading('')
    }, ({ id }) => {
      removeUserAddressesItem({ id })
      fetchUserAddresses()
    }])
  }

  const postCEP = useCallback((args) => {
    setCEP(args)
  }, [
    setCEP
  ])

  const fetchCategoryAddress = (category) => {
    if (category === 1) {
      return <WorkCategoryIcon />
    } else if (category === 2) {
      return <FavoriteAddressIcon />
    } else {
      return <HomeCategoryIcon />
    }
  }

  async function handleStore(id) {
    await fetchStore(() => {
      setLoading({ item: 'store', delete: true })
    })

    if (id === 4) {
      getStoreFees()
    }
  }

  async function getStoreFees() {
    const storeFeesRequest = axios.CancelToken
    const storeFeesRequestSource = storeFeesRequest.source()

    await fetchStoreFees(() => { }, {
      storeFeesRequestSource
    })
  }

  useCustomLayout({
    device,
    backgroundColor: '#fff'
  })

  useEffect(() => {
    const url = window.location.href;
    if (isMobile) {
      const access = {
        event: 'PageView',
        page_path: url,
        page_title: 'Endereços'
      }
      handleGTM(access)
    }

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId,
    isMobile,
  ])

  useEffect(() => {
    setTimeout(() => {
      fetchUserAddresses()
    }, 1000)

    return () => { }
  }, [
    fetchUserAddresses
  ])

  return <Wrapper>
    <ContentWrapper id='addresses-content'>
      <MobileHeaderWrapper>
        <CloseButton className='close' onClick={() => {
          if (handleCloseDialogAddress) {
            handleCloseDialogAddress()

            return
          }

          if (currentRoute) {
            history.push(`/${currentRoute}${URLParameters}`)
          } else {
            history.push(`/store${URLParameters}`)
          }
        }}>
          <CustomIcon name='Times' />
        </CloseButton>
      </MobileHeaderWrapper>
      {loading.includes('user-addresses') &&
        <LoadingContainer>
          <CustomLoading
            type={'spin'}
            id='default-loading'
            height={40}
            width={40}
          />
        </LoadingContainer>}
      {!loading.includes('user-addresses') && <AddressDialogContent>
        {userAddresses.length > 0 && <CustomTitle title="Onde você gostaria de receber o seu pedido?"></CustomTitle>}
        {userAddresses && userAddresses.length > 0 ? <>
          <AddressList>
            {userAddresses && userAddresses.map((data, index) => {
              const key = `addresses-list-item-${index}`

              const {
                id,
                address,
                number,
                neighborhood,
                complement,
                reference,
                cep,
                city,
                state,
                category,
                latitude,
                longitude
              } = data

              return (
                <>
                  <AddressCard key={key} id={key} className={`list-item ${userAddress && userAddress.id === id ? 'active-address' : ''}`}>
                    <AddressCardSideIcon onClick={async () => {
                      setUserAddress({
                        main: `${address}`,
                        number,
                        neighborhood: neighborhood,
                        verified: true,
                        city,
                        latitude,
                        longitude,
                        id
                      })

                      setAddress({
                        ...address,
                        allowed: false
                      })

                      getStoreFees()

                      if (handleCloseDialogAddress) {
                        handleCloseDialogAddress()
                      }

                      if (pathName && pathName === '/no-store') {
                        history.push(`/store${URLParameters}`)
                      }

                      if (!pathName) {
                        handleStore(modalityId)
                      }
                    }}>
                      {fetchCategoryAddress(category)}
                    </AddressCardSideIcon>
                    <AddressCardContent onClick={() => {
                      setUserAddress({
                        main: `${address}`,
                        number,
                        neighborhood: neighborhood,
                        verified: true,
                        city,
                        latitude,
                        longitude,
                        id
                      })

                      setCityLocation(city)
                      getStoreFees()

                      if (handleCloseDialogAddress) {
                        handleCloseDialogAddress()
                      }

                      if (pathName && pathName === '/no-store') {
                        history.push(`/store${URLParameters}`)
                      }

                      if (!pathName) {
                        handleStore(modalityId)
                      }
                    }}>
                      <AddressValue>
                        <StreetAddress>{address},</StreetAddress>
                        <NumberAddress>{number}</NumberAddress>
                      </AddressValue>
                      <AddressState>{neighborhood ? `${neighborhood} - ` : ''} {city ? `${city} - ` : ''} {state}</AddressState>
                      <AddressAdditional>{complement ? `${complement} - ` : ''} {reference ? `${reference} - ` : ''} </AddressAdditional>
                    </AddressCardContent>
                    <AddressCardActions>
                      {!selectedItem || selectedItem !== key ?
                        <MoreOptionsIcon onClick={() => {
                          setSelectedItem(key)
                        }} /> :
                        <AddressCardEditionActions>
                          <EditIcon onClick={() => {
                            handleAddressEdit({
                              id,
                              address,
                              number,
                              neighborhood,
                              complement,
                              reference,
                              cep,
                              city,
                              state,
                              category
                            })
                          }} />
                          <DeleteIcon onClick={() => {
                            postCEP('')
                            if (userAddress.id === id) {
                              setDeleteAddressModalEqual(true)

                              return
                            }

                            setDeleteAddressModal(true)
                          }} />
                          <CustomModalNotification
                            openModal={deleteAddressModalEgual}
                            content={'Você não pode excluir o endereço que está usando no momento!'}
                            handleCloseModal={handleCloseDialogDeleteEqual}
                            size={'xs'}
                            fullWidth={true}
                            actions={[{
                              label: 'Entendi',
                              classes: 'primary',
                              handleClick: () => {
                                handleCloseDialogDeleteEqual(false)
                              }
                            }]}
                          />
                          <CustomModalNotification
                            openModal={deleteAddressModal}
                            content={'Tem certeza que deseja excluir esse endereço?'}
                            handleCloseModal={handleCloseDialogDeleteAddress}
                            size={'xs'}
                            fullWidth={true}
                            actions={[{
                              label: 'Sim',
                              classes: 'primary',
                              handleClick: () => {
                                handleAddressDelete({ id })
                              }
                            }, {
                              label: 'Não',
                              classes: 'secondary',
                              handleClick: () => {
                                handleCloseDialogDeleteAddress(false)
                              }
                            }]}
                          />
                        </AddressCardEditionActions>
                      }
                    </AddressCardActions>
                  </AddressCard>
                </>
              )
            })}
          </AddressList> </> : null}
      </AddressDialogContent>}

      {!loading.includes('user-addresses') && <>
        {userAddress && userAddress.id ? <CustomTitle title="Gostaria de adicionar outro endereço?" /> : null}
        <NewAddress
          pathName={pathName}
          handleCloseDialogAddress={handleCloseDialogAddress}
          modalityId={modalityId}
          handleStore={handleStore}
          getStoreFees={getStoreFees}
        ></NewAddress>
      </>}
    </ContentWrapper>
  </Wrapper>
}

AddressesPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    userAddresses: state.main.userAddresses || [],
    userAddress: state.main.userAddress,
    userHistory: (state.main.userHistory) || {},
    cep: state.main.cep,
    device: (state.main.params && state.main.params.device) || '',
    loading: state.main.loading || [],
    modalityId: state.main.modality && state.main.modality.id,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStore: store.fetchStore,
  fetchStoreFees,
  setLoading: loading.setLoading,
  removeUserAddressesItem: userAddress.removeUserAddressesItem,
  deleteAddress,
  fetchUserAddresses,
  handleGTM,
  setAddress,
  setCEP,
  setNotification,
  setUserHistory,
  setUserAddress,
  setCityLocation
})

export const Addresses = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'addresses' })
)(AddressesPage))
