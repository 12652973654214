export default ({
  externalApiService
}) => {
  const strategy = {
    addressByGeolocation: async ({
      actions,
      data,
      dispatch,
      getState,
      maps
    }) => {
      const latitude = getState().main && getState().main.latitude
      const longitude = getState().main && getState().main.longitude

      const {
        setLoading
      } = actions

      const {
        loading,
        key
      } = data

      const {
        mapAddressByGeolocationData
      } = maps

      if (loading && loading.includes('address-by-geolocation')) {
        return false
      }

      dispatch(setLoading('address-by-geolocation'))
  
      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${key}`
      const result = await externalApiService.get(url)
      const mappedResult = mapAddressByGeolocationData(result.data)
  
      return mappedResult
    }
  }

  return {
    fetchAddressByGeolocation: async (payload) => {
      return await strategy['addressByGeolocation'](payload)
    }
  }
}
