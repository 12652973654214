import React from 'react'

import { ReactComponent as CouponIcon } from '../../assets/icons/icon_coupon.svg'
import CheckedIcon from '../../assets/icon_checked_success.png'
import { ReactComponent as IconClose } from '../../assets/icons/icon_close.svg'

import {
  Button,
  InputRounded,
  DefaultImage as Image
} from '../../components'

import {
  Wrapper,
  CouponForm,
  CouponTitle,
  CouponInputContainer,
  ShowCouponsLink,
  Notification
} from './styles'

export function Coupon(props) {
  const {
    handleAddCoupon,
    handleRemoveCoupon,
    handleViewCoupons,
    coupon,
    setCoupon,
    cartCoupon
  } = props

  return <Wrapper>
    {!cartCoupon && !cartCoupon.value && <CouponTitle>Adicionar cupom de desconto?</CouponTitle>}

    {cartCoupon && cartCoupon.value ? <>
      <CouponTitle>Cupom de desconto aplicado</CouponTitle>

      <Notification>Cupom cadastrado com sucesso! <Image src={CheckedIcon} alt="" /></Notification>
    </> : <>
      {cartCoupon.error && <Notification>Cupom inserido inválido! <IconClose /></Notification>}
    </>}
    <CouponForm onSubmit={(event) => { event.preventDefault() }}>
      <CouponInputContainer>
        <InputRounded placeholder='Digite o código do cupom' value={coupon} onChange={(event) => {
          setCoupon(event.target.value)
        }} />
        <CouponIcon />
      </CouponInputContainer>
      {cartCoupon ? <Button onClick={() => {
        handleRemoveCoupon()
      }}>Remover</Button> :
        <Button onClick={() => {
          handleAddCoupon()
        }}>Aplicar</Button>}
    </CouponForm>
    <ShowCouponsLink onClick={() => {
      handleViewCoupons()
    }}>Ver meus cupons</ShowCouponsLink>
  </Wrapper>
}
