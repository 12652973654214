export default ({
  apiService
}) => {
  const strategy = {
    request: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
   }) => {
      const {
        setLoading
      } = actions

      const {
        modalityId,
        latitude,
        longitude,
        loading
      } = data

      const {
        mapAgglomerationsList
      } = maps

      if (loading && loading.length && loading.indexOf('agglomerations') > -1) {
        return false
      }

      dispatch(setLoading('agglomerations'))

      const url = `aglomeracao/listar?modalidadeRetiradaId=${ modalityId }&latitude=${latitude}&longitude=${longitude}`
      const result = await apiService.get(url, auth)
      const mappedResult = mapAgglomerationsList(result.data)

      return mappedResult
    }
  }

  return {
    fetchAgglomerations: async (payload) => {
      return await strategy['request'](payload)
    }
  }
}
