import {
  useEffect
} from 'react'

import { CustomLayoutDevices } from '../../domain/custom-layout'

import { GetQueryString } from '../../domain/helpers/QueryString'

export function useCustomLayout({
  device,
  backgroundColor,
  cartContentMotion,
  section = '',
  styles = {}
}) {
  useEffect(() => {
    const qs = GetQueryString()

    const {
      dispositivo: qsDevice = 'default',
    } = qs

    const currentDevice = device || qsDevice

    function getiPhoneLayout(device) {
      if (device && CustomLayoutDevices.includes(device)) {
        document.querySelector('#root').classList.add('iphone')
      }

      if (!device || !CustomLayoutDevices.includes(device)) {
        document.querySelector('#root').classList.remove('iphone')
      }
    }

    function getiPhoneStoreLayout(device) {
      if (!cartContentMotion && CustomLayoutDevices.includes(device)) {
        document.querySelector('#root').style.background = '#fff'
        document.querySelector('#root > #store').style.background = '#fff'
      }

      if (!!cartContentMotion && CustomLayoutDevices.includes(device)) {
        const {
          primaryColor
        } = styles

        document.querySelector('#root').style.background = primaryColor
        document.querySelector('#root > #store').style.background = primaryColor
      }
    }

    if (section === 'store') {
      getiPhoneStoreLayout(currentDevice)
    }

    if (!section) {
      getiPhoneLayout(currentDevice)
    }

    if (backgroundColor) {
      document.querySelector('html').style.background = backgroundColor
      document.querySelector('body').style.background = backgroundColor
      document.querySelector('#root').style.background = backgroundColor
    }

    return () => {}
  }, [
    backgroundColor,
    cartContentMotion,
    section,
    styles,
    device
  ])
}

export function useDeviceLayout({
  isMobile
}){
  return (
    isMobile && window.screen.width < 960 ? true : false
  )
}
