import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'

import history from '../../../history'

import {
  pipe,
  GetContent,
  maskCep
} from '../../../domain/helpers'

import IpirangaLoadingGIF from '../../assets/ipiranga-loading.gif'

import { store } from '../../../state'

import {
  Notification
} from '../../components'

import {
  fetchAddressByCEP,
  fetchGeolocation,
  handleGTM,
  setNotification,
  setUserAddress,
  setUserHistory,
  patchAddress,
  postAddress,
  setAddress,
  setAddressByCEP,
  setCEP,
  setModality
} from '../../../redux/actions/main'

//import { PWAAnalytics } from '../../../domain/helpers/ipiranga/PWA'

import {
  Wrapper,
  ContentWrapper,
  LoadingWrapper,
  LoadingImage,
  PostalWarning
} from './styles'

import {
  AddAdressForm,
  FormFieldAddress,
  FormFieldAddressAdditional,
  FormFieldAddressNumber,
  FormFieldCity,
  FormFieldDistrict,
  FormFieldReference,
  FormFieldState,
  SaveAddressLabel,
  AddressCategory,
  AddressCategoryList,
  CEPContainer,
  Button,
  SubmitButton,
} from './../../address/styles'

import { InputRounded } from '../../components/Input'

import { ReactComponent as FavoriteCategoryIcon } from '../../assets/icons/icon_address_favorite_category.svg'
import { ReactComponent as HomeCategoryIcon } from '../../assets/icons/icon_address_home_category.svg'
import { ReactComponent as WorkCategoryIcon } from '../../assets/icons/icon_address_work_category.svg'
import { useDeviceLayout } from '../../hooks'

const addressCategoryTypes = [
  { id: 0, label: 'Casa', icon: HomeCategoryIcon },
  { id: 1, label: 'Trabalho', icon: WorkCategoryIcon },
  { id: 2, label: 'Favorito', icon: FavoriteCategoryIcon }
]

const Context = React.createContext({})

const requiredFields = [{
  name: 'main',
  notification: {
    message: 'O endereço é obrigatório'
  },
}, {
  name: 'number',
  notification: {
    message: 'O número é obrigatório'
  },
}, {
  name: 'cep',
  notification: {
    message: 'O CEP é obrigatório'
  },
}, {
  name: 'neighborhood',
  notification: {
    message: 'O bairro é obrigatório'
  },
}, {
  name: 'city',
  notification: {
    message: 'A cidade é obrigatória'
  },
}, {
  name: 'state',
  notification: {
    message: 'O estado é obrigatório'
  }
}
]

function NewAddressPage(props) {
  const {
    addressByCEP,
    address,
    fetchGeolocation,
    setAddress,
    setUserAddress,
    setUserHistory,
    setNotification,
    setAddressByCEP,
    fetchAddressByCEP,
    setCEP,
    patchAddress,
    postAddress,
    userAddress,
    handleGTM,
    notification,
    cep: deliveryCEP,
    handleCloseDialogAddress,
    accessToken,
    setModality,
    userHistory,
    pathName,
    handleStore,
    modalityId,
    userId,
    getStoreFees,
    URLParameters
  } = props

  const {
    main,
    number,
    neighborhood,
    city,
    state,
    complement,
    reference,
		type: changedCategory,
		pristine
  } = addressByCEP

  const {
    main: editedMain,
    number: editedNumber,
    //cep: editedCEP,
    neighborhood: editedNeighborhood,
    city: editedCity,
    state: editedState,
    complement: editedComplement,
    reference: editedReference,
    type: editedCategory
  } = address

  const [edit, setEdit] = useState(((address.id || address.main) ? true : false))
  const [category, setCategory] = useState(((!pristine ? changedCategory : (editedCategory || ''))))
  const [warningPostalCode, setWarningPostalCode] = useState(null)

  const displayedCEP = deliveryCEP
  const formattedCEP = `${displayedCEP ? maskCep(displayedCEP.replace(/-/g, '')) : ''}`

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useEffect(() => {
    const url = window.location.href;
    if (isMobile) {
      const access = {
        event: 'PageView',
        page_path: url,
        page_title: 'Novo Endereço'
      }
      handleGTM(access)
    }

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    isMobile,
    userId, 
    handleGTM
  ])

  const postCEP = useCallback((args) => {
    setCEP(args)
  }, [
    setCEP
  ])

  const handleCEPChange = (event) => {
    const {
      value
    } = event.target
    postCEP(maskCep(value.replace(/-/g, '')))
  }

  function handlePostalCodeClick() {
    if (formattedCEP && formattedCEP.length < 9) {
      setWarningPostalCode({
        type: 'warning',
        message: 'O CEP digitado é inválido! Tente outro!'
      })

      return
    }

    fetchAddressByCEP()
    setWarningPostalCode(null)
  }

  function handleInput(event) {
    const {
      name,
      value
    } = event.target

    const newValue = {
      [name]: value
    }

    setEdit(true)
    setAddress({
      ...addressByCEP,
      ...address,
      ...newValue
    })
  }

  async function handleSave() {
    const invalidFields = { value: [] }

    requiredFields && requiredFields.length && requiredFields.forEach((fieldsItem) => {
      const {
        name,
        notification
      } = fieldsItem

      if (!address[name] && name !== 'cep') {
        setNotification({
          ...notification,
          type: `new-address-${name}-warning`
        })

        Object.assign(invalidFields, {
          value: [
            ...invalidFields.value,
            fieldsItem[name]
          ]
        })
      }

      if (!address[name] && name === 'cep') {
        setAddress({
          ...address,
          cep: `${formattedCEP.substr(0, 5)}-${formattedCEP.substr(5, 4)}`
        })
      }
    })

    if (invalidFields.value && invalidFields.value.length) {
      return false
    }

    setNotification(null)

    // const newAddressData = {
    //   action: 'analytics',
    //   data: {
    //     eventType: 'add_new_address',
    //     parameters: {
    //       address: address.main,
    //       number: address.number,
    //       complement: address.complement,
    //       cep: address.cep || formattedCEP,
    //       neighborhood: address.neighborhood,
    //       city: address.city,
    //       state: address.state,
    //       reference: address.reference
    //     }
    //   }
    // }

    //handleGTM(newAddressData)

    //PWAAnalytics(newAddressData)

    if (!address.id && !accessToken && address.main && address.number && address.neighborhood && address.cep && address.city && address.state) {
      const newAddressByCep = {
        ...addressByCEP,
        number: address.number,
				type: category
      }

      setUserAddress({
				...newAddressByCep,
				verified: true
			})
      setAddressByCEP({
				...newAddressByCep,
				pristine: false,
				verified: true
			})

      if (handleCloseDialogAddress && typeof handleCloseDialogAddress === 'function') {
				handleCloseDialogAddress()
      }

      if (pathName && pathName === '/no-store') {
        history.push(`/store${URLParameters}`)
      }

      const {
        back
      } = userHistory

      if (back && back !== '/') {
        history.push(`/${back}${URLParameters}`)
      }

      getStoreFees()

      return
    }

    if (!address.id && accessToken) {
      await fetchGeolocation(address)

      setUserAddress({
        main: `${address.main}`,
        city: address.city,
        neighborhood: neighborhood,
        id: 0,
				verified: true
      })

      await postAddress([() => {
        setUserHistory({
          next: 'home',
          back: 'home'
        })

        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })

        handleCloseDialogAddress(false)
      }, () => {
        console.warn('Address created')

        setModality({ id: 4 })
        handleCloseDialogAddress(false)

        if (pathName && pathName === '/no-store') {
          history.push(`/store${URLParameters}`)
        }

        if (!pathName) {
          handleStore(modalityId)
        }

        const {
          back
        } = userHistory

        if (back && back !== '/') {
          history.push(`/${back}${URLParameters}`)
        }

        getStoreFees()
      }])

      return
    }

    if (address && address.id && accessToken) {
      await patchAddress([() => {
        setUserHistory({
          next: 'addresses',
          back: 'addresses'
        })

        setNotification({
          type: 'warning',
          message: 'Não foi possível atualizar seu endereço. Tente novamente.'
        })

        handleCloseDialogAddress(false)
      }, () => {
        handleCloseDialogAddress(false)

        const {
          back
        } = userHistory

        if (pathName && pathName === '/no-store') {
          history.push(`/store${URLParameters}`)
        } 

        if (!pathName) {
          handleStore(modalityId)
        }

        if (back && back !== '/') {
          history.push(`/${back}${URLParameters}`)
        }

        getStoreFees()
      }])

      return
    }

    handleCloseDialogAddress(false)
  }

  function handleCategory(args) {
    postType(args)
  }

  function postType(args) {
    setCategory(args)
		setAddressByCEP({
			...addressByCEP,
			pristine: false
		})
  }

  return <Wrapper>
    {(!addressByCEP && !userAddress) && <LoadingWrapper id='new-address-loading'>
      <LoadingImage src={IpirangaLoadingGIF} />
    </LoadingWrapper>}

    {(addressByCEP || userAddress) && <ContentWrapper id='new-address-content'>
      {userAddress && !userAddress.id ? <Notification
				id='new-address-notification'
        classes={'bold'}
        message={'Confirme se o seu endereço de entrega está correto e complemente com os dados faltantes'}
      /> : null}

      <AddAdressForm method='post' onSubmit={(event) => {
        event.preventDefault()
      }}>
        {warningPostalCode && warningPostalCode.type === 'warning' && <PostalWarning> O CEP digitado é inválido!</PostalWarning>}
        <CEPContainer>
          <InputRounded
            mainType='input'
            type='text'
            name='cep'
            value={formattedCEP}
            onChange={handleCEPChange}
            maxLength={10}
            placeholder="CEP" />
          <Button type="button" onClick={() => handlePostalCodeClick()}>OK</Button>
        </CEPContainer>
        <FormFieldAddress>
          <InputRounded
            mainType='input'
            type='text'
            name='main'
            value={(edit ? editedMain : main)}
            onChange={handleInput}
            notification={notification}
            maxLength={150}
            placeholder="Endereço"
          />
        </FormFieldAddress>
        <FormFieldAddressNumber>
          <InputRounded
            mainType='input'
            className='half'
            type='number'
            name='number'
            value={(edit ? editedNumber : number)}
            onChange={handleInput}
            notification={notification}
            maxLength={10}
            placeholder="Número" />
        </FormFieldAddressNumber>
        <FormFieldAddressAdditional>
          <InputRounded
            mainType='input'
            type='text'
            className='half'
            name='complement'
            value={(edit ? editedComplement : complement)}
            onChange={handleInput}
            notification={notification}
            maxLength={150}
            placeholder="Complemento" />
        </FormFieldAddressAdditional>
        <FormFieldDistrict>
          <InputRounded
            mainType='input'
            type='text'
            className='half'
            name='neighborhood'
            value={(edit ? editedNeighborhood : neighborhood)}
            onChange={handleInput}
            notification={notification}
            maxLength={60}
            placeholder="Bairro" />
        </FormFieldDistrict>
        <FormFieldCity>
          <InputRounded
            mainType='input'
            type='text'
            className='half'
            name='city'
            value={(edit ? editedCity : city)}
            onChange={handleInput}
            notification={notification}
            maxLength={50}
            readOnly={!!city || false}
            placeholder="Cidade" />
        </FormFieldCity>
        <FormFieldState>
          <InputRounded
            mainType='input'
            type='text'
            className='half'
            name='state'
            value={(edit ? editedState : state)}
            onChange={handleInput}
            notification={notification}
            maxLength={60}
            readOnly={!!state || false}
            placeholder="Estado" />
        </FormFieldState>
        <FormFieldReference>
          <InputRounded
            mainType='input'
            type='text'
            name='reference'
            value={(edit ? editedReference : reference)}
            onChange={handleInput}
            notification={notification}
            placeholder='Referência para o entregador' />
        </FormFieldReference>
        <SaveAddressLabel>Gravar endereço?</SaveAddressLabel>
        <AddressCategoryList>
          {
            addressCategoryTypes.map(({ label, icon: Icon, id }, index) => {
							return <AddressCategory key={index} className={(category === id && !pristine) ? 'category-active' : ''} onClick={(event) => {
									handleInput(event)
									handleCategory(id)
								}}>
									<Icon />
									<span>{label}</span>
								</AddressCategory>
							}
            )
          }
        </AddressCategoryList>
        <SubmitButton onClick={() => {
          handleSave()
        }}>Salvar endereço</SubmitButton>
      </AddAdressForm>
    </ContentWrapper>}
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    userAddresses: state.main.userAddresses || [],
    addressByCEP: (state.main.addressByCEP) || {},
    address: (state.main.address) || {},
    cep: (state.main.cep) || null,
    latitude: (state.main.latitude) || null,
    longitude: (state.main.longitude) || null,
    userAddress: (state.main.userAddress) || {},
    userHistory: (state.main.userHistory) || {},
    notification: state.main.notification || {},
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStore: store.fetchStore,

  fetchGeolocation,
  setAddress,
  setAddressByCEP,
  patchAddress,
  postAddress,
  setUserAddress,
  setUserHistory,
  setNotification,
  handleGTM,
  setCEP,
  fetchAddressByCEP,
  setModality,
  /* setAddressComplete */
})

export const NewAddress = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'addresses' })
)(NewAddressPage))
