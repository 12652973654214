import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  position: relative;

  * {
    font-family: 'Nunito', 'sans-serif';
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;
`

export const ContentWrapper = styled.div`
  padding: 0;
  opacity: 1;
  transition: all .25s ease-in;
  font-size: .875rem;
  min-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;
`
export const InfoBox = styled.div`
  transition: all .25s ease-in;
  position: relative;
  padding: 1.875rem;
  border-bottom: #E1E1E1 .063rem solid;

  &:last-child {
    margin-bottom: 0;
  }

  > h1 {
    text-align: left;
    border-bottom: none;
    padding: 1.25rem 0 0 0;
    font-size: 1.125rem;

    > span {
      color: ${ props => {
        if (props.inProgress === true) { 
          return '#5FC98B' 
        }
    
        return '#C3C3C3'
      } };
    }
  }

  > div {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 4.063rem calc(100% - (5rem + 2.5rem)) 1.25rem;
    grid-template-areas: 'image text arrow';

    > div {
      &.image {
        grid-area: image;
        min-height: 100%;
        display: flex;
        align-items: center;
        font-size: 1rem;

        > img {
          width: 3.438rem;
          height: auto;
        }
      }

      &.text {
        > p {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
          margin: 0;

          > strong {
            &.modality {
              color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
            }

            &.status {
              &.done {
                color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
              }
              &.inprogress {
                color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000' };
              }
            }

            &.price {
              font-size: .875rem;
              color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
            }
          }
        }

        > h4 {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
          margin: .625rem 0 0 0;
          font-size: 1rem;
        }
      }

      &.arrow {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
        display: flex;
        font-size: 1.25rem;
        justify-content: center;
        align-items: center;
      }
    }
  }
`
export const HelpBox = styled.div`
  margin-bottom: .625rem;
  padding: 1.25rem 1.875rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-bottom: #E1E1E1 .063rem solid;

  > h3 {
    margin-bottom: 1.25rem;
  }

  > div{
    min-width: 100%;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: .625rem;
    
    > button {
      flex: 1;
      padding: .625rem .313rem;
      border-radius: .938rem;
      background: #fff;
      margin: 0;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .125rem solid;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
      font-size: .875rem;

      &.off {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333' };
      }
    } 

  }
`

export const H3 = styled.h3`
  font-size: .875rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
`
export const Button = styled.button`
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && !props.disabled ? props.theme.main.general.primaryColor : '#999') || '#000' };
`
export const Span = styled.span``

export const ListItem = styled.div`
  margin-bottom: .625rem;
  padding: 1.25rem 1.875rem;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  border-bottom: #E1E1E1 .063rem solid;
`
export const List = styled.div`
  width: 100%;
  margin-bottom: .625rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  > span {
    flex: 1;
    font-weight: bold;

    &:nth-child(1) {
      min-width: 70%;
      max-width: 70%;

      > span {
        display: block;
        font-size: .75rem;
        font-weight: 400;
        margin-top: .625rem;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#999' };

        &.value{
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textBlueColor) || '#999' };
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    &:nth-child(2) {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#999' };
      text-align: right;
      
      > span{
        padding: .375rem .625rem;
        border-radius: .625rem;
        border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .125rem solid;
      }
    }
  }
`
export const SummaryWrapper = styled.div`
	padding: 1.25rem 1.875rem;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	flex: 1;
  border-bottom: #E1E1E1 .063rem solid;

	> p {
		line-height: .875rem;
    font-size: .875rem;
    margin: 0 auto;
    color: '#7D7D7D';
    padding: 0;
    margin: 0;
    font-size: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    flex: 1;
    min-width: 100%;

		&.subtotal {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
			margin-bottom: .625rem;
		}

		&.fee {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
			margin-bottom: 1.25rem;
		}

    &.discount {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
      margin-bottom: .625rem;
    }

		&.total {
			color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
			font-size: 1.125rem;
			line-height: 1.125rem;

			> span {
				font-weight: bold;
			}
		}

		> span {
			flex: 1;

			&.price {
				align-self: flex-end;
				flex: none;
			}
		}
	}
`

export const DeliveryBox = styled.div`
  margin-bottom: .625rem;
  box-sizing: border-box;
  padding: 1.25rem 1.875rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
  border-bottom: #E1E1E1 .063rem solid;
  
  > h3 {
    margin-bottom: .625rem;
  }
`

export const Footer = styled.footer`  
  flex: 1;
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.25rem;
  margin-top: 0;
  box-sizing: border-box;

  > p {
    margin-bottom: .938rem;
  }

  > button {
    max-width: 100%;
    min-width: 100%;
    flex: 1;
    font-size: .875rem;
    padding: 1.25rem 1.25rem;
    box-sizing: border-box;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333' };
    border-radius: .938rem;
    font-weight: bold;
  }
`

export const SelectedCardContainer = styled.p`
  margin: 0;
  width: 100%;
  max-width: 280px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  > span {
    font-size: .75rem;
    font-weight: bold;
    color: #686868;

    &:nth-child(2) {
      font-size: .875rem;
      font-weight: normal;
    }

    > img {
 
     margin-right: .313rem;
    }

    > a {
      margin-left: .313rem;

      > img {
        width: .938rem;
        height: auto;
      }
    }
  }
`
