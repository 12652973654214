import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: ${props => {
    if (!props.mobile) {
      return 'row'
    }

    return 'column'

  }};
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: 100%;
  font-size: .875rem;
  min-height: 100%;
  padding: 0 1.25rem;

  > img.main {
    margin-bottom: 1.25rem;
    width: 100%;
    height: auto;
    max-height: 263px;
  }

  > h2 {
    margin-bottom: 1.25rem;
  }
`

export const Textarea = styled.textarea`
  min-width: 100%;
  max-width: 100%;
  height: 5rem;
  border-radius: .938rem;
  padding: .625rem;
  box-sizing: border-box;
  border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'} .063rem solid;
  background: none;
`

export const Button = styled.button`
  display: flex;
  flex-direction: row;

  &:disabled {
    opacity: .4;
    pointer-events: none;
  }
`

export const CloseButton = styled.button`
  padding: 1rem;
  background: none;
  border: 0 none;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  text-align: end;
  
`

export const Image = styled.img`
  margin: 0;
`

export const H1 = styled.h1`
  margin: ${props => {
    if (props.additionals && props.additionals.length && !props.mobile) {
      return `145px 0 .8rem 0`
    }
    if(props.mobile){
      return `0 0 .8rem 0`
    }
    if(!props.image){
      return `0 0 .8rem 0`
    }

    return `120px 0 .8rem 0;`
  }};
  padding: 0;
  font-size: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: wrap;
  text-align: center;
`

export const H4 = styled.h4`
  margin: 0 0 .625rem 0;
  padding: 0;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#333'};
  text-align: center;
  border-top: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'};
  padding: 1.5rem 0 .5rem 0;
`

export const Div = styled.div``

export const Paragraph = styled.p`
	color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'} .063rem solid;
	padding: 0;
	margin: 0;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex: 1;
	min-width: 100%;
`

export const QuantityControlWrapper = styled.div``

export const Span = styled.span`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-size: .9rem;
  margin: 0 .5rem;
  font-weight: bold;

  @media(max-width: 60rem){
    font-size: .8rem;
  }
`

export const ButtonActions = styled.button`
  font-weight: bold;
  font-size: 1rem;
  border-radius: 50%;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border: ${props => {
    if (props.qtd > 0) {
      return `1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' }`
    }
    return `1px solid #E2E2E2`
  }};
`

export const NotesWrapper = styled.div`
  min-width: 100%;
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: ${props => {
    if (props.additionals && props.additionals.length) {
      return `2rem`
    }
    return `1rem`
  }};

  > h3 {
    margin-bottom: 1.25rem;
  }

  > textarea {
    margin-bottom: 1.25rem;
  }
  
  > ::-webkit-input-placeholder  { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > input:-moz-placeholder { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > textarea:-moz-placeholder { 
    font-size: .875rem;
    color:${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > .row {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;

    > .quantity {
      border-radius: .625rem;
      display: flex;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'} .063rem solid;
      min-height: 2.638rem;
      box-sizing: border-box;
      padding: 0 .313rem;
      max-width: 35%;
      min-width: 35%;

      > button {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1.875rem;
        padding: 0 .313rem;
        text-decoration: none;
        background: none;
      }

      > span {
        color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        font-weight: bold;
        font-size: 1rem;
      }      
    }

    > .add {
      flex: 1;
      max-width: 60%;
      min-width: 60%;

      > button {
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
        border-radius: .625rem;
        min-width: 100%;
        display: flex;
        height: 2.638rem;
        align-items: center;
        justify-content: space-between;
        font-weight: bold;
        font-size: .875rem;

        > span {
          flex: 1;
          color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff'};
        }
      }
    }
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: .938rem;
  

  > img {

    &.noimg{
      max-width: 30%;
      min-width: 30%;
      padding: 2rem;
    }

    flex: 1;
    width: ${props => {
      if (props.additionals && props.additionals.length && !props.mobile) {
        return '330px'
      }

      if (props.mobile) {
        return '240px'
      }
  
      return '280px'
    }};

    ${props => {
      if (!props.mobile && props.image) {
        return 'position: fixed;'
      }
    }};
  }
`

export const DatailBox = styled.div`
  width: ${props => {
    if (props.additionals && props.additionals.length && !props.mobile) {
      return '50%'
    }

    return '100%'

  }};

  > p {
    text-align: center;
    margin-bottom: 1.25rem;
    font-size: .738rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textdescriptionColor) || '#000'};

    &.description{
      font-size: .738rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textdescriptionColor) || '#000'};

      @media(max-width:60rem){
        font-size: .7rem;
      }
    }

    &.from {
      font-size: .838rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.alterTextdescriptionColor) || '#000'};
      margin:0;
    }

    &.price {
      font-size: 1.125rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.titleColor) || '#000'};
      font-weight: bold;
    }

    &.promo {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
      margin-bottom: 0;
      font-size: 1.125rem;
    }
  
    >span{
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
      margin-bottom: 0;
      font-size: 1rem;
      text-decoration: line-through;
      margin-left: .313rem;
    }
  }

  @media(min-width: 60rem){
    padding: ${props => {
      if (props.additionals && props.additionals.length) {
        return '1rem'
      }
  
      return '0 1rem'
    }};
  } 
`
export const AdditionalBox = styled.div`
  width: ${props => {
    if (!props.mobile) {
      return '50%'
    }

    return '100%'
  }};
 
 @media(min-width: 60rem){
   padding: 1rem;
 } 
`
