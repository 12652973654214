
import { CARD_BRANDS_IDS, CARD_BRANDS_NAMES } from '../../domain/enum/payment';

const availableBrands = [
    {
        name: CARD_BRANDS_NAMES.ELO,
        id: CARD_BRANDS_IDS.ELO,
        regex: /^(636368)|(636369)|(438935)|(504175)|(451416)|(636297)|(4576)|(4011)|(506699)/
    },
    {
        name: CARD_BRANDS_NAMES.SODEXO,
        id: CARD_BRANDS_IDS.SODEXO,
        regex: /^(606071)|(603389)/
    },
    {
        name: CARD_BRANDS_NAMES.VR,
        id: CARD_BRANDS_IDS.VR,
        regex: /^(637036)|(627416)/
    },
    {
        name: CARD_BRANDS_NAMES.TICKET_VR,
        id: CARD_BRANDS_IDS.TICKET_VR,
        regex: /^603342/
    },
    {
        name: CARD_BRANDS_NAMES.TICKET_VA,
        id: CARD_BRANDS_IDS.TICKET_VA,
        regex: /^602651/
    },
    {
        name: CARD_BRANDS_NAMES.ALELO,
        id: CARD_BRANDS_IDS.ALELO,
        regex: /^5067/
    },
    {
        name: CARD_BRANDS_NAMES.AMEX,
        id: CARD_BRANDS_IDS.AMEX,
        regex: /^3[47]/
    },
    {
        name: CARD_BRANDS_NAMES.DINERS,
        id: CARD_BRANDS_IDS.DINERS,
        regex: /^30(1|5)/
    },
    {
        name: CARD_BRANDS_NAMES.HIPERCARD,
        id: CARD_BRANDS_IDS.HIPERCARD,
        regex: /^38/
    },
    {
        name: CARD_BRANDS_NAMES.MASTERCARD,
        id: CARD_BRANDS_IDS.MASTERCARD,
        regex: /^(5[1-5]|2[2-7])/
    },
    {
        name: CARD_BRANDS_NAMES.VISA,
        id: CARD_BRANDS_IDS.VISA,
        regex: /^4/
    },
]

const getCardBrand = (cardNumber) => {
    const formattedNumber = String(cardNumber).replace(/-/g, '').replace(/\s/g,'')
    for (let i = 0; i < availableBrands.length; i++) {
        const brand = availableBrands[i];
        if (brand.regex.test(formattedNumber)) return brand;
    }
    return null;
}

export default getCardBrand;
