import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  padding-top: 0;
  box-sizing: border-box;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > .close-button {
    flex: 1;
    align-self: flex-end;
    margin-bottom: 1.25rem;
    font-weight: bold;
    position: absolute;
  }
`

export const ContentWrapper = styled.div`
  opacity: 1;
  transition: all .25s ease-in;
  font-size: .875rem;
  min-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;

  &.motion {
    opacity: 1;
  }

  > p {
    font-size: 1.125rem;
    margin-bottom: 2.5rem;
  }

	> .notification {
		padding: 0;
	}
`


export const Div = styled.div`
  flex: 1;
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
  text-align: center;
  border-bottom: #E1E1E1 .063rem solid;
  padding-bottom: 1.25rem;
`

export const H3 = styled.h3`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 500;
  min-width: 100%;
  margin-bottom: 1.25rem;
  text-align: left;
`

export const CloseButton = styled.button`
  background: none;
  border: 0 none;
  font-size: 1.125rem;
  margin-right: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const Form = styled.form`
  min-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  > input {
    min-width: 100%;
    max-width: 100%;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#CCC' };
  }

  > ::-webkit-input-placeholder  { 
    font-size: 1rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' };  
  }

  > input:-moz-placeholder { 
    font-size: 1rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
  }

  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    min-width: 100%;
    max-width: 100%;
    margin-bottom: 2.5rem;

    &.field {
      flex-direction: column;
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      min-width: 48%;
      max-width: 48%;

      > input {
        min-width: 100%;
        max-width: 100%;

        > ::-webkit-input-placeholder  { 
          font-size: 1rem;
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
        }

        > input:-moz-placeholder { 
          font-size: 1rem;
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#CCC' }; 
        }
      }

      > p {
        justify-content: flex-start;
        display: flex;
        text-align: left;
        font-size: .75rem;
      }
    }
  }
`

export const TypeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;

  > button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border: #FFF .063rem solid;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#666' };
    padding: .625rem;
    border-radius: .625rem;

    &.selected {
      border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .063rem solid;
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

      > span {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }
    }

    > span {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#666' };
      margin-right: .625rem;
      font-size: 1.25rem;
    }
  }
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' } .063rem solid;
	font-size: 1.125rem;
	height: 1.5rem;
	line-height: 1.5rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
	margin: 0 auto;
	flex: 1;
  min-width: 100%;
  margin: 0;
  padding: 0;
	margin-bottom: .313rem;

  &[type=number]::-webkit-inner-spin-button,
  &[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

export const Button = styled.button``

export const SaveButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.125rem;
  min-height: 3.125rem;
  line-height: 3.125rem;
  min-width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  min-height: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
  font-size: .875rem;

  &.notification {
    color: #f00;
  }
`
export const PostalWarning = styled.p`
  margin: 0;
  padding: 0;
  font-size: .875rem;
  color: red;
  font-weight: bold;
`
