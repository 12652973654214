export default ({
  addressByGeolocationRepository,
  maps,
  data
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      actions,
      dispatch,
      getState
    } = payload

    try {
      const address  = await addressByGeolocationRepository.fetchAddressByGeolocation({
        data,
        actions,
        maps,
        dispatch,
        getState
      })

      onSuccess({ address })
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
