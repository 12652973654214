import styled from 'styled-components'

export const Wrapper = styled.div`
	background: #fff;
	flex: 1;
	box-sizing: border-box;
	margin: 0 auto;
	position: relative;
	max-width: 100%;
	min-width: 100%;
	min-height: 100%;
	display: flex;
	${props => props.maxHeight ?
		`max-height: ${ props.maxHeight }px;
		min-height: ${ props.maxHeight }px;
		overflow: hidden;` :
		``}

	* {
		font-family: 'Nunito', 'sans-serif';
	}

	> div {
		display: flex;
		min-height: 100%;

		> main {
			background: #fff;
			min-height: 100%;
			max-height: 100%;
			box-sizing: border-box;
			position: relative;

			> div {
				&.row {
					margin: 1.5rem 2rem 0 2rem;

					> div {
						&.column {
							display: flex;
							flex-direction: column;

							&.center {
								display: flex;
								align-items: center;
								justify-content: center;
							}

							@media (min-width: 60rem) {
								max-width: 60%;
								min-width: 60%;

								&.center {
									display: flex;
									align-items: center;
									justify-content: center;
									max-width: 40%;
									min-width: 40%;
								}
							}

							> svg {
								flex: 1;
								height: unset;
								min-height: auto;
								max-height: unset;
								max-width: 30%;
								min-width: 30%;
								opacity: .3;

								path {
									fill: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#CCC' };
								}

								@media (min-width: 60rem) {
									max-width: 50%;
									min-width: 50%;
								}
							}
						}
					}
				}
			}

			> img {
				height: auto;
				max-height: unset;
				max-width: 100%;
				min-height: auto;
				min-width: 100%;
				width: 100%;
				display: none;

				@media (min-width: 60rem) {
					display: unset;
				}
			}
		}
	}

	@media (min-width: 60rem) {
		> div {
			> main {
				min-height: calc(100% - 5.625rem);
				max-height: calc(100% - 5.625rem);
				margin: 0px auto;
				padding-top: 5.625rem;
				box-sizing: unset;
			}
		}
	}
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    text-align: left;
    font-size: 1.25rem;
    text-transform: uppercase;
    padding: 1rem 0;
    margin: 1.5rem 2rem 0 2rem;
    min-width: calc(100% - 4rem);
  }
`

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  border-bottom: 0 none;
  padding: 1.5rem 0 .5rem 0;
  margin: 0 1.25rem .5rem;

  @media (min-width: 60rem) {
    font-size: 1.25rem;
    line-height: 1.688rem;
    padding: 2.25rem 0 .5rem 0;
    margin: 0 2rem 1rem;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;
`
