import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Image, Shimmer } from 'react-shimmer'

import {
  Wrapper
} from './styles'

export function Highlights(props) {
	const {
		data
	} = props

	return data.length ? <Wrapper id='home-highlights'>
		<Carousel autoPlay={ true } infiniteLoop={ true } interval={ 5000 } showThumbs={ false } showArrows={ false }>
			{ data && data.map((mappedItem, index) => {
        const {
          image,
          description
        } = mappedItem
        
        const key = `highlights-item-${ index }`

        return <Image key={ key } alt={ description } src={ image } fallback={ <Shimmer width={800} height={235}/> }></Image>
      }) }
		</Carousel>
	</Wrapper> : null
}
