import styled from 'styled-components'
import { OrderSummary } from '../../receipt/OrderSummary'
import { ActionButton, DeliveryAddressWrapper, DeliveryPaymentWrapper, Item, OrderSummaryTitle, TotalsWrapper } from '../../receipt/OrderSummary/styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  min-width: 100%;
	background: #fff;

  #cart {
    padding: 1.25rem 1rem;

    @media (min-width: 60rem) {
      padding: 2rem 1.5rem;
    }

    #notification {
      display: none;
    }
  }
`

export const Header = styled.header`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: .125rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: 1.25rem 1rem;

  @media (min-width: 60rem) {
    padding: 2rem 1.5rem;
    min-width: 100%;
    min-width: 100%;
  }
`

export const StoreOrder = styled.div`
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  margin: 0 0 .5rem 0;
`

export const StoreOrderLogo = styled.div`
  border: .125rem solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  padding: 0;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  min-width: 3.5rem;
  min-height: 3.5rem;
  max-width: 3.5rem;
  max-height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 0 0;
  overflow: hidden;

  > img {
    height: auto;
    max-height: unset;
    max-width: 100%;
    min-height: auto;
    min-width: 100%;
    width: 100%;
  }

  svg {
    width: calc(100% - .65rem);
  }
`

export const StoreOrderDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const StoreOrderName = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };

  @media (min-width: 60rem) {
    font-size: 1.25rem;
    line-height: 1.688rem;
  }
`

export const StoreOrderDate = styled.div`
  font-weight: 400;
  font-size: .875rem;
  line-height: .875rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };

  @media (min-width: 60rem) {
    font-size: 1rem;
    line-height: 1rem;
  }
`

export const StoreOrderModality = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0 .5rem 0 0;
  padding: 0 .5rem 0 0;
  border-right: 2px solid${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};

  @media (min-width: 60rem) {
    font-size: 1.25rem;
    line-height: 1.25rem; 
  }
`

export const StoreOrderStatus = styled.div`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;

  &.inprogress{
    color: #00892E;
  }

  &.finish{
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  }

  @media (min-width: 60rem) {
    font-size: 1.25rem;
    line-height: 1.25rem;
  }
`

export const Help = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.25rem 1rem;
  padding-bottom: 0;

  @media (min-width: 60rem) {
    padding: 1.25rem 1.5rem 0;  
    width: 100%;
  }
`

export const HelpLabel = styled.label`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.188.rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0 0 1rem 0;
  width: 100%;
`

export const HelpButton = styled.button`
  border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: 7px;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.188.rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  flex: 1 1 calc(50% - .5rem);
  max-width: calc(50% - .5rem);
  height: 2.5rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem .25rem;

  &:hover {
    background-color: #f7f7f7;
  }

  &:first-of-type {
    margin: 0 1rem 0 0;
  }

  @media (min-width: 60rem) {
    font-size: .875rem;
  }

  svg {
    margin: 0 0 0 .5rem;

    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      stroke: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`

export const CustomOrderSummary = styled(OrderSummary)`
  ${OrderSummaryTitle} {
    display: none;
  }

  ${Item} {
    padding: 1.5rem !important;
    margin: 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
      padding: 0 0 1.5rem 0;
    }

    &:first-of-type {
      padding: 1.5rem 0;
    }
  }

  ${TotalsWrapper} {
    padding: 0 1.5rem 1.5rem 1.5rem !important;
  }

  ${ActionButton} {
    margin: 1.5rem !important;
  }

  ${DeliveryAddressWrapper}, ${DeliveryPaymentWrapper} {
    display: flex !important;
    padding: 1.5rem !important;
  }
`
export const MobileHeader = styled.div`
  width: 100%
`
export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 1rem 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  border: 0 none;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1rem;
  position: absolute;
  cursor: pointer;
  left: 1.25rem;
  top: 1.25rem;
  z-index: 1;

  @media (min-width: 40rem) {
    display: none;
  }
`