import React, { useState } from 'react'

import { CustomSelect } from '../../components/CustomSelect'

import {
	CategoriesSelectWrapper
} from './styles'

export function CategoriesSelect(props) {
	const {
		data,
		handleSearchCategorie
	} = props
	const [selectedOption, setSelectOption] = useState('')

	const handleCategorie = (search) => {
		handleSearchCategorie(search)
	}

	return <CategoriesSelectWrapper>
		{data && <CustomSelect
			id='Categorias'
			variant='outlined'
			options={data}
			selectedOption={selectedOption}
			setSelectOption={setSelectOption}
			handleCategorie={handleCategorie}
			all='Todas as categorias'
		/>}
	</CategoriesSelectWrapper >
}
