import React from 'react'
import PropTypes from 'prop-types'

import {
  Wrapper
} from './styles'

import {
  Store
} from './../Store'

export function List(props) {
  const { data, store, handleStore, modalityId } = props

  return <Wrapper>
    {data.filter(filteredItem => !!filteredItem).map((mappedItem, index) => {
      const time = (new Date()).getTime()
      const key = `storeslist-${time}-${index}`

      const {
        id,
        name,
        images,
        category,
        distance,
        valueFrom,
        fullAddress,
        deliveryEstimate,
        status,
        pickup,
        delivery,
        coupon = {}
      } = mappedItem

      const kmDistance = String(distance).replace('.', ',')

      const Content = {
        component: Store,
        props: {
          data: {
            images,
            name,
            category,
            id,
            distance: kmDistance,
            valueFrom,
            fullAddress,
            deliveryEstimate,
            status,
            coupon,
            store,
            pickup,
            delivery,
            modalityId
          },

          handles: {
            handleStore
          }
        }
      }

      return (
        <Store key={key} {...Content.props} />
      )
    })}
  </Wrapper>
}

List.propTypes = {
  handles: PropTypes.shape({}),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  motion: PropTypes.bool
}
