import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  max-width: 100%;
  min-width: 100%;
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  box-sizing: border-box;
  overflow: hidden;
  overflow-x: scroll;
  max-width: 100%;
  min-width: 100%;
`

export const Label = styled.p`
  flex: 1;
  align-items: center;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  font-weight: bold;
  margin-top: .313rem;
  font-size: .813rem;
`

export const AgglomerationButton = styled.button`
  box-sizing: border-box;
  flex: 1;
  padding: 0;
  margin: 0;
  margin-right: 1.25rem;

  > img {
    max-height: 5rem;
    min-height: 5rem;
    max-width: 5rem;
    min-width: 5rem;
  }

  &:last-child {
    margin-right: 0;
  }
`

export const H1 = styled.h1`
  font-family: ${props => props.theme.newFontFamily};
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  font-size: 1.313rem;
  margin: 0;
  margin-bottom: 1.25rem;
  padding: 0;
  min-width: 100%;
  flex: none;
  font-weight: 600;
`

export const Paragraph = styled.p`
  flex: 1;
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#333'};
  min-width: 100%;
`

export const ChangeAddressButton = styled.button`
  border: 0 none;
  font-family: ${props => props.theme.newFontFamily};
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333'};
  height: 3.125rem;
  padding: 0 1.25rem;
  color: #fff;
  border-radius: .938rem;
  font-weight: bold;
  min-width: 100%;
`
