import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  padding-top: 1.25rem;
  box-sizing: border-box;
  position: relative;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > .back-button {
    flex: 1;
    align-self: flex-start;
    margin-bottom: 1.25rem;
    font-weight: bold;
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    z-index: 1;
    cursor: pointer;
  }

  > div {
    min-width: 100%;

    > div {
      
    }
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: uppercase;
  text-align: center;
  border-bottom: #E1E1E1 .063rem solid;
  padding-bottom: 1.25rem;
`

export const ContentWrapper = styled.div`
  padding: 0;
  opacity: 0;
  transition: all .25s ease-in;
  font-size: .875rem;
  min-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;

  &.motion {
    opacity: 1;
  }

  &.disabled {
    > ul {
      pointer-events: none;
    }
  }

  > h2 {
    margin-bottom: 1.25rem;
  }
`

export const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  list-style: inside none;
`

export const ListItemWrapper = styled.li`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 3.75rem calc(100% - (3.75rem + 5.938rem)) 5.938rem;
  grid-template-areas: 'icon address actions';

  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' } .125rem solid;
  border-radius: .938rem;
  padding: 0;
  margin-bottom: 1.25rem;
  transition: all .25s ease-in;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    &.icon {
      min-height: 100%;
      display: flex;
      align-items: center;
      grid-area: icon;
      padding: .625rem 1.25rem;
      font-size: 1rem;
      cursor: pointer;

      > span {
        display: flex;
        align-items: center;
        flex: 1;
        min-height: 100%;
        border-right: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' } .125rem solid;
        padding-right: 1.25rem;
      }
    }
  }

  > div + p {
    padding-left: 1.25rem;
  }

  > div {
    &.address {
      grid-area: address;
      padding: .625rem 1.25rem;
      padding-right: 0;
      cursor: pointer;

      > p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > span {
          &.first {
            flex: 1;
            font-size: 1rem;
            min-width: 100%;
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#666' }; 
          }

          &.second {
            flex: 1;
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
            font-size: .875rem;   
            min-width: 100%;
          }
        }
      }
    }
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

export const BackButton = styled.button`
  background: none;
  border: 0 none;
  font-size: 1.125rem;
  margin-right: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const Span = styled.span``

export const Div = styled.div``

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`
