import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  background: #F4F4F4;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border: 0.063rem solid #C3C3C3;
  border-radius: 0.75rem;
  height: 1.875rem;
  max-width: 10rem;
  min-width: 10rem;
  align-self: center;
`

const Button = styled.button`
  background: ${ props => {
    if (props.active === true) {
      return `${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' }`
    }

    return 'none'
  } };
  font-size: 0.875rem;
  font-weight: bold;
  color: ${ props => {
    if (props.active === true) {
      return '#fff'
    }

    return '#C3C3C3'
  } };
  border-radius: 0.75rem;
  flex: 1;
  padding: 0;
  margin: 0;
  height: 1.75rem;
`

const modalities = [{
  id: 4,
  label: 'Delivery'
}, {
  id: 2,
  label: 'Retirada'
}]

export function ModalityCheckbox(props) {
  const {
    modality,
    handleModality
  } = props

  return <Wrapper id='home-modalities'>
    {
      modalities && modalities.map((mappedItem, index) => {
        const key = `home-modalities-item-${index}`

        return <Button key={key} active={ (modality && mappedItem.id === modality.id) ? true : false } onClick={() => {
          handleModality(mappedItem)
        }}>{ mappedItem.label }</Button>
      })
    }
  </Wrapper>
}
