import React from 'react'

import { ReactComponent as IconPhone } from '../../assets/icons/icon_phone.svg'
import { ReactComponent as IconTyping } from '../../assets/icons/icon_typing.svg'

import {
  CustomIcon,
  DefaultImage as Image
} from '../../components'

import {
  Wrapper,
  Header,
  StoreOrder,
  StoreOrderLogo,
  StoreOrderDetails,
  StoreOrderName,
  StoreOrderDate,
  StoreOrderModality,
  StoreOrderStatus,
  Help,
  HelpButton,
  HelpLabel,
  CustomOrderSummary,
  MobileHeader,
  H1,
  BackButton
} from './styles';

export function Summary(props) {
  const {
    storeName,
    storeLogo,
    deliveryAddress,
    pickupAddress,
    date,
    modalityId,
    inProgress,
    phone,
    whatsapp,
    products,
    cpf,
    hasCpf,
    cardBrand,
    cardNumber,
    handleTrackOrder
  } = props

  return (
    <Wrapper>
      <MobileHeader>
          <H1>Meus Pedidos</H1>
          <BackButton className='back-button' onClick={() => {
            window.history.back()
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>
        </MobileHeader>
      <Header>
        <StoreOrder>
          <StoreOrderLogo>
            <Image src={storeLogo} />
          </StoreOrderLogo>
          <StoreOrderDetails>
            <StoreOrderName>{storeName}</StoreOrderName>
            <StoreOrderDate>{date}</StoreOrderDate>
          </StoreOrderDetails>
        </StoreOrder>
        <StoreOrderModality>{modalityId === 4 ? 'Delivery' : 'Retirada'}</StoreOrderModality>
        {inProgress ? <StoreOrderStatus className="inprogress">Em andamento</StoreOrderStatus> : <StoreOrderStatus className="finish">Finalizado</StoreOrderStatus>}
      </Header>
      <Help>
        <HelpLabel>Precisa de ajuda?</HelpLabel>
        <HelpButton href={`#${whatsapp}`} target='_blank'>
          Fale conosco
          <IconTyping />
        </HelpButton>
        <HelpButton href={`#${phone}`}>
          Ligar para loja
          <IconPhone />
        </HelpButton>
      </Help>
      <CustomOrderSummary
        products={products}
        modalityId={modalityId}
        deliveryAddress={modalityId === 4 ? deliveryAddress : ''}
        storeAddress={modalityId === 2 ? pickupAddress : ''}
        cardBrand={cardBrand}
        cardNumber={cardNumber}
        cpf={cpf}
        hasCpf={hasCpf}
        buttonName='Acompanhar Pedido'
        handleTrackOrder={handleTrackOrder}
      />
    </Wrapper>
  )
}
