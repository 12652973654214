import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import history from '../../history'

import {
	handleGTM,
	logout,
	setModality
} from '../../redux/actions/main'

import { cart } from '../../state'

import {
	pipe,
	GetContent,
	caculateProductsQuantity,
} from '../../domain/helpers'

import { useDeviceLayout } from '../hooks'

import {
	MainHeader
} from '../main-header'

import {
	containerMode,
	PageContainerSide,
	PageContainer,
	PageContainerHeader,
	PageContainerMain,
	Cart,
	CartContainer,
	Navigation,
	Skeleton
} from '..'

import { Header } from '../store/Header'

import { Infos } from './Infos'

import {
	Wrapper,
	LoadingWrapper,
} from './styles'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export function StoreInfoPage(props) {
	const {
		accessToken,
		loading,
		theme,
		storeName,
		storeImg,
		storeId,
		storeStatus,
		modalityId,
		deliveryFee,
		deliveryMinimumValue,
		headerBackgroundImage,
		pickupMinimumValue,
		deliveryTime,
		storeContact,
		openingHours,
		onlineFlags,
		presentialFlags,
		userName,
		userAddress,
		addressDelivery,
		cartProducts,
		setModality,
		storeDelivery,
		storePickup,
		scheduling,
		handleGTM,
		userId,
		URLParameters
	} = props

	const [visibleSignIn, setVisibleSignIn] = useState(false)
	const [sideMode, setSideMode] = useState({
		isOpen: true,
		mode: containerMode.default
	})
	const [viewCards, setViewCards] = useState(false)
	const [fullWidth, setFullWidth] = useState(true)
	const [fullScreen, setFullScreen] = useState(false)
	const screenMobile = false
	const [visibleNavigation, setVisibleNavigation] = useState(false)
	const storeLogo = theme.logoImage

	const storeHeaderImage = theme.headerImage
	const storeHeaderImageMobile = theme.headerImageMobile

	const {
		url,
		title,
		description
	} = useStoreSettings()

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	useEffect(() => {
		const url = window.location.href;
		const access = {
			event: 'PageView',
			page_path: url,
			page_title: 'Informações da loja'
		}
		handleGTM(access)

		if (userId) {
			handleGTM({ user_id: userId })
		}
	}, [
		userId,
		handleGTM
	])

	useEffect(() => {
		setFullWidth(true)

		if (isMobile) {
			setFullScreen(true)
		}
	}, [
		isMobile
	])

	async function handleModality(id) {
		setModality({ id: id })
	}

	function handleSideModeChange(mode) {
		const _isOpen = sideMode.mode !== mode;
		setSideMode({
			isOpen: _isOpen,
			mode: _isOpen ? mode : sideMode.default
		})
	}

	function handleContactUs() {
		history.push(`/contact-us${URLParameters}`)
	}

	function handleCloseDialogNavigation() {
		setVisibleNavigation(false)
	}

	return (
		<Wrapper id={'store-info'}>
			<Helmet>
				<title>{`${title} - Informações da loja`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/store-info`} />
			</Helmet>

			{loading.includes('store-info') && <LoadingWrapper id='store-info-loading'>
				<Skeleton module='store' />
			</LoadingWrapper>}

			{!loading.includes('store-info') && <PageContainer
				isSideOpen={sideMode.isOpen}
				mode={sideMode.mode}
			>
				<PageContainerHeader>
					<MainHeader
						currentRoute='store-info'
						mode='full'
						theme={theme}
						storeLogo={storeLogo}
						name={storeName}
						storeImg={storeImg}
						userName={userName}
						accessToken={accessToken}
						setVisibleSignIn={setVisibleSignIn}
						visibleSignIn={visibleSignIn}
						handleModality={handleModality}
						modality={modalityId}
						addressDelivery={userAddress ? userAddress : addressDelivery}
						isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
						cartItemsQuantity={caculateProductsQuantity(cartProducts)}
						userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
						cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
						opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
					/>
				</PageContainerHeader>
				<PageContainerMain opened={sideMode.isOpen}>
					<>
						<Header
							id={storeId}
							name={storeName}
							storeImg={storeImg}
							image={storeHeaderImage}
							status={storeStatus}
							modalityId={modalityId}
							deliveryFee={deliveryFee}
							deliveryMinimumValue={deliveryMinimumValue}
							pickupMinimumValue={pickupMinimumValue}
							deliveryTime={deliveryTime}
							backgroundImage={headerBackgroundImage}
							appWidth={screenMobile}
							viewCards={viewCards}
							setViewCards={setViewCards}
							modality={modalityId}
							handleModality={handleModality}
							accessToken={accessToken}
							setVisibleNavigation={setVisibleNavigation}
							storePickup={storePickup}
							storeDelivery={storeDelivery}
							scheduling={scheduling}
							storeHeaderImageMobile={storeHeaderImageMobile}
							isMobile={isMobile}
						/>

						<Infos
							storeContact={storeContact}
							openingHours={openingHours}
							onlineFlags={onlineFlags}
							presentialFlags={presentialFlags}
						/>
					</>
				</PageContainerMain>
				<PageContainerSide>
					{!isMobile && sideMode.mode === containerMode.sideFullContent &&
						<CartContainer logo={storeLogo}>
							<Cart
								mode='simple'
								screenMobile={isMobile}
								setViewCards={setViewCards}
								viewCards={viewCards}
								handleFinish={() => { }}
								handleClose={() => { }}
							/>
						</CartContainer>}

					{sideMode.mode === containerMode.headerFullContent ?
						<Navigation
							contactUs={() => handleContactUs()}
							userName={userName}
							currentRoute='contact-us'
						/> : null}
				</PageContainerSide>

				{isMobile && <Dialog
					onClose={() => handleCloseDialogNavigation()}
					aria-labelledby="navigation-dialog"
					open={visibleNavigation}
					maxWidth={'xs'}
					fullWidth={fullWidth}
					fullScreen={fullScreen}
					TransitionComponent={Transition}
				>
					<Navigation
						contactUs={() => handleContactUs()}
						userName={userName}
						currentRoute='store'
						userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
						setVisibleNavigation={setVisibleNavigation}
					></Navigation>
				</Dialog>}
			</PageContainer>}
		</Wrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.main.accessToken,
		addressDelivery: state.main.addressByCEP || '',
		cartProducts: (state.main.cart && state.main.cart.products) || [],
		loading: state.main.loading || [],
		registeredCoupons: state.main.coupons || { coupons: [] },
		userAddress: state.main.userAddress || '',
		userAddresses: state.main.userAddresses || [],
		userHistory: state.main.userHistory || {},
		userName: state.main.userName,
		storeImg: (state.main.store && state.main.store.logo) || '',
		modalityId: state.main.modality && state.main.modality.id,
		storeName: state.main.store && state.main.store.name,
		storeId: state.main.store && state.main.store.id,
		storeStatus: state.main.store && state.main.store.status,
		deliveryFee: state.main.store && state.main.store.deliveryFee,
		deliveryMinimumValue: state.main.store && state.main.store.deliveryMinimumValue,
		pickupMinimumValue: state.main.store && state.main.store.pickupMinimumValue,
		deliveryTime: state.main.store && state.main.store.deliveryTime,
		storeContact: state.main.store && state.main.store.contact,
		openingHours: state.main.store && state.main.store.openingHours,
		onlineFlags: state.main.store && state.main.store.onlineFlags,
		presentialFlags: state.main.store && state.main.store.presentialFlags,
		storePickup: (state.main.store && state.main.store.pickup) || null,
		storeDelivery: (state.main.store && state.main.store.delivery) || null,
		scheduling: state.main.store && state.main.store.scheduling,
		userId: state.main.userId,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchCart: cart.fetchCart,
	logout,
	setModality,
	handleGTM
})

export const StoreInfo = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'store-info' })
)(StoreInfoPage))
