import axios from 'axios'
import uuidv4 from "uuid/v4"

import {
  GOOGLE_API_KEY,
  MUNDIPAGG_PUBLIC_KEY,
  GTM_KEY,
  API_URL,
  PLATAFORM_ID,
  APP_ID
} from "../../config"

import {
  withAuthHeader,
  getCardTypeByBrand,
  getCardBrand,
  onlyNumbers
} from '../../infra/utils'

import {
  calculateCartTotal
} from '../../domain/helpers'

import {
  mapAddress,
  mapAddressByCEP,
  mapAddressCreateRequest,
  mapAddressCreateData,
  mapAddressDeleteData,
  mapAddressPatchRequest,
  mapUserInfo,
  mapUserAddressesList,
  mapOrderAnalysisRequest,
  mapOrderAnalysisData,
  mapOrderCreateRequest,
  mapOrderCreateData,
  mapLoginCreateRequest,
  mapLoginCreateData,
  mapReceiptData,
  mapCardsData,
  mapMundipaggAccessTokenData,
  mapCardToMundipaggApiRequest,
  mapAddCardRequestToApi,
  mapAddCardResponse,
  mapSignUpCreateRequest,
  mapSignUpCreateData,
  mapFbLoginFormToAPI,
  mapGoogleLoginFormToAPI,
  mapAuthAPIResponse,
  mapPhoneFormToAPI,
  mapCodeFormToAPI,
  mapReceiptRequestNumber,
  mapReceiptStatusData,
  mapDeleteCardRequestToApi,
  mapDeleteCardResponse,
  mapAddTicketCardRequestToApi,
  mapAddTicketCardResponse
} from '../../domain/adapters'

import history from '../../history'

const getDeviceToken = () => {
  const savedToken = localStorage.getItem('deviceToken');
  if (savedToken) return savedToken

  const newToken = uuidv4();
  localStorage.setItem('deviceToken', newToken)
  return newToken
}

const apiService = axios.create({
  responseType: 'json',
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Idioma": "pt-br",
    "Plataforma": PLATAFORM_ID,
    "Versao": "3.12",
    "DeviceToken": getDeviceToken(),
    "AplicativoId": APP_ID,
    "OneSignalPlayerId": ""
  }
})

const mainModule = 'main'

export const actionTypes = {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_SHOW_SIGN_UP: 'SET_SHOW_SIGN_UP',
  SET_ADDRESS_BY_CEP: 'SET_ADDRESS_BY_CEP',
  SET_THING: 'SET_THING',
  SET_DEVICE: 'SET_DEVICE',
  SET_ADDITONALS: 'SET_ADDITONALS',
  SET_OPEN_CART: 'SET_OPEN_CART',
  /* SET_ADDRESS_COMPLETE: 'SET_ADDRESS_COMPLETE', */
  SET_ADDRESS: 'SET_ADDRESS',
  SET_AGGLOMERATION: 'SET_AGGLOMERATION',
  SET_AGGLOMERATIONS: 'SET_AGGLOMERATIONS',
  SET_CARD: 'SET_CARD',
  SET_SAVED_CARD: 'SET_SAVED_CARD',
  SET_DELETE_CARD: 'SET_DELETE_CARD',
  SET_CATEGORIES: 'SET_CATEGORIES',
  SET_CEP: 'SET_CEP',
  SET_ERROR: 'SET_ERROR',
  SET_GEOLOCATION: 'SET_GEOLOCATION',
  SET_GTM: 'SET_GTM',
  SET_HIGHLIGHTS: 'SET_HIGHLIGHTS',
  SET_CARD_NAME: 'SET_CARD_NAME',
  SET_CARD_NUMBER: 'SET_CARD_NUMBER',
  SET_CARD_DATE: 'SET_CARD_DATE',
  SET_CARD_CPF: 'SET_CARD_CPF',
  SET_CARD_BRAND_ID: 'SET_CARD_BRAND_ID',
  SET_CARD_CVV: 'SET_CARD_CVV',
  SET_CARD_SAVED: 'SET_CARD_SAVED',
  SET_CONTACT_US: 'SET_CONTACT_US',
  SET_COUPONS: 'SET_COUPONS',
  SET_COUPON: 'SET_COUPON',
  SET_MODALITY: 'SET_MODALITY',
  SET_ORDER: 'SET_ORDER',
  SET_ORDER_HISTORY: 'SET_ORDER_HISTORY',
  SET_ORDERS: 'SET_ORDERS',
  SET_ORDER_FINISH_DATA: 'SET_ORDER_FINISH_DATA',
  SET_CART: 'SET_CART',
  SET_CART_COUPON: 'SET_CART_COUPON',
  SET_PARAMS: 'SET_PARAMS',
  SET_QUANTITY: 'SET_QUANTITY',
  SET_LOGIN: 'SET_LOGIN',
  SET_LOADING: 'SET_LOADING',
  SET_SEARCH: 'SET_SEARCH',
  SET_SELECTED_CATEGORY: 'SET_SELECTED_CATEGORY',
  SET_SELECTED_PRODUCT: 'SET_SELECTED_PRODUCT',
  SET_SIGN_UP: 'SET_SIGN_UP',
  SET_SIGN_IN: 'SET_SIGN_IN',
  SET_STORE: 'SET_STORE',
  SET_STORE_FEES: 'SET_STORE_FEES',
  SET_STORES: 'SET_STORES',
  SET_USER_ADDRESS: 'SET_USER_ADDRESS',
  SET_USER_ADDRESSES: 'SET_USER_ADDRESSES',
  SET_USER_CARD: 'SET_USER_CARD',
  SET_USER_CARD_CVV: 'SET_USER_CARD_CVV',
  SET_USER_CARDS: 'SET_USER_CARDS',
  SET_USER_HISTORY: 'SET_USER_HISTORY',
  SET_USER_ORDER_HISTORY: 'SET_USER_ORDER_HISTORY',
  SET_USER_NAME: 'SET_USER_NAME',
  SET_USER_CPF: 'SET_USER_CPF',
  SET_NOTIFICATION: 'SET_NOTIFICATION',
  SET_RECEIPT: 'SET_RECEIPT',
  SET_RECEIPT_STATUS: 'SET_RECEIPT_STATUS',
  SET_USER_SCHEDULING: 'SET_USER_SCHEDULING',
  ADD_PRODUCT: 'ADD_PRODUCT',
  REMOVE_SELECTED_PRODUCT: 'REMOVE_SELECTED_PRODUCT',
  REMOVE_SELECTED_PRODUCT_ADDITIONAL: 'REMOVE_SELECTED_PRODUCT_ADDITIONAL',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  REMOVE_SELECTED_CATEGORY: 'REMOVE_SELECTED_CATEGORY',
  REMOVE_SELECTED_OLD_CATEGORY: 'REMOVE_SELECTED_OLD_CATEGORY',
  REMOVE_SELECTED_OLD_PRODUCT: 'REMOVE_SELECTED_OLD_PRODUCT',
  SET_SEARCH_PRODUCT_CATEGORY: 'SET_SEARCH_PRODUCT_CATEGORY',
  SET_PRODUCT_CATEGORY: 'SET_PRODUCT_CATEGORY',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_SCREEN_HEIGHT: 'SET_SCREEN_HEIGHT',
  REMOVE_SCREEN_HEIGHT: 'REMOVE_SCREEN_HEIGHT',
  SET_CITY_LOCATION: 'SET_CITY_LOCATION',
  SET_RECEIPT_NOTIFICATION: 'SET_RECEIPT_NOTIFICATION',
  SET_CARD_BIRTH: 'SET_CARD_BIRTH',
  REMOVE_USER_ADDRESSES_ITEM: 'REMOVE_USER_ADDRESSES_ITEM',
  SET_SCHEDULING: 'SET_SCHEDULING',
  SET_USER_ID: 'SET_USER_ID',
  SET_METHOD_LOGIN: 'SET_METHOD_LOGIN',
  SET_PRODUCTS_REMOVED: 'SET_PRODUCTS_REMOVED',
  SET_STORE_SETTINGS: 'SET_STORE_SETTINGS',
  SET_URL_PARAMETERS: 'SET_URL_PARAMETERS'
}

function handleError(...args) {
  const e = args[0]
  const customMessage = args[2]
  const callback = args[3]

  return (dispatch, getState, api) => {
    dispatch(setLoading({ clean: true }))

    const error = JSON.parse(JSON.stringify(e))
    const {
      message
    } = error

    const extractCode = message && message.match(/[0-9]/g)

    const code = Number(extractCode && extractCode.join(''))

    if (code === 401) {
      dispatch(setAccessToken(null))
      dispatch(setLogin(null))
      dispatch(setNotification({
        type: 'warning',
        message: customMessage || 'Identificação inválida. Faça login novamente.',
        callback
      }))
    }
  }
}

export function handleGTM(data) {
  return (dispatch, getState, api) => {
    if (GTM_KEY && GTM_KEY !== 'false') {
      // ('Event: ', data)
    }

    if (GTM_KEY && GTM_KEY !== 'false' && !data) {
      dispatch(setGTM({
        events: null
      }))

      return
    }

    if (GTM_KEY && GTM_KEY !== 'false' && data && data.unique === true) {
      const gtm = dispatch(getGTM())

      const {
        events
      } = gtm

      const {
        eventId
      } = data

      const hasCalled = (events && events.filter(filteredItem => filteredItem.event === eventId)[0]) || false

      if (!hasCalled) {
        const newData = {
          ...data
        }

        delete newData.eventId
        delete newData.unique

        const newEvents = [
          ...(events && events.length ? events : []),
          data
        ]

        const newGTM = {
          ...gtm,
          events: newEvents
        }

        dispatch(setGTM(newGTM))

        window.dataLayer.push(newData)

        return
      }
    }

    if (GTM_KEY && GTM_KEY !== 'false' && data) {
      const gtm = dispatch(getGTM())

      const {
        events
      } = gtm

      const newEvents = [
        ...(events && events.length ? events : []),
        data
      ]

      const newGTM = {
        ...gtm,
        events: newEvents
      }

      dispatch(setGTM(newGTM))

      window.dataLayer.push(data)
    }
  }
}

export const isDelivery = () => (dispatch, getState, api) => {
  const modalityId = getState().main.modality && getState().main.modality.id

  return modalityId === 4
}

export const isPickup = () => (dispatch, getState, api) => {
  const modalityId = getState().main.modality && getState().main.modality.id

  return modalityId === 2
}

export const getGTM = () => (dispatch, getState, api) => {
  const gtm = getState().main.gtm

  return gtm
}

export const getStore = () => (dispatch, getState, api) => {
  const store = getState().main.store

  return store
}

export const getLogin = () => (dispatch, getState, api) => {
  const login = getState().main.login

  return login
}

export const getSignUp = () => (dispatch, getState, api) => {
  const signUp = getState().main.signUp

  return signUp
}

export const setHighlights = (highlights) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_HIGHLIGHTS,
    payload: highlights
  })
}


export const setOpenCart = (open) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_OPEN_CART,
    payload: open
  })
}

export const setParams = (params) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_PARAMS,
    payload: params
  })
}

export const setURLParameters = (parameters) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_URL_PARAMETERS,
    payload: parameters
  })
}

export const setStoresPagination = (pagination) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_PAGINATION,
    payload: pagination
  })
}

export const setStores = (stores) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_STORES,
    payload: stores
  })
}

export const setSignUp = (signUp) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SIGN_UP,
    payload: signUp
  })
}

export const setSignIn = (signIn) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SIGN_IN,
    payload: signIn
  })
}

export const setCategories = (categories) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CATEGORIES,
    payload: categories
  })
}

export const setProductCategory = (products) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_PRODUCT_CATEGORY,
    payload: products
  })
}

export const setScreenHeight = (screen) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SCREEN_HEIGHT,
    payload: screen
  })
}

export const removeScreenHeight = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SCREEN_HEIGHT
  })
}

export const setUserHistory = (userHistory) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_HISTORY,
    payload: userHistory
  })
}

export const setUserOrderHistory = (orderHistory) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_ORDER_HISTORY,
    payload: orderHistory
  })
}

export const setCard = (card) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD,
    payload: card
  })
}

export const setCardName = (cardName) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_NAME,
    payload: cardName
  })
}

export const setCardNumber = (cardNumber) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_NUMBER,
    payload: cardNumber
  })
}

export const setCardDate = (cardDate) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_DATE,
    payload: cardDate
  })
}

export const setCardBirth = (cardBirth) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_BIRTH,
    payload: cardBirth
  })
}

export const setGTM = (gtm) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_GTM,
    payload: gtm
  })
}

export const setCardCPF = (cardCPF) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_CPF,
    payload: cardCPF
  })
}

export const setCardBrandId = (cardBrandId) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_BRAND_ID,
    payload: cardBrandId
  })
}

export const setCardCVV = (cardCVV) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CARD_CVV,
    payload: cardCVV
  })
}

export const setNotification = (notification) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_NOTIFICATION,
    payload: notification
  })
}

export const setReceiptNotification = (receiptNotification) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_RECEIPT_NOTIFICATION,
    payload: receiptNotification
  })
}

export const setCEP = (cep) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CEP,
    payload: cep
  })
}

export const setUserCard = (card) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_CARD,
    payload: card
  })
}

export const setCVV = (cvv) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_CARD_CVV,
    payload: cvv
  })
}

export const setLoading = (loading) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: loading
  })
}

export const setAccessToken = (token) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_ACCESS_TOKEN,
    payload: token
  })
}

export const setUserId = (user_id) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_ID,
    payload: user_id
  })
}

export const setMethodAccess = (method) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_METHOD_LOGIN,
    payload: method
  })
}

export const setProductsRemoved = (product) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_PRODUCTS_REMOVED,
    payload: product
  })
}

export const setAddress = (address) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_ADDRESS,
    payload: address
  })
}

/* export const setAddressComplete = (complete) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_ADDRESS_COMPLETE,
    payload: complete
  })
} */

export const setThing = (thing) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_THING,
    payload: thing
  })
}

export const setDevice = (device) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_DEVICE,
    payload: device
  })
}

export const setAddressByCEP = (address) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_ADDRESS_BY_CEP,
    payload: address
  })
}

export const setGeolocation = (geolocation) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_GEOLOCATION,
    payload: geolocation
  })
}

export const setUserCards = (userCards) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_CARDS,
    payload: userCards
  })
}

export const setUserName = (userName) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_NAME,
    payload: userName
  })
}

export const setUserCpf = (userCpf) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_CPF,
    payload: userCpf
  })
}

export const setUserAddress = (userAddress) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESS,
    payload: userAddress
  })
}

export const setScheduling = (scheduling) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SCHEDULING,
    payload: scheduling
  })
}

export const setReceipt = (receipt) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_RECEIPT,
    payload: receipt
  })
}

export const setUserAddresses = (userAddresses) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESSES,
    payload: userAddresses
  })
}

export const setCityLocation = (city) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CITY_LOCATION,
    payload: city
  })
}

export const setStore = (store) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_STORE,
    payload: store
  })
}

export const setStoreFees = (storeFees) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_STORE_FEES,
    payload: storeFees
  })
}

export const setOrder = (order) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    payload: order
  })
}

export const setModality = (modality) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_MODALITY,
    payload: modality
  })
}

export const setAgglomeration = (agglomeration) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_AGGLOMERATION,
    payload: agglomeration
  })
}

export const setSelectedCategory = (category) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SELECTED_CATEGORY,
    payload: category
  })
}

export const setSearch = (search) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SEARCH,
    payload: search
  })
}

export const setSearchProductCategory = (search) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SEARCH_PRODUCT_CATEGORY,
    payload: search
  })
}

export const setSelectedProduct = (product) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SELECTED_PRODUCT,
    payload: product
  })
}

export const setCart = (cart) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_CART,
    payload: cart
  })
}

export const setShowSignUp = (showSignUp) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SHOW_SIGN_UP,
    payload: showSignUp
  })
}

export const removeSelectedProduct = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_PRODUCT
  })
}

export const removeSelectedOldCategory = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_OLD_CATEGORY
  })
}

export const removeSelectedOldProduct = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_OLD_PRODUCT
  })
}

export const removeSelectedProductsCategories = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_CATEGORY
  })
}

export const addProduct = (product) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.ADD_PRODUCT,
    payload: product
  })
}

export const setQuantity = (product) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_QUANTITY,
    payload: product
  })
}

export const setLogin = (login) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_LOGIN,
    payload: login
  })
}

export const setUserScheduling = (userScheduling) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_USER_SCHEDULING,
    payload: userScheduling
  })
}

export const removeProduct = (productIndex) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_PRODUCT,
    payload: productIndex
  })
}

export const removeSelectedProductAdditional = (productAdditional) => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.REMOVE_SELECTED_PRODUCT_ADDITIONAL,
    payload: productAdditional
  })
}

export const fetchGeolocation = (address) => async (dispatch, getState, api) => {
  try {
    const fullAddress = address && address.main && address.number && address.neighborhood && `${address.main}, ${address.number} - ${address.neighborhood}, ${address.city} - ${address.state}, ${address.cep}, Brasil`;
    const almostFullAddress = address && address.main && !address.number && address.neighborhood && `${address.main}, 1 - ${address.neighborhood}, ${address.city} - ${address.state}, ${address.cep}, Brasil`;
    const formattedAddress = almostFullAddress || fullAddress
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${formattedAddress}&key=${GOOGLE_API_KEY}`
    const result = await axios.get(url)

    const {
      data
    } = result

    const {
      results
    } = data

    if (results.length) {
      const {
        lat,
        lng
      } = results[0].geometry.location

      dispatch(setGeolocation({
        latitude: lat,
        longitude: lng
      }))

      dispatch(setAddress({
        ...address,
        latitude: lat,
        longitude: lng
      }))
    }
  } catch (e) {
    console.log(e)
  }
}

export const fetchUserAddress = (callback) => async (dispatch, getState, api) => {
  try {
    const latitude = getState().main.latitude
    const longitude = getState().main.longitude
    const loading = getState().main.loading

    if (loading && !loading.includes('user-address') && (!latitude || !longitude)) {
      return false
    }

    dispatch(setLoading('user-address'))

    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`
    const result = await axios.get(url)
    const mappedResult = mapAddress(result)

    const userAddress = getState().main.userAddress || {}

    const fullAddress = mappedResult.main && mappedResult.number ? `${mappedResult.main}` : ''
    const incompleteAddress = mappedResult.main ? `${mappedResult.main}` : ''

    const newUserAddress = fullAddress || incompleteAddress
    const {
      neighborhood,
      city
    } = mappedResult

    dispatch(setUserAddress({
      ...userAddress,
      geolocation: true
    }))

    if (userAddress.last && !userAddress.id) {
      dispatch(setUserAddress({
        ...userAddress,
        main: newUserAddress,
        neighborhood,
        city,
        latitude,
        longitude,
        id: 0,
        geolocation: true
      }))
    }

    if (!userAddress.last && !userAddress.id) {
      dispatch(setUserAddress({
        ...userAddress,
        backup: newUserAddress,
        neighborhood,
        city,
        latitude,
        longitude,
        id: 0,
        geolocation: true
      }))
    }

    dispatch(setAddress(mappedResult))
  } catch (e) {
    console.log(e)
  } finally {
    callback && callback()

    dispatch(setLoading({ item: 'user-address', delete: true }))
  }
}

export const fetchAddress = (callback) => async (dispatch, getState, api) => {
  try {
    const address = getState().main.address || {}
    const cep = getState().main.cep

    if (!address.id && cep) {
      setTimeout(() => {
        dispatch(fetchAddressByCEP())
      }, 1000)
    }

    if (!address.id && !cep) {
      setTimeout(() => {
        dispatch(fetchUserAddress())
      }, 1000)
    }
  } catch (e) {
    console.log(e)
  } finally {
    callback && callback()
  }
}

export const fetchAddressByCEP = (callback) => async (dispatch, getState, api) => {
  try {
    const cep = getState().main.cep
    const noMaskCep = cep.replace(/\.|-/g, '')

    const url = `https://viacep.com.br/ws/${noMaskCep}/json/`
    const result = await axios.get(url)
    const mappedResult = mapAddressByCEP(result.data)

    const stateAddress = getState().main && getState().main.address
    const latitude = (getState().main && getState().main.latitude) || 0
    const longitude = (getState().main && getState().main.longitude) || 0

    if (!!mappedResult.main) {
      const fullAddress = {
        latitude,
        longitude,
        ...stateAddress,
        ...mappedResult,
        pristine: true
      }

      dispatch(setAddressByCEP(fullAddress))
      dispatch(setAddress(fullAddress))
      dispatch(setUserAddress(fullAddress))

      await dispatch(fetchGeolocation(mappedResult))

      return
    }

    if (!!mappedResult.error) {
      dispatch(setAddress(null))
      dispatch(setAddressByCEP(null))

      return
    }
  } catch (e) {
    console.log(e)
  } finally {
    callback && callback()
  }
}

export const fetchUser = (callback) => async (dispatch, getState, api) => {
  try {
    const loading = getState().main.loading

    if (loading && loading.indexOf('user') > -1) {
      return false
    }

    dispatch(setLoading('user'))

    const url = `usuarios/obter`
    const result = await apiService.get(url, withAuthHeader(mainModule))
    const mappedResult = mapUserInfo(result.data)

    const userAddress = getState().main.userAddress || {}

    if (mappedResult && mappedResult.name) {
      dispatch({
        type: actionTypes.SET_USER_NAME,
        payload: mappedResult.name
      })
    }

    dispatch(setUserAddress({
      ...userAddress,
      last: true
    }))

    if (mappedResult && mappedResult.address && mappedResult.address.id && mappedResult.address.main) {
      dispatch(setUserAddress({
        ...userAddress,
        main: mappedResult.address.main,
        neighborhood: mappedResult.address.neighborhood,
        id: mappedResult.address.id,
        city: mappedResult.address.city,
        latitude: mappedResult.address.latitude,
        longitude: mappedResult.address.longitude,
        last: true
      }))

      return
    }

    if (mappedResult && mappedResult.address && !mappedResult.address.id && !mappedResult.address.main) {
      dispatch(setUserAddress({
        ...userAddress,
        main: userAddress.backup,
        neighborhood: mappedResult.address.neighborhood,
        id: mappedResult.address.id,
        city: mappedResult.address.city,
        latitude: mappedResult.address.latitude,
        longitude: mappedResult.address.longitude,
        last: true
      }))

      return
    }
  } catch (e) {
    console.log(e)
  } finally {
    callback && callback()

    dispatch(setLoading({ item: 'user', delete: true }))
  }
}

export const fetchStoreFees = (callback) => async (dispatch, getState, api) => {
  try {
    const loading = getState().main.loading
    const latitude = getState().main.latitude
    const longitude = getState().main.longitude
    const accessToken = getState().main.accessToken
    const userAddressId = getState().main.userAddress && getState().main.userAddress.id
    const storeId = (getState().main.store && getState().main.store.id) || (getState().main.receipt && getState().main.receipt.store && getState().main.receipt.store.id)
    const cep = getState().main.cep

    if (loading && loading.indexOf('store-fees') > -1) {
      return false
    }

    dispatch(setLoading('store-fees'))

    const deliveryUrl = `Estabelecimentos/ObterTaxaEntrega/${storeId}${accessToken && userAddressId ? `?enderecoDeliveryId=${userAddressId}` : `?latitude=${latitude}&longitude=${longitude}`}` // TODO
    const deliveryResult = await apiService.get(deliveryUrl, withAuthHeader(mainModule))

    const modalityPickup = (deliveryResult.data.ModalidadesRetirada.filter(filteredItem => filteredItem.ModalidadeRetirada === 2))
    const modalityDelivery = (deliveryResult.data.ModalidadesRetirada.filter(filteredItem => filteredItem.ModalidadeRetirada === 4))

    const pickupMinimumValue = modalityPickup && modalityPickup[0] && modalityPickup[0].ValorMinimoPedido
    const deliveryMinimumValue = modalityDelivery && modalityDelivery[0] && modalityDelivery[0].ValorMinimoPedido

    const pickupFee = modalityPickup && modalityPickup[0] && modalityPickup[0].Valor
    const deliveryFee = modalityDelivery && modalityDelivery[0] && modalityDelivery[0].Valor

    dispatch(setStoreFees({
      pickupMinimumValue,
      deliveryMinimumValue,
      pickupFee,
      deliveryFee
    }))

    dispatch(handleGTM({
      unique: true,
      eventId: 'homePostalCode',
      event: 'CEP Informado',
      cep: cep ?? '',
      taxa_de_entrega: deliveryFee ? deliveryFee : '',
      valor_minimo: deliveryMinimumValue ? deliveryMinimumValue : ''
    }))

    return
  } catch (e) {
    console.log(e)

    dispatch(setStoreFees({}))
  } finally {
    dispatch(setLoading({ item: 'store-fees', delete: true }))

    callback && callback()
  }
}

const postOrderAnalysis = (data) => async (dispatch, getState, api) => {
  try {
    const url = `Pedidos/Analisar`
    const dto = mapOrderAnalysisRequest(data)
    const result = await apiService.post(url, dto, withAuthHeader(mainModule))
    const mappedResult = mapOrderAnalysisData(result.data)

    const {
      konduto,
      orderId,
      success
    } = mappedResult

    if (success && orderId) {
      const analysis = {
        konduto,
        orderId,
        success
      }

      dispatch(setOrder({
        analysis
      }))

      return
    } else {
      const analysis = {
        konduto: null,
        orderId: null,
        success: false
      }

      dispatch(setOrder({
        analysis
      }))

      dispatch(setNotification({
        type: 'warning',
        message: 'A transação não foi realizada.'
      }))
    }
  } catch (e) {
    console.log(e)

    const analysis = {
      konduto: null,
      id: null,
      success: false
    }

    dispatch(setOrder({
      analysis
    }))

    dispatch(setNotification({
      type: 'warning',
      message: 'A transação não foi realizada.'
    }))
  }
}

export const fetchUserAddresses = (callback) => async (dispatch, getState, api) => {
  try {
    const loading = getState().main.loading
    const userAddress = getState().main.userAddress
    const accessToken = getState().main.accessToken

    if (!accessToken) {
      dispatch(setLoading({ item: 'user-addresses', delete: true }))

      return false
    }

    if (loading && loading.includes('user-addresses')) {
      return false
    }

    dispatch(setLoading('user-addresses'))

    const url = `enderecosdelivery/listar`
    const response = await apiService.get(url, withAuthHeader(mainModule))

    const mappedResult = mapUserAddressesList(response.data)

    if ((userAddress && userAddress.id) && mappedResult && mappedResult.length) {
      await dispatch(setUserAddresses(mappedResult))

      return
    }

    const filteredAddressById = userAddress && mappedResult.filter((filteredItem) => filteredItem.id === userAddress.id)
    const filteredAddresses = userAddress && mappedResult.filter((filteredItem) => filteredItem.id !== userAddress.id)

    if (filteredAddressById && filteredAddressById[0] && mappedResult && mappedResult.length && filteredAddresses && filteredAddresses.length) {
      const newFilteredAddresses = [filteredAddressById[0], ...filteredAddresses]
      await dispatch(setUserAddresses(newFilteredAddresses))

      return
    }

    if (mappedResult && mappedResult.length) {
      await dispatch(setUserAddresses(mappedResult))

      return
    }

    await dispatch(setUserAddresses([]))

    return mappedResult
  } catch (e) {
    console.log(e)
  } finally {
    callback && callback()

    dispatch(setLoading({ item: 'user-addresses', delete: true }))
  }
}

export const fetchReceiptStatus = ({
  checkoutId,
  modality,
  times
}) => async (dispatch, getState, api) => {
  try {
    const url = (modality === 1 || modality === 2) ? `Pedidos/ObterStatusPedidoBalcao?comandaId=${checkoutId}` : `Pedidos/ObterStatusDelivery?comandaId=${checkoutId}`
    const result = await apiService.get(url, withAuthHeader(mainModule))
    const mappedResult = mapReceiptStatusData(result.data)

    dispatch({
      type: actionTypes.SET_RECEIPT_STATUS,
      payload: mappedResult
    })

    dispatch(setLoading({ item: 'receipt-status', delete: true }))
  } catch (e) {
    console.log(e)

    dispatch(setLoading({ item: 'receipt-status', delete: true }))
  }
}

export const fetchRequestNumber = ({
  checkoutId
}) => async (dispatch, getState, api) => {
  try {
    const receipt = getState().main.receipt

    const url = `Pedidos/ObterNumeroChamada?comandaId=${checkoutId}`
    const apiResult = await apiService.get(url, withAuthHeader(mainModule))
    const response = mapReceiptRequestNumber(apiResult.data)

    const {
      requestNumber
    } = response

    dispatch({
      type: actionTypes.SET_RECEIPT,
      payload: {
        ...receipt,
        requestNumber
      }
    })

    dispatch(setLoading({ item: 'receipt-request-number', delete: true }))
  } catch (e) {
    console.log(e)

    dispatch(setLoading({ item: 'receipt-request-number', delete: true }))
  }
}

export const fetchReceipt = () => async (dispatch, getState, api) => {
  try {
    const orderId = (getState().main.order && getState().main.order.id) || (getState().main.order && getState().main.order.finishData && getState().main.order.finishData.vqpOrderId)
    const partnerOrderId = getState().main.params && getState().main.params.partnerOrderId

    dispatch(setLoading('receipt'))

    const url = `Pedidos/ObterComprovante?${partnerOrderId ? `parceiroPedidoId=${partnerOrderId}` : `comandaId=${orderId}`}`
    const result = await apiService.get(url, withAuthHeader(mainModule))
    const mappedResult = mapReceiptData(result.data)

    dispatch({
      type: actionTypes.SET_RECEIPT,
      payload: mappedResult
    })

    dispatch(setLoading(''))
  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_RECEIPT,
      payload: null
    })

    dispatch(setLoading(''))

  }
}

export const fetchCards = (callback) => async (dispatch, getState, api) => {
  const errorCallback = callback && callback[0]

  try {
    dispatch(setLoading({ item: 'cards', delete: true }))

    const storeId = getState().main.store && getState().main.store.id
    const loading = getState().main.loading

    if (loading && loading.indexOf('cards') > -1) {
      return false
    }

    dispatch(setLoading('cards'))

    const url = `Cartoes/Listar/${storeId}?delivery=true`
    const result = await apiService.get(url, withAuthHeader(mainModule))
    const mappedResult = mapCardsData(result.data)

    if (mappedResult.success) {
      dispatch({
        type: actionTypes.SET_USER_CARDS,
        payload: mappedResult
      })

      dispatch(setLoading({ item: 'cards', delete: true }))

      return
    }

    dispatch({
      type: actionTypes.SET_USER_CARDS,
      payload: null
    })

    dispatch(setLoading({ item: 'cards', delete: true }))
  } catch (e) {
    dispatch(handleError(e, 'payment', 'Não foi possível listar seus cartões cadastrados. Volte e tente novamente.', errorCallback))

    dispatch({
      type: actionTypes.SET_USER_CARDS,
      payload: null
    })
  }
}

const fetchMundipaggAccessToken = () => async (dispatch, getState, api) => {
  const url = `Cartoes/ObterAccessToken`
  const result = await apiService.post(url, {}, withAuthHeader(mainModule))
  const mappedResult = mapMundipaggAccessTokenData(result.data)

  return mappedResult
}

const postCardMundipagg = ({ card, mundipaggUserId, accessToken }) => async (dispatch, getState, api) => {
  const url = `https://api.mundipagg.com/core/v1/customers/${mundipaggUserId}/cards?appId=${MUNDIPAGG_PUBLIC_KEY}`
  const data = mapCardToMundipaggApiRequest(card)
  const result = await axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${accessToken}`,
      'Content-Type': 'application/json'
    }
  })

  return result
}

export const deleteAddress = (callback) => async (dispatch, getState, api) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : callback[0].callback
  const successCallback = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : callback[1].callback

  const address = getState().main.address

  try {
    const url = `enderecosdelivery/deletar/${address && address.id}`
    const result = await apiService.post(url, null, withAuthHeader(mainModule))
    const mappedResult = mapAddressDeleteData(result.data)

    if (mappedResult.success) {
      successCallback && successCallback({ id: address.id })

      return
    }

    dispatch(setAddress(null))

    errorCallback && errorCallback()
  } catch (e) {
    console.log(e)

    dispatch(setAddress(null))

    errorCallback && errorCallback()
  }
}

export const postAddress = (callback) => async (dispatch, getState, api) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : callback[0].callback
  const successCallback = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : callback[1].callback

  const stateAddress = getState().main.address

  await dispatch(fetchGeolocation(stateAddress))

  try {
    const data = mapAddressCreateRequest({
      ...stateAddress
    })
    const url = `enderecosdelivery/cadastrar`
    const result = await apiService.post(url, data, withAuthHeader(mainModule))
    const mappedResult = mapAddressCreateData(result.data)

    if (mappedResult.success) {
      dispatch(setAddress({
        ...stateAddress,
        savedData: {
          ...mappedResult
        }
      }))

      const fullAddress = stateAddress.main && stateAddress.number ? `${stateAddress.main}` : ''
      const incompleteAddress = stateAddress.main ? `${stateAddress.main}` : ''

      const userAddress = fullAddress || incompleteAddress

      dispatch(setUserAddress({
        ...stateAddress,
        main: userAddress,
        id: mappedResult.id,
        verified: false
      }))

      successCallback && successCallback()

      return
    }

    dispatch(setAddress({
      ...stateAddress,
      savedData: null
    }))

    errorCallback && errorCallback()
  } catch (e) {
    console.log(e)

    dispatch(setAddress({
      ...stateAddress,
      savedData: null
    }))

    errorCallback && errorCallback()
  }
}

export const patchAddress = (callback) => async (dispatch, getState, api) => {
  const errorCallback = callback && callback[0] && typeof callback[0] === 'function' ? callback[0] : callback[0].callback
  const successCallback = callback && callback[1] && typeof callback[1] === 'function' ? callback[1] : callback[1].callback

  const address = getState().main.address

  try {
    const data = mapAddressPatchRequest(address)
    const url = `enderecosdelivery/editar/${address && address.id}`
    const result = await apiService.post(url, data, withAuthHeader(mainModule))
    const mappedResult = mapAddressCreateData(result.data)

    if (mappedResult.success) {
      dispatch(setAddress({
        ...address,
        savedData: {
          ...mappedResult
        }
      }))

      const fullAddress = address.main && address.number ? `${address.main}` : ''
      const incompleteAddress = address.main ? `${address.main}` : ''

      const userAddress = fullAddress || incompleteAddress
      const neighborhood = `${address.neighborhood}`
      const city = `${address.city}`

      dispatch(setUserAddress({
        main: userAddress,
        neighborhood,
        city,
        id: mappedResult.id,
        latitude: address.latitude,
        longitude: address.longitude,
        verified: false
      }))

      successCallback && successCallback()

      return
    }

    dispatch(setAddress({
      ...address,
      savedData: null
    }))

    errorCallback && errorCallback()
  } catch (e) {
    console.log(e)

    dispatch(setAddress({
      ...address,
      savedData: null
    }))

    errorCallback && errorCallback()
  }
}

export const login = (data) => async (dispatch, getState, api) => {
  try {
    const login = getState().main.login || {}

    const url = `Usuarios/Logar`
    const dto = mapLoginCreateRequest(data)
    const result = await apiService.post(url, dto)
    const mappedResult = mapLoginCreateData(result.data)

    if (mappedResult.success) {
      const {
        id,
        name,
        accessToken,
        cpf
      } = mappedResult

      dispatch({
        type: actionTypes.SET_LOGIN,
        payload: {
          ...login,
          success: true,
          confirmationType: 'sms',
          resend: false,
          userData: {
            ...mappedResult
          }
        }
      })

      dispatch(setAccessToken(accessToken))
      dispatch(setUserName(name))
      dispatch(setUserCpf(cpf))
      dispatch(setUserId(id))

      return mappedResult
    }

    return mappedResult

  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_LOGIN,
      payload: {
        status: false,
        userData: null
      }
    })

    dispatch({
      type: actionTypes.SET_USER_NAME,
      payload: null
    })
  }
}

export const logout = (form) => async (dispatch, getState, api) => {
  const URLParameters = getState().main.URLParameters

  dispatch(setAccessToken(null))
  dispatch(setUserName(null))
  dispatch(setUserAddress(null))
  dispatch(setUserAddresses(null))
  dispatch(setAddressByCEP(null))
  dispatch(setAddress(null))
  dispatch(setCEP(null))
  dispatch(setUserCard(null))
  dispatch(setCart({ products: null }))
  dispatch(setUserCards(null))
  dispatch(setSignUp(null))
  dispatch(setUserId(null))

  history.push(`/store${URLParameters}`)
}

export const postLogin = (callback) => async (dispatch, getState, api) => {
  const login = getState().main.login
  const userAddressId = getState().main.userAddress && getState().main.userAddress.id
  const modalityId = getState().main.modality && getState().main.modality.id

  const addressCallback = callback && callback.length && callback.filter(filteredItem => filteredItem.name === 'address')

  try {
    const data = mapLoginCreateRequest(login)
    const url = `Usuarios/Logar`
    const result = await apiService.post(url, data)
    const mappedResult = mapLoginCreateData(result.data)

    if (modalityId === 4 && mappedResult.success && !userAddressId) {
      const {
        accessToken
      } = mappedResult

      dispatch(setAccessToken(accessToken))

      await dispatch(postAddress(addressCallback))
    }

    if (mappedResult.success) {
      dispatch({
        type: actionTypes.SET_LOGIN,
        payload: {
          ...login,
          success: true,
          confirmationType: 'sms',
          resend: false,
          userData: {
            ...mappedResult
          }
        }
      })

      dispatch({
        type: actionTypes.SET_USER_NAME,
        payload: mappedResult.name
      })

      return
    }

    dispatch({
      type: actionTypes.SET_LOGIN,
      payload: {
        status: false,
        userData: null
      }
    })

    dispatch({
      type: actionTypes.SET_USER_NAME,
      payload: null
    })
  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_LOGIN,
      payload: {
        status: false,
        userData: null
      }
    })

    dispatch({
      type: actionTypes.SET_USER_NAME,
      payload: null
    })
  }
}

export const postSignUp = () => async (dispatch, getState, api) => {
  const signUp = getState().main.signUp

  try {
    const data = mapSignUpCreateRequest(signUp)
    const url = `Usuarios/Cadastrar`
    const result = await apiService.post(url, data)
    const mappedResult = mapSignUpCreateData(result.data)

    const {
      CPF: cpf
    } = signUp

    if (mappedResult.success) {
      dispatch({
        type: actionTypes.SET_SIGN_UP,
        payload: {
          ...signUp,
          success: true,
          confirmationType: 'sms',
          resend: false,
          userData: {
            ...mappedResult
          }
        }
      })

      dispatch({
        type: actionTypes.SET_USER_NAME,
        payload: mappedResult.name
      })

      dispatch(setUserCpf(cpf))

      return
    }

    dispatch({
      type: actionTypes.SET_SIGN_UP,
      payload: {
        ...signUp,
        success: false,
        userData: {
          ...mappedResult
        }
      }
    })
  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_SIGN_UP,
      payload: {
        ...signUp,
        success: false,
        userData: null
      }
    })
  }
}

export const addTicketCard = () => async (dispatch, getState, api) => {
  try {
    const card = getState().main && getState().main.card

    const brandId = getCardBrand(onlyNumbers(card.number))

    if (brandId && brandId.id) {
      await dispatch(setCardBrandId(
        brandId.id
      ))
    }

    const cardData = {
      ...card,
      birth: card.birth.replace(/\/|-/g, ''),
      name: card.name.toUpperCase(),
      number: card.number.replace(/\s+/g, '')
    }

    const url = `Ticket/Cadastrar`
    const data = mapAddTicketCardRequestToApi(cardData)
    const apiResult = await apiService.post(url, data, withAuthHeader(mainModule))
    const mappedResult = mapAddTicketCardResponse(apiResult.data)

    if (mappedResult && mappedResult.success) {
      dispatch({
        type: actionTypes.SET_SAVED_CARD,
        payload: {
          ...data,
          ...mappedResult
        }
      })

      dispatch(setCard(null))

      return mappedResult
    }

    dispatch({
      type: actionTypes.SET_SAVED_CARD,
      payload: {
        ...data,
        ...mappedResult
      }
    })

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível cadastrar o cartão. Tente novamente.'
    }))

  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_SAVED_CARD,
      payload: null
    })

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível cadastrar o cartão. Tente novamente.'
    }))
  }
}

export const postCard = () => async (dispatch, getState, api) => {
  try {
    const card = getState().main && getState().main.card

    const brandId = getCardBrand(onlyNumbers(card.number))

    if (brandId && brandId.id) {
      await dispatch(setCardBrandId(
        brandId.id
      ))
    }

    const mundipaggData = await dispatch(fetchMundipaggAccessToken())
    const mundipaggCard = await dispatch(postCardMundipagg({
      card: {
        CVV: card.CVV,
        number: card.number.replace(/\s+/g, ''),
        cpf: card.CPF.replace(/\.|-/g, ''),
        date: card.date.replace(/\/|-/g, ''),
        brandId: brandId && brandId.id,
        name: card.name
      },
      mundipaggUserId: mundipaggData.userId,
      accessToken: mundipaggData.accessToken
    }))
    const cardData = {
      mundipaggCardId: mundipaggCard.data.id,
      mundipaggUserId: mundipaggData.userId,
      digits: mundipaggCard.data.last_four_digits,
      firstDigits: mundipaggCard.data.first_four_digits,
      expMonth: mundipaggCard.data.exp_month,
      expYear: mundipaggCard.data.exp_year,
      brandId: brandId && brandId.id,
      type: getCardTypeByBrand(brandId && brandId.id),
      cpf: card.CPF.replace(/\.|-/g, '')
    }
    const url = `Cartoes/Cadastrar`
    const data = mapAddCardRequestToApi(cardData)
    const result = await apiService.post(url, data, withAuthHeader(mainModule))
    const mappedResult = mapAddCardResponse(result.data)

    if (mappedResult && mappedResult.success) {
      dispatch({
        type: actionTypes.SET_SAVED_CARD,
        payload: {
          ...data,
          ...mappedResult
        }
      })

      dispatch(setCard(null))

      return mappedResult
    }

    dispatch({
      type: actionTypes.SET_SAVED_CARD,
      payload: {
        ...data,
        ...mappedResult
      }
    })

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível cadastrar o cartão. Tente novamente.'
    }))

  } catch (e) {
    console.log(e)

    dispatch({
      type: actionTypes.SET_SAVED_CARD,
      payload: null
    })

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível cadastrar o cartão. Tente novamente.'
    }))
  }
}

export const deleteCard = (card) => async (dispatch, getState, api) => {
  try {
    const url = `Cartoes/Excluir`
    const dto = mapDeleteCardRequestToApi(card)
    const apiResult = await apiService.post(url, dto, withAuthHeader(mainModule))
    const response = mapDeleteCardResponse(apiResult.data)
    if (response.success) {
      dispatch({
        type: actionTypes.SET_DELETE_CARD,
        payload: null
      })

      return response
    } else {
      dispatch({
        type: actionTypes.SET_DELETE_CARD,
        payload: null
      })

      return response
    }
  } catch (e) {
    console.log(e)
  }
}

export const postFacebookLogin = (form) => async (dispatch, getState, api) => {
  try {
    const url = `Usuarios/LogarViaFacebook`
    const data = mapFbLoginFormToAPI(form)
    const result = await apiService.post(url, data)
    const mappedResult = mapAuthAPIResponse(result.data)

    if (mappedResult.success && mappedResult.user && mappedResult.user.signUp) {
      dispatch(setLogin({
        email: form.email,
        password: null,
        success: true,
        userData: {
          ...mappedResult
        }
      }))

      return mappedResult
    }

    if (mappedResult.success && mappedResult.user && !mappedResult.user.signUp) {
      const {
        user
      } = mappedResult

      dispatch(setLogin({
        email: form.email,
        password: null,
        success: true,
        userData: {
          ...mappedResult,
          form
        }
      }))
      dispatch(setUserCpf(user.cpf))

      return mappedResult
    }

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível fazer login com o Facebook.'
    }))

    return mappedResult
  } catch (e) {
    console.log(e)
  }
}

export const postGoogleLogin = (form) => async (dispatch, getState, api) => {
  try {
    const url = `Usuarios/LogarViaGoogle`
    const data = mapGoogleLoginFormToAPI(form)
    const result = await apiService.post(url, data)
    const mappedResult = mapAuthAPIResponse(result.data)

    if (mappedResult.success && mappedResult.user && mappedResult.user.signUp) {
      dispatch(setLogin({
        email: form.email,
        password: null,
        success: true,
        userData: {
          ...mappedResult
        }
      }))

      return mappedResult
    }

    if (mappedResult.success && mappedResult.user && !mappedResult.user.signUp) {
      const {
        user
      } = mappedResult

      dispatch(setLogin({
        email: form.email,
        password: null,
        success: true,
        userData: {
          ...mappedResult,
          form
        }
      }))
      dispatch(setUserCpf(user.cpf))

      return mappedResult
    }

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível fazer login com o Google.'
    }))
  } catch (e) {
    console.log(e)
  }
}

export const postPhone = (from) => async (dispatch, getState, api) => {
  try {
    const signUp = getState().main.signUp
    const login = getState().main.login
    const accessToken = getState().main.accessToken

    const originData = from === 'login' ? login : signUp

    const {
      resend,
      confirmationType
    } = originData

    const url = `Usuarios/${!resend && confirmationType === 'sms' ? 'ConfirmarTelefone' : (
      resend && confirmationType === 'sms' ? 'ReenviarCodigoVerificacao' : 'ConfirmarTelefonePorVoz'
    )}`

    Object.assign(originData, {
      fullPhone: `+${originData.ddi}${originData.phone && originData.phone.replace(/\(|\)|-| /g, '')}`,
    })

    const data = mapPhoneFormToAPI(originData)
    const result = await apiService.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    const mappedResult = mapAuthAPIResponse(result.data)

    if (mappedResult && mappedResult.success) {
      dispatch(setSignUp({
        ...originData,
        phoneValidation: true
      }))

      return mappedResult
    }

    if (mappedResult && !mappedResult.success && mappedResult.message) {
      dispatch(setSignUp({
        ...originData,
        phoneValidation: false
      }))

      dispatch(setNotification({
        type: 'warning',
        message: mappedResult.message
      }))

      return mappedResult
    }

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível validar o telefone enviado.'
    }))

    return mappedResult
  } catch (e) {
    console.log(e)

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível validar o telefone enviado.'
    }))
  }
}

export const postCode = (from, callback) => async (dispatch, getState, api) => {
  const modalityId = getState().main.modality && getState().main.modality.id
  const addressCallback = callback && callback.length && callback.filter(filteredItem => filteredItem.name === 'address')

  try {
    const signUp = (from === 'login' ? getState().main.login : getState().main.signUp) || {}
    const login = getState().main.login || {}
    const userAddressId = getState().main.userAddress && getState().main.userAddress.id
    const accessToken = getState().main.accessToken

    const url = `Usuarios/ConfirmarCodigoVerificacao`
    const data = mapCodeFormToAPI(signUp)
    const result = await apiService.post(url, data, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    })
    const mappedResult = mapAuthAPIResponse(result.data)

    if (modalityId === 4 && mappedResult && mappedResult.success && !userAddressId) {
      dispatch(setAccessToken(accessToken))

      await dispatch(postAddress(addressCallback))
    }

    if (mappedResult && mappedResult.success) {
      dispatch(setSignUp({
        ...signUp,
        codeValidation: true
      }))

      dispatch(setLogin({
        ...login,
        codeValidation: true
      }))

      return mappedResult
    }

    if (mappedResult && !mappedResult.success && mappedResult.message) {
      dispatch(setSignUp({
        ...signUp,
        codeValidation: false
      }))

      dispatch(setNotification({
        type: 'warning',
        message: mappedResult.message
      }))

      return mappedResult
    }

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível validar o código enviado.'
    }))

    return mappedResult
  } catch (e) {
    console.log(e)

    dispatch(setNotification({
      type: 'warning',
      message: 'Não foi possível validar o código enviado.'
    }))
  }
}

export const postOrder = (callback, ...args) => async (dispatch, getState, api) => {
  const isUpdate = (args && args[0] && args[0]['update'] && args[0]['update'] === true) || false

  const errorCallback = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[0] && (callback.filter(filteredItem => filteredItem.name === 'order'))[0].callback
  const successCallback = callback && callback.filter(filteredItem => filteredItem.name === 'order') && (callback.filter(filteredItem => filteredItem.name === 'order'))[1] && (callback.filter(filteredItem => filteredItem.name === 'order'))[1].callback

  const addressCallback = callback && callback.length && callback.filter(filteredItem => filteredItem.name === 'address')

  try {
    const accessToken = getState().main.accessToken
    const storeId = getState().main.store && getState().main.store.id
    const storeName = getState().main.store && getState().main.store.name
    const products = getState().main.cart && getState().main.cart.products
    const modality = getState().main.modality
    const modalityId = getState().main.modality && getState().main.modality.id
    const address = getState().main.address
    const cartTotal = calculateCartTotal(products)
    const schedulingId = getState().main.scheduling && getState().main.scheduling.hours && getState().main.scheduling.hours.id
    const userCard = getState().main.userCard
    const userAddressId = { value: getState().main.userAddress && getState().main.userAddress.id }
    const deliveryFee = getState().main.store && getState().main.store.deliveryFee
    const pickupFee = getState().main.store && getState().main.store.pickupFee
    const orderId = getState().main.order && getState().main.order.id
    const couponId = (getState().main.cart && getState().main.cart.coupon && getState().main.cart.coupon.id) || 0
    const couponValue = (getState().main.cart && getState().main.cart.coupon && getState().main.cart.coupon.value) || 0
    const couponName = (getState().main.cart && getState().main.cart.coupon && getState().main.cart.coupon.name) || ''

    const specifiedAnalysisData = { value: {} }

    if (modalityId === 4 && !userAddressId.value) {
      await dispatch(postAddress(addressCallback))

      Object.assign(userAddressId, {
        value: getState().main.userAddress && getState().main.userAddress.id
      })
    }

    Object.assign(specifiedAnalysisData, {
      value: {
        address,
        modality,
        products,
        searaSchedulingId: schedulingId,
        storeId,
        cardBrandId: userCard.brandId,
        cardCvv: userCard.cvv,
        cartId: userCard.id,
        change: 0,
        counterDeliveryPlace: '',
        couponId,
        cpf: true,
        deliveryAddressId: userAddressId && userAddressId.value,
        deliveryPayment: false,
        fidelityScoreModelId: 0,
        ip: '',
        paymentType: userCard.paymentType,
        pickupFee,
        deliveryFee,
        total: cartTotal
      }
    })

    dispatch(setNotification(null))

    await dispatch(postOrderAnalysis({
      ...specifiedAnalysisData.value
    }))

    const analysis = getState().main.order && getState().main.order.analysis

    if (!analysis.success) {
      console.warn('Erro na análise')

      return
    }

    const {
      konduto
    } = analysis

    const specifiedOrderData = { value: {} }

    Object.assign(specifiedOrderData, {
      value: {
        address,
        konduto,
        modality,
        products,
        searaSchedulingId: schedulingId,
        storeId,
        cardBrandId: userCard.brandId,
        cardCvv: userCard.cvv,
        cartId: userCard.id,
        change: 0,
        counterDeliveryPlace: '',
        couponId,
        cpf: true,
        deliveryAddressId: userAddressId && userAddressId.value,
        deliveryPayment: false,
        fidelityScoreModelId: 0,
        ip: '',
        orderId: analysis.orderId,
        paymentType: userCard.paymentType,
        pickupFee,
        deliveryFee,
        total: cartTotal
      }
    })

    const url = !isUpdate ? `Pedidos/Adicionar` : `Pedidos/AtualizarPedidosPorComanda`
    const data = !isUpdate ? mapOrderCreateRequest({
      ...specifiedOrderData.value
    }) : mapOrderCreateRequest({
      orderId,
      products
    })
    const result = await apiService.post(url, data, withAuthHeader(mainModule))
    const mappedResult = mapOrderCreateData(result.data)

    if (mappedResult && mappedResult.success) {
      const clientId = 2
      const channelId = 20
      const ipirangaOrderId = 0
      const storeCnpj = mappedResult.cnpj

      const finishData = {
        accessToken,
        clientId,
        channelId,
        ipirangaOrderId,
        vqpOrderId: mappedResult.orderId,
        storeId,
        storeName,
        storeCnpj,
        // deliveryFee: typeValueModalityDelivery ? 0 : deliveryFee,
        deliveryFee,
        total: couponId && cartTotal && couponValue ? cartTotal - couponValue : cartTotal,
        couponValue: couponValue,
        couponName: couponName
      }

      await dispatch(setOrder({
        ...getState().main.order,
        finishData
      }))

      successCallback && successCallback(finishData)

      return
    }

    if (mappedResult && mappedResult.success) {
      await dispatch(setOrder({
        id: mappedResult.orderId
      }))

      const finishData = {
        accessToken,
        orderId: mappedResult.orderId || analysis.orderId,
        storeId,
        storeName,
        total: cartTotal,
        deliveryFee,
        pickupFee
      }

      await dispatch(setOrder({
        ...getState().main.order,
        finishData: {
          ...finishData,
          closed: true
        }
      }))

      successCallback && successCallback(finishData)

      return
    }

    if (mappedResult && !mappedResult.success && mappedResult.message) {
      await dispatch(setNotification({
        type: 'warning',
        message: mappedResult.message
      }))
    }

    errorCallback && errorCallback()
  } catch (e) {
    console.log(e)

    dispatch(setOrder({
      finishData: null
    }))
  }
}

export const postContactUsMessage = (message) => async (dispatch, getState, api) => {
  try {
    const url = `FaleConosco/Enviar`
    const response = await apiService.post(url, { Mensagem: message })
    return { success: true, response }
  } catch (e) {
    const message = 'Não foi possível enviar a mensagem para o suporte.'
    dispatch(setNotification({
      type: 'warning',
      message
    }))
    return { success: false, message }
  }
}

export const clearSignUpData = () => async (dispatch, getState, api) => {
  dispatch({
    type: actionTypes.SET_SIGN_UP,
    payload: undefined
  })
}


export const postSendNewPassword = (email) => async (dispatch, getState, api) => {
  try {
    const url = `Usuarios/RecuperarSenha`
    const response = await apiService.post(url, { Email: email })
    let result = {};

    if (response && response.data) {
      result = {
        ...result,
        success: response.data['Sucesso'],
        message: response.data['Mensagem']
      }
    }

    return result
  } catch (e) {
    const message = 'Não foi possível enviar a nova senha.'
    dispatch(setNotification({
      type: 'warning',
      message
    }))
    return { success: false, message }
  }
}
