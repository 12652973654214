import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  CategoryWrapper,
  H1
} from './styles'

import {
  Product,
  Context
} from '../'

function VerticalMenuComponent(props) {
  const {
    storeLogo,
    handleViewProduct,
    cart,
    categories,
    storeImg
  } = props

  useEffect(() => {
    const products = []

    if (categories) {
      categories.forEach(function (item) {
        products.push(item.products)
      })
    }

    return () => { }
  }, [
    categories
  ])

  return <>
    {categories && categories.length && categories.map((mappedItem, index) => {
      const {
        products,
        name: categoryName,
        id: categoryId
      } = mappedItem

      const key = `stores-products-category-${index}`

      return <CategoryWrapper name={mappedItem.name} id={`${mappedItem.name}`} key={key}>
        <H1>{mappedItem.name}</H1>

        {products && products.map((mappedItem, index) => {
          const {
            id,
            name,
            description,
            image,
            price,
            additionals,
            valorPromocional
          } = mappedItem

          const key = `stores-products-category-group-${index}`
          const formattedPrice = `R$ ${Number(price).toFixed(2).replace('.', ',')}`
          const formattedPricePromotional = valorPromocional ? `R$ ${Number(valorPromocional).toFixed(2).replace('.', ',')}` : null
          const cartProductPrevious = cart && (cart.products && cart.products.length) ? (cart.products.filter(filteredItem => filteredItem.id === id))[0] : null
          const selectedAdditionals = (cartProductPrevious && cartProductPrevious.selectedAdditionals) || []

          return <Product
            direction={'vertical'}
            key={key}
            selectedCategory={{
              name: categoryName,
              id: categoryId
            }}
            handleView={handleViewProduct}
            id={id}
            name={name}
            description={description}
            image={image}
            storeLogo={storeLogo}
            price={formattedPrice}
            rawPrice={price}
            rawPromocional={valorPromocional}
            additionals={additionals}
            selectedAdditionals={selectedAdditionals}
            valorPromocional={formattedPricePromotional}
            storeImg={storeImg}
          />
        })}
      </CategoryWrapper>
    })
    }</>
}

VerticalMenuComponent.propTypes = {
  storeLogo: PropTypes.string,
  selectedProduct: PropTypes.object,
  handleViewProduct: PropTypes.func,
  cart: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = (state) => {
  return {
    cart: state.main.cart || {},
    categories: (state.main.store && state.main.store.categories) || []
  }
}

const GetConnection = connect(mapStateToProps, {})

export const VerticalMenu = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(VerticalMenuComponent))
