import styled from 'styled-components'

export const Wrapper = styled.div`
  min-height: 100%;
  min-width: 100%;
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#CCC'};

  * {
    box-sizing: border-box;
  }

  @media (min-width: 60rem) {
    &.header-full-content--opened {
      margin: 0px;

      > main {
        margin: 0px;

				> div.open-cart{
					right: 21rem;
				}
      }

      > aside {
        background: none;
        min-height: calc(100% - 5.625rem);
        max-height: calc(100% - 5.625rem);
        top: 5.625rem;
				display: unset;

        > nav {
          min-height: 100%;
        }
      }
    }

    &.side-full-content--opened {
      margin: 0px;

      > main {
        margin: 0px;

				> div.open-cart{
					right: 21rem;
				}
      }

      > aside {
        min-height: 100%;
				display: unset;
      }
    }

    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    
    @keyframes fade-out {
      from {
        opacity: 1;
      }
      to {
        opacity: 0;
      }
    }
  }

  @media (min-width: 90rem) {
    &.side-full-content--opened,
    &.header-full-content--opened {
      margin: 0px;
    }
  }
`
