import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	min-width: 100%;
	flex: 1;
	padding-bottom: 1.25rem;
  margin-bottom: 1rem;
	border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;
`

export const Paragraph = styled.p`
  color: #fff;
  font-size: 1rem;
  flex: 1;
  min-width: calc(100% - 2.5rem);
  padding: 1.25rem;
  margin: 1.25rem;
  margin-bottom: 0;
  box-sizing: border-box;
  border: #fff .063rem solid;
  border-radius: .938rem;
`

export const Strong = styled.strong``
