import styled from 'styled-components'

export const Wrapper = styled.div`
  grid-area: summary;
  width: 100%;
  box-sizing: border-box;
  padding: 0;

  @media (min-width: 50rem) {
    padding: 0 0 2rem 2rem;

    > h3 {
      display: none;
    }
  }

  #cart-content {
    #notification {
      display: none;
    }
  }
`

export const H3 = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1rem;
  color: #6B6B6B;
  margin: 2rem 0 1rem 0;
  padding: 0 1.5rem;
  max-width: 12rem;
  text-transform: uppercase;

  @media (min-width: 50rem) {
    font-size: 1.25rem;
    line-height: 1.25rem;
    display: flex;
  }
`

export const OrderSummaryStore = styled.div`
  align-items: center;
  border-bottom: .15rem solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.grey00) || '#EEE'};
  padding: 0 0 1rem 0;
  margin: 0 0 1rem 0;
  display: none;

  @media (min-width: 50rem) {
    display: flex;
  }
`

export const StoreContainer = styled.label`
  display: flex;
  flex-direction: column;
  flex: 1 1 70%;
`

export const StoreLabel = styled.label`
  font-weight: 600;
  font-size: .875rem;
  line-height: 1.188rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const StoreName = styled.h3`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' }; 
  margin: 0;
`

export const StoreLogo = styled.img`
  flex: 0 1 30%;
  max-width: 4rem;
`
export const BrandImage = styled.img`
  width: 2rem;
`
