import React from 'react'

import {
  Wrapper,
  ContentWrapper,
  H1,
  H3,
  InfoBox,
  HelpBox,
  Button,
  ListItem,
  List,
  SummaryWrapper,
  SelectedCardContainer,
  DeliveryBox,
  Footer
} from './styles'

import {
  CustomIcon,
  Div,
  DefaultImage as Image,
  DefaultParagraph as Paragraph,
  H4,
  Span
} from '../../components'

import {   
  getCardBrandImageById,
  numberToCurrency,
  formatCpf
} from '../../../infra/utils'

import {PWACall} from '../../../domain/helpers/ipiranga/PWA'

export const Context = React.createContext({})

const SelectedCard = ({ brand, number}) => {
  return <SelectedCardContainer>
    <span>
      {brand && number ? <>
        <img src={getCardBrandImageById(brand)} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

        { number }
      </>: ''}
    </span>
  </SelectedCardContainer>
}

export function DeliveryOrder(props) {
  const {
    modality,
    storeName,
    storeLogo,
    storePhone,
    date,
    items,
    inProgress,
    summary,
    useCpf,
    cardBrand,
    cardNumber,
    deliveryAddress,
    handleReceipt,
    partnerOrderId
  } = props

  function handleCall(phone){
    PWACall(phone)
  }

  const SubtotalText = ({subtotal}) => (
    <Paragraph className='subtotal'><Span className='label'>Subtotal:</Span> <Span className='price'>{`${numberToCurrency(subtotal)}`}</Span></Paragraph>
  )

  const DiscountText = ({discount}) => (
    <Paragraph className='discount'>
      {discount ?
        <><Span className='label'>Desconto:</Span> <Span className='price'>{`-${numberToCurrency(discount)}`}</Span></> :
        null}
    </Paragraph>
  )

  const TotalText = ({total}) => (
    <Paragraph className='total'><Span className='label'>Total:</Span> <Span className='price'>{`${numberToCurrency(total)}`}</Span></Paragraph>
  )

  const DeliveryFeeText = ({deliveryFee}) => (
    <Paragraph className='fee'><Span className='label'>Taxa de entrega:</Span> <Span className='price'>{deliveryFee ? `${numberToCurrency(deliveryFee)}` : 'Grátis'}</Span></Paragraph>
  )

  return <Wrapper>
    <H1>{storeName}</H1>

    <ContentWrapper id='order-history-order-content'>
      <InfoBox inProgress={inProgress}>
        <Div>
          <Div className='image'>
            <Image src={storeLogo} />
          </Div>
          <Div className='text'>
            <H4>{storeName}</H4>
            <Paragraph>{date}</Paragraph>
          </Div>
        </Div>
        <H1>{modality === 2 ? 'Retirada' : 'Delivery'} | <Span>{inProgress ? 'Em andamento': 'Finalizado'}</Span></H1>
      </InfoBox>

      <HelpBox>
        <H3>Precisa de Ajuda?</H3>

        <Div>
          <Button className="off">Fale Conosco  <CustomIcon name='CommentDots' width='18px' />
          </Button>
          <Button onClick={() => { handleCall(storePhone) }}>
            Ligar para loja  <CustomIcon name='Phone' width='18px' />
          </Button>
        </Div>
      </HelpBox>

      <ListItem>
        {
          items && items.map((item, index) => {
            const {
              Quantidade: qty,
              Produto: name,
              Valor: value,
              Descricao: description,
              TextoAcompanhamento: extra
            } = item

            const key = `checkout-listitem-${index}`

            return <List key={key}>
              <span name="info">
                {name}

                <span>  
                  {extra || description}
                </span>

                <span className="value">{`${numberToCurrency(value)}`}</span>
              </span>

              <span name="info">
                <span>{qty}</span>
              </span>
            </List>
          })
        }
      </ListItem>
      
      {summary && <SummaryWrapper>
        <SubtotalText subtotal={summary.subtotal} />
        <DiscountText discount={summary.discount} />
        <DeliveryFeeText deliveryFee={summary.deliveryFee} />
        <TotalText total={summary.total} />
      </SummaryWrapper>}

      <DeliveryBox>
        <H3>Local de Entrega</H3>

        <Paragraph>{deliveryAddress}</Paragraph>
      </DeliveryBox>
    </ContentWrapper>

    <Footer>
      {useCpf && <Paragraph>CPF na nota: { formatCpf(useCpf) }</Paragraph>}

      <SelectedCard brand={cardBrand || 1} number={cardNumber} />

      {!partnerOrderId && <Button disabled={inProgress ? false : true} onClick={() => {
        handleReceipt()
      }}>Acompanhar pedido</Button>}
    </Footer>
  </Wrapper>
}
