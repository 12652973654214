import React, {
  useEffect, useState
} from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import {
  CustomIcon
} from '../../components'

import { List } from '../'

import { Search } from './Search'

import { setSearchProductCategory, setProductCategory } from '../../../redux/actions/main'

import { Coupon } from '../Coupon'

import {
  Wrapper,
  StoreWrapper,
  H1,
  MobileHeaderWrapper,
  CloseButton,
  Space
} from './styles'
import { CartBar } from '../CartBar'
import history from '../../../history'

function CategoriesProductsComponent(props) {

  const {
    storeName,
    selectedCategory,
    cart,
    selectedProducts,
    storeLogo,
    storeStatus,
    searchProductCategory,
    //removeSelectedProductsCategories,
    handleQuantity,
    handleViewProduct,
    handleAddProduct,
    setSearchProductCategory,
    setProductCategory,
    searchProducts,
    coupon,
    close,
    URLParameters
  } = props

  const categoryId = selectedCategory.id
  const categoryName = selectedCategory.name
  const displayFull = true

  const searchText = searchProductCategory && searchProductCategory.text

  const [renderCartBar, setRenderCartBar] = useState(false)

  useEffect(() => {
    setSearchProductCategory({ text: '' })

    return () => { }
  }, [
    setSearchProductCategory
  ])

  // function handleClose() {
  //   removeSelectedProductsCategories()
  //   setProductCategory({})
  // }

  function handleSearchText(args) {
    postSearchText(args)
  }

  function postSearchText(args) {
    setSearchProductCategory({
      text: args
    })
  }

  function handleSearch() {
    const productsResults = selectedProducts.filter(filteredItem => (filteredItem.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
      filteredItem.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1))

    if (searchText.length) {
      setProductCategory(productsResults)

      setTimeout(() => {

      }, 100)

      return
    }

    setProductCategory({})

  }

  return <Wrapper id='store-categories-products'>
    <MobileHeaderWrapper>
      <CloseButton className='close' onClick={() => {
        close()
      }}>
        <CustomIcon name='Times' />
      </CloseButton>
    </MobileHeaderWrapper>

    <StoreWrapper>{storeName}</StoreWrapper>

    <Coupon show={!!coupon} data={coupon} />

    <Search searchText={searchText} handleSearchText={handleSearchText} handleSearch={handleSearch} />

    <H1>{categoryName}</H1>

    <List
      direction={'horizontal'}
      categoryId={categoryId}
      categoryName={categoryName}
      handleQuantity={handleQuantity}
      handleViewProduct={handleViewProduct}
      handleAddProduct={handleAddProduct}
      cart={cart}
      products={searchProducts.length > 0 ? searchProducts : selectedProducts}
      storeImg={storeLogo}
      status={storeStatus}
      displayFull={displayFull}
    />

    <Space></Space>
    <CartBar
      renderCartBar={renderCartBar}
      setRenderCartBar={setRenderCartBar}
      handleClick={() => {
        history.push(`cart${URLParameters}`)
      }} />

  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeStatus: state.main.store && state.main.store.status,
    storeName: state.main.store && state.main.store.name,
    storeLogo: state.main.store && state.main.store.logo,
    selectedProduct: (state.main.store && state.main.store.selectedProduct) || {},
    selectedCategory: state.main.store && state.main.store.selectedCategory,
    cart: state.main.cart,
    modalityId: state.main.modality.id,
    selectedProducts: state.main.store && state.main.store.products,
    searchProductCategory: state.main.searchProductCategory,
    searchProducts: (state.main.store && state.main.store.searchProducts) || {},
    coupon: state.main.store && state.main.store.coupon,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const Connected = connect(mapStateToProps, {
  setSearchProductCategory,
  setProductCategory
})(withRouter(CategoriesProductsComponent))

export function CategoriesProducts(props) {
  return <Connected {...props} />
}
