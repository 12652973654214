import {
  actionTypes
} from '..'

export const setModality = (modality) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_MODALITY,
    payload: modality
 })
}
