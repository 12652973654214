import styled from 'styled-components'

export const Wrapper = styled.div`
  flex: none;

  .carousel-root {
    min-width: 280px;
    width: 100%;
    max-width: 100%;

    > .carousel {
      &.carousel-slider {
        overflow: unset;
      }

      .slide {
        background: none;

        > img {
          width: 100%;
          height: auto;
        }
      }

      .control-dots {
        position: absolute;
        left: 0;
        top: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: .938rem;

        > * {
          transition: all .25s;
        }

        > li {
          width: .375rem;
          height: .375rem;
          background: #DEDEDE;
          box-shadow: none;
          opacity: 1;
          margin: 0 .125rem;

          &.selected {
            background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
            width: .625rem;
            height: .625rem;
          }
        }
      }

      .carousel-status {
        display: none;
      }

      .thumbs-wrapper {
        display: none;
      }
    }
  }
`
