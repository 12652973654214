import styled from 'styled-components'

import {
  fadeIn
} from '../../components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  box-sizing: border-box;
  align-items: stretch;
  margin-top: 1rem;
  
  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > .close-button {
    flex: 1;
    align-self: flex-end;
    margin-bottom: 1.25rem;
    font-weight: bold;
    position: absolute;
  }

  > div {
    min-width: 100%;

    &#addresses-loading {
      padding-bottom: 0;
    }

    > div {
      &.fixed {
        background: #fff;
        padding: 1.25rem;
        position: fixed;
        top: calc(100% - 6.875rem);
        left: 0;
        width: 100%;
        box-shadow: 0 0 .625rem #e1e1e1;
        transform: translate(0, 0);
      }
    }

    > .overlay {
      min-width: 100%;

      > .postal-code {
        height: 17.813rem;
        min-height: 17.813rem;
        max-height: 17.813rem;
        top: calc(100% - 17.813rem);
        transform: translate(-50%, 0);
      }
    }
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: uppercase;
  text-align: center;
  border-bottom: #E1E1E1 .063rem solid;
  padding-bottom: 1.25rem;
`

export const ContentWrapper = styled.div`
  padding: 0 1.25rem;
  font-size: .875rem;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.25rem;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;

  ${fadeIn}

  &.disabled {
    > ul {
      pointer-events: none;
    }
  }

  > h2 {
    margin-bottom: 1.25rem;
  }
`

export const ListWrapper = styled.ul`
  margin: 0 0 20px 0;
  padding: 0;
  list-style: inside none;
`

export const ListItemWrapper = styled.li`
  display: grid;
  grid-gap: 0;
  grid-template-columns: 3.75rem calc(100% - (3.75rem + 5.938rem)) 5.938rem;
  grid-template-areas: 'icon address actions';

  border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' } .125rem solid;
  border-radius: .938rem;
  padding: 0;
  margin-bottom: 1.25rem;
  transition: all .25s ease-in;
  position: relative;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    &.icon {
      min-height: 100%;
      display: flex;
      align-items: center;
      grid-area: icon;
      padding: 0 1.25rem;
      font-size: 1rem;
      cursor: pointer;

      > span {
        display: flex;
        align-items: center;
        flex: 1;
        min-height: 100%;
        border-right: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' } .125rem solid;
        padding-right: 1.25rem;
      }
    }
  }

  > div + p {
    padding-left: 1.25rem;
  }

  > div {
    &.address {
      grid-area: address;
      padding: .625rem 1.25rem;
      padding-right: 0;
      cursor: pointer;

      > p {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        > span {
          &.first {
            flex: 1;
            font-size: 1rem;
            min-width: 100%;
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#666' }; 
          }

          &.second {
            flex: 1;
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
            font-size: .875rem;   
            min-width: 100%;
          }
        }
      }
    }
  }

  &.active {
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;

    > div {
      > span {
        border-right: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }

      > p {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

        > span {
          &.first, &.second {
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          }
        }
      }
    }

    > p {
      color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

      > span {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }
    }
  }
`

export const ActionsWrapper = styled.div`
  grid-area: actions;
  padding: 0 1.25rem 0 1.25rem;
  min-height: 100%;
  transition: all .25s ease-in;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    > button {
      font-size: 1.25rem;
      background: #fff;
    }

    &.holder {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      &.hide {
        display: none;
      }

      > button {
        font-size: .938rem;
        border: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'} .063rem solid;
        border-radius: 50%;
        width: 1.563rem;
        height: 1.563rem;
        display: flex;
        align-items: center;
        justify-content: center;

        > span {
          flex: 1;
          min-height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          > svg {
            color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#333'};
            flex: 1;
          }
        }
      }
    }

    &.main {
      border-left: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } .125rem solid;
      padding-left: 1.25rem;
      min-height: 100%;
      display: flex;

      > button {
        margin-right: 1.25rem;
        padding: .625rem 0;

        &:last-child {
          margin-right: 0;
        }

        > span {
          > svg {
            color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
          }
        }
      }
    }
  }
`

export const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

export const CloseButton = styled.button`
  background: none;
  border: 0 none;
  font-size: 1.125rem;
  margin-right: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const Span = styled.span``

export const Div = styled.div``

export const Button = styled.button`
  cursor: pointer;
  padding: 0;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
  min-height: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const NewAddressButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 4.375rem;
  min-height: 4.375rem;
  line-height: 4.375rem;
  min-width: calc(100% - 2.5rem);
  max-width: calc(100% - 2.5rem);
  padding: 0;
  margin: 0;
  font-size: 1rem;
  box-sizing: border-box;
`
export const LoadingContainer = styled.div`
  height: 500px;
  min-height: 500px;
  width: 100%;
  display:flex;
  flex: 1;
`
