import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AddressDialog = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 42.5rem;
  flex: 1;
  border: 1px solid gray;
  margin: 5rem 0;

  @media (min-width: 60rem) {
    min-width: 70%;
  }
`

export const AddressDialogContent = styled.main`
  padding: 0 0 1rem 0;
  flex: 1;
`

export const AddressDialogHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  padding: .5rem 1rem;
  position: relative;

  @media (min-width: 60rem) {
    display: none;
  }

  h1 {
    font-weight: bold;
    font-size: 1.25rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  }

  svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};

    path {
      stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    }
  }
`

export const AddressDialogTitle = styled.h2`
  font-weight: 700;
  font-size: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  margin: 0 0 2rem 0;
`

export const AddressCardSideIcon = styled.aside`
  border-right: 1px solid rgba(206, 206, 206, .5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 .85rem 0 0;
  margin: 0 .85rem 0 0;

  @media (min-width: 20rem) {
    padding: 0 1.5rem 0 0;
    margin: 0 1.5rem 0 0;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`

export const AddressCardContent = styled.main`
  flex: 1;
  min-width: 0;
  display: flex;
  flex-wrap: wrap;
`

const textOverflow  = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const StreetAddress = styled.h3`
  margin: 0;
  ${textOverflow}
`

export const NumberAddress = styled.h3`
  width: auto;
  text-align: start;
  margin: 0 0 0 .25rem;
`

export const AddressValue = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;

  ${StreetAddress}, ${NumberAddress} {
    font-weight: 600;
    font-size: 1rem;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' }; 
  }
`

export const AddressState = styled.span`
  font-size: .875rem;
  line-height: 1.25rem;
  width: 100%;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  ${textOverflow}
`

export const AddressAdditional = styled.span`
  font-size: .875rem;
  line-height: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  width: 100%;
  ${textOverflow}
`

export const AddressCardActions = styled.aside`
  margin: 0 0 0 .85rem;
  padding: 0 0 0 .85rem;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  @media (min-width: 20rem) {
    margin: 0 0 0 1.5rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }
`

export const AddressCardEditionActions = styled.div`
  display: flex;
  align-items: center;
  border-left: 1px solid rgba(206, 206, 206, .5);
  height: 100%;

  svg {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    margin: 0 0 0 1rem;
  }
`

export const AddressCard = styled.li`
  list-style: none;
  display: flex;
  align-items: center;
  border: 2px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  border-radius: 15px;
  height: 5rem;
  padding: .5rem .85rem;
  cursor: pointer;

  @media (min-width: 20rem) {
    padding: 1rem 1.5rem;
  }

  &:hover {
    background-color: #f8f8f891;
  }

  &.active-address {
    border: 2px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

    ${AddressCardSideIcon} {
      border-right-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      border-right-width: 2px;

      svg path {
        stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
      }
    }
  }
`

export const AddressList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;

  ${AddressCard}:not(:last-of-type) {
    margin: 0 0 1rem 0;
  }
`

export const AddAddressTitle = styled(AddressDialogTitle)`
  margin: 2rem 0;
`

export const CEPContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 2rem 0;
  flex: 1 0 100%;

  input {
    width: 60%
  }
`

export const Button = styled.button `
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  color: #fff;
  text-align: center;
  font-weight: 700;
  margin: 0 0 0 1rem;
  width: 6rem;
  height: 3rem;
  border: 0;
  border-radius: 15px;
  cursor: pointer;

  &:hover {
    background-color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  }
`

export const SubmitButton = styled(Button)`
  width: 100%;
  margin: 1rem 0 0;
`

export const AddAdressForm = styled.form`
  display: flex;
  flex-wrap: wrap;
`

export const FormFieldAddress = styled.div`
  flex: 1 0 100%;
  width: 100%;
  margin: 0 0 1rem 0;
`

export const FormFieldAddressNumber = styled.div`
  flex: 1 0 25%;
  max-width: 25%;
  margin: 0 0 1rem 0;
`

export const FormFieldAddressAdditional = styled.div`
  flex: 1 0 calc(75% - 1rem);
  max-width: calc(75% - 1rem);
  margin: 0 0 1rem 1rem;
`

export const FormFieldDistrict = styled.div`
  flex: 1 0 calc((100% / 3) - (2rem / 3));
  max-width: calc((100% / 3) - (2rem / 3));
  margin: 0 0 1rem 0;
`

export const FormFieldCity = styled.div`
  flex: 1 0 calc((100% / 3) - (2rem / 3));
  max-width: calc((100% / 3) - (2rem / 3));
  margin: 0 0 1rem 1rem;
`

export const FormFieldState = styled.div`
  flex: 1 0 calc((100% / 3) - (2rem / 3));
  max-width: calc((100% / 3) - (2rem / 3));
  margin: 0 0 1rem 1rem;
`

export const FormFieldReference = styled.div`
  flex: 1 0 100%;
  width: 100%;
`

export const SaveAddressLabel = styled.p`
  margin: 2rem 0 1rem;
  font-weight: 700;
  font-size: 1.125rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  width: 100%;
`

export const AddressCategory = styled.li`
  list-style: none;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  border-radius: 1rem;
  max-height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f4f4f4;
  padding: .5rem;
  margin: 0 0 .5rem 0;
  font-weight: 600;
  font-size: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  cursor: pointer;
  box-sizing: border-box;

  svg {
    width: 1rem;
    height: 1rem;
    flex: 0 0 1rem;
  }

  span {
    padding: 0 0 0 .5rem;
  }

  &.category-active {
    border: 2px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    transition: .1s;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };

    svg path {
      stroke: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    }
  }
`

export const AddressCategoryList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  gap: 1rem;
`

export const MobileHeaderWrapper = styled.header`
  width: 100%;

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
      font-size: 1.25rem;
      font-weight: 100;
    }
  }

  @media (min-width: 50rem) {
    display: none;
  }
`

export const CloseButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`
