import {
  actionTypes
} from '../'

import {
  loading
} from '../loading'

export const fetchHighlights = (callback) => (dispatch, getState, container) => {
  try {
    const latitude = getState().main.latitude
    const longitude = getState().main.longitude
    const modalityId = getState().main.modality && getState().main.modality.id

    const data = {
      latitude,
      longitude,
      modalityId
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchHighlights({
      onSuccess: ({ highlights}) => {
        dispatch(setHighlights(highlights))
      },
      onError: () => {
        dispatch(setHighlights([]))
      },
      onEmpty: () => {
        dispatch(setHighlights([]))
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setHighlights([]))
  } finally {
    callback && callback()
  }
}

export const setHighlights = (highlights) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_HIGHLIGHTS,
    payload: highlights
 })
}
