import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import { connect } from 'react-redux'

import NotepadIconPNG from '../assets/notepad-icon.png'
import IpirangaLoadingGIF from '../assets/ipiranga-loading.gif'

import {
  useCustomLayout, useDeviceLayout
} from '../hooks'

import {
  CustomIcon,
  Div,
  DefaultImage as Image,
  DefaultParagraph as Paragraph,
  H2,
  Button,
  H4,
  Strong
} from '../components'

import {
  order
} from '../../state'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import history from '../../history'

import {
  Wrapper,
  H1,
  ContentWrapper,
  LoadingWrapper,
  ListWrapper,
  ListItemWrapper,
  LoadingImage,
  BackButton,
  EmptyContentWrapper
} from './styles'

import { handleGTM, setUserHistory } from '../../redux/actions/main'

const getStatusLabel = ({
  status
}) => {
  if (status === 1) {
    return 'Em andamento'
  }

  if (status === 2) {
    return 'Finalizado'
  }
}

const getModalityLabel = ({
  modalityId
}) => {
  if (modalityId === 2) {
    return 'Retirada'
  }

  if (modalityId === 4) {
    return 'Delivery'
  }
}

export const Context = React.createContext({})

function EmptyContent({
  URLParameters,
  data
}) {
  const {
    content,
    footer,
    header,
    userHistory
  } = data

  const {
    button
  } = footer

  const {
    back = 'home'
  } = userHistory

  return <EmptyContentWrapper>
    <Div className='header'>
      <Image src={NotepadIconPNG} alt={header.image.text} />
    </Div>
    <Div className='content'>
      <H2>{content.title}</H2>

      <Paragraph>{content.text}</Paragraph>
    </Div>
    <Div className='footer'>
      <Button className="large full-width" onClick={() => {
        if (back && back !== 'order-history') {
          history.push(`/${back}${URLParameters}`)

          return
        }

        history.push(`/home${URLParameters}`)
      }}>{button.label}</Button>
    </Div>
  </EmptyContentWrapper>
}

function Content({
  data,
  handleOrder
}) {
  return <ListWrapper>
    { data.map((data, index) => {
      const key = `order-history-list-item-${ index }}`

      const {
        id,
        shortDate,
        modalityId,
        inProgress,
        amountPaid,
        store
      } = data

      const modalityLabel = getModalityLabel({ modalityId})
      const statusLabel = getStatusLabel({ status: !!inProgress ? 1 : 2})

      return (
        <ListItemWrapper key={key} className={`list-item`} onClick={() => {
          handleOrder(id)
        }}>
          <Div>
            <Div className='image'>
              <Image src={store['logoUrl']} />
            </Div>
            <Div className='text'>
              <H4>{store['name']}</H4>
              <Paragraph>{shortDate}</Paragraph>
              <Paragraph><Strong className='modality'>{modalityLabel} |</Strong> <Strong className={`status ${ !!inProgress ? 'inprogress' : 'done'}`}>{statusLabel}</Strong></Paragraph>
              <Paragraph><Strong className='price'>{`R$ ${amountPaid.toFixed(2).replace('.', ',')}` }</Strong></Paragraph>
            </Div>
            <Div className='arrow'>
              <CustomIcon name='AngleRight'/>
            </Div>
          </Div>
        </ListItemWrapper>
      )
   }) }
  </ListWrapper>
}

export function OrderHistoryPage(props) {
  const {
    orderHistory,
    userHistory,
    setUserHistory,
    fetchOrderHistory,
    loading,
    device,
    setOrder,
    userId,
    handleGTM,
    URLParameters
  } = props

  const context = useContext(Context)

  const {
    sections
  } = context

  const {
    orders = []
  } = orderHistory

  const selectedSectionName = orders && orders.length ? 'main-content' : 'empty-content'
  const selectedSection = sections.filter(filteredItem => filteredItem.id === selectedSectionName)[0]
  const sectionContent = selectedSection && selectedSection.data

  const {
    back
  } = userHistory

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const [contentMotion, setContentMotion] = useState(false)

  useCustomLayout({
    device,
    backgroundColor: '#fff'
 })

 useEffect(() => {
  const url = window.location.href;
  if (isMobile) {
    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Meus pedidos'
    }
    handleGTM(access)
  }

  if(userId){
    handleGTM({user_id: userId})
  }
}, [
  isMobile,
  userId,
  handleGTM
])

  useEffect(() => {
    setTimeout(() => fetchOrderHistory(), 1000)
  }, [
    fetchOrderHistory
  ])

  useEffect(() => {
    if (orderHistory.orders && orderHistory.length && !contentMotion) {
      setTimeout(() => setContentMotion(true), 500)
    }

    if(loading.indexOf('order-history') < 0 && !orderHistory.length && !contentMotion) {
      setTimeout(() => {
        setContentMotion(true)
      }, 500)
    }
  }, [
    loading,
    orderHistory,
    contentMotion
  ])

  function handleOrder(id) {
    setOrder({
      id
    })

    setUserHistory({
      back: 'order-history'
    })

    history.push(`/order-history/${id}${URLParameters}`)
  }

  return <Wrapper>
    <H1>{ context.title }</H1>
    
    <BackButton className='back-button' onClick={ () => {
      if (back && back !== 'order-history') {
        history.push(`/${back}${URLParameters}`)

        return
      }

      history.push(`/home${URLParameters}`)
    } }>
      <CustomIcon name='AngleLeft'/>
    </BackButton>

    { loading.indexOf('order-history') >= 0 && <LoadingWrapper id='order-history-loading'>
      <LoadingImage src={ IpirangaLoadingGIF } />
    </LoadingWrapper> }

    { loading.indexOf('order-history') < 0 && orderHistory.orders ? <ContentWrapper id='order-history-content' className={`${ contentMotion ? 'motion' : ''}`}>
      { orderHistory.orders && orderHistory.orders.length ? <Content data={ orderHistory.orders } handleOrder={handleOrder} /> : <EmptyContent URLParameters={URLParameters} data={{...sectionContent, userHistory}} /> }
    </ContentWrapper> : null }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    orderHistory: state.main.orderHistory || { orders: [] },
    device: (state.main.params && state.main.params.device) || '',
    userHistory: state.main.userHistory || {},
    loading: state.main.loading || '',
    loaded: state.main.loaded || [],
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchOrderHistory: order.fetchOrderHistory,
  setOrder: order.setOrder,
  setUserHistory,
  handleGTM
})

export const OrderHistory = React.memo(pipe(
  GetConnection,
  GetContent({context: Context, id: 'order-history'})
)(OrderHistoryPage))
