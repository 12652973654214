import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import LogRocket from 'logrocket'
import setupLogRocketReact from 'logrocket-react'

import {
  SENTRY,
  SENTRY_DSN,
  SENTRY_PROJECT,
  SENTRY_RATE,
  LOGROCKET,
  LOGROCKET_PROJECT,
  GOOGLE_ANALYTICS
} from './config'

import App from './view/app'
import createStore from './state/createStore'
import * as container from './container'
import * as serviceWorker from './infra/service-worker'

const store = createStore({
  container
})

if (SENTRY === 'true') {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: `${ SENTRY_PROJECT }@` + process.env.npm_package_version,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: SENTRY_RATE
  })
}

if (LOGROCKET === 'true') {
  LogRocket.init(`${ LOGROCKET_PROJECT }`)
  setupLogRocketReact(LogRocket)

  if (SENTRY === 'true') {
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL);
      })
    })
  }

  if (GOOGLE_ANALYTICS) {
    LogRocket.getSessionURL(function (sessionURL) {
      window.ga && window.ga('send', {
        hitType: 'event',
        eventCategory: 'LogRocket',
        eventAction: sessionURL
      })
    })
  }
}

ReactDOM.render(<App store={store} />, document.getElementById('root'))

serviceWorker.unregister()
