import Theme from './theme.json'

export const ORIGIN = process.env.REACT_APP_ORIGIN || 'https://www.voceqpad.com.br'
export const STORE_NAME = process.env.REACT_APP_STORE_NAME || 'VQP Teste'
export const URL = process.env.REACT_APP_URL || 'https://ceiaprontaseara.com.br'
export const TITLE = process.env.REACT_APP_TITLE || 'Ceia Pronta Seara'
export const DESCRIPTION = process.env.REACT_APP_DESCRIPTION || 'Ceia Pronta Seara é mais uma novidade da marca para um Natal repleto de sabor e praticidade.'
export const API_URL = process.env.REACT_APP_API_URL ||
  'https://app-voceqpad-stage.azurewebsites.net/api/api/'
  // 'https://voceqpad-dev.azurewebsites.net/api/api/'
  //'//www.voceqpad.com.br/api/api/'
  // 'https://voceqpad-staging.azurewebsites.net/api/api/'

export const APP_ID = process.env.REACT_APP_APP_ID || 17
export const ESTABELECIMENTO_ID = process.env.REACT_APP_ESTABELECIMENTO_ID || 10
export const PLATAFORM_ID = process.env.REACT_APP_PLATAFORM_ID || 3
export const ANDROID_APP_URL = process.env.REACT_APP_ANDROID_APP_URL
export const IPHONE_APP_URL = process.env.REACT_APP_IPHONE_APP_URL
export const WEBSITE_QRCODE_URL = process.env.REACT_APP_WEBSITE_QRCODE_URL
export const THEME = Theme

// Feature toggle settings
export const MULTI_STORE = process.env.REACT_APP_MULTI_STORE || 'true'
export const CARDAPIO_CONSULTA = process.env.REACT_APP_CARDAPIO_CONSULTA || 'false'
export const CARDAPIO_CARD = process.env.REACT_APP_CARDAPIO_CARD || 'false'
export const GELADEIRA_PEGUEEPAGUE = process.env.REACT_APP_GELADEIRA_PEGUEEPAGUE || 'false'
export const BALCAO = process.env.REACT_APP_BALCAO || 'false'
export const MESA = process.env.REACT_APP_MESA || 'false'
export const IPIRANGA = process.env.REACT_APP_IPIRANGA || 'false'
export const AMPM = process.env.REACT_APP_AMPM || 'false'
export const RELEASE_CANDIDATED = process.env.REACT_APP_RELEASE_CANDIDATED || 'true'
export const FLOW = process.env.REACT_APP_FLOW || '2'
export const DELIVERY_SCHEDULING = process.env.REACT_APP_DELIVERY_SCHEDULING || 'false'
export const LOGIN = process.env.REACT_APP_LOGIN || 'true'
export const PAYMENT = process.env.REACT_APP_PAYMENT || 'true'
export const MINIMALIST_STORE_LIST = process.env.REACT_APP_MINIMALIST_STORE_LIST || 'true'
export const REACT_APP_WHATSAPP = process.env.REACT_APP_WHATSAPP || 'true'
export const REACT_APP_WHATSAPP_NUMBER = process.env.REACT_APP_WHATSAPP_NUMBER || "+5511999310914"

// Integration settings
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY || 'AIzaSyBUKUg5XlcJFnqSQ1R8phCnbRe7owwMVYI'
export const GOOGLE_ANALYTICS = process.env.REACT_APP_GOOGLE_ANALYTICS || 'true'
export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || ''
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID || '400139721477449'
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || '82003366480-siij1o32fe9vrfse8s2koekdllq9dsda.apps.googleusercontent.com'
export const GTM_KEY = process.env.REACT_APP_GTM_KEY || 'false'
export const SENTRY = process.env.REACT_APP_SENTRY || 'false'
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN || 'https://2e297670e9ea40edb474639d423e9278@o559881.ingest.sentry.io/5695087'
export const SENTRY_PROJECT = process.env.REACT_APP_SENTRY_PROJECT || 'voceqpad'
export const SENTRY_RATE = process.env.REACT_APP_SENTRY_RATE || '1.0'
export const LOGROCKET = process.env.REACT_APP_LOGROCKET || 'false'
export const LOGROCKET_PROJECT = process.env.REACT_APP_LOGROCKET_PROJECT || '6g1nvp/voceqpad-seara'
export const MUNDIPAGG_PUBLIC_KEY = process.env.REACT_APP_MUNDIPAGG_PUBLIC_KEY || 'pk_nwBRe4zcBs1qba2d'
