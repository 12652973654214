import React, {
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

/* import FacebookButtonImage from '../assets/facebook.svg'
import GoogleButtonImage from '../assets/google.svg' */
import LoadingGIF from '../../assets/loading.gif'

import history from '../../../history'

import { CustomIcon } from '../../components'

import {
  postLogin,
  setNotification,
  setLogin,
  setModality,
  getLogin,
  setAccessToken,
  postAddress,
  postFacebookLogin,
  postGoogleLogin,
  setUserHistory,
  postCode,
  postPhone,
  setCard,
  setUserCard,
  handleGTM
} from '../../../redux/actions/main'

import {
  Form
} from '..'

import {
  Notification
} from '../..'

import {
  Phone
} from '../../sign-up'

import {
  Wrapper,
  H1,
  LoadingImage,
  LoadingWrapper,
  ContentWrapper,
  CloseButton,
  Paragraph,
  Div,
  OverlayWrapper
} from './styles'

export const Context = React.createContext({})

function LoginPage(props) {
  const {
    postLogin,
    setLogin,
    setNotification,
    getLogin,
    setAccessToken,
    postAddress,
    modalityId,
    login,
    userHistory,
    setUserHistory,
    setCard,
    setUserCard,
    postCode,
    postPhone,
    handleGTM,
    loading,
    URLParameters
  } = props

  const {
    phone,
    code,
    ddi,
    country,
    messageType,
    messageErrors,
    resend
  } = login

  const {
    next,
    back
  } = userHistory

  const [contentMotion, setContentMotion] = useState(false)
  const [edit, setEdit] = useState(false)
  const [contentOverlayMotion, setContentOverlayMotion] = useState(false)
  const [phoneReady, setPhoneReady] = useState(false)
  const [phoneStep, setPhoneStep] = useState(0)
  const [time, setTime] = useState(0)

  useEffect(() => {
    document.querySelector('#root').style.background = '#fff'

    setLogin({
      ...login,
      ddi: 55,
      country: 'brasil',
      messageType: 'sms',
      messageErrors: 0,
      resend: false
   })

    setTimeout(() => setContentMotion(true), 1000)
  }, [
    setLogin,
    login
  ])

  useEffect(() => {
    if (phoneReady) {
      setContentOverlayMotion(true)
    }
  }, [
    phoneReady
  ])

  function handleSignUp() {
    history.push(`/sign-up${URLParameters}`)
  }

  function handleInput(event) {
    const {
      name,
      value
    } = event.target

    if (!edit) {
      setLogin({...login})
      setEdit(true)
    }

    const newValue = {
      [name]: value
    }

    if (name === 'ddi') {
      const value = event.target.value.split('|')
      const country = value[0]
      const ddi = value[1]

      Object.assign(newValue, {
        country,
        ddi
      })
    }

    setLogin({
      ...login,
      ...newValue
   })
  }

  async function handleSubmit() {
    await postSubmit()

    const login = getLogin()

    if (login && login.success && next && login.userData && login.userData.subscriptionStatus === 1) {
      const token = login.userData && login.userData.accessToken

      await setAccessToken(token)
      await postSave()

      handleGTM({
        'event': `LOGIN`
      })

      setCard(null)
      setUserCard(null)

      setNotification(null)

      history.push(`${next}${URLParameters}`)
      
      setUserHistory({
        next: null
      })
      
      return
    }

    if (login && login.success && !next && login.userData && login.userData.subscriptionStatus === 1) {
      await setAccessToken(getLogin().userData && getLogin().userData.accessToken)
      await postSave()

      handleGTM({
        'event': `LOGIN`
      })

      setCard(null)
      setUserCard(null)

      setNotification(null)

      history.push(`/cart${URLParameters}`)

      return
    }

    if (login && login.success && login.userData && login.userData.subscriptionStatus === 2) {
			await setAccessToken(getLogin().userData && getLogin().userData.accessToken)
      await postSave()

      handleGTM({
        'event': `LOGIN`
      })

      setPhoneReady(true)
      setNotification(null)

      return
    }

    setNotification({
      type: 'warning',
      message: 'Não foi possível fazer o login!'
   })
  }

  async function postSave() {
    if (modalityId === 2) {
      return false
    }

    await postAddress([() => {
      setUserHistory({
        next: 'login',
        back: 'login'
      })

      setNotification({
        type: 'warning',
        message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
      })

      history.push(`/new-address${URLParameters}`)
    }, () => {
      console.warn('Address created')
    }])
  }

  async function postSubmit() {
    await postLogin([{
      name: 'address',
      type: 'error',
      callback: () => {
        setUserHistory({
          next: 'login',
          back: 'login'
        })
  
        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })

        history.push(`/new-address${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'success',
      callback: () => {
        console.warn('Address created')
      }
    }])
  }

  function handleStep(args) {
    setPhoneStep(args)
  }

  function postTime(args) {
    setTime(args)
  }

  async function handleTime(args, time = 0, data) {
    if (!time && data) {
      await setLogin({
        ...login,
        ...data
      })
    }

    setTimeout(() => {
      postTime(Number(time + 1))

      if (time + 1 < args) {
        handleTime(args, time + 1)
      }
    }, 1000)
  }

  async function handlePhoneSubmit() {
    await postPhone('login')

    handleTime(30, 0)

    if (login.phoneValidation) {
      return
    }

    setNotification({
      type: 'Não foi possível enviar o código para seu telefone celular.'
   })
  }

  async function handleCodeSubmit() {
    await postCode('login', [{
      name: 'address',
      type: 'error',
      callback: () => {
        setUserHistory({
          next: 'login',
          back: 'login'
        })
  
        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })
        
        history.push(`/new-address${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'success',
      callback: () => {
        console.warn('Address created')
      }
    }])

    const login = getLogin()

    if (login.codeValidation) {
      const token = login.userData && login.userData && login.userData.accessToken

      setCard(null)
      setUserCard(null)
      setAccessToken(token)
      setLogin({
        ...login,
        success: true
      })
      setPhoneReady(false)

      handleGTM({
        'event': `LOGIN`
      })

      history.push(`${next}${URLParameters}`)

      return
    }

    setPhoneReady(true)
    setPhoneStep(0)
  }

  return <Wrapper>
    <CloseButton className='close' onClick={ () => {
      history.push(`${back}${URLParameters}`)

      setNotification(null)
      setUserHistory({
        back: null
      })
    }}>
      <CustomIcon name='Times' />
    </CloseButton>

    <H1>Seja bem vindo a Ceia de Natal!</H1>

    <Notification />

    {loading.indexOf('loading') >= 0 && <LoadingWrapper id='login-loading'>
      <LoadingImage src={LoadingGIF} />
    </LoadingWrapper> }

    {loading.indexOf('loading') < 0 && <ContentWrapper id='login-content' className={ contentMotion ? 'motion' : '' }>
      <Paragraph>Faça login com o seu e-mail.</Paragraph>

      <Form data={ login } handleInput={ handleInput } handleSubmit={ handleSubmit } handleSignUp={ handleSignUp } />

      <OverlayWrapper className={ contentOverlayMotion ? 'motion' : '' }>
        <Div className='background' onClick={() => {
          if (contentOverlayMotion) {
            setContentOverlayMotion(false)
            setPhoneReady(false)
          }
        }} />

        { phoneReady ? 
          <Phone
            step={ phoneStep }
            country={ country || 'brasil' }
            ddi={ ddi || 55 }
            phone={ phone }
            code={ code }
            resend={ resend }
            messageType={ messageType }
            messageErrors={ messageErrors }
            time={ time }
            handleTime={ handleTime }
            handleStep={ handleStep }
            handleInput={ handleInput }
            handlePhoneSubmit={ handlePhoneSubmit }
            handleCodeSubmit={ handleCodeSubmit } /> :
          null }
      </OverlayWrapper>
    </ContentWrapper> }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeLogo: state.main.store && state.main.store.logo,
    login: state.main.login || {},
    loading: state.main.loading || [],
    userHistory: state.main.userHistory || {},
    modalityId: state.main.modality && state.main.modality.id,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  getLogin,
  postLogin,
  setAccessToken,
  setLogin,
  setModality,
  setNotification,
  postAddress,
  postGoogleLogin,
  postFacebookLogin,
  setUserHistory,
  setCard,
  setUserCard,
  postCode,
  postPhone,
  handleGTM
})

export function Login(props) {
  const MemoizedComponent = React.memo(LoginPage)
  const Component = pipe(
    GetConnection,
    GetContent({context: Context, id: 'login'})
  )(MemoizedComponent)

  return <Component { ...props } />
}
