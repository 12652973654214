interface AgglomerationsList  {
  label: string
  image: string
  id: number
}

export const mapAgglomerationsList = (data: any) : AgglomerationsList[] | [] => {
  const agglomerations = (data['Aglomerados'] && data['Aglomerados'].length && data['Aglomerados'].map((mappedItem: any) => {
    return {
      label: mappedItem['Nome'],
      image: mappedItem['ImagemUrl'],
      id: mappedItem['AglomeracaoId']
    }
 })) || []

	return agglomerations
}
