import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { pipe } from '../../../domain/helpers'

import {
  modality,
  store,
  loading
} from '../../../state'

import {
  setAccessToken,
  setUserName,
  setUserAddress,
  setUserAddresses,
  setAddressByCEP,
  setAddress,
  setCEP,
  setUserCard,
  setCart,
  setUserCards,
  setSignUp,
  handleGTM,
  setURLParameters
} from '../../../redux/actions/main'

import { useCustomLayout } from '../../hooks'

import {
  Stepper
} from '../../components'

import {
  Home,
  Splash
} from '../'

function WelcomeComponent(props) {
  const {
    setModality,
    setStoresPagination,
    setLoading,
    setStore,
    setStores,
    setAccessToken,
    setUserName,
    setUserAddress,
    setUserAddresses,
    setAddressByCEP,
    setAddress,
    setCEP,
    setUserCard,
    setCart,
    setUserCards,
    setSignUp,
    theme,
    location,
    handleGTM,
    setURLParameters
  } = props

  const [loadedGTM, setLoadedGTM] = useState(false)

  const {
    primaryColor
  } = props.theme.main.general

  useCustomLayout({
    backgroundColor: primaryColor
  })

  useEffect(() => {
    const url = window.location.href;
    const divUrl = url.split("?")

    if (divUrl && divUrl.length > 1) {
      const urlParameters = divUrl ? `?${divUrl[1]}` : ''
      setURLParameters(urlParameters)
    }

    if (!loadedGTM) {
      setLoadedGTM(true)
      const access = {
        unique: true,
        eventId: 'homePageView',
        event: 'PageView',
        page_path: url,
        page_title: 'Home'
      }

      handleGTM(access)
    }
  }, [
    handleGTM,
    loadedGTM,
    setURLParameters
  ])

  useEffect(() => {
    setAccessToken(null)
    setUserName(null)
    setUserAddress(null)
    setUserAddresses(null)
    setAddressByCEP(null)
    setAddress(null)
    setCEP(null)
    setUserCard(null)
    setCart({ products: null })
    setUserCards(null)
    setSignUp(null)
    setStore(null)
    setStores([])
    setModality({ id: 4 })
    setStoresPagination({
      offset: 0,
      total: 1
    })
    setLoading({ item: 'welcome', delete: true })
    setLoading({ item: 'stores-list', delete: true })
    setLoading({ item: 'store', delete: true })
    setLoading({ item: 'address', delete: true })
    setLoading({ item: 'addressess', delete: true })
    setLoading({ item: 'userAddress', delete: true })
    setLoading({ item: 'userAddressess', delete: true })

  }, [
    setModality,
    setStoresPagination,
    setLoading,
    setStore,
    setStores,
    setAccessToken,
    setUserName,
    setUserAddress,
    setUserAddresses,
    setAddressByCEP,
    setAddress,
    setCEP,
    setUserCard,
    setCart,
    setUserCards,
    setSignUp
  ])

  return <>
    <Stepper steps={[{
      element: (stepProps) => <Home theme={theme} location={location} {...stepProps} />
    }, {
      element: (stepProps) => <Splash theme={theme} {...stepProps} />
    }]} />
  </>
}

WelcomeComponent.propTypes = {}

const mapStateToProps = (state) => {
  return {}
}

const GetConnection = connect(mapStateToProps, {
  setModality: modality.setModality,
  setStoresPagination: store.setStoresPagination,
  setStore: store.setStore,
  setStores: store.setStores,
  setLoading: loading.setLoading,
  setAccessToken,
  setUserName,
  setUserAddress,
  setUserAddresses,
  setAddressByCEP,
  setAddress,
  setCEP,
  setUserCard,
  setCart,
  setUserCards,
  setSignUp,
  handleGTM,
  setURLParameters
})

export const Welcome = React.memo(pipe(
  GetConnection
)(WelcomeComponent))
