export default ({
  storeRepository,
  maps,
  auth
}) => {
  return async (payload) => {
    const {
      onSuccess,
      onError,
      data,
      actions,
      dispatch,
      getState
    } = payload

    try {
      const store = await storeRepository.fetchStore({
        data,
        actions,
        maps,
        dispatch,
        getState,
        auth: auth()
      })

      onSuccess({store})
    } catch(e) {
      console.log(e)

      onError()
    }
  }
}
