import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border-right: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
  position: fixed;
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#CCC'};
  z-index: 2;
  height: 100px;

  > img{
    width: 70px;
  }

  @media (min-width: 60rem) {
    border-right: 0;
    padding: 1rem 2rem;
    max-height: 5.625rem;
    min-height: 5.625rem;

    ${props => {
      if (props.opened) {
        return `width: calc(100% - 20rem);`
      }

      return `width: 100%`
    }}
  }
`

export const LocationAddressFull = styled.span`
  margin: 0 .5rem 0 .5rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
  font-weight: 500;
  font-size: 1rem;
  display: none;

  @media(min-width: 70rem){
    display: unset;
  }
`

export const LocationAddressSmall = styled.span`
  margin: 0 .5rem 0 .5rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
  font-weight: 500;
  font-size: 1rem;

  @media(max-width: 70rem){
    display: none;
  }
`


export const LocationAddress = styled.span`
  margin: 0 .5rem 0 .5rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
  font-weight: 500;
  font-size: 1rem;

  @media(min-width: 70rem){
    display: none;
  }
`

export const DeliveryAddressLocationContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg path {
    fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
    fill-opacity: .8;
  }
  
  &:hover {
    ${LocationAddress} {
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
      opacity: .8;
    }

    svg path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'};
      fill-opacity: .8;
    }
  }
`

export const StoreSelectContainer = styled.div`
  height: 4rem;
  border: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000'};
  border-radius: 0.8rem;
  padding: .4rem .8rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  * {
    pointer-events: none;
  }

  > img {
    width: 50px;
    height: 100%;
  }

  svg path {
    fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    fill-opacity: .8;
  }
`
export const StoreName = styled.span`
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
  font-weight: bold;
  font-size: .8rem;
  padding: 0 .7rem;
  max-width: 8rem;
`

export const DeliveryAddressTitle = styled.span``

export const DeliveryAddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 1.5rem;

  ${DeliveryAddressTitle} {
    margin: 0 0 .5rem 0;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
    font-size: .875rem;
    font-weight: 700;
  }
`

export const CartItemsButtonContainer = styled.div`
  position: relative;
  margin: 0 .35rem 0 0; 
`

export const CartItemsQuantity = styled.span`
  border: 2px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  border-radius: 50%;
  width: .1rem;
  height: .1rem;
  padding: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
  font-size: .55rem;
  background-color: white;
`

export const StoreHeaderActionButton = styled.button`
  background-color: transparent;
  border: 0;
  display: flex;
  align-items: center;
  color: ${props => props.opened ? `${(props.theme && props.theme.main && props.theme.main.general
    && props.theme.main.general.primarycolor) || '#333'}` : `${(props.theme && props.theme.main && props.theme.main.general
      && props.theme.main.general.primarycolor) || '#333'}`};
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  height: 2rem;
  position: relative;

  ${props => !props.opened ? `
    &:hover {
      color: ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primarycolor) || '#333'};

      svg path:not([stroke]) {
        fill: ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#333'};
      }

      ${CartItemsQuantity} {
        border: 2px solid ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#333'};
      }
    }
  ` : `

    ${CartItemsQuantity} {
        border: 2px solid ${(props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.backgroundBody) || '#333'};
    }
  `
  };

  svg {
    margin: 0;
    width: 2rem;

    g{
      stroke: ${props => props.opened ? `${(props.theme && props.theme.main &&
    props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'}` : `${(props.theme && props.theme.main &&
      props.theme.main.general && props.theme.main.general.colorTextHeader)}`};
    }

    path{
      stroke: ${props => props.opened ? `${(props.theme && props.theme.main &&
    props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'}` : `${(props.theme && props.theme.main &&
      props.theme.main.general && props.theme.main.general.colorTextHeader)}`};
    }

    path:not([stroke]) {
      fill: ${props => props.opened ? `${(props.theme && props.theme.main &&
    props.theme.main.general && props.theme.main.general.colorTextHeader) || '#333'}` : `${(props.theme && props.theme.main &&
      props.theme.main.general && props.theme.main.general.colorTextHeader)}`};
    }
  }

`

export const StoreHeaderActionsContainer = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translate(0, -50%);
  left: auto;

  ${StoreHeaderActionButton}:first-of-type {
    margin: 0 .2rem 0 0;
  }
`

export const ModalityContainer = styled.div`
  position: absolute;
  left: auto;
  top: 50%;
  right: 10rem;
  transform: translate(0, -50%); 
  margin: 0 2rem;
  display: flex;
  border:${props => {
    if (!props.storeDelivery) {
      return 'none'
    }

    return `1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'}`

  }};
  background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000'};
  border-radius: .5rem;

  &.btn-group button {
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'};
    padding: 5px 15px;
    cursor: pointer;
    float: left;
    font-weight: bold;
    border-radius: .5rem;
    font-size: .8rem;

    &.active{
      background-color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
      border-radius: .5rem;
      color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
      box-shadow:2px 2px 9px 1px;
      z-index: 1;
    }
  }

  @media (min-width: 60rem) {
    ${props => {
      if (!!props.opened) {
        return `display: none;`
      } else {
        return `display: unset;`
      }
    }}
  }

  @media (min-width: 70rem) {
    display: unset;
  }
`

export const Button = styled.button``


export const IconButtonCartsItemsAmount = styled.span`
  border: 2px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.colorTextHeader) || '#000'};
  background: #FFF;
  border-radius: 50%;
  width: .1rem;
  height: .1rem;
  padding: .35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 9px;
  right: -6px;
  font-size: .55rem;
`

export const Img = styled.div`
  position: absolute;
  right: -5px;
  margin-top: 5px;
  animation: fade-in 500ms;

  ${props => !props.opened && `display: none;`};
`