import React, {
  useEffect,
  useState,
  useContext
} from 'react'
import { connect } from 'react-redux'

import LoadingGIF from '../assets/ipiranga-loading.gif'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import history from '../../history'

import { CustomIcon } from '../components'

import {
  setUserCard,
  fetchCards,
  setCVV,
  setCard,
  setCardName,
  setCardNumber,
  setCardDate,
  setCardBrandId,
  setCardCPF,
  setCardCVV,
  setNotification,
  setUserHistory,
  postCard,
  handleGTM
} from '../../redux/actions/main'

import {
  CVV,
  List,
  NewCard
} from './'

import {
  Notification
} from '../'

import {
  Wrapper,
  ContentWrapper,
  LoadingWrapper,
  LoadingImage,
  H1,
  BackButton,
  NewPaymentButton,
  OverlayWrapper,
  Div
} from './styles'

export const Context = React.createContext({})

export function PaymentPage(props) {
  const {
    card,
    userCard,
    userCards,
    fetchCards,
    setUserCard,
    setCVV,
    setCardName,
    setCardNumber,
    setCardDate,
    setCardCPF,
    setCardBrandId,
    setCardCVV,
    setUserHistory,
    setNotification,
    postCard,
    cartProducts,
    modalityId,
    storeName,
    handleGTM,
    URLParameters
  } = props

  const {
    cvv
  } = userCard

  const {
    name: cardName,
    number: cardNumber,
    date: cardDate,
    CPF: cardCPF,
    CVV: cardCVV
  } = card

  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [contentMotion, setContentMotion] = useState(false)
  const [contentOverlayMotion, setContentOverlayMotion] = useState(false)
  const [CVVReady, setCVVReady] = useState(false)
  const [newPaymentReady, setNewPaymentReady] = useState(false)

  const context = useContext(Context)

  useEffect(() => {
    document.querySelector('#root').style.background = '#fff'

    async function getCards() {
      await fetchCards([() => {
        setUserHistory({
          next: 'cart'
        })

        history.push(`/login${URLParameters}`)
      }])

      setLoaded(true)
      setLoading(false)
    }

    if (!loaded && !loading) {
      setLoading(true)
      getCards()
    }

    if (loaded && !loading) {
      setTimeout(() => setContentMotion(true), 1000)
    }

    if (CVVReady || newPaymentReady) {
      setContentOverlayMotion(true)
    }
  }, [
    loading,
    loaded,
    fetchCards,
    CVVReady,
    newPaymentReady,
    setUserHistory,
    URLParameters
  ])

  function handleCard(args) {
    let modalitySelected = null

    if (modalityId === 2) {
      modalitySelected = 'Retirada'
    }

    if (modalityId === 4) {
      modalitySelected = 'Delivery'
    }

    handleGTM({
      'event': `CHECKOUT - ${modalitySelected} - ${storeName}`,
      'ecommerce': {
        'checkout': {
          'actionField': { 'step': 2, 'option': args.paymentType },
          'products': cartProducts.map(function (item) {
            return {
              name: item.name,
              id: item.id,
              price: item.price,
              brand: null,
              categoria: item.category.name,
              variant: null,
              quantity: item.quantity
            }
          })
        }
      }
   })

    postUserCard({
      ...card,
      ...args
   })

    history.push(`/cart${URLParameters}`)
  }

  function postUserCard(args) {
    setUserCard(args)
  }

  async function handleAddCard() {
    await postCard()

    setContentOverlayMotion(false)
    setCVVReady(false)
    setNewPaymentReady(false)
    setCard(null)
    setLoading(false)
    setLoaded(false)
  }

  function handleCVV(args) {
    postCVV(args)
  }

  function postCVV(args) {
    setCVV(args)
  }

  function handleCardNumber(args) {
    postCardNumber(args)
  }

  function postCardNumber(args) {
    setCardNumber(args)
  }

  function handleCardDate(args) {
    postCardDate(args)
  }

  function postCardDate(args) {
    setCardDate(args)
  }

  function handleCardName(args) {
    postCardName(args)
  }

  function postCardName(args) {
    setCardName(args)
  }

  function handleCardCPF(args) {
    postCardCPF(args)
  }

  function postCardCPF(args) {
    setCardCPF(args)
  }

  function handleCardBrandId(args) {
    postCardBrandId(args)
  }

  function postCardBrandId(args) {
    setCardBrandId(args)
  }

  function handleCardCVV(args) {
    postCardCVV(args)
  }

  function postCardCVV(args) {
    setCardCVV(args)
  }

  function handleNext() {
    history.push(`/cart${URLParameters}`)
  }

  function handleNewPayment() {
    setNewPaymentReady(true)
  }

  return <Wrapper id='cards' loading={loading}>
    <H1>{context && context.header && context.header.title}</H1>
    <BackButton onClick={() => {
      setNotification(null)

      history.push(`/cart${URLParameters}`)
    }}>
      <CustomIcon name='ArrowLeft' />
    </BackButton>

    {loading && <LoadingWrapper id='cards-loading'>
      <LoadingImage src={LoadingGIF} />
    </LoadingWrapper>}

    {!loading && <ContentWrapper id='cards-content' className={contentMotion ? 'motion' : ''}>
      <Notification />

      <List data={userCards} selectedCard={userCard} handleCard={handleCard} />

      <NewPaymentButton onClick={() => {
        handleNewPayment()
      }}>Adicionar outro cartão</NewPaymentButton>

      <OverlayWrapper className={contentOverlayMotion ? 'motion' : ''}>
        <Div className='background' onClick={() => {
          if (contentOverlayMotion) {
            setContentOverlayMotion(false)
            setCVVReady(false)
            setNewPaymentReady(false)
            setCard(null)
            setLoading(false)
            setLoaded(false)
          }
        }} />

        { CVVReady ? <CVV cvv={ cvv } handleCVV={ handleCVV } handleNext={ handleNext } /> : null }

        { newPaymentReady ?
          <NewCard
            number={ cardNumber }
            name={ cardName }
            date={ cardDate }
            CPF={ cardCPF }
            CVV={ cardCVV }

            handleNumber={ handleCardNumber }
            handleName={ handleCardName }
            handleDate={ handleCardDate }
            handleCPF={ handleCardCPF }
            handleBrandId={ handleCardBrandId }
            handleCVV={ handleCardCVV }
            handleNext={ handleAddCard }
          /> : null }
      </OverlayWrapper>
    </ContentWrapper>}
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.main.store && state.main.store.name,
    modalityId: state.main.modality && state.main.modality.id, 
    cartProducts: state.main.cart && state.main.cart.products,
    userCards: state.main.userCards && state.main.userCards.cards,
    userCard: state.main.userCard || {},
    card: state.main.card || {},
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setUserCard,
  fetchCards,
  setCVV,
  setCardName,
  setCardNumber,
  setCardDate,
  setCardBrandId,
  setCardCPF,
  setCardCVV,
  setUserHistory,
  setNotification,
  postCard,
  handleGTM
})

export const Payment = React.memo(pipe(
  GetConnection,
  GetContent({context: Context, id: 'payment'})
)(PaymentPage))
