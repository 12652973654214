import styled from 'styled-components'

export const Wrapper = styled.form`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	box-sizing: border-box;
	margin: 0 auto;
	flex: 1;
	background: #fff;
	border-radius: .938rem;
  min-width: 100%;
  min-height: 100%;

	> div {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		flex: 1;
		min-width: 100%;
	}
`

export const Button = styled.button`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.invertedPrimaryColor) || '#fff' };
  background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  max-height: 3.75rem;
  min-height: 3.75rem;
  line-height: 3.75rem;
  min-width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.125rem;
  box-sizing: border-box;
	margin-bottom: 2.5rem;
`

export const Input = styled.input`
	border: 0 none;
	border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' } .063rem solid;
	font-size: 1rem;
	text-align: left;
	height: 1.875rem;
	line-height: 1.875rem;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
	margin: 0 auto;
	flex: 0;
	margin-bottom: 1.25rem;
	min-width: 80%;
`

export const Div = styled.div``

export const ForgotPasswordButton = styled.button`
	background: none;
	border: 0 none;
	padding: 0;
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#333' };
	flex: 1;
  line-height: 1.5rem;
	max-width: 40%;
`

export const SignUpButton = styled.button`
	color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#fff' };
  background: none;
  border-radius: .938rem;
  font-size: .875rem;
  font-weight: bold;
  flex: 1;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  font-size: 1.125rem;
  box-sizing: border-box;
	max-width: 50%;
`
