import React, {
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'

import IpirangaLoadingGIF from '../../assets/ipiranga-loading.gif'

import {
  CustomIcon
} from '../../components'

import {
  order
} from '../../../state'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'
import { PWAClose } from '../../../domain/helpers/ipiranga/PWA'

import history from '../../../history'

import {
  PickupOrder,
  DeliveryOrder
} from '../'

import {
  Wrapper,
  ContentWrapper,
  LoadingWrapper,
  BackButton,
  LoadingImage
} from './styles'

export const Context = React.createContext({})

export function OrderComponent(props) {
  const {
    order,
    userHistory,
    fetchOrder,
    modalityId,
    setOrder,
    loading,
    orderId,
    params,
    URLParameters
  } = props

  const {
    back
  } = userHistory

  const {
    partnerOrderId
  } = params

  const [contentMotion, setContentMotion] = useState(false)

  useEffect(() => {
    setTimeout(() => fetchOrder(), 1000)
  }, [
    fetchOrder
  ])

  useEffect(() => {
    document.querySelector('#root').style.background = '#fff'

    if (order && order.store && order.store.name) {
      setTimeout(() => setContentMotion(true), 500)
    }
  }, [
    order
  ])

  const orderProps = {
    modality: modalityId,
    id: orderId,
    storeName: order.store && order.store.name,
    storeLogo: order.store && order.store.logoUrl,
    storePhone: order.store && order.store.phone,
    storeAddress: order.store && order.store.address,
    date: order.orderDate,
    status: order.status,
    inProgress: order.inProgress,
    items: order.list,
    summary: order.summary,
    useCpf: order.cpf,
    cardBrand: order.cardBrand,
    cardNumber: order.numberCard,
    deliveryAddress: order.deliveryAddress,
    partnerOrderId,
    handleReceipt: () => {
      setOrder({
        id: orderId
      })
  
      history.push(`/receipt${URLParameters}`)
    }
  }

  return <Wrapper>
    <BackButton className='back-button' onClick={ () => {
      if (partnerOrderId) {
        PWAClose && PWAClose()

        return
      }

      if(back) {
        history.push(`/${back}${URLParameters}`)

        return
      }

      history.push(`/home${URLParameters}`)
    } }>
      <CustomIcon name='AngleLeft'/>
    </BackButton>

    { loading.indexOf('order') >= 0 && <LoadingWrapper id='order-loading'>
      <LoadingImage src={ IpirangaLoadingGIF } />
    </LoadingWrapper> }

    { loading.indexOf('order') < 0 && <ContentWrapper id='order-content' className={`${ contentMotion ? 'motion' : ''}`}>
      { modalityId === 2 ? <PickupOrder {...orderProps} /> : <DeliveryOrder {...orderProps} /> }
    </ContentWrapper> }
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    orderHistory: state.main.orderHistory,
    userHistory: state.main.userHistory || {},
    loading: state.main.loading || [],
    order: state.main.order || {},
    orderId: (state.main.order && state.main.order.id) || 0,
    modalityId: state.main.order && state.main.order.modalityId,
    params: state.main.params || {},
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setOrder: order.setOrder,
  fetchOrder: order.fetchOrder
})

export function Order(props) {
  const Component = pipe(
    GetConnection,
    GetContent({context: Context, id: 'order'})
  )(OrderComponent)
  const MemoizedComponent = React.memo(Component)

  return <MemoizedComponent {...props} />
}
