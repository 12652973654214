import React, {
  useState,
  useEffect,
  useCallback
} from 'react'
import { connect } from 'react-redux'

import history from '../../history'

import {
  pipe,
  GetContent
} from '../../domain/helpers'
import {
  //PWAAnalytics,
  PWAClose
} from '../../domain/helpers/ipiranga/PWA'

import {
  useCustomLayout,
  useDeviceLayout,
  useInterval
} from '../hooks'

import {
  CustomLoading,
  CustomIcon,
  DefaultImage as Image,
} from '../components'

import {
  fetchReceipt,
  setReceipt,
  fetchRequestNumber,
  fetchReceiptStatus,
  setCart,
  setReceiptNotification,
  handleGTM
} from '../../redux/actions/main'

import {
  ReceiptPageContainer,
  ReceiptMain,
  LoadingContainer,
  MobileHeader,
  BackButton,
  H1,
  WhattsAppImg
} from './styles'

import {
  Pickup,
  Delivery,
} from './'
import { MainHeader } from '../main-header'
import WhattsAppImage from '../assets/whatsapp_icone.png'
import { REACT_APP_WHATSAPP, REACT_APP_WHATSAPP_NUMBER } from '../../config'

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const Context = React.createContext({})

export function ReceiptPage(props) {
  const {
    loading,
    device,
    orderId,
    modalityId,
    cpf,
    cardBrand,
    cardNumber,
    cashChange,
    userName,
    storeName,
    setCart,
    fetchReceipt,
    receipt,
    handleGTM,
    storeAddress,
    deliveryAddress,
    deliveryTime,
    products,
    requestNumber,
    fetchRequestNumber,
    fetchReceiptStatus,
    notification,
    setReceiptNotification,
    setReceipt,
    completed,
    date,
    phone,
    whattsapp,
    logoStore,
    theme,
    scheduling,
    userId,
    location,
    URLParameters
  } = props

  const storeLogo = theme.logoImageStore
  const hasCpf = cpf ? true : false
  const [dialog, setDialog] = useState(null)
  const [interval, setInterval] = useState(0)
  const [stateRequestNumber, setStateRequestNumber] = useState(-1)
  const [status, setStatus] = useState(1)
  const [isRequestNumberReady, setRequestNumberReady] = useState(true)
  const [isStatusReady, setStatusReady] = useState(true)
  const [delay, setDelay] = useState(0)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const getRequestNumber = useCallback(async () => {
    await fetchRequestNumber({
      checkoutId: orderId
    })

    setRequestNumberReady(true)

    return () => { }
  }, [
    orderId,
    fetchRequestNumber
  ])

  const getOrderStatus = useCallback(async (modalityId = 0) => {
    if (status === 2) {
      return () => { }
    }

    await fetchReceiptStatus({
      checkoutId: orderId,
      modality: modalityId
    })

    setStatusReady(true)

    if (modalityId === 2 && completed) {
      setStatus(2)

      return () => { }
    }

    if (modalityId === 4 && notification) {
      setReceiptNotification(notification)
    }

    if (modalityId === 4
      && notification
      && notification.status >= 5
      && notification.statuses[3].active === true) {
      setStatus(2)

      return () => { }
    }

    return () => { }
  }, [
    fetchReceiptStatus,
    orderId,
    setReceiptNotification,
    status,
    notification,
    completed
  ])

  useCustomLayout({
    device,
    backgroundColor: '#fff'
  })

  useEffect(() => {
    setReceipt(null)

    return () => { }
  }, [
    setReceipt
  ])

  useEffect(() => {
    if (modalityId === 2 && stateRequestNumber === -1 && !!isRequestNumberReady) {
      setRequestNumberReady(false)
      sleep(10000).then(() => {
        getRequestNumber()
      })
    }

    return () => { }
  }, [
    modalityId,
    stateRequestNumber,
    getRequestNumber,
    isRequestNumberReady
  ])

  useEffect(() => {
    if (!receipt || (receipt && !receipt.isPaid)) {
      sleep(delay).then(() => {
        fetchReceipt()
        setDelay(5000)
      })

      return () => { }
    }

    if (receipt && !!receipt.isPaid && !!isStatusReady) {
      setStatusReady(false)
      sleep(10000).then(() => {
        setStateRequestNumber(receipt.requestNumber)
        getOrderStatus(receipt.modalityId)
      })

      return () => { }
    }

    return () => { }
  }, [
    fetchReceipt,
    getRequestNumber,
    getOrderStatus,
    receipt,
    isStatusReady,
    delay
  ])

  useEffect(() => {
    const url = window.location.href
    if (isMobile) {
      const access = {
        event: 'PageView',
        page_path: url,
        page_title: 'Comprovante'
      }
      handleGTM(access)
    }

    if (userId) {
      handleGTM({ user_id: userId })
    }
  }, [
    isMobile,
    userId,
    handleGTM
  ])

  useInterval(() => {
    if (interval > 15 && !dialog) {
      setDialog({
        title: 'Pagamento realizado com sucesso! :)',
        message: 'Mas por algum motivo não conseguimos gerar o seu comprovante, por favor consulte mais tarde dentro do historico de pedidos.',
        actions: [{
          label: 'Voltar ao início',
          handleClick: () => {
            PWAClose && PWAClose()
          }
        }]
      })

      return () => { }
    }

    setInterval(interval + 1)

    return () => { }
  }, 1000)

  function handleStatus(status) {
    setStatus(status)
  }

  function handleFinishOrder() {
    setCart({ products: null })

    history.push(`/home${URLParameters}`)

    return
  }

  function handleWhattsApp() {
    let otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = `http://api.whatsapp.com/send?1=pt_BR&phone=${REACT_APP_WHATSAPP_NUMBER}`;

    const whattsAppData = {
      'event': 'click - botão de whatsapp',
      'user_id': userId ? userId : ''
    }

    handleGTM(whattsAppData)
  }

  return (
    <>
      <ReceiptPageContainer>
        <MobileHeader>
          <H1>Comprovante</H1>
          <BackButton className='back-button' onClick={() => {
            window.history.back()
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>
        </MobileHeader>
        {!isMobile && <MainHeader
          currentRoute='receipt'
          theme={theme}
          mode='simple'
          storeName={storeName}
          storeLogo={storeLogo}
          pathName={location.pathname}
        />}
        {loading.includes('receipt') &&
          <LoadingContainer>
            <CustomLoading
              type={'spin'}
              id='default-loading'
              height={40}
              width={40}
            />
          </LoadingContainer>}
        {!loading.includes('receipt') && <ReceiptMain>
          {REACT_APP_WHATSAPP === 'true' &&
            <WhattsAppImg onClick={() => handleWhattsApp()}>
              <Image src={WhattsAppImage} alt="" />
            </WhattsAppImg>}
          {modalityId === 4 && deliveryAddress && notification && <Delivery
            storeName={storeName}
            logoStore={logoStore}
            userName={userName}
            status={status}
            requestNumber={requestNumber}
            date={date}
            deliveryTime={deliveryTime}
            products={products}
            cpf={cpf}
            hasCpf={hasCpf}
            cashChange={cashChange}
            cardBrand={cardBrand}
            cardNumber={cardNumber}
            notification={notification}
            deliveryAddress={deliveryAddress}
            phone={phone}
            whattsapp={whattsapp}
            handleFinishOrder={handleFinishOrder}
            handleStatus={handleStatus}
            modalityId={modalityId}
            scheduling={scheduling}
            orderId={orderId}
            URLParameters={URLParameters}
          />}

          {modalityId === 2 && requestNumber && <Pickup
            storeName={storeName}
            logoStore={logoStore}
            userName={userName}
            status={status}
            requestNumber={requestNumber}
            date={date}
            deliveryTime={deliveryTime}
            products={products}
            cashChange={cashChange}
            cpf={cpf}
            hasCpf={hasCpf}
            cardBrand={cardBrand}
            cardNumber={cardNumber}
            storeAddress={storeAddress}
            phone={phone}
            whattsapp={whattsapp}
            handleFinishOrder={handleFinishOrder}
            orderId={orderId}
          />}
        </ReceiptMain>}
      </ReceiptPageContainer>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
    deliveryAddress: (state.main.receipt && state.main.receipt.deliveryAddress) || '',
    notification: (state.main.receiptStatus && state.main.receiptStatus.notification) || {},
    deliveryTime: (state.main.receiptStatus && state.main.receiptStatus.notification && state.main.receiptStatus.notification.deliveryTime) || '',
    products: (state.main.receipt && state.main.receipt.list && state.main.receipt.summary && {
      list: state.main.receipt.list,
      summary: state.main.receipt.summary
    }) || { list: [], summary: {} },
    device: (state.main.params && state.main.params.device) || '',
    isPaid: (state.main.receipt && state.main.receipt.isPaid) || '',
    loading: state.main.loading || [],
    modalityId: (state.main.receipt && state.main.receipt.modalityId) || 0,
    completed: (state.main.receiptStatus && state.main.receiptStatus.completed) || false,
    orderId: (state.main.order && state.main.order.id) || (state.main.order && state.main.order.finishData && state.main.order.finishData.vqpOrderId) || 0,
    receipt: state.main.receipt || null,
    storeName: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.name) || '',
    storeAddress: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.address) || '',
    logoStore: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.logoUrl) || '',
    phone: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.phone) || '',
    whattsapp: (state.main.receipt && state.main.receipt.store && state.main.receipt.store.whattsapp) || '',
    date: state.main.receipt && state.main.receipt.orderDate,
    requestNumber: (state.main.receipt && state.main.receipt.requestNumber) || -1,
    userName: state.main.userName,
    cardBrand: (state.main.receipt && state.main.receipt.cardBrand) || '',
    cardNumber: (state.main.receipt && state.main.receipt.cardNumber) || '',
    scheduling: state.main.receipt && state.main.receipt.scheduling,
    cpf: state.main.receipt && state.main.receipt.cpf,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchReceipt,
  fetchRequestNumber,
  fetchReceiptStatus,
  setCart,
  handleGTM,
  setReceipt,
  setReceiptNotification
})

export const Receipt = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'receipt' })
)(ReceiptPage))
