import {
  actionTypes
} from '../'

import {
  loading
} from '../loading'

export const setOrders = (orders) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDERS,
    payload: orders
 })
}

export const setOrderHistory = (orderHistory) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER_HISTORY,
    payload: orderHistory
 })
}

export const setOrder = (order) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ORDER,
    payload: order
 })
}

export const fetchOrders = (callback) => (dispatch, getState, container) => {
  try {
    const stateOrders = getState().main.orders || {}

    const data = {}

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchOrders({
      onSuccess: ({ orders}) => {
        if (orders && orders.orders) {
          dispatch(setOrders({
            ...stateOrders,
            orders: orders.orders,
            retry: orders.retry
          }))

          return
        }

        dispatch(setOrders({
          orders: [],
          retry: false
        }))
      },
      onError: () => {
        dispatch(setOrders({
          orders: [],
          retry: false
        }))
      },
      onEmpty: () => {
        dispatch(setOrders({
          orders: [],
          retry: false
        }))
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setOrders({
      orders: [],
      retry: true
    }))
  } finally {
    callback && callback()
  }
}

export const fetchOrder = (callback) => (dispatch, getState, container) => {
  try {
    const orderId = getState().main.order && getState().main.order.id
    const partnerOrderId = getState().main.params && getState().main.params.partnerOrderId

    const data = {
      orderId,
      partnerOrderId
    }

    const actions = {
      setLoading: loading.setLoading
    }

    const done = () => {
      dispatch(loading.setLoading({ item: 'order', delete: true}))
    }

    return container.fetchOrder({
      onSuccess: ({ order}) => {
        if (order) {
          dispatch(setOrder({
            ...order
          }))
        }

        done()
      },
      onError: () => {
        dispatch(setOrder(null))

        done()
      },
      onEmpty: () => {
        dispatch(setOrder(null))

        done()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setOrder(null))
  } finally {
    callback && callback()
  }
}

export const fetchOrderHistory = (callback) => (dispatch, getState, container) => {
  try {
    const stateOrderHistory = getState().main.orderHistory || {}

    const {
      pagination
    } = stateOrderHistory

    const data = {
      pagination
    }

    const actions = {
      setLoading: loading.setLoading
    }

    const done = () => {
      dispatch(loading.setLoading({ item: 'order-history', delete: true}))
    }

    return container.fetchOrderHistory({
      onSuccess: ({ orders}) => {
        if (orders && orders.orders && orders.orders.length) {
          dispatch(setOrderHistory({
            ...stateOrderHistory,
            orders: orders.orders,
            message: orders.message,
            status: orders.status
          }))

          done()

          return
        }

        dispatch(setOrderHistory({
          orders: [],
          message: '',
          status: false
        }))

        done()
      },
      onError: () => {
        dispatch(setOrderHistory({
          orders: [],
          message: '',
          status: false
        }))

        done()
      },
      onEmpty: () => {
        dispatch(setOrderHistory({
          orders: [],
          message: '',
          status: false
        }))

        done()
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setOrderHistory({
      orders: [],
      message: '',
      status: false
   }))
  } finally {
    callback && callback()
  }
}
