export const citiesAuthorized = [
    'Americo Brasiliense',
    'Araraquara',
    'Armacao dos Buzios',
    'Barueri',
    'Belo Horizonte',
    'Brasilia',
    'Cajamar',
    'Campinas',
    'Campo Grande',
    'Caraguatatuba',
    'Goiania',
    'Guarulhos',
    'Jau',
    'Maua',
    'Pilar Do Sul',
    'Piracicaba',
    'Ribeirao Preto',
    'Sao Paulo',
    'Sao Jose Do Rio Preto',
    'Sao Roque',
    'Saquarema',
    'Sorocaba',
    'Sumare',
    'Suzano', 
    'Rio de Janeiro', 
    'Porto Alegre', 
]

export function verifyAddressCities(cities) {
    const allowedCities = citiesAuthorized
    const getFilter = (filteredItem) => allowedCities.includes(filteredItem && filteredItem.normalize('NFD').replace(/[\u0300-\u036f]/g, ''))
    const isAllow = cities.some(getFilter)

    return !isAllow
  }