import React, {
  useState
} from 'react'

import {
  Wrapper,
  H4,
  Paragraph,
  Button,
  Input,
  Div
} from './styles'

export function PostalCode(props) {
	const {
    title,
    message,
    inputs,
    actions
  } = props

  const [warning, setWarning] = useState(false)

	return <Wrapper className='postal-code'>
		{ title ? <H4>{ title }</H4> : null }

		{ message ? <Paragraph>{ message }</Paragraph> : null }

    <Div className={`input ${warning ? 'warning' : ''}`}>
      <Input
        maxLength={ 9 }
        max={99999999}
        type='number'
        placeholder='00000-000'
        value={ inputs[0].value || ''}
        onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
        onChange={ (event) => {
          const { value } = event.target

          inputs[0].handleChange(value)
        } } />

      { warning ? <Paragraph className='warning'>CEP inválido</Paragraph> : null }
    </Div>

    { actions ? <Div>
      { actions.map((actionsMappedItem, index) => {
        const {
          label,
          handleClick
        } = actionsMappedItem

        const key = `overlay-postal-code-button-item-${ index }-${ new Date().getTime() }`
      
        return <Button key={ key } onClick={ () => {
          if (inputs[0].value.length < 8) {
            setWarning(true)

            return
          }

          handleClick()
        } }>
            { label }
          </Button>
      }) }
    </Div> : null }
	</Wrapper>
}
