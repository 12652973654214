import styled, { css } from 'styled-components'
import { Button } from '../../components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: border-top: ${props => {
    if (props.scheduling) {
      return 'none'
    }

    return `1.5rem`

  }};
  border-top: ${props => {
    if (props.scheduling) {
      return 'none'
    }

    return `1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}`

  }};

  * {
    box-sizing: border-box;
  }
`

export const OrderSummaryTitle = styled.h3`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 2rem 0 0 0;
  text-align: start;
  flex: 1;
  padding: 0 1.5rem;
 `

export const ItemsList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 1.5rem 1.5rem 0;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
`

export const Item = styled.li`
  flex: 1;
  display: flex;
  margin: 0 0 2rem 0;
`

export const ItemDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
`

export const ItemName = styled.p`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
`

export const ItemDescription = styled.p`
  font-weight: 400;
  font-size: .875rem;
  line-height: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000'};
  margin: 0 0 .5rem 0;
`

export const ItemPrice = styled.p`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  margin: 0;
`

export const ItemAmount = styled.span`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  flex: 0 0 35px;
  height: 35px;
  border: 3px solid rgba(84, 84, 84, .2);
  border-radius: 10px;
  margin: 0 0 0 .5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TotalsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0 0;
  padding: 0 1.5rem 1.5rem;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};
`

export const TotalsItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const normalLabel = css`
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`

export const SubTotalLabel = styled.div`
  ${normalLabel}
`

export const SubTotalValue = styled.div`
  ${normalLabel}
`

export const DeliveryFeeLabel = styled.div`
  ${normalLabel}
  margin: 0 0 1rem 0;
`

export const DeliveryFeeValue = styled.div`
  ${normalLabel}
  margin: 0 0 1rem 0;
`

const boldLabel = css`
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000'};
`

export const TotalLabel = styled.div`
  ${boldLabel}
`

export const TotalValue = styled.div`
  ${boldLabel}
`

export const DeliveryAddressWrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};

  @media (min-width: 60rem) {
    display: none;
  }
`

export const DeliveryAddressLabel = styled.span`
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
`

export const DeliveryAddress = styled.div`
  font-weight: 400; 
  font-size: 1rem;
  line-height: 1.25rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000'};
  display: flex;
  align-items: center;

  &.schedule{
    font-size: .9rem;
  }

  >span{
    margin-left: 5px;
    font-weight: bold;
  }

  svg {
    margin: 0 .5rem 0 0;
    width: .75rem;

    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    }
  }
`

export const DeliveryPaymentWrapper = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'};

  @media (min-width: 60rem) {
    display: none;
  }
`

export const DeliveryPaymentCPF = styled.span`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.375rem;
  color: #7D7D7D;
`

export const DeliveryPaymentCard = styled.span`
  margin: .5rem 0 0 0;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.688rem;
  color: #7D7D7D;
  display: flex;
  align-items: center;

  svg {
    margin: 0 .5rem 0 0;
    display: block;
    width: 2rem;

    path {
      fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
    }
  }
`

export const ActionButton = styled(Button)`
  flex: 0 0 3rem;
  margin: 1.5rem 1.5rem 0;
  font-weight: bold;
  font-size: .875rem;
  line-height: 1.188rem;
  color: #fff;
  cursor: pointer;
`
export const CardImage = styled.img`
    width: 50px;
    padding: 10px;
`

export const Help = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 1.25rem 1rem;
  padding-bottom: 0;
  width: 100%;

  @media (min-width: 60rem) {
    display: none;
  }
`

export const HelpLabel = styled.label`
  font-weight: 700;
  font-size: .875rem;
  line-height: 1.188.rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0 0 1rem 0;
  width: 100%;
`

export const HelpButton = styled.button`
  border: 1px solid ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  border-radius: 15px;
  font-weight: 400;
  font-size: .75rem;
  line-height: 1.188.rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  flex: 1 1 calc(50% - .5rem);
  max-width: calc(50% - .5rem);
  height: 2.5rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .5rem .25rem;

  &:hover {
    background-color: #f7f7f7;
  }

  &:first-of-type {
    margin: 0 1rem 0 0;
  }

  @media (min-width: 60rem) {
    font-size: .875rem;
  }

  svg {
    margin: 0 0 0 .5rem;

    path {
      fill: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
      stroke: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
    }
  }
`
