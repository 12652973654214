import React from 'react'

import Flags from './Flags/Flags'

import {
    countries
} from '../../../domain/enum/countries'

const getInfo = ({
    url
}) => {
    const country = countries.filter((filteredItem) => (filteredItem.url === url))

    return country[0]
}

const Country = (props) => {
    const {
        url
    } = props
    
    const countryInfo = getInfo({url})

    const {
        url: countryName
    } = countryInfo

    return <Flags country={countryName} />
}

export default Country
