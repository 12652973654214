import styled from 'styled-components'

export const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  box-sizing: border-box;
  min-width: 100%;
  max-width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-bottom: 1.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  gap: 1.5rem;
  padding-bottom: 1rem;
  overflow-x: auto;
  flex-direction: row;

  &:last-child {
    margin-bottom: 0;
  }

  @media (min-width: 60rem) {
    padding-bottom: 0;
    overflow-x: unset;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin-bottom: 2rem;
  }
`

export const H1 = styled.h1`
  margin: 0;
  padding: 0;
  min-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#e2e2e2'} .063rem solid;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#333'};
  font-size: 1.125rem;
  position: relative;

  > span {
    font-size: .8rem;
    line-height: 1.25rem;
    display: flex;
    justify-content: center;
    max-height: 1.25rem;
    position: absolute;
    right: 0;
    top: .3rem;
    color: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};

    > span {
      margin-left: .2rem;
    }

    @media (min-width: 60rem) {
      display: none;
    }
  }

  @media (min-width: 60rem) {
    margin-bottom: 2rem;
  }
`

export const Span = styled.span``
