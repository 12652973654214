import React from 'react'

import { ReactComponent as ClockIcon } from '../../../assets/icons/icon_clock.svg';
import { ReactComponent as DownloadIcon } from '../../../assets/icon_download.svg';
import { ReactComponent as LocationIcon } from '../../../assets/icons/icon_checkout_address.svg'
import { selectMonth } from '../../../../domain/helpers/SelectedMonth'

import {
  getCardBrandImageById,
  formatCpf
} from '../../../../infra/utils'

import {
  DetailsContainer,
  DeliveryEstimate,
  DetailsItem,
  ItemContent,
  ItemLabel,
  OrderDate,
  CustomerMessage,
  CardImage,
  ActionButton
} from './styles'

const SelectedCard = ({ brand, number }) => {
  return <>
    {brand && number ? <>
      <CardImage src={brand} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

      <span>{number}</span>
    </> : '...'}
  </>
}

export function Details({
  userName,
  deliveryTime,
  deliveryAddress,
  date,
  cardBrand,
  cardNumber,
  cpf,
  hasCpf,
  scheduling,
  orderId
}) {

  const splitDate = scheduling ? scheduling.date.split("/") : ''
  const day = splitDate ? splitDate[0] : ''
  const month = splitDate ? selectMonth(parseInt(splitDate[1])) : ''

  return (
    <DetailsContainer>
      <CustomerMessage>{userName},<br /> seu pedido <span>#{orderId}</span> foi confirmado!</CustomerMessage>
      <OrderDate>{date}</OrderDate>
      {!scheduling && <DeliveryEstimate>
        <ClockIcon />
        {deliveryTime}
      </DeliveryEstimate>}
      {scheduling && <DetailsItem>
        <ItemLabel>Data da entrega</ItemLabel>
        <ItemContent>
          <span>{scheduling ? `${scheduling.week ? `${scheduling.week},` : ''} ${scheduling ? `${day} de ${month}` : ''}` : ''}</span>
        </ItemContent>
      </DetailsItem>}
      <DetailsItem>
        <ItemLabel>Local de entrega</ItemLabel>
        <ItemContent>
          <LocationIcon />
          <span>{deliveryAddress}</span>
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>Forma de pagamento</ItemLabel>
        <ItemContent iconWidth='2rem'>
          <SelectedCard brand={cardBrand} number={cardNumber} />
        </ItemContent>
      </DetailsItem>
      <DetailsItem>
        <ItemLabel>CPF na nota</ItemLabel>
        <ItemContent>
          <span>{hasCpf ? formatCpf(cpf) : '000.000.000-00'}</span>
        </ItemContent>
      </DetailsItem>

      <ActionButton><a
        download="modo_preparo_ceia_natal.png"
        href={`modo_preparo_ceia_natal.png`}
      > Baixar orientações de preparo! <DownloadIcon /></a></ActionButton>
    </DetailsContainer>
  )
}
