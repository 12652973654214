import React, {
  useContext
} from 'react'
import PropTypes from 'prop-types'

import IpirangaLogo from '../../assets/ipiranga/logo-ipiranga.svg'

import { Icon } from '../../components/common'

import {
  Skeleton
} from '../../'

import {
  Agglomerations,
  Context,
  Highlights
} from '..'

import {
  Overlay,
  OrderTracking
} from '../../'

import {
  ModalityCheckbox
} from './'

import {
  Wrapper,
  Subtitle,
  Title,
  AddressButton,
  LogoImage,
  Span,
  LoadingWrapper,
  LogoWrapper,
  ContentWrapper,
  ExitButton,
  Div
} from '../styles'

export function Main(props) {
  const {
    handles,
    data,
    overlay,
  } = props

  const {
    handleAgglomeration,
    handleAddressChange,
    handleLogout,
    handleModality
  } = handles

  const {
    agglomerations,
    highlights,
    userName,
    userAddress,
    loaded,
    modality
  } = data

  const {
    differentAddress,
    disallowedCity,
    motion
  } = overlay

  const {
    set: setOverlayMotion,
    value: overlayMotion,
  } = motion

  const context = useContext(Context)

  const greetingsIsObject = typeof context.title === 'object'
  const greetingsLength = greetingsIsObject && context.greetings.length
  const filteredGreetings = greetingsLength && context.greetings.filter(filteredItem => filteredItem.subId === 1)
  const greetingsLabel = filteredGreetings && filteredGreetings[0] && filteredGreetings[0].label

  const subTitleIsObject = typeof context.title === 'object'
  const subTitleLength = subTitleIsObject && context.title.length
  const filteredSubtitle = subTitleLength && context.title.filter(filteredItem => filteredItem.subId === 1)
  const subTitleLabel = filteredSubtitle && filteredSubtitle[0] && filteredSubtitle[0].label

  return <Wrapper>
    <Div>
      <ExitButton onClick={ () => {
        handleLogout()
      } }>
        <Icon name='logout' width='11px' />
      </ExitButton>

      <LogoWrapper>
        <LogoImage src={ IpirangaLogo }></LogoImage>
      </LogoWrapper>

      <Title>
        { `${ greetingsLabel } ${userName}` }
      </Title>

      <AddressButton onClick={ () => {
        handleAddressChange()
      } }>
        <Icon name='localization' width='12px' />
        <Span>{ userAddress && userAddress.main }</Span>
        <Icon name='arrowButtomLocation' width='11px' />
      </AddressButton>
    </Div>

    { !loaded && <LoadingWrapper id='home-loading'>
      <Skeleton module='home' />
    </LoadingWrapper>}

    { loaded && agglomerations && highlights && <ContentWrapper id='home-content' className={  'motion' }>
      <OrderTracking />

      <Highlights data={ highlights } />

      { agglomerations && agglomerations.length > 0 && <ModalityCheckbox modality={ modality } handleModality={ handleModality } /> }

      <Subtitle>{ subTitleLabel }</Subtitle>

      <Agglomerations data={ agglomerations } handleAgglomeration={ handleAgglomeration } handleAddressChange={ handleAddressChange } />

      <Overlay
        className={`home`}
        show={!!overlayMotion}
        differentAddress={differentAddress}
        disallowedCity={disallowedCity}
        background={{
          show: true,
          handleClick: () => {
            if (!!overlayMotion) {
              setOverlayMotion(false)
            }
          }
        }}
      />
    </ContentWrapper> }
  </Wrapper>
}

Main.propTypes = {
  handles: PropTypes.arrayOf(PropTypes.func),
  data: PropTypes.arrayOf(PropTypes.shape({})),
  motion: PropTypes.bool
}
