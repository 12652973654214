import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withTheme } from 'styled-components'

import history from '../../../history'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  fetchUserAddress,
  setUserHistory
} from '../../../redux/actions/main'

import {
  store,
  loading
} from '../../../state'

import {
  CustomLoading
} from '../../components'

import AddressPinIcon from '../../assets/ipiranga/address-pin-icon.png'

import {
  Wrapper,
  ContentWrapper,
  Image,
  Paragraph,
  Span
} from './styles'
import { ESTABELECIMENTO_ID, MULTI_STORE } from '../../../config'

export const Context = React.createContext({})

function SplashPage(props) {
  const {
    loading,
    fetchStores,
    setStore,
    currentStep,
    stores,
    setUserHistory,
    setLoading,
    addressByCEP,
		address,
    URLParameters
  } = props

  useEffect(() => {
    if (currentStep === 2) {
      fetchStores(() => {
				setTimeout(() => {
					setUserHistory({
						back: '/',
						next: '/'
					})
	
					history.push(`/store${URLParameters}`)
				}, 1500)
			})
    }

    return () => ({})
  }, [
		URLParameters,
		setUserHistory,
    fetchStores,
    currentStep,
    setLoading
  ])

  useEffect(() => {
    if (MULTI_STORE === 'false' && currentStep === 2) {
      setStore({ id: ESTABELECIMENTO_ID })
    }

    if (MULTI_STORE === 'true' && stores && stores.list && currentStep === 2) {
      stores.list && stores.list[0] && stores.list[0].id && setStore({
        id: stores.list[0].id
      })
    }

    return () => ({})
  }, [
    stores,
    setStore,
    currentStep,
  ])

  return <Wrapper>
    {loading.includes('welcome') &&
      <CustomLoading
        color={'#fff'}
        type={'spin'}
        id='default-loading'
        height={40}
        width={40}
      />}

    {!loading.includes('welcome') && ((addressByCEP && addressByCEP.main) || (address && address.main)) && <ContentWrapper id='welcome-splash-content'>
      <Paragraph>
        <Image src={AddressPinIcon} alt='Ícone de mapa' />
        <Span>{((addressByCEP && addressByCEP.main) || (address && address.main)) || '…'}</Span>
      </Paragraph>
    </ContentWrapper>}
  </Wrapper>
}

SplashPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  userAddress: PropTypes.shape({})
}

const mapStateToProps = (state) => {
  return {
    addressByCEP: (state.main.addressByCEP) || {},
		address: (state.main.address) || {},
    loading: state.main.loading || [],
    stores: state.main.stores || [],
    storeId: (state.main.store && state.main.store.id) || 0,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStores: store.fetchStores,
  setStore: store.setStore,
  setLoading: loading.setLoading,

  fetchUserAddress,
  setUserHistory
})

export const Splash = React.memo(pipe(
  withTheme,
  GetConnection,
  GetContent({ context: Context, id: 'welcome' })
)(SplashPage))
