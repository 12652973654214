export function maskCard(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/^(\d{4})(\d)/g, "$1 $2")
    v = v.replace(/^(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3")
    v = v.replace(/^(\d{4})\s(\d{4})\s(\d{4})(\d)/g, "$1 $2 $3 $4")
    return v
}

export function maskCpf(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d)/, "$1.$2")
    v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2")
    return v
}

export function maskDate(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{2})(\d)/, "$1/$2")
    return v
}

export function maskBirth(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{2})(\d)/, "$1/$2")
    v = v.replace(/(\d{2})(\d)/, "$1/$2")
    return v
}

export function maskCep(v) {
    v = v.replace(/\D/g, "")
    v = v.replace(/(\d{5})(\d)/, "$1-$2")
    return v
}