import {
  isPickup,
  isDelivery
} from '../../redux/actions/main'

export default ({
  apiService
}) => {
  const strategy = {
    defaultPickup: async (payload) => {
      return await strategy['pickupRequest']({
        ...payload,
        data: {
          agglomerationId: payload.data.agglomerationId,
          cityId: payload.data.cityId,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          modalityId: payload.data.modalityId,
          searchText: payload.data.searchText,  
          paginationOffset: payload.data.paginationOffset
        }
      })
    },
    defaultDelivery: async (payload) => {
      return await strategy['deliveryRequest']({
        ...payload,
        data: {
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,  
          searchText: payload.data.searchText,  
          paginationOffset: payload.data.paginationOffset
        }
      })
    },
    ipirangaPickup: async (payload) => {
      return await strategy['pickupRequest']({
        ...payload,
        data: {
          agglomerationId: payload.data.agglomerationId,
          cityId: payload.data.cityId,
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,
          modalityId: payload.data.modalityId,
          searchText: payload.data.searchText,  
          paginationOffset: payload.data.paginationOffset
        }
      })
    },
    ipirangaDelivery: async (payload) => {
      return await strategy['deliveryRequest']({
        ...payload,
        data: {
          latitude: payload.data.latitude,
          longitude: payload.data.longitude,  
          searchText: payload.data.searchText,  
          paginationOffset: payload.data.paginationOffset
        }
      })
    },
    pickupRequest: async ({
      data,
      auth,
      maps,
      actions,
      dispatch,
      getState
    }) => {
      const {
        setLoading
      } = actions

      const {
        mapPickupStoresList,
        mapStoresRequest
      } = maps

      const loading = (getState().main && getState().main.loading) || []

      if (loading && loading.length && loading.indexOf('stores-list') > -1) {
        return false
      }

      dispatch(setLoading('stores-list'))
  
      const url = `Estabelecimentos/Listar`
      const result = await apiService.post(url, mapStoresRequest(data), auth)
      const mappedResult = !data.storeId ?
        mapPickupStoresList(result.data) :
        mapPickupStoresList(result.data).filter(filteredItem => filteredItem.id === data.storeId)
  
      return mappedResult && result && {
        list: mappedResult.filter(filteredItem => filteredItem && filteredItem.id && filteredItem.distance <= filteredItem.range),
        remaining: result.data.Total
      }
    },
    deliveryRequest: async ({
      data,
      auth,
      dispatch,
      actions,
      maps,
      getState
    }) => {
      const {
        setLoading
      } = actions
  
      const {
        mapStoresList,
        mapStoresRequest
      } = maps

      const loading = (getState().main && getState().main.loading) || []

      if (loading && loading.length && loading.indexOf('stores-list') > -1) {
        return false
      }

      dispatch(setLoading('stores-list'))
  
      const url = `Estabelecimentos/Listar`
      const result = await apiService.post(url, mapStoresRequest(data), auth)
      const mappedResult = !data.storeId ?
        mapStoresList({
          data: result.data,
          modalityId: 4
        }) :
        mapStoresList({
          data: result.data,
          modalityId: 4
        }).filter(filteredItem => filteredItem.id === data.storeId)
  
      return mappedResult && result && {
        list: mappedResult.filter(filteredItem => filteredItem && filteredItem.id),
        remaining: result.data.Total
      }
    }/* ,
    ampmDeliveryRequest: async ({
      data,
      auth,
      dispatch,
      actions,
      maps,
      getState
    }) => {
      const {
        setLoading
      } = actions
  
      const {
        mapDeliveryStoresList,
        mapStoresRequest
      } = maps

      const loading = (getState().main && getState().main.loading) || []

      if (loading && loading.length && loading.indexOf('stores-list') > -1) {
        return false
      }

      dispatch(setLoading('stores-list'))
  
      const url = `Estabelecimentos/ListarDelivery`
      const result = await apiService.post(url, mapStoresRequest(data), auth)
      const mappedResult = !data.storeId ?
        mapDeliveryStoresList(result.data) :
        mapDeliveryStoresList(result.data).filter(filteredItem => filteredItem.id === data.storeId)
  
      return mappedResult && result && {
        list: mappedResult.filter(filteredItem => filteredItem && filteredItem.id),
        remaining: result.data.Total
      }
    } */,
    addressValidationRequest: async ({
      data,
      auth,
      maps
    }) => {
      const {
        mapAddressValidationRequest,
        mapAddressValidationData
      } = maps

      const {
        storeId
      } = data
  
      const url = `enderecosdelivery/validar/${ storeId }`
      const response = await apiService.post(url, mapAddressValidationRequest(data), auth)
      const mappedResult = mapAddressValidationData(response.data)

      return mappedResult
    },
    pickupStoreRequest: async ({
      data,
      dispatch,
      actions,
      auth,
      maps
    }) => {
      const {
        mapStoreData
      } = maps
      const {
        setLoading
      } = actions
  
      const {
        storeId,
        selectedCategoryIndex,
        accessToken,
        userAddressId,
      } = data
  
      dispatch(setLoading('store'))

      const url = `Estabelecimentos/Obter/${storeId}${ accessToken && userAddressId ? `?enderecoDeliveryId=${ userAddressId }` : '' }`
      const response = await apiService.get(url, auth)
      const mappedResult = mapStoreData(response.data, selectedCategoryIndex)

      return mappedResult
    },
    deliveryStoreRequest: async ({
      data,
      auth,
      maps,
      dispatch,
      actions,
    }) => {
      const {
        mapStoreData
      } = maps
      const {
        setLoading
      } = actions

      const {
        storeId,
        selectedCategoryIndex,
        accessToken,
        userAddressId,
        latitude,
        longitude
      } = data
  
      dispatch(setLoading('store'))

      const url = `Estabelecimentos/Obter/${storeId}${ accessToken && userAddressId ? `?enderecoDeliveryId=${ userAddressId }` : `?latitude=${latitude}&longitude=${longitude}`}` // TODO
      const response = await apiService.get(url, auth)
      const mappedResult = mapStoreData(response.data, selectedCategoryIndex)

      return mappedResult
    }
  }

  return {
    fetchStores: async (payload) => {
      const {
        dispatch
      } = payload

      const isModalityPickup = !!dispatch(isPickup())
      const isModalityDelivery = !!dispatch(isDelivery())
  
      if (isModalityPickup) {
        return await strategy['defaultPickup'](payload)
      }
  
      if (isModalityDelivery) {
        return await strategy['defaultDelivery'](payload)
      }
    },

    fetchStore: async (payload) => {
      const {
        dispatch
      } = payload

      const isModalityPickup = !!dispatch(isPickup())
      const isModalityDelivery = !!dispatch(isDelivery())

      if (isModalityPickup) {
        return await strategy['pickupStoreRequest'](payload)
      }

      if (isModalityDelivery) {
        return await strategy['deliveryStoreRequest'](payload)
      }
    },

    fetchStoreAddressValidation: async (payload) => {
      return await strategy['addressValidationRequest'](payload)
    }
  }
}
