import {
  actionTypes
} from '..'

import {
  loading
} from '../loading'

export const setAddress = (address) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_ADDRESS,
    payload: address
  })
}

export const setCEP = (cep) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CEP,
    payload: cep
  })
}

export const setAddressByGeolocation = (userAddress) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_USER_ADDRESS,
    payload: userAddress
  })
}

export const removeUserAddressesItem = (userAddressesItem) => (dispatch) => {
  dispatch({
    type: actionTypes.REMOVE_USER_ADDRESSES_ITEM,
    payload: userAddressesItem
  })
}

export const fetchAddressByGeolocation = (callback) => (dispatch, getState, container) => {
  try {
    const data = {}
    const actions = {
      setLoading: loading.setLoading
    }

    const stateAddress = getState().main && getState().main.address
    const latitude = getState().main && getState().main.latitude
    const longitude = getState().main && getState().main.longitude

    return container.fetchAddressByGeolocation({
      onSuccess: ({ address }) => {
        if (address) {
          const fullAddress = {
            latitude,
            longitude,
            ...stateAddress,
            ...address
          }

          dispatch(setAddressByGeolocation(fullAddress))
          dispatch(setAddress(fullAddress))
          dispatch(setCEP(fullAddress.cep))

          return
        }

        dispatch(setAddressByGeolocation({}))
      },
      onError: () => {
        dispatch(setAddressByGeolocation({}))
      },
      onEmpty: () => {
        dispatch(setAddressByGeolocation({}))
      },
      dispatch,
      data,
      actions,
      getState
   })
  } catch (e) {
    console.log(e)

    dispatch(setAddressByGeolocation({}))
  } finally {
    callback && callback()
  }
}
