import React, {
  useEffect,
  Fragment
} from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  CategoryWrapper,
  H1,
  Span
} from './styles'

import {
  Product,
  Context
} from '../'
import { CustomIcon } from '../../components'

function CardMenuComponent(props) {
  const {
    storeLogo,
    handleViewProduct,
    handleAddProduct,
    cart,
    categories,
    storeImg,
    handleViewCategoriesProducts,
    setVisibleCategories,
    status,
    opened
  } = props

  useEffect(() => {
    const products = []

    if (categories) {
      categories.forEach(function (item) {
        products.push(item.products)
      })
    }

    return () => { }
  }, [
    categories
  ])

  function handleCategoriesProducts(name) {
    const params = {
      name,
      selectedProductsCategories: true,
      openModalCategory: true
    }

    handleViewCategoriesProducts(params)
  }

  return <>
    {categories && categories.length && categories.map((mappedItem, index) => {
      const {
        products,
        name: categoryName,
        id: categoryId
      } = mappedItem

      const key = `stores-products-category-${index}-${categoryId}`

      return <Fragment key={key}>
        <H1>{mappedItem.name}
          <Span onClick={() => {
            handleCategoriesProducts(mappedItem.name)
            setVisibleCategories(true)
          }}>Ver tudo <CustomIcon name='AngleRight' /></Span>
        </H1>

        <CategoryWrapper name={mappedItem.name} id={`${mappedItem.name}`} key={key}>
          {products && products.map((mappedItem, index) => {
            const {
              id,
              name,
              description,
              image,
              price,
              additionals,
              valorPromocional
            } = mappedItem

            const key = `stores-products-category-group-${index}-${id}`
            const formattedPrice = `${Number(price).toFixed(2).replace('.', ',')}`
            const formattedPricePromotional = valorPromocional ? `${Number(valorPromocional).toFixed(2).replace('.', ',')}` : null
            const cartProductPrevious = cart && (cart.products && cart.products.length) ? (cart.products.filter(filteredItem => filteredItem.id === id))[0] : null
            const cartProductPreviousQty = cartProductPrevious && cartProductPrevious.quantity
            const selectedAdditionals = (cartProductPrevious && cartProductPrevious.selectedAdditionals) || []

            return <Product
              direction={'card'}
              key={key}
              quantity={cartProductPreviousQty}
              selectedCategory={{
                name: categoryName,
                id: categoryId
              }}
              status={status}
              handleView={handleViewProduct}
              handleAdd={handleAddProduct}
              id={id}
              name={name}
              description={description}
              image={image}
              storeLogo={storeLogo}
              price={formattedPrice}
              rawPrice={price}
              rawPromocional={valorPromocional}
              additionals={additionals}
              selectedAdditionals={selectedAdditionals}
              valorPromocional={formattedPricePromotional}
              storeImg={storeImg}
              opened={opened}
            />
          })}
        </CategoryWrapper>
      </Fragment>
    })
    }</>
}

CardMenuComponent.propTypes = {
  storeLogo: PropTypes.string,
  selectedProduct: PropTypes.object,
  handleViewProduct: PropTypes.func,
  cart: PropTypes.shape({}),
  categories: PropTypes.arrayOf(PropTypes.shape({}))
}

const mapStateToProps = (state) => {
  return {
    cart: state.main.cart || {},
    categories: (state.main.store && state.main.store.categories) || []
  }
}

const GetConnection = connect(mapStateToProps, {})

export const CardMenu = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(CardMenuComponent))
