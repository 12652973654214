import styled from 'styled-components'

export const Wrapper = styled.header`
  background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  top: 0;
  box-sizing: border-box;
  min-height: 5.625rem;
  max-height: 5.625rem;
  min-width: 100%;
  max-width: 100%;

  > img {
    min-width: 4rem;
    max-width: 4rem;
    min-height: auto;
    max-height: unset;
  }

  button {
    max-width: 11rem;
    height: 2.5rem;
    border-radius: 8px;
    font-size: .875rem;
  }
`
