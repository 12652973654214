import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
  handleGTM,
  logout,
  setModality
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import { cart } from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import history from '../../history'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  containerMode,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Skeleton,
  Navigation,
  Cards
} from '..'


import {
  H1,
  Wrapper,
  LoadingWrapper,
  BackButton
} from './styles'

export const Context = React.createContext({})

export function MyCardsPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    loading,
    theme,
    userAddress,
    userName,
    storeName,
    storeImg,
    modalityId,
    setModality,
    payment,
    handleGTM,
    userId,
    URLParameters
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

  const context = useContext(Context)

  const newCardContent = context.sections.filter(item => item.id === 'new-card-content')[0]
  const newCardContentTitle = newCardContent.data.header.title

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [sideMode, setSideMode] = useState({
    isOpen: false,
    mode: containerMode.default
  })
  const [viewCards, setViewCards] = useState(false)
  const [newCard, setNewCard] = useState(false)


  useEffect(() => {
    const url = window.location.href;
    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Meus cartões'
    }
    handleGTM(access)

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId,
    handleGTM
  ])

  async function handleModality(id) {
    setModality({ id: id })
  }

  const handleSideModeChange = (mode) => {
    const _isOpen = sideMode.mode !== mode

    setSideMode({
      isOpen: _isOpen,
      mode: _isOpen ? mode : sideMode.default
    })
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleNewCard() {
    if (!newCard) {
      setNewCard(true)
    } else {
      setNewCard(false)
    }
  }

  return (
    <Wrapper id={'my-cards'}>
			<Helmet>
				<title>{`${title} - Meus cartões`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/my-cards`} />
			</Helmet>

      {loading.includes('my-cards') && <LoadingWrapper id='my-cards-loading'>
        <Skeleton module='store' />
      </LoadingWrapper>}

      {!loading.includes('my-cards') && <PageContainer
        isSideOpen={sideMode.isOpen}
        mode={sideMode.mode}
      >
        <PageContainerHeader>
          <MainHeader
            currentRoute='my-cards'
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={userAddress ? userAddress : addressDelivery}
            isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
            cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
            opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sideMode.isOpen}>
          <Image src={storeImage} alt="" />

          <H1>{!newCard ? context.title : newCardContentTitle}</H1>

          <BackButton className='back-button' onClick={() => {
            if (!newCard) {
              window.history.back()
            } else {
              handleNewCard(false)
            }
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>

          <Cards payment={payment || false} newCard={newCard} handleNewCard={handleNewCard} />
        </PageContainerMain>
        <PageContainerSide>
          {!isMobile && sideMode.mode === containerMode.sideFullContent &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

          {sideMode.mode === containerMode.headerFullContent ?
            <Navigation
              contactUs={() => handleContactUs()}
              userName={userName}
              currentRoute='my-cards'
            /> : null}
        </PageContainerSide>
      </PageContainer>}
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    addressDelivery: state.main.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.main.loading || [],
    registeredCoupons: state.main.coupons || { coupons: [] },
    userAddress: state.main.userAddress || '',
    userAddresses: state.main.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.main.userName,
    storeImg: (state.main.store && state.main.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.main.store && state.main.store.name,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCart: cart.fetchCart,
  logout,
  setModality,
  handleGTM
})

export const MyCards = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'my-cards' })
)(MyCardsPage))
