import React from 'react'
import { connect } from 'react-redux'

import {
  pipe
} from '../../../domain/helpers'

import {
  CustomIcon
} from '../../components'

import {
  setQuantity
} from '../../../redux/actions/main'

import {
  Image,
  H2,
  Wrapper,
  Div,
  Paragraph,
  Button,
  Span
} from './styles'

export function HorizontalProductComponent(props) {
  const {
    handleView,
    handleAdd,
    setQuantity,
    id,
    name,
    storeLogo,
    price,
    image,
    measure = '1un',
    quantity,
    valorPromocional,
    status,
    className,
    storeImg
  } = props

  function handleQtd(args) {
    setQuantity(args)
  }

  return <Wrapper className={`product ${className} ${!id ? 'hidden' : ''}`}>
    <Div className='holder'>
      { image && <Div className='image' onClick={ handleView }>
        <Image src={ image } />
      </Div> }

      { !image && <Div className='image-store' onClick={ handleView }>
        <Image src={ storeImg } />
      </Div> }

      { !image && storeLogo && <Div className='image store-logo' onClick={ handleView }>
        <Image src={ storeLogo } />
      </Div> }

      <H2 onClick={ handleView }>{ name }</H2>

      <Paragraph onClick={ handleView } className='measure'>{ measure }</Paragraph>

      {!valorPromocional && <Paragraph className='price'>{ price }</Paragraph>}

      {valorPromocional && <Paragraph className='promo'>{ `R$ ${ Number(valorPromocional).toFixed(2).replace('.', ',') }` } <Span>{ price }</Span></Paragraph>}

      {status && status === 1 ? (!quantity ?
        <Button onClick={ handleAdd }><CustomIcon name='Plus' /></Button> :
        <Div className='quantity'>
          <Button className='active' onClick={ () => {
            if (quantity === 1) {
              handleQtd({
                id,
                quantity: 0
              })				
      
              return
            }
      
            handleQtd({
              id,
              quantity: -1
            })
          } }><CustomIcon name='Minus' /></Button>

          <Span>{ quantity }</Span>

          <Button className='active' onClick={ () => {
            if (quantity === 999) {
              return
            }
      
            handleQtd({
              id,
              quantity: 1
            })
          } }><CustomIcon name='Plus' /></Button>
        </Div>) : 
        <Button className='disabled'><CustomIcon name='Plus' /></Button>  
      }
      </Div>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    selectedCategory: state.main.store && state.main.store.selectedCategory
  }
}

const GetConnection = connect(mapStateToProps, {
  setQuantity
})

export function HorizontalProduct(props) {
  const MemoizedComponent = React.memo(HorizontalProductComponent)
  const Component = pipe(
    GetConnection
  )(MemoizedComponent)

  return <Component { ...props } />
}
