import React, {
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import {
  Dialog,
  Slide
} from '@material-ui/core'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
  cart,
  coupon
} from '../../state'

import history from '../../history'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  handleGTM,
  postOrder,
  setCart,
  setUserHistory,
  setNotification,
  setCVV,
  setScheduling
} from '../../redux/actions/main'

import {
  CouponsList
} from '../coupons/simple'

import {
  MainHeader
} from '../main-header'

import {
  Wrapper,
  Main
} from './styles'

import {
  Details,
  Summary
} from './'
import { Scheduling } from '../scheduling'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function OrderConfirmationComponent(props) {
  const {
    userCard,
    userAddress,
    storeName,
    storeStatus,
    loading,
    cpf,
    location,
    theme,
    addCoupon,
    setCoupon,
    setCartCoupon,
    coupon,
    cartCoupon,
    handleGTM,
    postOrder,
    setCart,
    setUserHistory,
    setNotification,
    modalityId,
    storeImg,
    storeAddress,
    scheduling,
    userScheduling,
    setScheduling,
    userId,
    deliveryFee,
    products,
    URLParameters
  } = props

  const storeLogo = theme.logoImageStore

  const [hasCpf, setHasCpf] = useState(false)
  const [visibleCoupons, setVisibleCoupons] = useState(false)
  const [visibleScheduling, setVisibleScheduling] = useState(false)

	const {
		url,
		title,
		description
	} = useStoreSettings()

  useEffect(() => {
    const url = window.location.href;
    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Confirmação do pedido'
    }
    handleGTM(access)

    if (userId) {
      handleGTM({ user_id: userId })
    }
  }, [
    userId,
    handleGTM
  ])

  function postCoupon() {
    addCoupon([() => {
      setCartCoupon({
        error: true
      })
      setCoupon('')
    }, (data) => {
      setCartCoupon({
        code: coupon,
        ...data
      })
      setCoupon('')
    }])
  }

  function handleCoupon(data) {
    setCartCoupon({
      ...data
    })

    setVisibleCoupons(false)
  }

  function handleAddCoupon() {
    postCoupon()
  }

  function handleRemoveCoupon() {
    setCartCoupon('')
    setCoupon('')
  }

  function handleViewCoupons() {
    setVisibleCoupons(true)
  }

  function handleCloseDialogCoupons() {
    setVisibleCoupons(false)
  }

  function handleCloseDialogSecheduling() {
    setVisibleScheduling(false)
  }

  function handleHasCpf() {
    setHasCpf(!hasCpf)
  }

  async function handleFinish() {
    if (storeStatus && storeStatus !== 1) {
      setNotification({
        type: 'warning',
        message: 'Ops! A loja está fechada. Tente em outro horário!!'
      })

      return
    }

    await postOrder([{
      name: 'order',
      type: 'error',
      callback: () => {

      }
    }, {
      name: 'order',
      type: 'success',
      callback: (payload) => {
        const {
          total,
          vqpOrderId,
        } = payload

        const {
          name,
          value,
          minValue,
          typeValueModalityDelivery,
          //valueModalityDelivery
        } = coupon

        // const checkoutDataFinish = {
        //   action: 'analytics',
        //   data: {
        //     eventType: 'begin_purchase',
        //     parameters: {
        //       store_name: storeName,
        //       modality: modalityId,
        //       product_list: products.map(function (item) {
        //         return {
        //           product_name: item.name,
        //           product_value: item.valorPromocional ? item.valorPromocional : item.price,
        //           quantity: item.quantity
        //         }
        //       }),
        //       payment_value: coupon && (total > minValue) && !typeValueModalityDelivery ? total - value : total,
        //       coupon_value: coupon && !typeValueModalityDelivery ? value : valueModalityDelivery,
        //       coupon_cod: coupon ? name : '',
        //       order_id: orderId
        //     }
        //   }
        // }

        const checkoutDataFinish = {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: vqpOrderId,
                affiliation: 'Online Store',
                revenue: coupon && (total > minValue) && !typeValueModalityDelivery ? total - value : total,
                tax: '',
                shipping: deliveryFee,
                coupon: coupon ? name : '',
              },
              products: products ? products.map((product) => {
                return {
                  name: product.name,
                  id: product.id,
                  price: product.price,
                  brand: 'Seara',
                  category: product.category.name,
                  variant: '',
                  quantity: product.quantity
                }
              }) : []
            }
          }
        }

        handleGTM(checkoutDataFinish)

        setCart({
          products: null
        })

        setScheduling(null)

        history.push(`/receipt${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'error',
      callback: () => {
        setUserHistory({
          next: 'cart',
          back: 'cart'
        })

        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })

        history.push(`/new-address${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'success',
      callback: () => {
        console.warn('Address created')
      }
    }], {
      update: false
    })
  }

  return <Wrapper>
		<Helmet>
			<title>{`${title} - Confirmação de pedido`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${url}/order-confirmation`} />
		</Helmet>

    <MainHeader
      currentRoute='order-confirmation'
      theme={theme}
      mode='simple'
      storeName={storeName}
      storeLogo={storeLogo}
    />

    <Main>
      <Details
        loading={loading}
        userCard={userCard}
        addressDelivery={userAddress}
        coupon={cartCoupon ? cartCoupon.code : coupon}
        cartCoupon={cartCoupon}
        setCoupon={setCoupon}
        hasCpf={hasCpf}
        cpf={cpf}
        storeStatus={storeStatus}
        handleRemoveCoupon={handleRemoveCoupon}
        handleHasCpf={handleHasCpf}
        handleFinish={handleFinish}
        handleAddCoupon={handleAddCoupon}
        handleViewCoupons={handleViewCoupons}
        theme={theme}
        modalityId={modalityId}
        storeAddress={storeAddress}
        scheduling={scheduling}
        setVisibleScheduling={setVisibleScheduling}
        userScheduling={userScheduling}
        URLParameters={URLParameters}
      />

      <Summary
        mode='simple'
        storeName={storeName}
        storeLogo={storeLogo}
        storeImg={storeImg}
        pathName={location.pathname}
      />

      <Dialog
        onClose={() => handleCloseDialogCoupons()}
        aria-labelledby="coupons-list-dialog"
        open={visibleCoupons}
        TransitionComponent={Transition}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <CouponsList theme={theme} handleCoupon={handleCoupon} />
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogSecheduling()}
        aria-labelledby="scheduling-dialog"
        open={visibleScheduling}
        TransitionComponent={Transition}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <Scheduling handleCloseDialogSecheduling={handleCloseDialogSecheduling} />
      </Dialog>
    </Main>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeName: state.main.store && state.main.store.name,
    storeStatus: state.main.store && state.main.store.status,
    coupon: state.main.coupon || '',
    cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
    cpf: state.main.userCpf || '',
    userCard: state.main.userCard || '',
    userAddress: state.main.userAddress || '',
    loading: state.main.loading || '',
    products: (state.main.cart && state.main.cart.products) || [],
    modalityId: state.main.modality && state.main.modality.id,
    storeImg: (state.main.store && state.main.store.logo) || '',
    storeAddress: state.main.store && state.main.store.address,
    scheduling: state.main.store && state.main.store.scheduling,
    userScheduling: state.main.scheduling,
    userId: state.main.userId,
    deliveryFee: state.main.store && state.main.store.deliveryFee,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  addCoupon: coupon.addCoupon,
  setCoupon: coupon.setCoupon,
  setCartCoupon: cart.setCartCoupon,
  handleGTM,
  postOrder,
  setCart,
  setUserHistory,
  setNotification,
  setCVV,
  setScheduling
})

export const OrderConfirmation = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'order-confirmation' })
)(OrderConfirmationComponent))
