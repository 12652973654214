import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
	logout,
	setModality,
	handleGTM
} from '../../redux/actions/main'

import { cart } from '../../state'

import {
	pipe,
	GetContent,
	caculateProductsQuantity
} from '../../domain/helpers'

import {
	DefaultImage as Image,
	Button
} from '../components'
import NoStoreIcon from '../assets/no-store.png'

import { MainHeader } from '../main-header'

import { containerMode } from '../page-container'
import { CartContainer } from '../cart'

import {
	Addresses,
	Cart,
	PageContainer,
	PageContainerHeader,
	PageContainerMain,
	PageContainerSide
} from '..'

import {
	Navigation,
	Skeleton,
} from '..'

import {
	Wrapper,
	LoadingWrapper,
	Contened,
	H2,
	Paragraph
} from './styles'
import { useDeviceLayout } from '../hooks'
import history from '../../history'
import { Dialog, Slide } from '@material-ui/core'
import { TrainRounded } from '@material-ui/icons'

const sideStyle = {
	overflow: 'auto'
}

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />
})

export const Context = React.createContext({})

export function NoStorePage(props) {
	const {
		accessToken,
		loading,
		theme,
		storeName,
		storeImg,
		modalityId,
		userAddress,
		addressDelivery,
		userName,
		cartProducts,
		setModality,
		location,
		handleGTM,
		userId,
		URLParameters
	} = props

	const storeImage = theme.headerImage
	const storeLogo = theme.logoImage

	const [activeDialogSignIn, setActiveDialogSignIn] = useState(false)
	const [sideMode, setSideMode] = useState({
		isOpen: false,
		mode: containerMode.default
	})
	const [viewCards, setViewCards] = useState(false)
	const [cartSlideDown, setCartSlideDown] = useState(false)
	const [visibleAddress, setVisibleAddress] = useState(false)
	const [fullScreen, setFullScreen] = useState(false)

	const isMobile = useDeviceLayout({
		isMobile: true
	})

	const fullWidth = true

	useEffect(() => {
		setCartSlideDown(false)
		setModality({ id: 4 })

		if (isMobile) {
			setFullScreen(true)
		}
	}, [isMobile, setModality])

	useEffect(() => {
		const url = window.location.href;
		const access = {
			event: 'PageView',
			page_path: url,
			page_title: 'Loja não encontrada'
		}
		handleGTM(access)

		if (userId) {
			handleGTM({ user_id: userId })
		}
	}, [
		userId,
		handleGTM
	])

	async function handleModality(id) {
		setModality({ id: id })
	}

	const handleSideModeChange = (mode) => {
		const _isOpen = sideMode.mode !== mode
		setSideMode({
			isOpen: _isOpen,
			mode: _isOpen ? mode : sideMode.default
		})
	}

	function handleContactUs() {
		history.push(`/contact-us${URLParameters}`)
	}

	function handleNoToken() {
		setActiveDialogSignIn(true)

		setTimeout(() => {
			setActiveDialogSignIn(false)
		}, 2000)
	}

	function handleCloseDialogAddress() {
		setVisibleAddress(false)
	}

	return (
		<Wrapper id={'no-store'}>
			{loading.includes('no-store') && <LoadingWrapper id='no-store-loading'>
				<Skeleton module='store' />
			</LoadingWrapper>}

			{!loading.includes('no-store') && <PageContainer
				isSideOpen={sideMode.isOpen}
				mode={sideMode.mode}
			>
				<PageContainerHeader>
					<MainHeader
						mode='full'
						theme={theme}
						name={storeName}
						modality={modalityId}
						handleModality={handleModality}
						storeLogo={storeLogo}
						storeImg={storeImg}
						userName={userName}
						accessToken={accessToken}
						addressDelivery={userAddress ? userAddress : addressDelivery}
						activeDialogSignIn={activeDialogSignIn}
						isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
						cartItemsQuantity={caculateProductsQuantity(cartProducts)}
						userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
						cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
						homeDisabled={true}
						pathName={location.pathname}
						opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
					/>
				</PageContainerHeader>
				<PageContainerMain>
					<>
						<Image src={storeImage} alt="" />
						<Contened>
							<Image src={NoStoreIcon} alt='Ícone para sem loja' width="120px" />

							<H2>Não estamos operando na sua região ainda.</H2>
							<Paragraph>Estamos trabalhando para ampliar nossa área de atendimento.
								Infelizmente não poderemos atende-lo no momento.</Paragraph>
							<Button onClick={() => setVisibleAddress(TrainRounded)}>Alterar Endereço</Button>
						</Contened>
					</>
				</PageContainerMain>
				<PageContainerSide styles={sideStyle}>
					{sideMode.mode === containerMode.sideFullContent &&
						<CartContainer
							logo={storeLogo}
							storeImg={storeImg}
							name={storeName}
						>
							<Cart
								mode='simple'
								screenMobile={isMobile}
								setViewCards={setViewCards}
								viewCards={viewCards}
								className={cartSlideDown ? 'slide-down' : ''}
								handleNoToken={handleNoToken}
							/>
						</CartContainer>
					}

					{sideMode.mode === containerMode.headerFullContent ?
						<Navigation
							contactUs={() => handleContactUs()}
							userName={userName}
							currentRoute='no-store'
						/> : null}
				</PageContainerSide>
			</PageContainer>}

			<Dialog
				onClose={() => handleCloseDialogAddress()}
				aria-labelledby="address-dialog"
				open={visibleAddress}
				maxWidth={'sm'}
				fullWidth={fullWidth}
				TransitionComponent={Transition}
				fullScreen={fullScreen}
			>
				<Addresses
					pathName={location.pathname}
					handleCloseDialogAddress={handleCloseDialogAddress}
				/>
			</Dialog>

		</Wrapper>
	)
}

const mapStateToProps = (state) => {
	return {
		accessToken: state.main.accessToken,
		addressDelivery: state.main.addressByCEP || '',
		cartProducts: (state.main.cart && state.main.cart.products) || [],
		loading: state.main.loading || [],
		registeredCoupons: state.main.coupons || { coupons: [] },
		userAddress: state.main.userAddress || '',
		userAddresses: state.main.userAddresses || [],
		userHistory: state.main.userHistory || {},
		userName: state.main.userName,
		storeImg: (state.main.store && state.main.store.logo) || '',
		modalityId: state.main.modality && state.main.modality.id,
		storeName: state.main.store && state.main.store.name,
		storeId: state.main.store && state.main.store.id,
		storeStatus: state.main.store && state.main.store.status,
		deliveryFee: state.main.store && state.main.store.deliveryFee,
		deliveryMinimumValue: state.main.store && state.main.store.deliveryMinimumValue,
		pickupMinimumValue: state.main.store && state.main.store.pickupMinimumValue,
		deliveryTime: state.main.store && state.main.store.deliveryTime,
		storeContact: state.main.store && state.main.store.contact,
		openingHours: state.main.store && state.main.store.openingHours,
		onlineFlags: state.main.store && state.main.store.onlineFlags,
		userId: state.main.userId,
		URLParameters: (state.main.URLParameters) || ''
	}
}

const GetConnection = connect(mapStateToProps, {
	fetchCart: cart.fetchCart,
	logout,
	setModality,
	handleGTM
})

export const NoStore = React.memo(pipe(
	GetConnection,
	GetContent({ context: Context, id: 'no-store' })
)(NoStorePage))
