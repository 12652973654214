import {
  actionTypes
} from '../'

import {
  loading
} from '../loading'

import {
  modality,
  store,
  userAddress
} from '../'

export const setCart = (cart) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CART,
    payload: cart
  })
}

export const setCartCoupon = (coupon) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_CART_COUPON,
    payload: coupon
  })
}

export const fetchCart = (callback) => (dispatch, getState, container) => {
  try {
    const orderId = getState().main.order && getState().main.order.id

    const data = {
      orderId
    }

    const actions = {
      setLoading: loading.setLoading
    }

    return container.fetchCart({
      onSuccess: ({ cart}) => {
        dispatch(setCart({
          products: cart.items
        }))
        dispatch(modality.setModality({
          id: cart.modality.id
        }))
        dispatch(store.setStore({
          id: cart.store.id,
          name: cart.store.name,
          address: cart.store.address
        }))
        dispatch(userAddress.setUserAddress({
          main: cart.deliveryAddress
        }))
      },
      onError: () => {
        dispatch(setCart([]))
      },
      onEmpty: () => {
        dispatch(setCart([]))
      },
      dispatch,
      data,
      actions
   })
  } catch (e) {
    console.log(e)

    dispatch(setCart([]))
  } finally {
    callback && callback()
  }
}
