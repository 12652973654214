import React, {
  useEffect,
  useState
} from 'react'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import {
  THEME
} from '../../config'

import GlobalStyle from './AppGlobalStyle'
import WhiteLabel from '../white-label'
import history from '../../history'

// Notifications
/* import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css' */
import "animate.css"

const CURRENT_VERSION = '1.0.2'

function App({
  store
}) {
  const [basePath, setBasePath] = useState('')
  const [pathname, setPathname] = useState('')
  const [storeId, setStoreId] = useState('')
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    const localStorageVQP = localStorage.getItem('vqp') ? JSON.parse(localStorage.getItem('vqp')) : null
    const pathnames = window.location.pathname.split('/')

    if (localStorageVQP && localStorageVQP.version && localStorageVQP.version !== CURRENT_VERSION) {
      localStorage.removeItem('vqp')
      localStorage.removeItem('main')

      const initialLocalStorageVersion = { version: CURRENT_VERSION }

      localStorage.setItem('vqp', JSON.stringify(initialLocalStorageVersion))
    }

    if (!localStorageVQP) {
      const initialLocalStorageVersion = { version: CURRENT_VERSION }

      localStorage.removeItem('vqp')
      localStorage.removeItem('main')
      localStorage.setItem('vqp', JSON.stringify(initialLocalStorageVersion))
    }

    const localStorageStore = JSON.parse(localStorage.getItem('main'))

    if (!loaded) {
      setBasePath(window.location.pathname)
      setLoaded(true)
    }

    if (loaded && parseInt(pathnames[1])) {
      setPathname(pathnames)
    }

    if (!pathname[1] && localStorageStore && localStorageStore.store && localStorageStore.store.id) {
      setStoreId(localStorageStore.store.id)

      return () => { }
    }

    if (pathname[1]) {
      setStoreId(pathname[1])

      return () => { }
    }

    return () => { }
  }, [
    basePath,
    setBasePath,
    storeId,
    setStoreId,
    pathname,
    setPathname,
    loaded,
    setLoaded
  ])

  return <Router history={history} basePath={basePath}>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <GlobalStyle />
        {/* <ReactNotification /> */}
        <WhiteLabel storeId={storeId} />
      </Provider>
    </ThemeProvider>
  </Router>
}

export default Sentry.withProfiler(App)
