import React, {
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'

//import { PWAAnalytics } from '../../domain/helpers/ipiranga/PWA'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import {
  CustomIcon
} from '../components'

import {
  removeSelectedProduct,
  setSelectedProduct,
  addProduct,
  removeProduct,
  handleGTM,
  removeSelectedProductAdditional,
  setSelectedCategory,
  setOpenCart
} from '../../redux/actions/main'

import {
  Wrapper,
  ImageWrapper,
  Textarea,
  Button,
  CloseButton,
  Image,
  H1,
  H4,
  Paragraph,
  Span,
  NotesWrapper,
  Div
} from './styles'

import {
  Additionals
} from './'


export const Context = React.createContext({})

const Notes = ({
  status,
  quantity,
  total,
  update,
  handleQuantity,
  handleAdd,
  scheduling
}) => {
  return <NotesWrapper id='product-notes'>
    {!scheduling && <><H4>Alguma observação?</H4>
      <Textarea placeholder='Deseja fazer alguma observação adicional? Digite aqui' /></>}

    <Div className='row'>
      <Div className='quantity'>
        <Button onClick={() => {
          handleQuantity({
            quantity: -1
          })
        }}>-</Button>
        <Span>{quantity}</Span>
        <Button onClick={() => {
          handleQuantity({
            quantity: 1
          })
        }}>+</Button>
      </Div>
      {status && status === 1 ? <Div className='add'>
        <Button onClick={() => {
          handleAdd()
        }}>
          <Span>{!!update ? 'Atualizar' : 'Adicionar'}</Span>
          <Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
        </Button>
      </Div> : <Div className='add'>
        <Button disabled onClick={() => {
          handleAdd()
        }}>
          <Span>{'Adicionar'}</Span>
          <Span>{`R$ ${total && (Number(total).toFixed(2)).replace('.', ',')}`}</Span>
        </Button>
      </Div>}
    </Div>
  </NotesWrapper>
}

function ProductPage(props) {
  const {
    storeStatus,
    selectedProduct,
    selectedCategory,
    removeSelectedProductAdditional,
    setSelectedProduct,
    addProduct,
    removeProduct,
    cart,
    handleGTM,
    handleClose,
    cartClicked,
    setOpenCart,
    scheduling
  } = props

  const {
    id,
    name,
    image,
    description,
    quantity,
    additionals,
    valorPromocional
  } = selectedProduct

  const [loaded, setLoaded] = useState(false)
  const [lastQuantity, setLastQuantity] = useState(0)
  const [update, setUpdate] = useState(0)
  const [total, setTotal] = useState(0)
  const [price, setPrice] = useState(0)
  const [cartProductPreviousQuantity, setCartProductPreviousQuantity] = useState(0)
  
  useEffect(() => {
    const {
      products
    } = cart

    const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === id))[0] : null
    const cartProductPreviousQuantity = (cartProductPrevious && cartProductPrevious.quantity) || 0
    const finalQuantity = !update && cartProductPreviousQuantity ? cartProductPreviousQuantity : quantity
    const price = `R$ ${Number(selectedProduct.price).toFixed(2).replace('.', ',')}`

    const selectedAdditionals = selectedProduct.selectedAdditionals || cartProductPrevious.selectedAdditionals

    const selectedAdditionalsGroups = selectedAdditionals && selectedAdditionals.length && selectedAdditionals.map((mappedItem) => {
      const selectedAdditionalsMaximum = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 3)
      const selectedAdditionalsAverage = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 2)
      const selectedAdditionalsFixes = mappedItem.items && mappedItem.items.length && mappedItem.items.filter(filteredItem => filteredItem.calcType === 1)

      const additionalsMaximumTotal = (selectedAdditionalsMaximum && selectedAdditionalsMaximum.length && Math.max.apply(Math, selectedAdditionalsMaximum.map((selectedAdditionalsMaximumItem) => selectedAdditionalsMaximumItem.price))) || 0
      const additionalsAverageTotal = selectedAdditionalsAverage && selectedAdditionalsAverage.length ? (selectedAdditionalsAverage.reduce((acc, current) => Number(acc + current.price), [])) / selectedAdditionalsAverage.length : 0
      const additionalsFixesTotal = selectedAdditionalsFixes && selectedAdditionalsFixes.length ? selectedAdditionalsFixes.reduce((acc, current) => {
        if (current.quantity > 1) {
          return Number(acc + (current.price * current.quantity))
        }

        return Number(acc + current.price)
      }, []) : 0

      return additionalsMaximumTotal + additionalsAverageTotal + additionalsFixesTotal
    })
    const finalPriceProduct = !valorPromocional ? selectedProduct.price : valorPromocional

    const selectedAdditionalsGroupsTotal = (selectedAdditionalsGroups && selectedAdditionalsGroups.length && selectedAdditionalsGroups.reduce((acc, current) => Number(acc + current), [])) || 0

    setLastQuantity(finalQuantity)
    setTotal(finalQuantity * (finalPriceProduct + selectedAdditionalsGroupsTotal))
    setPrice(price)
    setCartProductPreviousQuantity(cartProductPreviousQuantity)

    return () => { }
  }, [
    cart,
    id,
    quantity,
    selectedProduct,
    valorPromocional,
    update,
    loaded,
    setLoaded
  ])

  // function handleClose () {
  //   setSelectedCategory({
  //     name: selectedCategory.name,
  //     selectedProductsCategories: openModalCategory ? true : false
  //   })

  //   removeSelectedProduct()
  // }

  function handleQuantity(item) {
    const {
      quantity: quantityIncrement
    } = item

    const newProduct = {
      ...selectedProduct,
      quantity: lastQuantity + quantityIncrement,
      category: selectedCategory,
    }

    setUpdate(true)

    if (newProduct.quantity > 999) return false
    if (newProduct.quantity < 1) return false

    setLastQuantity(newProduct.quantity)
    setSelectedProduct(newProduct)
  }

  function handleAdditionalsChange(item, hasAdded) {
    const {
      quantity: quantityIncrement
    } = item

    if (hasAdded) {
      removeSelectedProductAdditional(item)

      return
    }

    const {
      groupId,
      calcType,
      minimum,
      maximum
    } = item

    const {
      products
    } = cart

    const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
    const selectedAdditionals = selectedProduct.selectedAdditionals || cartProductPrevious.selectedAdditionals
    const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

    if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
      const group = document.getElementById(`additionals-group-${selectedAdditionalGroup.groupId}-${selectedAdditionalGroup.calcType}`)

      group.classList.add('maximum')

      return false
    }

    if (!selectedAdditionalGroup) {
      const newGroup = {
        groupId,
        calcType,
        minimum,
        maximum,
        items: [
          item
        ]
      }

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...selectedAdditionals,
          newGroup
        ]
      }

      setSelectedProduct(newProduct)

      return
    }

    if (selectedAdditionalGroup) {
      const {
        items = []
      } = selectedAdditionalGroup

      const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
        if (mappedItem.groupId === groupId && mappedItem.calcType === calcType) {
          const newItems = [
            ...items,
            item
          ]

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        return mappedItem
      })

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...newSelectedAdditionals
        ]
      }

      setSelectedProduct(newProduct)

      return
    }
  }

  function handleAdditionalsQuantity(item) {
    const {
      id,
      quantity: quantityIncrement,
      groupId,
      calcType,
      minimum,
      maximum
    } = item

    const {
      products
    } = cart

    const cartProductPrevious = (products && products.length) ? (products.filter(filteredItem => filteredItem.id === item.id))[0] : null
    const selectedAdditionals = selectedProduct.selectedAdditionals || cartProductPrevious.selectedAdditionals
    const selectedAdditionalGroup = (selectedAdditionals && selectedAdditionals.length && (selectedAdditionals.filter(filteredItem => filteredItem.groupId === groupId && filteredItem.calcType === calcType)[0])) || null

    if (selectedAdditionalGroup && selectedAdditionalGroup.items && selectedAdditionalGroup.items.length + quantityIncrement > maximum) {
      return false
    }

    if (!selectedAdditionalGroup) {
      const newItem = {
        ...item,
        quantity: 1
      }

      const newGroup = {
        groupId,
        calcType,
        minimum,
        maximum,
        items: [
          newItem
        ]
      }

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...selectedAdditionals,
          newGroup
        ]
      }

      setSelectedProduct(newProduct)

      return
    }

    if (selectedAdditionalGroup) {
      const {
        items = []
      } = selectedAdditionalGroup

      const newSelectedAdditionals = selectedAdditionals.map(mappedItem => {
        const hasSelectedAdditional = mappedItem.items && mappedItem.items.filter(filteredItem => filteredItem.code === id)

        const quantityTotal = items.reduce((acc, current) => {
          return Number(acc + current.quantity)
        }, 0)
        const quantityRemaining = maximum - quantityTotal

        if (!hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && quantityRemaining) {
          const newItem = {
            ...item,
            quantity: 1
          }

          const newItems = [
            ...items,
            newItem
          ]

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        if (hasSelectedAdditional.length && mappedItem.groupId === groupId && mappedItem.calcType === calcType && ((quantityRemaining && quantityIncrement > 0) || ((!quantityRemaining || quantityRemaining) && quantityIncrement < 0))) {
          const newItems = items && items.length && (items.map((newItemsMappedItem) => {
            const {
              quantity: newItemsMappedItemQuantity = 0
            } = newItemsMappedItem

            const newQuantity = newItemsMappedItemQuantity + quantityIncrement

            if (newQuantity >= 1 && newQuantity <= maximum && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
              return {
                ...newItemsMappedItem,
                quantity: newQuantity
              }
            }

            if (newQuantity < 1 && hasSelectedAdditional && hasSelectedAdditional[0] && hasSelectedAdditional[0].code === newItemsMappedItem.code) {
              return false
            }

            return {
              ...newItemsMappedItem
            }
          })).filter(filteredItem => filteredItem !== false)

          const newMappedItem = {
            ...mappedItem,
            items: newItems
          }

          return newMappedItem
        }

        return mappedItem
      })

      const newProduct = {
        ...selectedProduct,
        selectedAdditionals: [
          ...newSelectedAdditionals
        ]
      }

      setSelectedProduct(newProduct)

      return
    }
  }

  async function handleAdd() {
    const product = {
      ...selectedProduct,
      quantity: lastQuantity
    }

    const {
      additionals,
      selectedAdditionals
    } = selectedProduct

    const requiredAdditionals = additionals && additionals.filter(filteredItem => filteredItem.minimum >= 1)

    const hasMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
      const selectedAdditional = selectedAdditionals.filter(selectedAdditionalsFilteredItem =>
        selectedAdditionalsFilteredItem.groupId === requiredAdditionalsFilteredItem.groupId &&
        selectedAdditionalsFilteredItem.calcType === requiredAdditionalsFilteredItem.calcType &&
        (selectedAdditionalsFilteredItem.items && selectedAdditionalsFilteredItem.items.length >= requiredAdditionalsFilteredItem.minimum)
      )

      if (!selectedAdditional.length) {
        return false
      }

      const {
        items = []
      } = selectedAdditional[0]

      if (!items.length) {
        return false
      }

      return true
    })

    const hasNoMinimum = requiredAdditionals && requiredAdditionals.filter(requiredAdditionalsFilteredItem => {
      const selectedAdditional = selectedAdditionals.filter(selectedAdditionalsFilteredItem =>
        selectedAdditionalsFilteredItem.groupId === requiredAdditionalsFilteredItem.groupId &&
        selectedAdditionalsFilteredItem.calcType === requiredAdditionalsFilteredItem.calcType &&
        (selectedAdditionalsFilteredItem.items && selectedAdditionalsFilteredItem.items.length >= requiredAdditionalsFilteredItem.minimum)
      )

      if (!selectedAdditional.length) {
        return true
      }

      const {
        items = []
      } = selectedAdditional[0]

      if (!items.length) {
        return true
      }

      return false
    })

    hasMinimum && hasMinimum.length && hasMinimum.forEach((hasMinimum) => {
      const group = document.getElementById(`additionals-group-${hasMinimum.groupId}-${hasMinimum.calcType}`)

      group.classList.remove('required')
    })

    if (hasMinimum.length < requiredAdditionals.length && hasNoMinimum.length) {
      hasNoMinimum.forEach((hasNoMinimumItem) => {
        const group = document.getElementById(`additionals-group-${hasNoMinimumItem.groupId}-${hasNoMinimumItem.calcType}`)

        group.classList.add('required')
      })

      return false
    }

    // const addCartProductData = {
    //   "action": "analytics",
    //   "data": {
    //     "eventType": "add_product_to_cart",
    //     "parameters": {
    //       "store_name": storeName,
    //       "modality": modalityId,
    //       'product_name': product.name,
    //       'product_value': product.valorPromocional ? product.valorPromocional : product.price,
    //       'quantity': product.quantity
    //     }
    //   }
    // }

    const addProductData = {
      event: 'addToCart',
      ecommerce: {
        'add': {
          'products': [{
            'name': product.name,
            'id': product.id,
            'price': product.valorPromocional ? product.valorPromocional : product.price,
            'brand': 'Seara',
            'category': product.category && product.category.name,
            'variant': '',
            'quantity': product.quantity
          }]
        }
      }
    }

    handleGTM(addProductData)

    //PWAAnalytics(addCartProductData)

    setOpenCart(true)
    await removeProduct(product)
    await addProduct(product)

    cartClicked()
    handleClose()
  }

  if (!name || !price) {
    return null
  }

  return <Wrapper id='store-product'>
    <CloseButton className='close-button' onClick={(event) => {
      handleClose()
    }}>
      <CustomIcon name='Times' />
    </CloseButton>

    {image ? <ImageWrapper>
      <Image className='main' src={image} alt={name} />
    </ImageWrapper> : null}

    <H1>{name}</H1>

    <Paragraph>{description}</Paragraph>

    <a download="modo_preparo_ceia_natal.png" href={`/modo_preparo_ceia_natal.png`}>
      Para ver a melhor forma de preparar<br/> a sua Ceia, <u>clique aqui</u>
    </a>

    {!valorPromocional && <Paragraph className='price'>{price}</Paragraph>}

    {valorPromocional && <Paragraph className='promo'>{`R$ ${Number(valorPromocional).toFixed(2).replace('.', ',')}`} <Span>{price}</Span></Paragraph>}

    {additionals && additionals.length ? <Additionals
      data={additionals}
      cart={cart}
      selectedProduct={selectedProduct}
      handleQuantity={handleAdditionalsQuantity}
      handleChange={handleAdditionalsChange}
    /> : null}

    <Notes
      total={total}
      update={!!cartProductPreviousQuantity}
      quantity={lastQuantity}
      handleQuantity={handleQuantity}
      handleAdd={handleAdd}
      status={storeStatus}
      scheduling={scheduling}
    />
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    storeStatus: state.main.store && state.main.store.status,
    storeName: state.main.store && state.main.store.name,
    selectedProduct: (state.main.store && state.main.store.selectedProduct) || {},
    selectedCategory: state.main.store && state.main.store.selectedCategory,
    openModalCategory: state.main.store && state.main.store.selectedCategory && state.main.store.selectedCategory.openModalCategory,
    cart: state.main.cart,
    modalityId: state.main.modality.id,
    scheduling: state.main.store && state.main.store.scheduling
  }
}

const GetConnection = connect(mapStateToProps, {
  removeSelectedProduct,
  removeSelectedProductAdditional,
  setSelectedProduct,
  addProduct,
  removeProduct,
  handleGTM,
  setSelectedCategory,
  setOpenCart
})

export const Product = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'payment' })
)(ProductPage))
