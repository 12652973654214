import React, {
  useContext,
  useEffect,
  useState
} from 'react'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import { ReactComponent as IconContactUs } from '../assets/icons/icon_contact_us.svg'
import { ReactComponent as IconClose } from '../assets/icons/icon_close.svg'
import { ReactComponent as IconCheck } from '../assets/icons/icon_check.svg'

import {
  setModality,
  handleGTM
} from '../../redux/actions/main'

import {
  useDeviceLayout
} from '../hooks'

import {
  contactUs
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

import {
  DefaultImage as Image,
  CustomIcon,
  InputRounded,
  CustomLoading
} from '../components'

import {
  Cart,
  CartContainer
} from '../cart'

import {
  MainHeader
} from '../main-header'

import {
  containerMode,
  PageContainer,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide
} from '../page-container'

import {
  Navigation,
} from '..'

import {
  H1,
  Wrapper,
  BackButton,
  Form,
  SendButton,
  PageSubtitle,
  PageParagraph,
  ErrorMessage,
  SuccessMessage,
  Row,
  Column,
  Message
} from './styles'

const requiredField = {
  name: 'message',
  message: 'A mensagem é obrigatória'
}

export const Context = React.createContext({})

export function ContactUsPage(props) {
  const {
    accessToken,
    addressDelivery,
    cartProducts,
    sendContactUs,
    contactUs,
    loading,
    setContactUs,
    theme,
    userAddress,
    userName,
    storeName,
    storeImg,
    modalityId,
    handleGTM,
    userId
  } = props

  const storeImage = theme.headerImage
  const storeLogo = theme.logoImage

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const context = useContext(Context)

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const { sections } = useContext(Context)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [sideMode, setSideMode] = useState({
    isOpen: false,
    mode: containerMode.default
  })
  const [viewCards, setViewCards] = useState(false)

  const contactUsSection = sections && sections.filter(item => item.id === 'send')[0]

  const registeredContactUssSectionTitle = contactUsSection.title
  const registeredContactUssSectionText = contactUsSection.text

  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)

  useEffect(() => {
    const url = window.location.href;
    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Fale conosco'
    }

    handleGTM(access)

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId,
    handleGTM
  ])

  useEffect(() => {
    if (contactUs && contactUs.notifications && contactUs.notifications.length) {
      setContactUs({
        ...contactUs.notifications[0]
      })
    }
  }, [
    contactUs,
    setContactUs,
  ])

  async function handleModality(id) {
    setModality({ id })
  }

  const handleSideModeChange = (mode) => {
    const _isOpen = sideMode.mode !== mode

    setSideMode({
      isOpen: _isOpen,
      mode: _isOpen ? mode : sideMode.default
    })
  }

  function handleContactUs() {

  }

  async function handleSubmit() {
    setError(undefined)
    setSuccess(false)

    if (!contactUs.message) {
      setError(requiredField.message)
      return
    }

    setPending(true)
    await sendContactUs([() => {

    }, () => {
      setContactUs({ message: '' })
      setSuccess(true)
    }])

    setPending(false)
  }

  return (
    <Wrapper id={'contact-us'}>
			<Helmet>
				<title>{`${title} - Fale conosco`}</title>
				<meta name='description' content={description} />
				<link rel='canonical' href={`${url}/contact-us`} />
			</Helmet>

      <PageContainer
        isSideOpen={sideMode.isOpen}
        mode={sideMode.mode}
      >
        <PageContainerHeader>
          <MainHeader
            currentRoute='contact-us'
            mode='full'
            theme={theme}
            storeLogo={storeLogo}
            name={storeName}
            storeImg={storeImg}
            userName={userName}
            accessToken={accessToken}
            setVisibleSignIn={setVisibleSignIn}
            visibleSignIn={visibleSignIn}
            handleModality={handleModality}
            modality={modalityId}
            addressDelivery={userAddress ? userAddress : addressDelivery}
            isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
            cartItemsQuantity={caculateProductsQuantity(cartProducts)}
            userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
            cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
            opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
          />
        </PageContainerHeader>
        <PageContainerMain opened={sideMode.isOpen}>
          <Image src={storeImage} alt="" />

          <H1>{context.title}</H1>

          <BackButton className='back-button' onClick={() => {
            window.history.back()
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>

          {!error && success &&
            <Message className="success">
              <span>Mensagem enviada com sucesso!</span>
              <IconCheck />
            </Message>
          }

          {error && <Message className="error">
            <span>{error}</span>
            <IconClose />
          </Message>
          }

          <Row>
            <Column>
              <PageSubtitle>{registeredContactUssSectionTitle}</PageSubtitle> {/* Dúvidas, elogios ou reclamação */}
              <PageParagraph>{registeredContactUssSectionText}</PageParagraph> {/* Conta tudo, aqui sua opinião vale muito pra gente! :) */}
              <Form>
                <InputRounded
                  as='textarea'
                  placeholder='Escreva aqui sua mensagem'
                  name='message'
                  value={contactUs && contactUs.message}
                  onChange={(event) => {
                    setContactUs({ message: event.target.value })
                  }}
                />
                {error && <ErrorMessage>{error}</ErrorMessage>}
                {!error && success && <SuccessMessage>Mensagem enviada com sucesso!:DDDDDDDDDDD</SuccessMessage>}
                <SendButton
                  onClick={() => handleSubmit()}
                  type='button'
                  disabled={pending}
                >{loading.includes('send-contact-us') ?
                  <CustomLoading
                    color={'#fff'}
                    type={'spin'}
                    id='default-loading'
                    height={30}
                    width={30} /> :
                  'Enviar'}</SendButton>
              </Form>
            </Column>
            <Column className='center'>
              <IconContactUs id='contactUsIcon' />
            </Column>
          </Row>
        </PageContainerMain>
        <PageContainerSide>
          {!isMobile && sideMode.mode === containerMode.sideFullContent &&
            <CartContainer logo={storeLogo}>
              <Cart
                mode='simple'
                screenMobile={isMobile}
                setViewCards={setViewCards}
                viewCards={viewCards}
                handleFinish={() => { }}
                handleClose={() => { }}
              />
            </CartContainer>}

          {sideMode.mode === containerMode.headerFullContent ?
            <Navigation
              contactUs={() => handleContactUs()}
              userName={userName}
              currentRoute='contact-us'
            /> : null}
        </PageContainerSide>
      </PageContainer>
    </Wrapper>
  )
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    addressDelivery: state.main.addressByCEP || '',
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    loading: state.main.loading || [],
    coupon: state.main.coupon || {},
    contactUs: state.main.contactUs || { message: '', notifications: [] },
    userAddress: state.main.userAddress || '',
    userAddresses: state.main.userAddresses || [],
    userHistory: state.main.userHistory || {},
    userName: state.main.userName,
    storeImg: (state.main.store && state.main.store.logo) || '',
    modalityId: state.main.modality && state.main.modality.id,
    storeName: state.main.store && state.main.store.name,
    userId: state.main.userId
  }
}

const GetConnection = connect(mapStateToProps, {
  sendContactUs: contactUs.sendContactUs,
  setContactUs: contactUs.setContactUs,

  setModality,
  handleGTM
})

export const ContactUs = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'contact-us' })
)(ContactUsPage))

/* import React, {
  useState,
} from 'react'
import { connect } from 'react-redux'

import { postContactUsMessage } from '../../redux/actions/main'
import history from '../../history'
import { InputRounded } from '../components';
import { PageContainer, PageContainerHeader, PageContainerMain } from '../page-container';
import { ReactComponent as IconContactUs } from '../assets/icons/icon_contact_us.svg';
import { ReactComponent as IconArrowLeft } from '../assets/icons/icon_arrow_left.svg';
import {
import { setModality } from '../../state/modality/Modality';
  Content,
  ContentHeader,
  PageTitle,
  ContentWrapper,
  Form,
  SendButton,
  PageSubtitle,
  PageParagraph,
  ErrorMessage,
  SuccessMessage
} from './styles';

const requiredField = {
  name: 'message',
  message: 'A mensagem é obrigatória'
}

function ContactUsPage(props) {
  const {
    postContactUsMessage,
  } = props

  const [message, setMessage] = useState('')
  const [error, setError] = useState(undefined)
  const [success, setSuccess] = useState(false)
  const [pending, setPending] = useState(false)

  function handleInput(event) {
    setMessage(event.target.value);
  }

  function handleBackToStore() {
    history.push('store')
  }

  async function handleSubmit() {
    setError(undefined)
    setSuccess(false)

    if (!message) {
      setError(requiredField.message)
      return
    }

    setPending(true)
    const result = await postContactUsMessage(message)

    if (result.success) {
      setMessage('')
      setSuccess(true)
    } else {
      setError(result.message || 'Erro ao enviar mensagem')
    }

    setPending(false)
  }

  return (
    <PageContainer>
      <PageContainerHeader>
        HEADER
      </PageContainerHeader>
      <PageContainerMain>
        <Content>
          <ContentHeader>
            <IconArrowLeft onClick={() => handleBackToStore()}/>
            <PageTitle>SUPORTE</PageTitle>
          </ContentHeader>
          <ContentWrapper>
            <PageSubtitle>Dúvidas, elogios ou reclamação</PageSubtitle>
            <PageParagraph>Conta tudo, aqui sua opinião vale muito pra gente! :)</PageParagraph>
            <Form>
              <InputRounded
                as='textarea'
                placeholder='Escreva aqui sua mensagem'
                name='message'
                onChange={ handleInput }
                value={ message }
              />
              { error && <ErrorMessage>{ error }</ErrorMessage> }
              { !error && success && <SuccessMessage>Mensagem enviada com sucesso!</SuccessMessage>}
              <SendButton
                onClick={() => handleSubmit()}
                type='button'
                disabled={pending}
              >Enviar</SendButton>
            </Form>
          </ContentWrapper>
          <IconContactUs id='contactUsIcon'/>
        </Content>
      </PageContainerMain>
    </PageContainer>
  )
}

const mapStateToProps = () => {
  return {}
}

const Connected = connect(mapStateToProps, {
  postContactUsMessage,
})(ContactUsPage)

export function ContactUs( props ) {
  return <Connected { ...props } />
} */
