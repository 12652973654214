import React, {
  useEffect
} from 'react'
import PropTypes from 'prop-types'

import {
  CustomIcon
} from '../../components'

import {
  Wrapper,
  Input,
  Button
} from './styles'

export function Search(props) {
  const {
    handleSearch,
    handleSearchText,
    searchText,
    show
  } = props

  useEffect(() => {
    return () => {}
  }, [])

  return (<>
    {show && <Wrapper id='store-search'>
      <Input placeholder='Buscar algo' value={ searchText } onKeyDown={ (event) => {
        const {
          keyCode
        } = event
        
        if (keyCode === 13) {
          handleSearch()
        }
      } } onChange={ (event) => {
        const {
          value
        } = event.target

        handleSearchText(value)
      }} />

      <Button onClick={ () => {
        handleSearch()
      } }>
        <CustomIcon name='Search' />
      </Button>
    </Wrapper>}
  </>)
}

Search.propTypes = {
  show: PropTypes.bool,
  handleSearch: PropTypes.func,
  handleSearchText: PropTypes.func,
  searchText: PropTypes.string
}
