export default ({
  apiService
}) => {
  const strategy = {
    request: async ({
      data,
      auth,
      dispatch,
      actions,
      maps,
    }) => {
      const {
        setLoading
      } = actions

      const {
        userAddress,
        loading
      } = data

      const {
        mapUserAddressesList
      } = maps

      if (loading && loading.length && loading.indexOf('user-addresses') > -1) {
        return false
      }

      dispatch(setLoading('user-addresses'))

      const url = `enderecosdelivery/listar`
      const result = await apiService.get(url, auth)
      const mappedResult = mapUserAddressesList(result.data)

      if (mappedResult && mappedResult.length) {
        return mappedResult
      }

      const filteredAddressById = mappedResult.filter((filteredItem) => filteredItem.id === userAddress.id)
      const filteredAddresses = mappedResult.filter((filteredItem) => filteredItem.id !== userAddress.id)

      if (filteredAddressById[0] && mappedResult && mappedResult.length && filteredAddresses && filteredAddresses.length) {
        const newFilteredAddresses = [filteredAddressById[0], ...filteredAddresses]

        return newFilteredAddresses
      }

      return []
    }
  }

  return {
    fetchUserAddresses: async (payload) => {
      return await strategy['request'](payload)
    }
  }
}
