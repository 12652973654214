export function getDistanceFromLatLong(data) {
    const{
        latLast,
        latGeo,
        longLast,
        longGeo
    } = data

    var R = 6371;
    var dLat = deg2rad(latGeo-latLast);
    var dLon = deg2rad(longGeo-longLast); 
    var a = 
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(latLast)) * Math.cos(deg2rad(latGeo)) * 
        Math.sin(dLon/2) * Math.sin(dLon/2)
        ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c;
    return d;
}
  
export function deg2rad(deg) {
    return deg * (Math.PI/180)
}
