import React, { useEffect } from 'react'

import { ReactComponent as IconLocation } from '../../assets/icons/icon_location.svg'
import { ReactComponent as IconExpandDown } from '../../assets/icons/icon_expand_down.svg'
import { ReactComponent as IconUser } from '../../assets/icons/icon_singin.svg'
import { ReactComponent as BasketIcon } from '../../assets/icons/icon_cesta.svg'
import { ReactComponent as HomeIcon } from '../../assets/icons/icon_home.svg'

import {
  DeliveryAddressContainer,
  DeliveryAddressLocationContainer,
  DeliveryAddressTitle,
  LocationAddress,
  StoreHeaderActionButton,
  StoreHeaderActionsContainer,
  Wrapper,
  CartItemsButtonContainer,
  ModalityContainer,
  Button,
  StoreSelectContainer,
  StoreName,
  IconButtonCartsItemsAmount,
  LocationAddressFull,
  LocationAddressSmall
} from './styles'

import { useDeviceLayout } from '../../hooks'

import history from '../../../history'
import { MULTI_STORE } from '../../../config'
import { caculateProductsQuantity } from '../../../domain/helpers'

export function Full(props) {
  const {
    userName,
    userAddress,
    userClicked,
    cartClicked,
    handleViewAddress,
    visibleSignIn,
    setVisibleSignIn,
    isCartOpen,
    storeLogo,
    modality,
    handleModality,
    context,
    modalityId,
    storeAddress,
    setVisibleStoreList,
    storeName,
    storeImg,
    accessToken,
    storeDelivery,
    storePickup,
    homeDisabled,
    products,
    userCpf,
    subscriptionStatus,
    setOpenCart,
    openCart,
    opened,
    homeOnClick,
    URLParameters
  } = props

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const { content } = context
  const deliverySection = content.filter(item => item.id === 'delivery-place')[0]
  const deliverySectionTitle = deliverySection.title
  const pickupSection = content.filter(item => item.id === 'pickup-place')[0]
  const pickupSectionTitle = pickupSection.title
  const modalitySection = content.filter(item => item.id === 'modality')[0]
  const modalitySectionActions = modalitySection.actions
  const modalityDeliveryButton = modalitySectionActions.filter(item => item.id === 'delivery')[0]
  const modalityDeliveryButtonLabel = modalityDeliveryButton.label
  const modalityPickupButton = modalitySectionActions.filter(item => item.id === 'pickup')[0]
  const modalityPickupButtonLabel = modalityPickupButton.label

  useEffect(() => {
    setOpenCart(false)
  }, [
    setOpenCart
  ])

  const selectAddressDelivery = userAddress.main

  const displayAddress = modalityId === 4 ?
    (selectAddressDelivery && selectAddressDelivery.length > 30 && selectAddressDelivery.length < 40 ?
      (selectAddressDelivery.slice(0, -17) + '...') : selectAddressDelivery && selectAddressDelivery.length > 40 && selectAddressDelivery.length < 50 ?
        (selectAddressDelivery.slice(0, -30) + '...') : selectAddressDelivery && selectAddressDelivery.length > 50 ?
          (selectAddressDelivery.slice(0, -35) + '...') : selectAddressDelivery) + (userAddress.number ? `, ` + userAddress.number : '')
    : storeAddress && storeAddress.slice(0, -9) + '...'


  const fullDisplayAddress = modalityId === 4 ?
    selectAddressDelivery + (userAddress.number ? `, ` + userAddress.number : '') :
    storeAddress

  return (
    <Wrapper opened={openCart}>
      {MULTI_STORE === 'true' && storeName ?
        <StoreSelectContainer onClick={() => setVisibleStoreList(true)}>
          <img src={storeImg} alt="logo" />
          <StoreName>{storeName}</StoreName>
          <IconExpandDown />
        </StoreSelectContainer> :
        <img src={storeLogo} alt="logo" />}
      <DeliveryAddressContainer>
        <DeliveryAddressTitle>{modalityId === 4 ? deliverySectionTitle : pickupSectionTitle}</DeliveryAddressTitle>
        <DeliveryAddressLocationContainer onClick={() => {
          if (modalityId === 4) {
            handleViewAddress()
          }
        }}>
          <IconLocation />
          {fullDisplayAddress && fullDisplayAddress.length > 16 ? <>
            <LocationAddressSmall>{displayAddress}</LocationAddressSmall>
          </> : <>
            <LocationAddressFull>{fullDisplayAddress}</LocationAddressFull>
          </>}
          <LocationAddress>{displayAddress}</LocationAddress>
          <IconExpandDown />
        </DeliveryAddressLocationContainer>
      </DeliveryAddressContainer>
      {modality && <ModalityContainer storeDelivery={storeDelivery} className='btn-group' opened={opened}>
        {storeDelivery && storePickup ? <>
          <Button
            onClick={() => { handleModality(4) }}
            className={modality === 4 ? 'active' : ''} modality={modality}>
            {modalityDeliveryButtonLabel}
          </Button><Button
            onClick={() => { handleModality(2) }}
            className={modality === 2 ? 'active' : ''} modality={modality}>
            {modalityPickupButtonLabel}
          </Button></> : null}
      </ModalityContainer>}
      <StoreHeaderActionsContainer>
        <StoreHeaderActionButton onClick={() => {
          if (!homeDisabled) {
            setOpenCart(false)
            if (isCartOpen) {
              cartClicked()
            }
            if (homeOnClick && typeof homeOnClick === 'function') {
              homeOnClick()
            }
            history.push(`/store${URLParameters}`)
          }
        }}>
          <HomeIcon />
        </StoreHeaderActionButton>
        {userName && userCpf && accessToken && subscriptionStatus === 1 ? <StoreHeaderActionButton onClick={() => {
          setOpenCart(false)
          userClicked()
        }}>
          <IconUser />
        </StoreHeaderActionButton> :
          <StoreHeaderActionButton onClick={() => {
            if (isCartOpen) {
              cartClicked()
            }

            if (isMobile) {
              history.push(`/login${URLParameters}`)

              return
            }
            setOpenCart(false)
            visibleSignIn ? setVisibleSignIn(false) : setVisibleSignIn(true)
          }
          }>
            <IconUser />
          </StoreHeaderActionButton>}
        <StoreHeaderActionButton
          onClick={() => {
            setOpenCart(openCart ? false : true)
            cartClicked()
          }}
          opened={isCartOpen}
        >
          <CartItemsButtonContainer>
            <IconButtonCartsItemsAmount>{caculateProductsQuantity(products)}</IconButtonCartsItemsAmount>
            <BasketIcon />
          </CartItemsButtonContainer>
        </StoreHeaderActionButton>
      </StoreHeaderActionsContainer>
    </Wrapper>
  )
}
