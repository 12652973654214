import React from 'react'
import styled from 'styled-components'

import sources from './index'

const Img = styled.img`
  width: 2.188rem;
  height: auto;
  max-height: 1.375rem;
  margin-right: 0.75rem;
`

const Flags = (props) => {
    const {
        country
    } = props

    if (country) {
        return <Img src={sources[country]} />
    }
}

export default Flags
