import React from 'react'

import {
  DefaultImage as Image
} from '../../components'

import {
  Wrapper,
  CardSide,
  StoreLogoContainer,
  CardContent,
  OrderDate,
  OrderItems,
  OrderItemsAmount,
  OrderItemsLabel,
  OrderItemsAddress
} from './styles';

export function Order(props) {
  const {
    address,
    date,
    id,
    modalityId,
    qty,
    total,
    handleClick,
    inProgress,
    selected,
    store
  } = props

  return <Wrapper inProgress={inProgress} selected={selected} onClick={() => handleClick(id)}>
    <CardSide>
      <StoreLogoContainer>
        <Image src={store.logoUrl} alt={`Logo ${store.name}`} />
      </StoreLogoContainer>
    </CardSide>
    <CardContent>
      <OrderDate>{date}</OrderDate>
      <OrderItems>
        <OrderItemsAmount>{qty}</OrderItemsAmount>
        <OrderItemsLabel>
          {`Ite${qty === 1 ? 'm' : 'ns'}`} por <span>R$ {Number(total).toFixed(2).replace('.', ',')}</span>
        </OrderItemsLabel>
      </OrderItems>
      {modalityId === 4 ?
        <OrderItemsAddress>Entregue em: {address}</OrderItemsAddress> :
        <OrderItemsAddress>Retirado em: {store && store.address}</OrderItemsAddress>}
    </CardContent>
  </Wrapper>
}
