import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper } from './styles';
import { containerMode } from './config';

export function PageContainer({ isSideOpen, mode, children, styles }) {
	const modeClass = isSideOpen && mode !== 'default' ? mode : '';

	return (
		<Wrapper
			className={modeClass}
			style={styles}
		>
			{ children }
		</Wrapper>
	)
}

PageContainer.propTypes = {
	isSideOpen: PropTypes.bool,
	mode: PropTypes.oneOf(Object.values(containerMode)),
	children: PropTypes.node
}
