import React from 'react'

import {
  Wrapper,
  Div
} from './styles'

import {
  Warning,
  PostalCode,
  Dialog
} from '.'

export function Overlay(props) {
  const {
    className,
    show,
    warning = {},
    postalCode = {},
    background = {
      handleClick: () => ({}),
      show: true
    },
    disallowedCity = {},
    differentAddress = {},
    receiptIsNotPaid = {}
  } = props

  return !show ? null : <Wrapper className={`overlay ${className}` }>
    {background && background.show && <Div className='background' onClick={background.handleClick} /> }

    {warning && warning.title ? <Warning className='warning'
      title={warning.title}
      message={warning.message}
      actions={warning.actions} /> : null }

    {postalCode && postalCode.title ? <PostalCode className='postal-code'
      title={postalCode.title}
      message={postalCode.message}
      actions={postalCode.actions}
      inputs={postalCode.inputs} /> : null }

    {differentAddress && differentAddress.title ? <Dialog className='different-address'
      title={differentAddress.title}
      message={differentAddress.message}
      actions={differentAddress.actions}
      inputs={differentAddress.inputs} /> : null }

    {disallowedCity && disallowedCity.title ? <Dialog className='disallowed-city'
      title={disallowedCity.title}
      message={disallowedCity.message}
      actions={disallowedCity.actions} /> : null }

    {receiptIsNotPaid && receiptIsNotPaid.title ? <Dialog className='receipt-is-not-paid'
      title={receiptIsNotPaid.title}
      classe={'receipt-is-not-paid'}
      message={receiptIsNotPaid.message}
      actions={receiptIsNotPaid.actions} /> : null }
  </Wrapper>
}
