import React, {
  useEffect,
  useState
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'
import axios from 'axios'
import {Helmet} from 'react-helmet'

import { CARDAPIO_CARD, REACT_APP_WHATSAPP, REACT_APP_WHATSAPP_NUMBER } from '../../config'
import WhattsAppImage from '../assets/whatsapp_icone.png'

import {
	useStoreSettings
} from '../hooks'

import {
  modality,
  store
} from '../../state'

import {
  pipe,
  GetContent,
  caculateProductsQuantity
} from '../../domain/helpers'

//import { PWAAnalytics } from '../../domain/helpers/ipiranga/PWA'

import history from '../../history'

import {
  addProduct,

  handleGTM,

  fetchStoreFees,

  removeProduct,
  removeSelectedProduct,
  removeSelectedOldCategory,
  removeSelectedOldProduct,
  removeSelectedProductsCategories,

  setSelectedCategory,
  setSelectedProduct,
  setSearch,
  setCategories,
  setQuantity,
  setOpenCart,
  setShowSignUp
} from '../../redux/actions/main'

import {
  useCustomLayout,
  useDeviceLayout
} from '../hooks'

import {
  DefaultImage as Image,
  CustomIcon
} from '../components'

import {
  Product,
  ProductCard,
  Cards,
  Cart,
  CartContainer,
  Skeleton,
  MainHeader,
  Navigation,
  PageContainer,
  containerMode,
  PageContainerHeader,
  PageContainerMain,
  PageContainerSide,
  OrderTracking
} from '..'

import {
  Header,
  Search,
  CartBar,
  Menu,
  Categories,
  FixedHeader,
  CategoriesProducts,
  CategoriesProductsCard,
  CategoriesSelect
} from '.'

import {
  Wrapper,
  Paragraph,
  LoadingWrapper,
  SubHeaderWrapper,
  H1,
  BackButton,
  WhattsAppImg
} from './styles.js'

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

function StorePage(props) {
  const {
    aisle,
    accessToken,
    allCategories,
    categories,
    cartProducts,
    deliveryFee,
    deliveryMinimumValue,
    deliveryTime,
    device,
    headerBackgroundImage,
    modalityId,
    oldSelectedProduct,
    oldSelectedCategory,
    openModalCategory,
    orderId,
    pickupMinimumValue,
    products,
    screenHeightSelected,
    search,
    selectedCategory,
    selectedProduct,
    selectedProductsCategories,
    stateLoading,
    storeName,
    storeImg,
    storeId,
    storeStatus,
    theme,
    userName,
    openCart,
    storePickup,
    storeDelivery,
    addProduct,
    location,
    scheduling,
    userHistory,
    addressDelivery,
    userId,
    URLParameters,

    fetchStore,
    fetchStoreFees,

    handleGTM,

    removeProduct,
    removeSelectedOldCategory,
    removeSelectedOldProduct,
    removeSelectedProduct,

    setCategories,
    setModality,
    setQuantity,
    setSearch,
    setSelectedCategory,
    setSelectedProduct,
    setOpenCart,
    setShowSignUp,
    removeSelectedProductsCategories
  } = props

  const {
    main
  } = theme

  const storeHeaderImage = theme.headerImage
  const storeHeaderImageMobile = theme.headerImageMobile
  const storeLogo = theme.logoImageSingin
  const colorPrimary = theme.main.general.backgroundMain

  const {
    general
  } = main

  const searchText = search && search.text

  const [noResults, setNoResults] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [cartDisplay, setCartDisplay] = useState(false)
  const [cartSlideDown, setCartSlideDown] = useState(false)
  const [storeContentMaxHeight, setStoreContentMaxHeight] = useState(0)
  const [sideMode, setSideMode] = useState({
    isOpen: false,
    mode: containerMode.default
  })
  const [visibleProduct, setVisibleProduct] = useState(false)
  const [visibleProductCard, setVisibleProductCard] = useState(false)
  const [visibleNavigation, setVisibleNavigation] = useState(false)
  const [visibleCategories, setVisibleCategories] = useState(false)
  const [visibleCategoriesCard, setVisibleCategoriesCard] = useState(false)
  const [fullWidth, setFullWidth] = useState(true)
  const [fullScreen, setFullScreen] = useState(false)
  const [screenMobile, setScreenMobile] = useState(false)
  const [viewCards, setViewCards] = useState(false)
  const [activeDialogSignIn, setActiveDialogSignIn] = useState(false)
  const [newCard, setNewCard] = useState(false)

  const menuProps = {
    storeName,
    storeLogo,
    storeImg,
    modalityId,
    selectedCategory,
    selectedProduct,
    direction: CARDAPIO_CARD === "true" ? 'card' : aisle ? 'horizontal' : 'vertical',
    status: storeStatus,
    opened: sideMode.isOpen
  }

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useCustomLayout({
    device,
    section: 'store',
    styles: general,
    backgroundColor: '#fff'
  })

  useEffect(() => {
    const url = window.location.href;

    const access = {
      event: 'PageView',
      page_path: url,
      page_title: 'Cardápio'
    }

    handleGTM(access)

    if (userId) {
      handleGTM({ user_id: userId })
    }
  }, [
    userId,
    handleGTM
  ])

  useEffect(() => {
    setShowSignUp(false)

    return (() => { })
  }, [setShowSignUp])

  useEffect(() => {
    setFullWidth(true)

    if (isMobile) {
      setFullScreen(true)
      setScreenMobile(true)
    }

    if ((loaded && !loading) && !storeId) {
      setLoaded(true)
      history.push(`no-store${URLParameters}`)
    }

    if ((loaded && !loading) && !storeName) {
      setLoaded(true)
      history.push(`no-store${URLParameters}`)
    }

    const cartRequest = axios.CancelToken
    const cartRequestSource = cartRequest.source()

    const storeRequest = axios.CancelToken
    const storeRequestSource = storeRequest.source()

    const storeFeesRequest = axios.CancelToken
    const storeFeesRequestSource = storeFeesRequest.source()

    async function getStoreFees() {
      await fetchStoreFees(() => { }, {
        storeFeesRequestSource
      })
    }

    async function getStore() {
      await fetchStore(() => {
        setLoaded(true)
        setLoading(false)
      }, {
        storeRequestSource
      })
    }

    if (!loaded && !loading && !products.length && modalityId === 2) {
      setLoading(true)
      getStore()
    }

    if (!loaded && !loading && !products.length && modalityId === 4) {
      setLoading(true)
      getStore()
      getStoreFees()
    }

    if (!loaded && !loading && stateLoading.includes('store')) {
      setLoading(true)
    }

    if ((loaded && !loading) || products.length) {
      setLoaded(true)
      setTimeout(() => setLoading(false), 1500)
    }

    return () => {
      storeRequestSource.cancel()
      cartRequestSource.cancel()
    }
  }, [
    userName,
    loaded,
    loading,
    products,
    selectedCategory,
    storeName,
    storeLogo,
    fetchStore,
    modalityId,
    handleGTM,
    setSelectedCategory,
    cartDisplay,
    selectedProduct,
    device,
    selectedCategory,
    selectedProductsCategories,
    orderId,
    accessToken,
    screenHeightSelected,
    fetchStoreFees,
    stateLoading,
    isMobile,
    storeId,
    deliveryMinimumValue,
    deliveryFee,
    addressDelivery,
    URLParameters
  ])

  useEffect(() => {
    if (oldSelectedProduct && oldSelectedProduct.id) {
      const prevProductElement = Array.from(document.querySelectorAll('#store-products > div > div > div > div > h2')).find(element => !!(element.textContent.trim() === oldSelectedProduct.name.trim()))
      const scrollTop = (prevProductElement && prevProductElement.getBoundingClientRect().top - (window.innerHeight / 2))

      scrollTop && window.scroll(0, scrollTop)
    }

    return (() => { })
  }, [
    oldSelectedProduct
  ])

  useEffect(() => {
    if (oldSelectedCategory && oldSelectedCategory.name) {
      const prevProductElement = Array.from(document.querySelectorAll('#store-products > div')).find(element => element.getAttribute('name').trim() === oldSelectedCategory.name.trim())
      const scrollTop = (prevProductElement && prevProductElement.offsetTop)

      scrollTop && window.scroll(0, scrollTop)
    }

    return (() => { })
  }, [
    oldSelectedCategory
  ])

  const cartHelper = {
    handleClose: () => {
      const storeQuerySelector = document.querySelector('#store-content')
      const maxHeight = storeQuerySelector && parseInt(storeQuerySelector.offsetHeight)

      setStoreContentMaxHeight(maxHeight)
      setCartSlideDown(true)

      setTimeout(() => {
        setCartDisplay(false)
        setCartSlideDown(false)
      }, 500)
    }
  }

  function handleNoToken() {
    setActiveDialogSignIn(true)

    setTimeout(() => {
      setActiveDialogSignIn(false)
    }, 2000)
  }

  function handleSelectedProductCategories(args) {
    removeSelectedOldProduct()
    setSelectedCategory(args)
  }

  function handleQuantity(args) {
    setQuantity(args)
  }

  function handleCloseDialog() {
    setSelectedCategory({
      name: selectedCategory.name,
      selectedProductsCategories: openModalCategory ? true : false
    })

    removeSelectedProduct()

    if (CARDAPIO_CARD === "false") {
      setVisibleProduct(false)

      return
    }

    setVisibleProductCard(false)
  }

  function handleCloseDialogNavigation() {
    setVisibleNavigation(false)
  }

  function handleCloseDialogCategories() {
    setVisibleCategories(false)
  }

  function handleCloseDialogCategoriesCard() {
    setVisibleCategoriesCard(false)
  }


  async function handleModality(id) {
    setModality({ id: id })

    await fetchStore(() => {
      setLoaded(true)
      setLoading(false)
    })

    if (id === 4) {
      const storeFeesRequest = axios.CancelToken
      const storeFeesRequestSource = storeFeesRequest.source()

      await fetchStoreFees(() => { }, {
        storeFeesRequestSource
      })
    }
  }

  function handleViewProduct(args) {
    if (CARDAPIO_CARD === "false") {
      setVisibleProduct(true)
    } else {
      setVisibleProductCard(true)
    }

    const name = (args.category && args.category.name) || ''

    setSelectedCategory({
      name,
      selectedProductsCategories: false,
      openModalCategory
    })

    removeSelectedOldCategory()
    setSelectedProduct(args)

    // const viewProductData = args && {
    //   action: 'analytics',
    //   data: {
    //     eventType: 'tap_product',
    //     parameters: {
    //       store_name: storeName,
    //       modality: modalityId === 4 ? 'delivery' : 'retirada',
    //       product_name: args.name,
    //       product_value: args.valorPromocional || args.price
    //     }
    //   }
    // }

    const ecommerce = {
      click: {
        actionField: {
          list: ''
        },
        products: {
          id: args.id,
          name: args.name,
          category: args && args.category && args.category.name,
          list: '',
          position: '',
        }
      }
    }

    const viewProduct = args && {
      event: 'eec.impressionClick',
      ecommerce: ecommerce
    }

    const detailProduct = args && {
      event: 'eec.detail',
      ecommerce: ecommerce
    }

    handleGTM(viewProduct)
    handleGTM(detailProduct)

    //PWAAnalytics(viewProductData)
  }

  function handleCategory(args) {
    const {
      name
    } = args

    const categoryElement = document.getElementById(`store-products-category-${name}`)

    if (categoryElement) {
      window.scroll(0, categoryElement.offsetTop - 140)

      setSelectedCategory(args)
    }
  }

  async function handleAddProduct(args) {
    const product = args && {
      ...args
    }

    await removeProduct(product)
    await addProduct(product)

    // const addProductData = product && {
    //   action: 'analytics',
    //   data: {
    //     eventType: 'add_product_to_cart',
    //     parameters: {
    //       store_name: storeName,
    //       modality: modalityId === 4 ? 'delivery' : 'retirada',
    //       product_name: product.name,
    //       product_value: product.valorPromocional || product.price
    //     }
    //   }
    // }

    if (!sideMode.isOpen && !isMobile) {
      handleSideModeChange(containerMode.sideFullContent)
    }

    setOpenCart(true)

    //handleGTM(addProductData)
    //PWAAnalytics(addProductData)
  }

  function handleSearchCategorie(search) {
    const categorieResult = allCategories && allCategories.length
      && allCategories.filter(filteredItem => filteredItem.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').indexOf(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) > -1)

    if (categorieResult.length && search !== 'all') {
      setStoreContentMaxHeight(0)

      setCategories(categorieResult)
      setNoResults(false)

      return
    }

    setStoreContentMaxHeight(0)
    setCategories(allCategories)
    setNoResults(true)
  }

  function handleSearchText(args) {
    postSearchText(args)
  }

  function postSearchText(args) {
    setSearch({
      text: args
    })
  }

  function handleSearch() {
    const categoriesResults = allCategories && allCategories.length && allCategories.reduce((acc, category) => {
      const results = category.products.filter(filteredItem => {
        return (filteredItem.name.toLowerCase().indexOf(searchText.toLowerCase()) > -1 ||
          filteredItem.description.toLowerCase().indexOf(searchText.toLowerCase()) > -1) ? true : false
      })

      if (results.length) {
        acc.push({
          id: category.id,
          name: category.name,
          products: results
        })
      }

      return acc
    }, [])

    if (categoriesResults.length) {
      setStoreContentMaxHeight(0)

      setCategories(categoriesResults)
      setNoResults(false)

      setTimeout(() => {

      }, 100)

      return
    }

    setStoreContentMaxHeight(0)
    setCategories(allCategories)
    setNoResults(true)
  }

  const handleSideModeChange = (mode) => {
    const _isOpen = sideMode.mode !== mode

    setSideMode({
      isOpen: _isOpen,
      mode: _isOpen ? mode : sideMode.default
    })
  }

  function handleContactUs() {
    history.push(`/contact-us${URLParameters}`)
  }

  function handleNewCard() {
    if (!newCard) {
      setNewCard(true)
    } else {
      setNewCard(false)
    }
  }

  function handleWhattsApp() {
    let otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location = `http://api.whatsapp.com/send?1=pt_BR&phone=${REACT_APP_WHATSAPP_NUMBER}`;

    const whattsAppData = {
      'event': 'click - botão de whatsapp',
      'user_id': userId ? userId : ''
    }

    handleGTM(whattsAppData)
  }

  return <Wrapper id={'store'} maxHeight={storeContentMaxHeight}>
		<Helmet>
			<title>{`${title} - Cardápio`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${url}/store`} />
		</Helmet>

    <PageContainer
      isSideOpen={sideMode.isOpen}
      mode={sideMode.mode}
    >
      <PageContainerHeader>
        {loading && <LoadingWrapper>
          <Skeleton module='header' />
        </LoadingWrapper>}

        {!loading && <MainHeader
          currentRoute='store'
          mode='full'
          theme={theme}
          name={storeName}
          modality={modalityId}
          handleModality={handleModality}
          storeLogo={storeLogo}
          storeImg={storeImg}
          userName={userName}
          accessToken={accessToken}
          activeDialogSignIn={activeDialogSignIn}
          isCartOpen={sideMode.mode === containerMode.sideFullContent && sideMode.isOpen}
          cartItemsQuantity={caculateProductsQuantity(cartProducts)}
          homeOnClick={() => setViewCards(false)}
          userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
          cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
          pathName={location.pathname}
          opened={sideMode.mode === containerMode.sideFullContent || sideMode.mode === containerMode.headerFullContent}
        />}
      </PageContainerHeader>
      <PageContainerMain opened={sideMode.isOpen}>
        {!viewCards && <>
          {stateLoading.includes('store') && <Skeleton module={isMobile ? 'store-mobile' : 'store'} />}

          {!stateLoading.includes('store') && <>
            {REACT_APP_WHATSAPP === 'true' &&
              <WhattsAppImg className={isMobile && cartProducts.length > 0 ? 'ativo' : sideMode.mode === containerMode.sideFullContent ||
                sideMode.mode === containerMode.headerFullContent ? 'open-cart' : ''} onClick={() => handleWhattsApp()}>
                <Image src={WhattsAppImage} alt="" />
              </WhattsAppImg>}

            <Header
              id={storeId}
              name={storeName}
              storeImg={storeImg}
              image={storeHeaderImage}
              status={storeStatus}
              modalityId={modalityId}
              deliveryFee={deliveryFee}
              deliveryMinimumValue={deliveryMinimumValue}
              pickupMinimumValue={pickupMinimumValue}
              deliveryTime={deliveryTime}
              backgroundImage={headerBackgroundImage}
              appWidth={screenMobile}
              viewCards={viewCards}
              setViewCards={setViewCards}
              modality={modalityId}
              handleModality={handleModality}
              accessToken={accessToken}
              setVisibleNavigation={setVisibleNavigation}
              storePickup={storePickup}
              storeDelivery={storeDelivery}
              scheduling={scheduling}
              storeHeaderImageMobile={storeHeaderImageMobile}
              isMobile={isMobile}
              URLParameters={URLParameters}
            />

            {isMobile && !cartDisplay && <Categories
              data={categories}
              handleCategory={handleCategory} />}

            {viewCards ? null : <SubHeaderWrapper>
              <CategoriesSelect
                data={allCategories}
                handleSearchCategorie={handleSearchCategorie}
              />
              <Search
                show={true}
                searchText={searchText}
                handleSearchText={handleSearchText}
                handleSearch={handleSearch}
              />
            </SubHeaderWrapper>}

            {!viewCards && <OrderTracking />}

            {noResults && !storeName ? <Paragraph>Nenhum produto foi encontrado.</Paragraph> :
              <>
                {isMobile && !cartDisplay && <FixedHeader name={storeName} />}

                {!viewCards && <Menu
                  {...menuProps}
                  handleQuantity={handleQuantity}
                  handleViewProduct={handleViewProduct}
                  handleAddProduct={handleAddProduct}
                  handleGTM={handleGTM}
                  handleSelectedProductCategories={handleSelectedProductCategories}
                  handleSelectedCategory={setSelectedCategory}
                  selectedProductsCategories={selectedProductsCategories}
                  handleSearchCategorie={handleSearchCategorie}
                  setVisibleCategories={setVisibleCategories}
                  setVisibleCategoriesCard={setVisibleCategoriesCard}
                />}
              </>
            }
            {isMobile && (!selectedProduct && !cartDisplay && !viewCards) ?
              <CartBar
                handleClick={() => {
                  history.push(`cart${URLParameters}`)
                }} /> :
              null
            }
          </>
          }
        </>}

        {!!viewCards && <>
          <Image src={storeHeaderImage} alt="" />

          <H1>Meus cartões</H1>

          <BackButton className='back-button' onClick={() => {
            const {
              back
            } = userHistory

            if (back) {
              history.push(`/${back}${URLParameters}`)

              return
            }
          }}>
            <CustomIcon name='AngleLeft' />
          </BackButton>

          <Cards setViewCards={setViewCards} screenMobile={screenMobile} payment={true} newCard={newCard} handleNewCard={handleNewCard} />
        </>}
      </PageContainerMain>
      <PageContainerSide>
        {!isMobile && sideMode.mode === containerMode.sideFullContent &&
          <CartContainer
            logo={storeLogo}
            storeImg={storeImg}
            name={storeName}
            setOpenCart={setOpenCart}
            openCart={openCart}
            cartClicked={() => handleSideModeChange(containerMode.sideFullContent)}
          >
            <Cart
              mode='simple'
              screenMobile={screenMobile}
              setViewCards={setViewCards}
              viewCards={viewCards}
              className={cartSlideDown ? 'slide-down' : ''}
              handleFinish={() => {
                const storeQuerySelector = document.querySelector('#cart-content')
                const maxHeight = storeQuerySelector && parseInt(storeQuerySelector.offsetHeight)

                setStoreContentMaxHeight(maxHeight)
              }}
              handleClose={cartHelper.handleClose}
              handleNoToken={handleNoToken}
            />
          </CartContainer>
        }
        {sideMode.mode === containerMode.headerFullContent &&
          <Navigation
            contactUs={() => handleContactUs()}
            userName={userName}
            currentRoute='store'
            userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
          />
        }
      </PageContainerSide>

      <Dialog
        onClose={() => handleCloseDialog()}
        aria-labelledby="product-dialog"
        open={visibleProduct}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
      >
        {selectedProduct ?
          <Product
            handleClose={handleCloseDialog}
            cartClicked={() => !sideMode.isOpen && handleSideModeChange(containerMode.sideFullContent)}
          /> : null}
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialog()}
        aria-labelledby="product-card-dialog"
        open={visibleProductCard}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        fullWidth={fullWidth}
        PaperProps={{
          elevation: 0,
          style: {
            maxWidth: isMobile ? '' : selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? '814px' : '372px',
            maxHeight: isMobile ? 'calc( 100% - 30px)' : selectedProduct && selectedProduct.additionals &&
              selectedProduct.additionals.length ? 'calc( 100% - 112px)' : 'calc( 100% - 96px)',
            width: isMobile ? 'calc( 100% - 34px)' : '',
            // height: isMobile ?
            margin: isMobile ? '5px' : '',
            backgroundColor: colorPrimary
          }
        }}
      >
        {selectedProduct ?
          <ProductCard
            handleClose={handleCloseDialog}
            cartClicked={() => !sideMode.isOpen && handleSideModeChange(containerMode.sideFullContent)}
          /> : null}
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogCategories()}
        aria-labelledby="categories-dialog"
        open={visibleCategories}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
      >
        <CategoriesProducts
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          removeSelectedProductsCategories={removeSelectedProductsCategories}
          handleQuantity={handleQuantity}
          handleViewProduct={handleViewProduct}
          handleAddProduct={handleAddProduct}
          close={handleCloseDialogCategories}
        />
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogCategoriesCard()}
        aria-labelledby="categories-card-dialog"
        open={visibleCategoriesCard}
        TransitionComponent={Transition}
        maxWidth={'sm'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
      >
        <CategoriesProductsCard
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          removeSelectedProductsCategories={removeSelectedProductsCategories}
          handleQuantity={handleQuantity}
          handleViewProduct={handleViewProduct}
          handleAddProduct={handleAddProduct}
          close={handleCloseDialogCategoriesCard}
          storeLogo={storeLogo}
        />
      </Dialog>

      {isMobile && <Dialog
        onClose={() => handleCloseDialogNavigation()}
        aria-labelledby="navigation-dialog"
        open={visibleNavigation}
        maxWidth={'xs'}
        fullWidth={fullWidth}
        fullScreen={fullScreen}
        TransitionComponent={Transition}
      >
        <Navigation
          contactUs={() => handleContactUs()}
          userName={userName}
          currentRoute='store'
          userClicked={() => handleSideModeChange(containerMode.headerFullContent)}
          setVisibleNavigation={setVisibleNavigation}
        ></Navigation>
      </Dialog>}
    </PageContainer>
  </Wrapper>
}

StorePage.propTypes = {
  aisle: PropTypes.bool,
  allCategories: PropTypes.arrayOf(PropTypes.shape({})),
  categories: PropTypes.arrayOf(PropTypes.shape({})),
  device: PropTypes.string,
  deliveryFee: PropTypes.number,
  deliveryMinimumValue: PropTypes.number,
  deliveryTime: PropTypes.string,
  headerBackgroundImage: PropTypes.string,
  modalityId: PropTypes.number,
  pickupMinimumValue: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.shape({})),
  search: PropTypes.shape({}),
  selectedCategory: PropTypes.shape({}),
  selectedProduct: PropTypes.shape({}),
  storeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  storeLogo: PropTypes.string,
  storeName: PropTypes.string,
  storeStatus: PropTypes.number,
  userName: PropTypes.string,

  addProduct: PropTypes.func,

  fetchStore: PropTypes.func,
  fetchStoreFees: PropTypes.func,

  handleGTM: PropTypes.func,

  removeProduct: PropTypes.func,
  removeSelectedOldCategory: PropTypes.func,
  removeSelectedOldProduct: PropTypes.func,
  removeSelectedProduct: PropTypes.func,

  setCategories: PropTypes.func,
  setModality: PropTypes.func,
  setQuantity: PropTypes.func,
  setSelectedCategory: PropTypes.func,
  setSelectedProduct: PropTypes.func,
  setSearch: PropTypes.func,
  removeSelectedProductsCategories: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    addressDelivery: state.main.addressByCEP || '',
    userAddress: state.main.userAddress || '',
    storeId: state.main.store && state.main.store.id,
    storeName: state.main.store && state.main.store.name,
    storeStatus: state.main.store && state.main.store.status,
    categories: (state.main.store && state.main.store.categories) || [],
    storeImg: (state.main.store && state.main.store.logo) || '',
    products: (state.main.store && state.main.store.products) || [],
    cartProducts: (state.main.cart && state.main.cart.products) || [],
    allCategories: state.main.store && state.main.store.allCategories,
    selectedCategory: state.main.store && state.main.store.selectedCategory,
    selectedProductsCategories: state.main.store && state.main.store.selectedCategory && state.main.store.selectedCategory.selectedProductsCategories,
    openModalCategory: state.main.store && state.main.store.selectedCategory && state.main.store.selectedCategory.openModalCategory,
    selectedProduct: state.main.store && state.main.store.selectedProduct,
    oldSelectedProduct: (state.main.store && state.main.store.oldSelectedProduct) || {},
    oldSelectedCategory: (state.main.store && state.main.store.oldSelectedCategory) || {},
    headerBackgroundImage: state.main.store && state.main.store.images && state.main.store.images.headerBackgroundImage,
    deliveryFee: state.main.store && state.main.store.deliveryFee,
    deliveryMinimumValue: state.main.store && state.main.store.deliveryMinimumValue,
    pickupMinimumValue: state.main.store && state.main.store.pickupMinimumValue,
    aisle: (state.main.store && state.main.store.aisle) || false,
    deliveryTime: state.main.store && state.main.store.deliveryTime,
    search: state.main.search,
    modalityId: state.main.modality && state.main.modality.id,
    device: (state.main.params && state.main.params.device) || '',
    orderId: state.main.order && state.main.order.id,
    accessToken: state.main.accessToken,
    userName: state.main.userName,
    screenHeightSelected: state.main.store && state.main.store.screen && state.main.store.screen.value,
    coupon: state.main.store && state.main.store.coupon,
    stateLoading: state.main.loading || [],
    userHistory: state.main.userHistory || {},
    openCart: state.main.openCart || null,
    storePickup: (state.main.store && state.main.store.pickup) || null,
    storeDelivery: (state.main.store && state.main.store.delivery) || null,
    scheduling: state.main.store && state.main.store.scheduling,
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  addProduct,

  fetchStore: store.fetchStore,
  fetchStoreFees,

  handleGTM,

  removeProduct,
  removeSelectedOldCategory,
  removeSelectedOldProduct,
  removeSelectedProduct,

  setCategories,
  setQuantity,
  setModality: modality.setModality,
  setSelectedCategory,
  setSelectedProduct,
  setSearch,
  setOpenCart,
  setShowSignUp,
  removeSelectedProductsCategories
})

export const Store = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(StorePage))
