import {
  actionTypes
} from '../'

export const setLoading = (loading) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_LOADING,
    payload: loading
 })
}
