import React from 'react'
import PropTypes from 'prop-types'
import {
  connect
} from 'react-redux'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  CardMenu,
  HorizontalMenu,
  VerticalMenu
} from '..'

import {
  Wrapper
} from './styles'

const Context = React.createContext({})

export function MenuComponent(props) {
  const {
    storeLogo,
    selectedProduct,
    handleQuantity,
    handleAddProduct,
    handleViewProduct,
    direction,
    status,
    handleSelectedProductCategories,
    handleSelectedCategory,
    storeImg,
    setVisibleCategories,
    setVisibleCategoriesCard,
    opened
  } = props

  return <Wrapper id='store-products' className={direction !== 'vertical' ? 'horizontal' : ''}>
    {direction === 'vertical' ? <VerticalMenu
      storeLogo={storeLogo}
      storeImg={storeImg}
      selectedProduct={selectedProduct}
      handleQuantity={handleQuantity}
      handleViewProduct={handleViewProduct}
      handleAddProduct={handleAddProduct}
    /> : direction === 'horizontal' ? <HorizontalMenu
      storeImg={storeImg}
      storeLogo={storeLogo}
      selectedProduct={selectedProduct}
      handleViewProduct={handleViewProduct}
      handleAddProduct={handleAddProduct}
      status={status}
      handleViewCategoriesProducts={handleSelectedProductCategories}
      handleSelectedCategory={handleSelectedCategory}
      setVisibleCategories={setVisibleCategories}
    /> : direction === 'card' ? <CardMenu
      storeImg={storeImg}
      storeLogo={storeLogo}
      selectedProduct={selectedProduct}
      handleViewProduct={handleViewProduct}
      handleAddProduct={handleAddProduct}
      status={status}
      handleViewCategoriesProducts={handleSelectedProductCategories}
      handleSelectedCategory={handleSelectedCategory}
      setVisibleCategories={setVisibleCategoriesCard}
      opened={opened}
    /> : null}
  </Wrapper>
}

MenuComponent.propTypes = {
  modalityId: PropTypes.number,
  storeName: PropTypes.string,
  storeLogo: PropTypes.string,
  selectedProduct: PropTypes.shape({}),
  selectedProductsCategories: PropTypes.bool,
  handleQuantity: PropTypes.func,
  handleAddProduct: PropTypes.func,
  handleViewProduct: PropTypes.func,
  handleGTM: PropTypes.func,
  direction: PropTypes.string,
  status: PropTypes.number,
  handleSelectedProductCategories: PropTypes.func,
  handleSelectedCategory: PropTypes.func
}

const mapStateToProps = (state) => {
  return {}
}

const GetConnection = connect(mapStateToProps, {})

export const Menu = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'store' })
)(MenuComponent))
