import React, {
  useLayoutEffect,
  useState,
  useContext,
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Helmet} from 'react-helmet'

import {
  setGeolocation,
  setCEP,
  fetchAddressByCEP,
} from '../../../redux/actions/main'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  loading,
  address
} from '../../../state'

import {
	useStoreSettings
} from '../../hooks'

import {
  CustomLoading
} from '../../components'

import {
  Button,
  Wrapper,
  Paragraph,
  Span,
  Input,
  GeolocationPage,
  GeolocationContainer,
  StoreLogoImage,
  GeolocationGreetingsText,
  GeolocationDescriptionText,
  GeolocationActions,
  /* GeolocationButton,
  GeolocationButtonIconContainer,
  GeolocationButtonSpan, */
  CEPForm,
  PostalCodeInputContainer
} from './styles'

// import { Icon } from '../../components/common'

export const Context = React.createContext({})

function HomeComponent(props) {
  const {
    accessToken,
    currentStep,
    fetchAddressByCEP,
    // fetchAddressByGeolocation,
    goNextStep,
    loading,
    postalCode,
    setCEP,
    // setGeolocation,
    setLoading,
    theme
  } = props

  const context = useContext(Context)
  const { sections } = context
  const section = sections.filter(item => item.id === 'greetings')[0]
  const sectionTitle = section.content.title
  const sectionText = section.content.text
  // const sectionActions = section.content.actions
  const sectionForm = section.content.forms
  // const sectionGeolocationButton = sectionActions.filter(item => item.id === 'geolocation-button')[0]
  // const geolocationButtonLabel = sectionGeolocationButton.label
  const sectionPostalCodeForm = sectionForm.filter(item => item.id === 'postal-code-form')[0]
  const sectionPostalCodeInput = sectionPostalCodeForm.fields.filter(item => item.id === 'postal-code-input')[0]
  const postalCodeInputLabel = sectionPostalCodeInput.label
  const postalCodeInputWarningText = sectionPostalCodeInput.warning
  const sectionPostalCodeButton = sectionPostalCodeForm.actions.filter(item => item.id === 'submit-button')[0]
  const postalCodeButtonLabel = sectionPostalCodeButton.label

  const storeBackgroundImage = theme.backgroundImage
  const storeLogo = theme.logoImage

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const [warning, setWarning] = useState(false)

  useLayoutEffect(() => {
    setLoading({ item: 'welcome', delete: true })

    if (accessToken) {
      goNextStep()
    }

    return () => ({})
  }, [
    setLoading,
    accessToken,
    goNextStep
  ])  

  /* async function handleLocationClick() {
    setLoading('welcome')

    await navigator.geolocation.getCurrentPosition(async function (position) {
      const {
        latitude,
        longitude
      } = position.coords

      setGeolocation({
        latitude,
        longitude
      })

      await fetchAddressByGeolocation()

      goNextStep(2)
    })
  } */

  function handlePostalCodeChange(event) {
    const {
      value
    } = event.target

    setCEP(value)
  }


  async function handlePostalCodeClick() {
    if (postalCode && postalCode.length < 8) {
      setWarning(true)

      return
    }

    await fetchAddressByCEP()
    goNextStep()
  }

  return <Wrapper>
		<Helmet>
			<title>{title}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={url} />
		</Helmet>

    {loading.includes('welcome') &&
      <CustomLoading
        color={'#fff'}
        type={'spin'}
        id='default-loading'
        height={40}
        width={40}
      />}

    {!loading.includes('welcome') && currentStep === 1 &&
      <GeolocationPage backgroundImage={storeBackgroundImage}>
        <GeolocationContainer>
          <StoreLogoImage src={storeLogo} />
          <GeolocationGreetingsText>{sectionTitle}</GeolocationGreetingsText>
          <GeolocationDescriptionText>
            {sectionText}
          </GeolocationDescriptionText>
          <GeolocationActions>
            {/* <GeolocationButton onClick={handleLocationClick}>
              <GeolocationButtonIconContainer>
                <Icon name='currentLocation' />
              </GeolocationButtonIconContainer>
              <GeolocationButtonSpan>
                {geolocationButtonLabel}
              </GeolocationButtonSpan>
            </GeolocationButton> */}
            <CEPForm>
              <PostalCodeInputContainer>
                <Span>{postalCodeInputLabel}</Span>
                <Input
                  type='text'
                  maxLength={9}
                  max={99999999}
                  placeholder='00000-000'
                  value={postalCode || ''}
                  onKeyDown={(evt) => evt.key === 'e' && evt.preventDefault()}
                  onChange={handlePostalCodeChange}
                />
              </PostalCodeInputContainer>
              <Button onClick={handlePostalCodeClick}>{postalCodeButtonLabel}</Button>
            </CEPForm>
            {warning ? <Paragraph className='warning'>{postalCodeInputWarningText}</Paragraph> : null}
          </GeolocationActions>
        </GeolocationContainer>
      </GeolocationPage>
    }
  </Wrapper>
}

HomeComponent.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    userAddress: state.main.userAddress || '',
    addressByCEP: (state.main.addressByCEP) || {},
    loading: state.main.loading || [],
    postalCode: state.main.cep || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  setGeolocation,
  setCEP,
  fetchAddressByCEP,

  fetchAddressByGeolocation: address.fetchAddressByGeolocation,
  setLoading: loading.setLoading
})

export const Home = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'welcome' })
)(HomeComponent))
