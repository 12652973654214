import React from 'react'

import {
  Wrapper,
  H4,
  Div,
  Paragraph,
  Strong
} from './styles'

export function Debugger(props) {
  const {
    show,
    data
  } = props

  const {
    url
  } = data

  if (!show) {
    return null
  }

  return <Wrapper>
    <H4>DEBUGGER</H4>

    <Div>
      <Paragraph>
        <Strong>URL:</Strong> { JSON.stringify(url).replace(/,/g, ', ') }
      </Paragraph>
    </Div>
  </Wrapper>
}
