export default ({
  apiService
}) => {
  const strategy = {
    request: async ({
      data,
      auth,
      dispatch,
      actions,
      maps
   }) => {
      const {
        setLoading
      } = actions

      const {
        modalityId,
        latitude,
        longitude,
        loading,
        userAddress
      } = data

      const {
        mapHighlightsList
      } = maps

      if (loading && loading.length && loading.indexOf('highlights') > -1) {
        return false
      }

      dispatch(setLoading('highlights'))

      const url = `${ userAddress && userAddress.id ? `destaques/listar?fluxodelivery=false&enderecodeliveryid=${ userAddress.id }` : 
      (modalityId === 2 ?
      `destaques/listar?fluxodelivery=false&latitude=${latitude}&longitude=${longitude}` :
      `destaques/listar?fluxodelivery=true&latitude=${latitude}&longitude=${longitude}`) }`
      const result = await apiService.get(url, auth)
      const mappedResult = mapHighlightsList(result.data)

      return mappedResult
    }
  }

  return {
    fetchHighlights: async (payload) => {
      return await strategy['request'](payload)
    }
  }
}
