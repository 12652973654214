import React from 'react'

import { ReactComponent as IconInfo } from '../../assets/icons/icon_info.svg'
import { ReactComponent as IconMotorcycle } from '../../assets/icons/icon_motorcycle.svg'
import { ReactComponent as IconPickup } from '../../assets/icons/icon_pickup.svg'
import { ReactComponent as IconCurrency } from '../../assets/icons/icon_currency.svg'
import { ReactComponent as IconClock } from '../../assets/icons/icon_clock.svg'
import { ReactComponent as IconNavigation } from '../../assets/icons/icon_navigation.svg'
import { ReactComponent as IconArrowLeft } from '../../assets/icons/icon_arrow_left.svg'
import { ReactComponent as IconStoreList } from '../../assets/icons/icon_store_list.svg'
import { ReactComponent as IconStoreAlter } from '../../assets/icons/icon_store_alter.svg'
import { ReactComponent as IconStoreAgendamento } from '../../assets/icons/icon_agendamento.svg'

import {
  DetailedStoreHeaderContainer,
  HeaderBanner,
  HeaderStore,
  StoreIcon,
  StoreDetails,
  StoreName,
  StoreStatus,
  StoreCosts,
  StoreCost,
  CostIconContainer,
  HeaderNavigation,
  ModalityContainer,
  Button,
  ButtonDisable,
  HeaderBannerMobile
} from './styles'
import history from '../../../history'
import { MULTI_STORE } from '../../../config'

const getStatusProps = (args) => {
  const allStatus = {
    1: { name: 'Aberto', textClassName: 'store-status--open' },
    2: { name: 'Fechado', textClassName: 'store-status--close' },
    3: { name: 'Indisponível', textClassName: 'store-status--unavailable' },
    4: { name: 'Sem comunicação', textClassName: 'store-status--no-communication' },
    5: { name: 'Fechado Temporariamente', textClassName: 'store-status--temporarily-closed' },
    6: { name: 'Fechado Temporariamente', textClassName: 'store-status--temporarily-closed' }
  }

  return allStatus[args] || allStatus['2']
}

export function Header(props) {
  const {
    name,
    image,
    status,
    deliveryFee,
    deliveryMinimumValue,
    pickupMinimumValue,
    deliveryTime,
    modalityId,
    modality,
    handleModality,
    storeImg,
    accessToken,
    setVisibleNavigation,
    setVisibleStoreList,
    storeDelivery,
    storePickup,
    opened,
    scheduling,
    storeHeaderImageMobile,
    isMobile,
    URLParameters
  } = props

  const minimumValue = modalityId === 2 ? pickupMinimumValue : deliveryMinimumValue

  const statusProps = getStatusProps(status);

  return (
    <DetailedStoreHeaderContainer>
      <HeaderNavigation>
        <IconArrowLeft className='none' />
        {MULTI_STORE === 'true' ? <ModalityContainer className='btn-store'>
          <Button onClick={() => setVisibleStoreList(true)}>
            <IconStoreList className="home" />
            <IconStoreAlter />
          </Button>
        </ModalityContainer> : <ButtonDisable></ButtonDisable>}
        <ModalityContainer className='btn-group' opened={opened}>
          {storeDelivery && storePickup && <Button
            onClick={() => { handleModality(4) }}
            className={modality === 4 ? 'active' : ''}>
            Delivery
          </Button>}
          {storeDelivery && storePickup && <Button
            onClick={() => { handleModality(2) }}
            className={modality === 2 ? 'active' : ''}>
            Retirada
          </Button>}
        </ModalityContainer>
        <IconNavigation onClick={() => {
          if (!accessToken) {
            history.push(`/login${URLParameters}`)

            return
          }

          setVisibleNavigation(true)
        }} />
      </HeaderNavigation>
      {!isMobile ? <HeaderBanner>
        <img src={image} alt="banner" />
      </HeaderBanner> : <HeaderBannerMobile>
        <img src={storeHeaderImageMobile} alt="banner" />
      </HeaderBannerMobile>}
      <HeaderStore>
        <StoreIcon>
          <img src={storeImg} alt="logo" />
        </StoreIcon>
        <StoreDetails>
          <StoreName>
            <h1>{name}</h1>
            <IconInfo onClick={() => history.push(`/store-info${URLParameters}`)} />
          </StoreName>
          <StoreStatus className={statusProps.textClassName}>
            {statusProps.name}
          </StoreStatus>
          <StoreCosts>
            <StoreCost className='fee'>
              <CostIconContainer>
                {modality === 4 ? <IconMotorcycle /> : null}
                {modality === 2 ? <IconPickup /> : null}
              </CostIconContainer>
              <h3>{modalityId === 2 ? 'taxa de retirada' : 'taxa de entrega'}</h3>

              <p>{((deliveryFee && `R$ ${deliveryFee.toFixed(2).replace('.', ',')}`) || 'Grátis')}</p>
            </StoreCost>
            <StoreCost className='minimum-value'>
              <CostIconContainer>
                <IconCurrency />
              </CostIconContainer>
              <h3>valor minimo</h3>

              <p>{minimumValue ? `R$ ${minimumValue.toFixed(2).replace('.', ',')}` : '...'}</p>
            </StoreCost>
            <StoreCost className='time'>
              <CostIconContainer>
                {!scheduling ? <IconClock /> : <IconStoreAgendamento />}
              </CostIconContainer>
              <h3>{scheduling ? 'entrega agendada' : 'tempo de preparo'}</h3>

              <p>{!scheduling ? deliveryTime ? deliveryTime : '...' : ''}</p>
            </StoreCost>
          </StoreCosts>
        </StoreDetails>
      </HeaderStore>
    </DetailedStoreHeaderContainer>
  )
}
