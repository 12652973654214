import styled from 'styled-components'

export const Wrapper = styled.div`
  &.actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    flex: 1;
    gap: 1.25rem;

    > button {
      flex: 1;
    }
  }
`
export const ModalWrapper = styled.div`
  padding: 30px;
`
export const Title = styled.h2`
  font-size: 1.5rem;
  text-align: center;
`
export const Paragraph = styled.p`
  color: #737373;
  text-align: center;
`
export const Div = styled.div`
  min-width: 100%;
  display: flex;
`
export const Button = styled.button`
  border-radius: .938rem;
  font-weight: bold;
  flex: 1;
  max-height: 2.5rem;
  min-height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 .938rem;
  margin: 10px;
  font-size: .875rem;
  line-height: .875rem;
  box-sizing: border-box;
  cursor: pointer;

  &.primary {
    color: #fff;
    background: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  }

  &.secondary {
    background: #fff;
    color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
    border: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' } 1px solid;
  }
`
export const Form = styled.form`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 1rem;
`
export const FormField = styled.div`
    flex: 1 0 100%;
    width: 100%;
    margin: 0 0 1rem 0;
`