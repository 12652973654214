import React from 'react'
import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import StoreLogoImageFile from '../assets/seara/logo_seara.svg'
import { ReactComponent as IconGoogle } from '../assets/icons/icon_google_multicolor.svg'
import { ReactComponent as IconFacebook } from '../assets/icons/icon_facebook.svg'
import { ReactComponent as IconClose } from '../assets/icons/icon_close.svg'

import {
  FACEBOOK_APP_ID,
  GOOGLE_CLIENT_ID
} from '../../config'

import {
  login,
  fetchUserAddresses,
  postAddress,
  setNotification,
  fetchGeolocation,
  postFacebookLogin,
  postGoogleLogin,
  postSignUp,
  postPhone,
  postCode,
  setUserHistory,
  setCard,
  setSignIn,
  setSignUp,
  setUserCard,
  setAccessToken,
  setLogin,
  getSignUp,
  setUserCpf,
  setUserName,
  handleGTM,
  setMethodAccess
} from '../../redux/actions/main'

import { loading } from '../../state'

import history from '../../history'

import { pipe, GetContent } from '../../domain/helpers'

import { useDeviceLayout } from '../hooks'

import {
  CustomIcon,
  CustomLoading,
  InputRounded
} from '../components'

import {
  Wrapper,
  Form,
  FormField,
  StoreLogoImage,
  SubmitButton,
  SingUpButton,
  Divider,
  DividerLabel,
  GoogleButton,
  FacebookButton,
  TermsOfServiceLabel,
  ForgotPasswordLink,
  MobileHeaderWrapper,
  CloseButton,
  ErrorMessage
} from './styles'

import { Phone } from '../sign-up'
import {
  PhoneHeader,
  Title
} from '../sign-up/styles'

const Context = React.createContext({})

function SignInPage(props) {
  const {
    setVisibleSignIn,
    login,
    loading,
    setLoading,
    setSignIn,
    setNotification,
    postAddress,
    fetchUserAddresses,
    setVisibleSignUp,
    setVisibleForgotPassword,
    postGoogleLogin,
    postFacebookLogin,
    theme,
    setSignUp,
    setLogin,
    close,
    signIn,
    getSignUp,
    postPhone,
    postCode,
    setAccessToken,
    setUserHistory,
    setUserCpf,
    setUserName,
		accessToken,
		userHistory,
    handleGTM,
    userId,
    setMethodAccess,
    URLParameters
  } = props

  const {
    phone,
    code,
    ddi,
    country,
    confirmationType,
    messageErrors,
    resend
  } = signIn

  const storeLogo = theme.logoImageSignIn

	const {
		url,
		title,
		description
	} = useStoreSettings()

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)
  const [message, setMessage] = useState(null)
  const [phoneReady, setPhoneReady] = useState(false)
  const [signInError, setSignInError] = useState(null)
  const [phoneHeaderTitle, setPhoneHeaderTitle] = useState('Cadastrar celular')
  const [phoneStep, setPhoneStep] = useState(0)
  const [time, setTime] = useState(30)

  let timeout = null

  useEffect(() => {
    const url = window.location.href;

    if (isMobile) {
      const access = {
        event: 'PageView',
        page_path: url,
        page_title: 'Login'
      }
      handleGTM(access)
    }

    if(userId){
      handleGTM({user_id: userId})
    }
  }, [
    userId, 
    handleGTM, 
    isMobile
  ])

  useEffect(() => {
    setEmail('')
    setPassword('')
    setLoading({ item: 'auth', delete: true })

    return () => ({})
  }, [setLoading])

  function handleInput(event) {
    const {
      name,
      value
    } = event.target

    const newValue = {
      [name]: value
    }

    if (name === 'ddi') {
      const value = event.target.value.split('|')
      const country = value[0]
      const ddi = value[1]

      Object.assign(newValue, {
        country,
        ddi
      })
    }

    setSignIn({
      ...signIn,
      ...newValue
    })
  }

  function handleStep(args) {
    const titles = {
      0: 'Cadastrar celular',
      1: 'Código de confirmação'
    }
    setPhoneHeaderTitle(titles[args])
    setPhoneStep(args)
  }

  function postTime(args) {
    setTime(args)
  }

  function handleTime(startTime, time = 0, data = {}) {
    const signUp = getSignUp()

    if (!time && data) {
      setSignUp({
        ...signUp,
        ...data
      })
    }

    if (!!data.clear) {
			postTime(30, 30)
      clearTimeout(timeout)

      return
    }

    timeout = setTimeout(() => {
      postTime(Number(time - 1))

      if (time - 1 > 0) {
        handleTime(startTime, time - 1)
      }
    }, 1000)
  }

  async function handlePhoneSubmit() {
    const result = await postPhone('login')

    if (signIn.phoneValidation || (result && result.success)) {
			const newConfirmationType = messageErrors + 1 >= 4 ? 'phone' : 'sms'

      handleTime(30, 30, {
				messageErrors: messageErrors + 1,
				confirmationType: newConfirmationType,
				resend: true,
				clear: false
			})
      setNotification(null)
      setSignInError(null)
      return { success: true }
    }

    const notificationMessage = result && result.message && result.message.length ? result.message :
      'Não foi possível enviar o código para seu telefone celular.'
    setNotification({
      type: 'warning',
      message: notificationMessage
    })
    setSignInError(notificationMessage)
    return { success: false }
  }

  async function handleCodeSubmit() {
    const result = await postCode('login', [{
      name: 'address',
      type: 'error',
      callback: (a) => {
        setUserHistory({
          next: 'login',
          back: 'login'
        })

        const notificationMessage = 'Não foi possível cadastrar seu endereço. Tente novamente.'
        setNotification({
          type: 'warning',
          message: notificationMessage
        })

        history.push(`/new-address${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'success',
      callback: (b) => {

        console.warn('Address created')
      }
    }])

    const signUp = getSignUp()

    if (!!result.success && !!signUp.codeValidation) {
      const token = accessToken

      const {
        email,
        password
      } = signUp

      setCard(null)
      setUserCard(null)
      setAccessToken(token)
      setLogin({
        ...signIn,
        email,
        password,
        success: true,
        subscriptionStatus: 1
      })
      setPhoneReady(false)
      setNotification(null)
      setSignInError(null)

      if (close && typeof close === 'function') {
        close()
      } else {
				const {
					back
				} = userHistory

				if (back && back !== '/') {
					history.push(`/${back}${URLParameters}`)
				} else {
					history.push(`/store${URLParameters}`)
				}
			}

      return result
    }

    const notificationMessage = result && result.message && result.message.length ? result.message :
      'Não foi possível validar o código para seu telefone celular.'
    setNotification({
      type: 'warning',
      message: notificationMessage
    })
    setSignInError(notificationMessage)

    setPhoneReady(true)
    setPhoneStep(1)

    return result
  }

  const SubmitLogin = async () => {
    setLoading('auth')
    const result = await login({ email, password })

    if (result.success && result.accessToken && result.subscriptionStatus === 1) {
      const token = result.accessToken

      setAccessToken(token)

      setLogin({
        ...signIn,
        email,
        password,
        success: true,
        subscriptionStatus: 1
      })

      const dataSingIn = {
        'event': 'usuário logado',
        'conectado_via': 'email'
      }

      handleGTM(dataSingIn)
      setMethodAccess('email')

      await postAddress([() => {
        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })

      }, () => {
        console.warn('Address created')
      }])

      setTimeout(() => fetchUserAddresses(), 500)

      if (isMobile) {
        setMessage(null)
        setLoading({ item: 'auth', delete: true })

				const {
					back
				} = userHistory

        if (back && back !== '/') {
					history.push(`/${back}${URLParameters}`)
				} else {
					history.push(`/store${URLParameters}`)
				}

        return
      }

      setVisibleSignIn(false)
      setMessage(null)
      setLoading({ item: 'auth', delete: true })
      return
    } else if (!result.success && result.message && result.subscriptionStatus !== 1) {
      setMessage(result.message)
      setLoading('')
      return
    } else if (result.success && result.accessToken && result.subscriptionStatus === 2) {
			const token = result && result.accessToken

      setAccessToken(token)

      setLogin({
        ...signIn,
        ddi: 55,
        country: 'brasil',
        confirmationType: 'sms',
        messageErrors: 0,
        resend: false
      })

      setPhoneReady(true)

      return
    } else {
      const notificationMessage = (signIn.userData && signIn.userData.message) || 'Não foi possível fazer o cadastro!';
      setNotification({
        type: 'warning',
        message: notificationMessage
      })
      setSignInError(notificationMessage)

      setLoading(false)
    }
  }

  const handleSubmitFacebookLogin = async (form) => {
    setMessage('')
    const errorMessage = 'Erro ao realizar o login com o Facebook, por favor tente novamente'

    if (form && form.error) {
      setMessage(errorMessage)

      return
    }

    const dataSingIn = {
      'event': 'usuário logado',
      'conectado_via': 'facebook'
    }

    handleGTM(dataSingIn)
    setMethodAccess('facebook')

    setLoading('auth')
    const result = await postFacebookLogin(form)
    
    handleSocialLoginResult(result, form, errorMessage, 'facebook')
  }

  const handleSubmitGoogleLogin = async (form) => {
    setMessage('')
    const errorMessage = 'Erro ao realizar o login com o Google, por favor tente novamente'

    if (form && form.error) {
      setMessage(errorMessage)

      return
    }
    
    const dataSingIn = {
      'event': 'usuário logado',
      'conectado_via': 'gmail'
    }

    handleGTM(dataSingIn)
    setMethodAccess('gmail')

    setLoading('auth')
    const result = await postGoogleLogin(form)
    
    handleSocialLoginResult(result, form, errorMessage, 'google')
  }

  const handleSocialLoginResult = (result, form, errorMessage, vendor) => {
    const {
      cpf,
      name
    } = result.user

    if (result && result.success && result.user && result.user.signUpStatus === 1) {
      const token = result.user && result.user.token && result.user.token.accessToken

      setAccessToken(token)
      setLogin({
        ...signIn,
        email: form.email || form.profileObj.email,
        password: '',
        success: true,
        subscriptionStatus: 1
      })

      setUserName(name)
      setUserCpf(cpf)

      if (close && typeof close === 'function') {
        close()
      } else {
        history.push(`/store${URLParameters}`)
      }

      return
    }

    if (result && result.success && result.user && result.user.signUpStatus === 2) {
      const token = result.user && result.user.token && result.user.token.accessToken

      setAccessToken(token)

      setLogin({
        ...signIn,
        ddi: 55,
        country: 'brasil',
        confirmationType: 'sms',
        messageErrors: 0,
        resend: false
      })

      setPhoneReady(true)

      return
    }

    if (result && result.user && result.user.signUp && form && form.profileObj && vendor === 'google') {
      setSignUp({
        name: form.profileObj.givenName,
        lastName: form.profileObj.familyName,
        email: form.profileObj.email,
        image: form.profileObj.imageUrl,
        googleToken: form.accessToken,
        googleUserId: form.googleId
      })

      if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
        setVisibleSignUp(true)
      } else {
        history.push(`/sign-up${URLParameters}`)
      }

      return
    }

    if (result && result.user && result.user.signUp && form && form.userID && vendor === 'facebook') {
      const userNames = form.name.split(' ')

      setSignUp({
        name: userNames[0],
        lastName: userNames[userNames.length - 1],
        email: form.email,
        image: form.picture.data.url,
        facebookToken: form.accessToken,
        facebookUserId: form.userID
      })
      
      if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
        setVisibleSignUp(true)
      } else {
        setUserHistory({
          back: 'login'
        })

        history.push(`/sign-up${URLParameters}`)
      }

      return
    }

    result && setMessage(result.error || errorMessage)
  }

  return <Wrapper>
		<Helmet>
			<title>{`${title} - Login`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={`${close && typeof close === 'function' ? `${url}/store` : `${url}/login`}`} />
		</Helmet>

    {!phoneReady ? <>
      <MobileHeaderWrapper>
        <CloseButton className='close' onClick={() => {
          history.push(`/store${URLParameters}`)
        }}>
          <CustomIcon name='Times' />
        </CloseButton>
      </MobileHeaderWrapper>

      <StoreLogoImage src={storeLogo ? storeLogo : StoreLogoImageFile} />

      {message &&
        <ErrorMessage>
          <span>{message}</span>
          <IconClose />
        </ErrorMessage>
      }

      <Form onSubmit={(event) => {
        event.preventDefault()
      }}>
        <FormField>
          <InputRounded
            value={email || ''}
            onChange={e => setEmail(e.target.value)}
            type="text"
            name="email"
            placeholder="Usuário"
          />
        </FormField>
        <FormField>
          <InputRounded
            value={password || ''}
            onChange={e => setPassword(e.target.value)}
            type="password"
            name="pass"
            placeholder="Senha" />
        </FormField>
        <ForgotPasswordLink onClick={() => {
          setVisibleForgotPassword()
        }}>Esqueci minha senha</ForgotPasswordLink>
        <SubmitButton onClick={() => {
          SubmitLogin()
        }}>
          {loading.includes('auth') ?
            <CustomLoading
              color={'#fff'}
              type={'spin'}
              id='default-loading'
              height={30}
              width={30} /> :
            'Entrar'}
        </SubmitButton>
        <SingUpButton onClick={() => {
          if (setVisibleSignUp && typeof setVisibleSignUp === 'function') {
            setVisibleSignUp(true)
          } else {
            history.push(`/sign-up${URLParameters}`)
          }
        }}>Ainda não tenho conta</SingUpButton>
        <Divider>
          <DividerLabel>OU</DividerLabel>
        </Divider>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Login"
          onSuccess={handleSubmitGoogleLogin}
          onFailure={handleSubmitGoogleLogin}
          cookiePolicy={'single_host_origin'}
          render={renderProps => (
            <GoogleButton
              type='button'
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <IconGoogle />
              <label>entrar com <span>Google</span></label>
            </GoogleButton>
          )}
        />
        {<FacebookLogin
          appId={FACEBOOK_APP_ID}
          autoLoad={false}
          fields="name,email,picture"
          disableMobileRedirect={true}
          isMobile={isMobile}
          callback={handleSubmitFacebookLogin}
          render={({ onClick, isDisabled }) => (
            <FacebookButton
              onClick={onClick}
              disabled={isDisabled}
              type='button'
            >
              <IconFacebook />
              <label>entrar com <span>Facebook</span></label>
            </FacebookButton>
          )}
        />}
        <TermsOfServiceLabel>
          Ao se cadastrar você concorda com os <a href='#a'>Termos de Uso</a>
        </TermsOfServiceLabel>
      </Form>
    </> : <>
      {signInError &&
        <ErrorMessage>
          <span>{signInError}</span>
          <IconClose />
        </ErrorMessage>
      }
      <PhoneHeader>
        <Title>{phoneHeaderTitle}</Title>
      </PhoneHeader>
      <Phone
        step={phoneStep}
        country={country || 'brasil'}
        ddi={ddi || 55}
        phone={phone}
        code={code}
        resend={resend}
        confirmationType={confirmationType}
        messageErrors={messageErrors}
        time={time}
        handleTime={handleTime}
        handleStep={handleStep}
        handleInput={handleInput}
        handlePhoneSubmit={handlePhoneSubmit}
        handleCodeSubmit={handleCodeSubmit}
      />
    </>}
  </Wrapper>
}

SignInPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]))
}

const mapStateToProps = (state) => {
  return {
    loading: state.main.loading || [],
    address: (state.main.address) || {},
    signIn: (state.main.login) || {},
    userHistory: (state.main.userHistory) || {},
		accessToken: state.main.accessToken || '',
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  login,
  fetchUserAddresses,
  postAddress,
  setNotification,
  fetchGeolocation,
  postGoogleLogin,
  postFacebookLogin,
  postSignUp,
  setSignIn,
  setLoading: loading.setLoading,
  postPhone,
  postCode,
  setUserHistory,
  setCard,
  setUserCard,
  setAccessToken,
  setLogin,
  getSignUp,
  setSignUp,
  setUserCpf,
  setUserName,
  handleGTM,
  setMethodAccess
})

export const SignIn = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'signin' })
)(SignInPage))
