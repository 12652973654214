import React from 'react'
import { Image, Shimmer } from 'react-shimmer'

import {
  Wrapper,
  H1,
  Paragraph,
  ChangeAddressButton,
  ListWrapper,
  AgglomerationButton,
  Label
} from './styles';

export function Agglomerations(props) {
  const {
    data,
    handleAgglomeration,
    handleChangeAddress
  } = props

  return <Wrapper id='home-agglomerations'>
    {(!data || (data && !data.length)) ? <>
      <H1>Infelizmente não encontramos nenhuma loja na sua região</H1>

      <Paragraph>
        Gostaria de tentar outro endereço ou ser notificado quando alguma loja, próxima a você, for adicionada?
      </Paragraph>

      <ChangeAddressButton onClick={() => {
        handleChangeAddress()
      }}>Trocar endereço</ChangeAddressButton>
    </> : <>
        <ListWrapper>
          {data.map((mappedItem, index) => {
            const {
              id,
              image,
              label
            } = mappedItem

            const key = `agglomerations-item-${ index }`

            return <AgglomerationButton key={ key } onClick={() => {
              handleAgglomeration({
                id
              })
            }}>
              <Image src={ image } alt={ label } fallback={<Shimmer width={80} height={80}/>}/>
              <Label>{ label }</Label>
            </AgglomerationButton>
          })}
        </ListWrapper>
      </>}
  </Wrapper>
}
