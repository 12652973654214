import React from 'react'

import { Side } from './styles'

export function PageContainerSide({ children, styles, className }) {
	return (
		<Side className={className} style={styles}>
			{ children }
		</Side>
	)
}
