import React, {
  useContext, useEffect, useState
} from 'react'
import { connect } from 'react-redux'
import { Dialog, Slide } from '@material-ui/core'

import history from '../../history'

import {
  setModality,
  setOpenCart,
  setShowSignUp,
  fetchStoreFees
} from '../../redux/actions/main'

import axios from 'axios'

import {
  store
} from '../../state'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import { useDeviceLayout } from '../hooks'

import {
  Full,
  Simple
} from './'

import {
  Addresses,
  ForgotPassword,
  SignIn,
  SignUp,
  StoresList,
  useForgotPasswordDialogStyles
} from '..'

import {
  Wrapper
} from './styles'

const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function MainHeaderComponent(props) {
  const {
    mode,
    theme,
    storeName,
    userName,
    userClicked,
    cartClicked,
    isCartOpen,
    cartItemsQuantity,
    modality,
    handleModality,
    storeAddress,
    modalityId,
    name,
    storeImg,
    accessToken,
    products,
    storePickup,
    storeDelivery,
    setModality,
    activeDialogSignIn,
    homeDisabled = false,
    userCpf,
    subscriptionStatus,
    setOpenCart,
    openCart,
    showSignUp,
    setShowSignUp,
    pathName,
    opened,
    currentRoute,
    userAddress,
    addressByCEP,
    homeOnClick,
    fetchStoreFees,
    fetchStore,
    URLParameters
  } = props

  const storeLogo = theme.logoImageStore

  const context = useContext(Context)
  const { sections } = context
  const fullWidth = true

  const [visibleAddress, setVisibleAddress] = useState(false)
  const [visibleStoreList, setVisibleStoreList] = useState(false)
  const [visibleSignIn, setVisibleSignIn] = useState(false)
  const [signUpInitialData, setSignUpInitialData] = useState(undefined)
  const [visibleForgotPassword, setVisibleForgotPassword] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const forgotPasswordDialogClasses = useForgotPasswordDialogStyles()

  const fullSection = sections && sections.filter(item => item.id === 'full')[0]
  const simpleSection = sections && sections.filter(item => item.id === 'simple')[0]

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useEffect(() => {
    if (modalityId === 4 && userAddress && !userAddress.verified && addressByCEP && (!addressByCEP.main || !addressByCEP.number || !addressByCEP.neighborhood || !addressByCEP.city || !addressByCEP.state) &&
      userAddress && (!userAddress.main || !userAddress.number || !userAddress.neighborhood || !userAddress.city || !userAddress.state)) {
      setVisibleAddress(true)
    }
  }, [
    modalityId,
    userAddress,
    addressByCEP
  ])

  async function handleFetchStore() {
    await fetchStore()

    if (modalityId === 4) {
      const storeFeesRequest = axios.CancelToken
      const storeFeesRequestSource = storeFeesRequest.source()

      await fetchStoreFees(() => { }, {
        storeFeesRequestSource
      })
    }
  }

  function selectedModality(id) {
    handleModality(id)
  }

  function handleBack() {
    if (pathName === '/receipt') {
      handleFetchStore()
    }

    history.push(`./store${URLParameters}`)
  }

  function handleCloseDialogStoresList() {
    setVisibleStoreList(false)
  }

  function handleCloseDialogAddress() {
    setVisibleAddress(false)
  }

  function handleViewAddress() {
    setVisibleAddress(true)
  }

  function handleCloseDialogSignIn() {
    setVisibleSignIn(false)
  }

  function handleCloseDialogSignUp() {
    setShowSignUp(false)
  }

  function handleVisibleSignUp() {
    handleCloseDialogSignIn()
    setShowSignUp(true)
  }

  function handleVisibleForgotPassword() {
    handleCloseDialogSignIn()
    setVisibleForgotPassword(true)
  }

  function handleCloseDialogForgotPassword() {
    setVisibleForgotPassword(false)
    setVisibleSignIn(true)
  }

  function handleShowSignUpFromSignIn(data) {
    setSignUpInitialData(data)
    handleVisibleSignUp()
  }

  useEffect(() => {
    if (pathName === '/store') {
      if (!storePickup) {
        setModality({ id: 4 })
      }

      if (storeDelivery && storePickup) {
        setModality({ id: 4 })
      }

      if (!storeDelivery && storePickup) {
        setModality({ id: 2 })
      }
    }

    if (isMobile) {
      setFullScreen(true)
    }

    if (activeDialogSignIn) {
      setVisibleSignIn(true)
    }
  }, [
    isMobile,
    storePickup,
    storeDelivery,
    setModality,
    activeDialogSignIn,
    pathName
  ])

  if (mode === 'full') {
    return (
      <Wrapper>
        <Full
          setVisibleStoreList={setVisibleStoreList}
          storeName={name}
          userName={userName}
          userCpf={userCpf}
          subscriptionStatus={subscriptionStatus}
          userAddress={userAddress}
          userClicked={userClicked}
          cartClicked={cartClicked}
          handleViewAddress={handleViewAddress}
          visibleSignIn={visibleSignIn}
          setVisibleSignIn={setVisibleSignIn}
          isCartOpen={isCartOpen}
          cartItemsQuantity={cartItemsQuantity}
          context={fullSection}
          storeLogo={storeLogo}
          modality={modality}
          handleModality={selectedModality}
          storeAddress={storeAddress}
          modalityId={modalityId}
          storeImg={storeImg}
          accessToken={accessToken}
          products={products}
          storePickup={storePickup}
          storeDelivery={storeDelivery}
          homeDisabled={homeDisabled}
          setOpenCart={setOpenCart}
          openCart={openCart}
          opened={opened}
          homeOnClick={homeOnClick}
          URLParameters={URLParameters}
        />

        <Dialog
          onClose={() => handleCloseDialogStoresList()}
          aria-labelledby="stores-list-dialog"
          open={visibleStoreList}
          TransitionComponent={Transition}
          maxWidth={'xs'}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          URLParameters={URLParameters}
        >
          <StoresList close={handleCloseDialogStoresList} />
        </Dialog>

        <Dialog
          onClose={(event, reason) => {
            if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
              event.preventDefault()
            }
          }}
          aria-labelledby="address-dialog"
          open={visibleAddress}
          maxWidth={'sm'}
          fullWidth={fullWidth}
          TransitionComponent={Transition}
          fullScreen={fullScreen}
        >
          <Addresses
            currentRoute={currentRoute}
            pathName={pathName}
            handleCloseDialogAddress={handleCloseDialogAddress}
          />
        </Dialog>

        <Dialog
          onClose={() => handleCloseDialogSignIn()}
          aria-labelledby="login-dialog"
          open={visibleSignIn}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          PaperProps={{
            style: {
              maxWidth: '25rem',
              borderRadius: '.75rem'
            }
          }}
        >
          <SignIn
            setVisibleSignIn={setVisibleSignIn}
            setVisibleSignUp={handleVisibleSignUp}
            setVisibleForgotPassword={handleVisibleForgotPassword}
            showSignUp={handleShowSignUpFromSignIn}
            close={() => { setVisibleSignIn(false) }}
            theme={theme}
          ></SignIn>
        </Dialog>

        <Dialog
          onClose={() => handleCloseDialogSignUp()}
          aria-labelledby="signUp-dialog"
          open={showSignUp}
          maxWidth={'xs'}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
        >
          <SignUp
            setVisibleSignIn={setShowSignUp}
            initialData={signUpInitialData}
            close={() => { setShowSignUp(false) }}
          >
          </SignUp>
        </Dialog>

        <Dialog
          onClose={() => handleCloseDialogForgotPassword()}
          aria-labelledby="forgot-password-dialog"
          open={visibleForgotPassword}
          maxWidth={'xs'}
          fullWidth={fullWidth}
          fullScreen={fullScreen}
          classes={{
            scrollPaper: forgotPasswordDialogClasses.scrollPaper,
            paper: forgotPasswordDialogClasses.paper
          }}
          TransitionComponent={Transition}
        >
          <ForgotPassword backStep={() => handleCloseDialogForgotPassword()} />
        </Dialog>
      </Wrapper>
    )
  }

  return <Simple
    context={simpleSection}
    storeLogo={storeLogo}
    storeName={storeName}
    handleBack={handleBack}
  />
}

MainHeaderComponent.propTypes = {}

const mapStateToProps = (state) => {
  return {
    products: (state.main.cart && state.main.cart.products) || [],
    accessToken: state.main.accessToken,
    storeName: state.main.store && state.main.store.name,
    userCpf: state.main.userCpf,
    modalityId: state.main.modality && state.main.modality.id,
    storeAddress: state.main.store && state.main.store.address,
    stores: state.main.stores || [],
    storePickup: (state.main.store && state.main.store.pickup) || null,
    storeDelivery: (state.main.store && state.main.store.delivery) || null,
    openCart: state.main.openCart || null,
    subscriptionStatus: (state.main.login && state.main.login.subscriptionStatus) || 0,
    showSignUp: state.main.showSignUp || false,
    userAddress: state.main.userAddress || {},
    addressByCEP: state.main.addressByCEP || {},
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchStore: store.fetchStore,
  setModality,
  setOpenCart,
  setShowSignUp,
  fetchStoreFees
})

export const MainHeader = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'main-header' })
)(MainHeaderComponent))
