
import React, {
  useContext,
  useEffect
} from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import {
  coupon
} from '../../../state'

import {
  pipe,
  GetContent
} from '../../../domain/helpers'

import {
  Skeleton
} from '../../'

import {
  ContentWrapper,
  LoadingWrapper,
  Wrapper,
  H1,
  H2,
  Paragraph
} from './styles'

import { Content } from '../content'
import { EmptyContent } from '../empty-content'

export const Context = React.createContext({})

function CouponsListPage(props) {
  const {
    loading,
    registeredCoupons,

    fetchCoupons,

    handleCoupon
  } = props

  const {
    sections,
    title,
    text
  } = useContext(Context)

  const registeredCouponsSection = sections && sections.filter(item => item.id === 'registered-coupons')[0]

  const subTitle = registeredCouponsSection && registeredCouponsSection.title
  const registeredCouponsSelectedSectionName = registeredCoupons && registeredCoupons.length ? 'main-content' : 'empty-content'
  const registeredCouponsSelectedSection = (registeredCouponsSection && registeredCouponsSection.content && registeredCouponsSection.content.filter(item => item.id === registeredCouponsSelectedSectionName)[0]) || {}

  useEffect(() => {
    setTimeout(() => fetchCoupons(), 500)
  }, [
    fetchCoupons
  ])

  return <Wrapper>
    {!!loading.includes('coupons') && <LoadingWrapper id='coupons-list-loading'>
      <Skeleton module='coupons-list' />
    </LoadingWrapper>}

    {!loading.includes('coupons') && <><ContentWrapper id='coupons-list-content'>
      <H1>{title}</H1>

      <Paragraph>{text}</Paragraph>

      <H2>{subTitle}</H2>

      {registeredCoupons && registeredCoupons.length ?
        <Content mode='simple' data={registeredCoupons} handleCoupon={handleCoupon} /> :
        <EmptyContent data={{ ...registeredCouponsSelectedSection }} />}
    </ContentWrapper></>}
  </Wrapper>
}

CouponsListPage.propTypes = {
  loading: PropTypes.arrayOf(PropTypes.string),
  registeredCoupons: PropTypes.arrayOf(PropTypes.shape({})),

  fetchCoupons: PropTypes.func,

  handleCoupon: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    loading: state.main.loading || [],
    registeredCoupons: (state.main.coupons && state.main.coupons.coupons) || { coupons: [] }
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchCoupons: coupon.fetchCoupons
})

export const CouponsList = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'coupons' })
)(CouponsListPage))
