import React from 'react'

import {
    Wrapper,
    ContactWrapper,
    Title,
    Contact,
    Paragraph,
    Contened,
    ScheduleWrapper,
    Schedule,
    PaymentWrapper,
    PaymentOnline,
    PaymentOffline
} from './styles'

export function Infos(props) {
    const {
        storeContact,
        openingHours,
        onlineFlags,
        presentialFlags
    } = props

    function handleDayWeek(day) {
        switch (day) {
            case "Dom":
                return "Domingo"
            case "Seg":
                return "Segunda"
            case "Ter":
                return "Terça"
            case "Qua":
                return "Quarta"
            case "Qui":
                return "Quinta"
            case "Sex":
                return "Sexta"
            case "Sab":
                return "Sábado"
            case "Fer":
                return "Feriado"
            default:
                break;
        }
    }

    return <Wrapper>
        <ContactWrapper>
            <Contact>
                <Title>
                    Contato
                </Title>
                <Contened>
                    <Paragraph className="phone"><strong>Telefone:</strong> {`(${storeContact.ddd}) ${storeContact.phone}`}</Paragraph>
                    <Paragraph><strong>Email:</strong> {storeContact.email}</Paragraph>
                </Contened>
            </Contact>
        </ContactWrapper>

        <ScheduleWrapper>
            <Schedule>
                <Title>
                    Horário de Funcionamento
                </Title>
                {openingHours && openingHours.map((item) => {
                    return (<>
                        <Contened>
                            <Paragraph className="label" today={item.today}>
                                <span>{handleDayWeek(item.day)}:</span>
                            </Paragraph>
                            <Paragraph className="itens" today={item.today}>
                                {item.hour}
                            </Paragraph>
                        </Contened>
                    </>)
                })}
            </Schedule>
        </ScheduleWrapper>

        <PaymentWrapper>
            <Contened className="payment">
                {onlineFlags && onlineFlags.length > 0 && <PaymentOnline>
                    <Title>
                        Pagamentos aceitos pelo site
                    </Title>
                    {onlineFlags.map((item, index) => {
                        return <img src={item} width='50' height='30' alt={`online-payment-${index}`}/>
                    })}
                </PaymentOnline>}
                {presentialFlags && presentialFlags.length > 0 && <PaymentOffline>
                    <Title>
                        Pagamentos aceitos pelo site
                    </Title>
                    {presentialFlags.map((item, index) => {
                        return <img src={item} width='50' height='30' alt={`offline-payment-${index}`}/>
                    })}
                </PaymentOffline>}
            </Contened>
        </PaymentWrapper>
    </Wrapper>
}
