import React, {
  useEffect,
  useState,
  useContext,
  useCallback
} from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

/* DDD */

import {
  highlight,
  loading,
  userAddress,
  order,
  modality,
  store
} from '../../state'

import {
  pipe,
  GetContent
} from '../../domain/helpers'

import { verifyAddressCities }  from '../../domain/helpers/CitiesValidation'

import {
  useCustomLayout
} from '../hooks'

/* OLD */

import history from '../../history'

import {
  setStores,
  setStore,
  setUserHistory,
  handleGTM,
  setAddress
} from '../../redux/actions/main'

import { getDistanceFromLatLong } from '../../domain/helpers/ipiranga/CalculateLocalization'

import {
  PWAClose,
  //PWAAnalytics
} from '../../domain/helpers/ipiranga/PWA'

import {
  Main
} from './'

import {
  Debugger
} from '../'

export const Context = React.createContext({})

export function HomePage(props) {
  const {
    address,
    highlights,
    loading,
    modality,
    userAddress,
    userAddresses,
    userName,
    device,
    params,
    latitude,
    longitude,

    handleGTM,

    fetchHighlights,
    setHighlights,

    fetchUserAddresses,
    setUserAddress,

    setModality,
    setLoading,
    setStores,
    setUserHistory,
    setStoresPagination,
    setStore,
    setOrders,
    setAddress,
  } = props

  const context = useContext(Context)

  const [loaded, setLoaded] = useState(false)
  const [ready, setReady] = useState(false)
  const [contentOverlayMotion, setContentOverlayMotion] = useState(false)

  const [overlayDifferentAddress, setOverlayDifferentAddress] = useState({})
  const [overlayDisallowedCity, setOverlayDisallowedCity] = useState({})

  const handleAddressConfirm = useCallback(() => {
    setContentOverlayMotion(false)

    setUserAddress({
      ...userAddress,
      verified: true
    })

    setAddress({
      ...address,
      allowed: true
    })
  }, [
    userAddress,
    setUserAddress,
    address,
    setAddress
  ])

  const handleAddressChange = useCallback(() => {
    setUserHistory({
      next: 'home',
      back: 'home'
   })

    if (!userAddresses.length) {
      history.push('/addresses')

      return
    }

    // const addressData = {
    //   action: 'analytics',
    //   data: {
    //     eventType: 'tap_address_home',
    //     parameters: null 
    //   }
    // }

    history.push('/addresses')

    //handleGTM(addressData)
    //PWAAnalytics(addressData)
  }, [
    //handleGTM,
    setUserHistory,
    userAddresses
  ])

  useCustomLayout({
    device,
    backgroundColor: '#fff'
  })

  useEffect(() => {
    async function getHighlights() {
      await fetchHighlights()
    }

    async function getUserAddresses() {
      await fetchUserAddresses()
    }

    setStoresPagination({
      offset: 0
    })

    setStore(null)

    setOrders({
      orders: [],
      retry: true
    })

    getHighlights()
    getUserAddresses()

    return () => {}
  }, [
    fetchHighlights,
    fetchUserAddresses,
    setStoresPagination,
    setOrders,
    setStore
  ])

  useEffect(() => {
    function verifyAddress({
      callback,
      conditionals,
      cities
    }) {
      const distanceCallback = callback && callback[0]
      const cityCallback = callback && callback[1]
      const showOverlay = conditionals && conditionals[0]

      const latLast = userAddress && userAddress.latitude
      const longLast = userAddress && userAddress.longitude
      const latGeo = latitude
      const longGeo = longitude

      const data = {
        latLast,
        latGeo,
        longLast,
        longGeo
      }

      const verifyDistance = (getDistanceFromLatLong(data) > 1)
      const verifyCities = verifyAddressCities(cities)

      if (verifyCities && showOverlay) {
        cityCallback && cityCallback()

        return
      }

      if (verifyDistance && showOverlay) {
        distanceCallback && distanceCallback()

        return
      }
    }

    if (address && (!address.allowed || !userAddress.verified)) {
      verifyAddress({
        conditionals: [!contentOverlayMotion],
        callback: [() => {
          const overlayAddressIsObject = typeof context.overlay === 'object'
          const overlayAddressLength = overlayAddressIsObject && context.overlay.length
          const filteredOverlayAddress = overlayAddressLength && context.overlay.filter(filteredItem => filteredItem.id === 'address')
          const title = filteredOverlayAddress && filteredOverlayAddress[0] && filteredOverlayAddress[0].title
          const message = filteredOverlayAddress && filteredOverlayAddress[0] && filteredOverlayAddress[0].message
          const yesLabel = filteredOverlayAddress && filteredOverlayAddress[0] && filteredOverlayAddress[0].yesButton.label
          const noLabel = filteredOverlayAddress && filteredOverlayAddress[0] && filteredOverlayAddress[0].noButton.label

          setOverlayDisallowedCity(null)
          setContentOverlayMotion(true)
          setOverlayDifferentAddress({
            title,
            message,
            actions: [{
              label: yesLabel,
              handleClick: handleAddressConfirm
            }, {
              label: noLabel,
              handleClick: handleAddressChange
            }]
          })
        }, () => {
          const overlayCityIsObject = typeof context.overlay === 'object'
          const overlayCityLength = overlayCityIsObject && context.overlay.length
          const filteredOverlayCity = overlayCityLength && context.overlay.filter(filteredItem => filteredItem.id === 'city')
          const title = filteredOverlayCity && filteredOverlayCity[0] && filteredOverlayCity[0].title
          const message = filteredOverlayCity && filteredOverlayCity[0] && filteredOverlayCity[0].message
          const yesLabel = filteredOverlayCity && filteredOverlayCity[0] && filteredOverlayCity[0].yesButton.label
          const noLabel = filteredOverlayCity && filteredOverlayCity[0] && filteredOverlayCity[0].noButton.label

          setOverlayDifferentAddress(null)
          setContentOverlayMotion(true)
          setOverlayDisallowedCity({
            title,
            message,
            actions: [{
              label: yesLabel,
              handleClick: () => {
                PWAClose()
              }
            }, {
              label: noLabel,
              handleClick: handleAddressChange
            }]
          })
        }],
        cities: [userAddress && userAddress.city]
      })
    }

    return () => {}
  }, [
    address,
    userAddress,
    contentOverlayMotion,
    latitude,
    longitude,
    context,
    handleAddressChange,
    handleAddressConfirm,
    setAddress
  ])

  useEffect(() => {
    const modalityId = modality && modality.id

    if (!modalityId) {
      setModality({
        id: 4
      })
    }

    if (!!loading.length && !!loading && !loaded && !ready) {
      setLoading('')
    }

    if (!loaded && !ready) {
      setStores({
        list: []
      })
      setLoaded(true)
      setReady(true)
    }

    return () => {}
  }, [
    loading,
    loaded,
    modality,
    ready,
    setLoading,
    setModality,
    setStores
  ])

  function handleLogout() {
    PWAClose()
  }

  function handleModality(modality) {
    setHighlights(null)

    setLoaded(false)
    setReady(false)
    postModality(modality)
  }

  function postModality(modality) {
    setModality(modality)
    fetchHighlights()
  }

  const Content = {
    component: Main,
    props: {
      data: {
        highlights,
        userName,
        userAddress,
        loaded,
        modality
      },
  
      handles: {
        handleAddressConfirm,
        handleAddressChange,
        handleLogout,
        handleModality,
        handleGTM
      },
    
      overlay: {
        motion: {
          value: contentOverlayMotion,
          set: setContentOverlayMotion
        },
        differentAddress: overlayDifferentAddress,
        disallowedCity: overlayDisallowedCity
      }
    }
  }

  const {
    props: finalProps,
    component: Component
  } = Content

  return <>
    <Component { ...finalProps } />

    <Debugger
      show={false}
      data={{
        url: {
          params
        }
      }}
      />
  </>
}

HomePage.propTypes = {
  address: PropTypes.shape({}),
  agglomerations: PropTypes.arrayOf(PropTypes.shape({})),
  highlights: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string])),
  modality: PropTypes.shape({}),
  userAddress: PropTypes.shape({}),
  userAddresses: PropTypes.arrayOf(PropTypes.shape({})),
  userName: PropTypes.string,

  fetchAgglomerations: PropTypes.func,
  fetchHighlights: PropTypes.func,
  fetchUserAddresses: PropTypes.func,
  
  setAgglomeration: PropTypes.func,
  setAgglomerations: PropTypes.func,
  setLoading: PropTypes.func,
  setHighlights: PropTypes.func,
  setModality: PropTypes.func,
  setStores: PropTypes.func,
  setUserAddress: PropTypes.func,
  setUserHistory: PropTypes.func
}

const mapStateToProps = (state) => {
  return {
    address: state.main.address,
    highlights: state.main.highlights,
    loading: (state.main.loading) || [],
    modality: (state.main.modality) || {},
    userAddress: state.main.userAddress,
    userAddresses: state.main.userAddresses || [],
    device: (state.main.params && state.main.params.device) || '',
    userName: (state.main.userName) || '…',
    params: (state.main.params) || {},
    latitude: state.main.latitude,
    longitude: state.main.longitude
  }
}

const GetConnection = connect(mapStateToProps, {
  fetchHighlights: highlight.fetchHighlights,
  setHighlights: highlight.setHighlights,
  
  fetchUserAddresses: userAddress.fetchUserAddresses,
  setUserAddress: userAddress.setUserAddress,

  setLoading: loading.setLoading,

  setOrders: order.setOrders,

  setModality: modality.setModality,

  setStoresPagination: store.setStoresPagination,

  setAddress,
  setStores,
  setStore,
  setUserHistory,
  handleGTM
})

export const Home = React.memo(pipe(
  GetConnection,
  GetContent({context: Context, id: 'home'}),
  withRouter
)(HomePage))
