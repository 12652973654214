import { createGlobalStyle } from 'styled-components'

import { THEME } from '../../config'

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Nunito', 'sans-serif';
  }

  html, body {
    -webkit-overflow-scrolling: touch;
    height: 100%;
    background: ${THEME.main.general.primaryColor};
  }

  html {
    font-size: 100%;

    &.no-scroll {
      overflow: hidden;
      margin: 0; 
      height: 100%;
    }
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    box-sizing: border-box;
    overflow-x: hidden;

    &.no-scroll {
      overflow: hidden;
    }
  }

  button {
    border: 0 none;
  }

  #root {
    background: ${THEME.main.general.primaryColor};
    min-width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    &.no-scroll {
      max-height: 100%;
      overflow: hidden;
    }

    &.iphone {
      padding-top: 2.5rem;

      #fixed-header {
        padding-top: 2.5rem;
        height: 5.625rem;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          height: 2.5rem;
          background: #fff;
          content: '';
          width: 100%;
        }
      }

      #store-categories {
        top: 5.625rem;
      }
    }
  }

  input,
  textarea,
  select,
  button {
    outline: none !important;
  }

  *:focus {
    outline: 0;
    outline: none;
  }

  .MuiPaper-root {   
    ::-webkit-scrollbar {
      width: 6px;
    }
    
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#000'}; 
        -webkit-box-shadow: inset 0 0 6px ${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textNinethColor) || '#CCC'}; 
    }
  
  }
`
export default GlobalStyle
