import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 0 2rem 2rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: -60px;

  > div {
    flex: 1;

    &.header {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 12.5rem;
      flex: 0;

      @media(min-width: 60rem){
        display: none;
      }

      > img {
        min-width: 4.375rem;
        max-width: 4.375rem;
        min-height: auto;
        max-height: unset;
      }

      svg {
        opacity: .2;

        g {
          path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
          }
        }
        path {
          fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        g {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        path {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
      }
    }

    &.icon-content {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 12.5rem;
      flex: 0;

      @media(min-width: 60rem){
        min-width: 40%;
        max-width: 40%;
      }

      @media(max-width: 60rem){
        display: none;
      }

      svg {
        opacity: .2;
        g {
          path {
            fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
          }
        }
        path {
          fill:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        g {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
        path {
          stroke:${props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#333'};
        }
      }
    }

    &.content {
      @media(min-width: 60rem){
        min-width: 60%;
        max-width: 60%;
      }

      @media(max-width:60rem){
        text-align: center;
      }
      
      > h2 {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
      }

      > p {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
        font-size: 1rem;
      }
    }

    &.footer {
      max-width: 100%;
      min-width: 100%;
      min-height: 4.375rem;
      max-height: 4.375rem;
    }
  }

  @media(max-width:60rem){
    flex-direction: column;
    padding: 2rem;
    margin-top: 0px;
  }
`
