import { CARD_BRANDS_IDS, CARD_TYPES } from '../../domain/enum/payment';

const types = {
    [CARD_BRANDS_IDS.MASTERCARD]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.VISA]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.AMEX]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.ELO]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.HIPERCARD]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.ALELO]: CARD_TYPES.VOUCHER,
    [CARD_BRANDS_IDS.BEN]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.DINERS]: CARD_TYPES.CREDIT_CARD,
    [CARD_BRANDS_IDS.SODEXO]: CARD_TYPES.VOUCHER,
    [CARD_BRANDS_IDS.TICKET_VR]: CARD_TYPES.VOUCHER,
    [CARD_BRANDS_IDS.TICKET_VA]: CARD_TYPES.VOUCHER,
    [CARD_BRANDS_IDS.VR]: CARD_TYPES.VOUCHER,
}

const getCardTypeByBrand = (brandId) => {
    return types[brandId];
}

export default getCardTypeByBrand;
