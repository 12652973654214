import styled from 'styled-components'

export const Side = styled.aside`
	position: fixed;
	left: calc(100% - 20rem);
	top: 0;
	min-width: 20rem;
	max-width: 20rem;
	right: auto;
	z-index: 2;
	display: none;
	max-height: 100%;
	min-height: 100%;
  	overflow-y: auto;
	background: #fff;

	

	&.placed-order {
		z-index: 1;
	}
`
