import React from 'react'

import { ReactComponent as LocationIcon } from '../../assets/icons/icon_checkout_address.svg'
import { ReactComponent as IconPhone } from '../../assets/icons/icon_phone.svg'
import { ReactComponent as IconTyping } from '../../assets/icons/icon_typing.svg'
import {
  formatCpf,
  getCardBrandImageById,
  numberToCurrency
} from '../../../infra/utils'

import history from '../../../history'

import {
  Container,
  OrderSummaryTitle,
  ItemsList,
  Item,
  ItemDetails,
  ItemName,
  ItemDescription,
  ItemPrice,
  ItemAmount,
  TotalsWrapper,
  TotalsItem,
  SubTotalLabel,
  SubTotalValue,
  DeliveryFeeLabel,
  DeliveryFeeValue,
  TotalLabel,
  TotalValue,
  DeliveryAddressWrapper,
  DeliveryAddressLabel,
  DeliveryAddress,
  DeliveryPaymentWrapper,
  DeliveryPaymentCard,
  ActionButton,
  CardImage,
  Help,
  HelpLabel,
  HelpButton
} from './styles'
import { selectMonth } from '../../../domain/helpers/SelectedMonth'

const SubtotalText = ({ subtotal }) => (<>
  <SubTotalLabel>Subtotal:</SubTotalLabel>
  <SubTotalValue>{numberToCurrency(subtotal)}</SubTotalValue>
</>)

const DiscountText = ({ discount }) => (<>
  {discount ? <><DeliveryFeeLabel>Desconto:</DeliveryFeeLabel>
    <DeliveryFeeValue>{numberToCurrency(discount)}</DeliveryFeeValue></> :
    null}
</>)

const DeliveryFeeText = ({ deliveryFee, modalityId }) => (<>
  <DeliveryFeeLabel>{modalityId === 4 ? 'Taxa de entrega' : 'Taxa de retirada'}:</DeliveryFeeLabel>
  <DeliveryFeeValue>{deliveryFee ? `${numberToCurrency(deliveryFee)}` : 'Grátis'}</DeliveryFeeValue>
</>)

const TotalText = ({ total }) => (<>
  <TotalLabel>TOTAL:</TotalLabel>
  <TotalValue>{numberToCurrency(total)}</TotalValue>
</>)

const SelectedCard = ({ brand, number }) => {
  return <>
    {brand && number ? <>
      <CardImage src={brand} alt={`Bandeira do cartao: ${getCardBrandImageById(brand)}`} />

      <span>{number}</span>
    </> : '...'}
  </>
}

export function OrderSummary({
  products = {},
  buttonName,
  className,
  deliveryAddress,
  cardBrand,
  cardNumber,
  modalityId,
  storeAddress,
  handleTrackOrder,
  scheduling,
  phone,
  whatsapp,
  cpf,
  hasCpf,
  URLParameters
}) {

  const splitDate = scheduling ? scheduling.date.split("/") : ''
  const day = splitDate ? splitDate[0] : ''
  const month = splitDate ? selectMonth(parseInt(splitDate[1])) : ''

  return (
    <Container className={className} scheduling={scheduling}>
      <OrderSummaryTitle>Resumo do Pedido</OrderSummaryTitle>
      {
        products && products.list && products.list.map((item, index) => {
          const {
            quantity,
            product,
            price,
            description,
            id
          } = item

          const key = `checkout-listitem-${index}-${id}`

          return <ItemsList key={key}>
            <Item>
              <ItemDetails>
                <ItemName>{product}</ItemName>
                <ItemDescription>{`${description}`}</ItemDescription>
                <ItemPrice>{numberToCurrency(price)}</ItemPrice>
              </ItemDetails>
              <ItemAmount>{quantity}</ItemAmount>
            </Item>
          </ItemsList>
        })
      }
      {products.summary && <TotalsWrapper>
        <TotalsItem>
          <SubtotalText subtotal={products.summary.subtotal} />
        </TotalsItem>
        <TotalsItem>
          <DiscountText discount={products.summary.discount} />
        </TotalsItem>
        <TotalsItem>
          <DeliveryFeeText deliveryFee={products.summary.deliveryFee} modalityId={modalityId} />
        </TotalsItem>
        <TotalsItem>
          <TotalText total={products.summary.total} />
        </TotalsItem>
      </TotalsWrapper>}
      {scheduling && <DeliveryAddressWrapper>
        <DeliveryAddressLabel>Data da Entrega</DeliveryAddressLabel>
        <DeliveryAddress className="schedule">
          {scheduling ? `${scheduling.week ? `${scheduling.week},` : ''} ${scheduling ? `${day} de ${month},` : ''}` : ''}&ensp;<span>08:00h as 18:00h</span>
        </DeliveryAddress>
      </DeliveryAddressWrapper>}
      <DeliveryAddressWrapper>
        <DeliveryAddressLabel>{
          modalityId === 4 ?
            'Local de entrega' :
            'Local de retirada'}
        </DeliveryAddressLabel>
        <DeliveryAddress>
          <LocationIcon />
          {modalityId === 4 ? deliveryAddress : storeAddress}
        </DeliveryAddress>
      </DeliveryAddressWrapper>
      <DeliveryPaymentWrapper>
        {/* <DeliveryPaymentCPF>CPF na nota: {hasCpf ? formatCpf(cpf) : '000.000.000-00'}</DeliveryPaymentCPF> */}
        <DeliveryAddressLabel>Forma de Pagamento</DeliveryAddressLabel>
        <DeliveryPaymentCard>
          <SelectedCard brand={cardBrand} number={cardNumber} />
        </DeliveryPaymentCard>
      </DeliveryPaymentWrapper>
      <DeliveryAddressWrapper>
        <DeliveryAddressLabel>CPF na nota</DeliveryAddressLabel>
        <DeliveryAddress>
          {hasCpf ? formatCpf(cpf) : '000.000.000-00'}
        </DeliveryAddress>
      </DeliveryAddressWrapper>
      <Help>
        <HelpLabel>Precisa de ajuda?</HelpLabel>
        <HelpButton href={`#${whatsapp}`} target='_blank'>
          Fale conosco
          <IconTyping />
        </HelpButton>
        <HelpButton href={`#${phone}`}>
          Ligar para loja
          <IconPhone />
        </HelpButton>
      </Help>
      <ActionButton onClick={() => {
        if (handleTrackOrder) {
          handleTrackOrder()

          return
        }

        history.push(`/store${URLParameters}`)
      }}>{buttonName}</ActionButton>
    </Container>
  )
}
