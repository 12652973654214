import styled from 'styled-components'

export const EmptyContentWrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 1.25rem;
  padding-bottom: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > div {
    flex: 1;

    &.header {
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 12.5rem;
      flex: 0;
      min-width: 100%;
      max-width: 100%;

      > img {
        min-width: 4.375rem;
        max-width: 4.375rem;
        min-height: auto;
        max-height: unset;
      }
    }

    &.content {
      > h2 {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
      }

      > p {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
        font-size: 1rem;
      }
    }

    &.footer {
      max-width: 100%;
      min-width: 100%;
      min-height: 4.375rem;
      max-height: 4.375rem;
    }
  }
`

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  margin: 0 auto;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 100%;
  min-width: 100%;
  padding-top: 1.25rem;
  box-sizing: border-box;
  position: relative;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > .back-button {
    flex: 1;
    align-self: flex-start;
    margin-bottom: 1.25rem;
    font-weight: bold;
    position: absolute;
    left: 1.25rem;
    top: 1.25rem;
    z-index: 1;
    cursor: pointer;
  }

  > div {
    min-width: 100%;
    padding-bottom: 1.25rem;
  }
`

export const H1 = styled.h1`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
  margin: 0;
  padding: 0;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
  min-width: 100%;
  text-transform: none;
  text-align: center;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' } .063rem solid;
  padding-bottom: 1.25rem;
`

export const ContentWrapper = styled.div`
  opacity: 0;
  transition: all .25s ease-in;
  font-size: .875rem;
  min-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
  min-height: 100%;

  &.motion {
    opacity: 1;
  }

  &.disabled {
    > ul {
      pointer-events: none;
    }
  }

  > h2 {
    margin-bottom: 1.25rem;
  }
`

export const ListWrapper = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: 1.25rem;
  list-style: inside none;
`

export const ListItemWrapper = styled.li`
  padding: 0;
  transition: all .25s ease-in;
  position: relative;
  padding: 1.25rem;
  border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textSecondaryColor) || '#000' } .063rem solid;

  &:last-child {
    margin-bottom: 0;
  }

  > div {
    display: grid;
    grid-gap: 0;
    grid-template-columns: 4.375rem calc(100% - (70px + 1.25rem)) 1.25rem;
    grid-template-areas: 'image text arrow';

    > div {
      &.image {
        grid-area: image;
        min-height: 100%;
        display: flex;
        align-items: center;
        font-size: 1rem;

        > img {
          width: 100%;
          height: auto;
        }
      }

      &.text {
        padding-left: .938rem;

        > p {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
          margin: 0;

          > strong {
            &.modality {
              color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
            }

            &.status {
              &.done {
                color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textThirdColor) || '#000' };
              }
              &.inprogress {
                color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textGreenColor) || '#000' };
              }
            }

            &.price {
              font-size: .875rem;
              color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
            }
          }
        }

        > h4 {
          color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
          margin: 0;
        }
      }

      &.arrow {
        color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
        display: flex;
        font-size: 1.25rem;
        justify-content: center;
        align-items: center;
      }
    }
  }
`

export const BackButton = styled.button`
  background: none;
  border: 0 none;
  font-size: 1.125rem;
  margin-right: 1.25rem;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textHighlightColor) || '#000' };
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 100%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`
