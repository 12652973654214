import styled from 'styled-components'

export const Wrapper = styled.div`
  background: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  min-width: 100%;
  padding: 1.25rem;

  * {
    font-family: 'Nunito', 'sans-serif';
  }

  > button {
    &.close {
      background: none;
      padding: 0;
      margin: 0;
      position: absolute;
      right: 1.25rem;
      top: 1.25rem;
    }
  }

  > #notification {
    margin-bottom: 1.25rem;
  }
`

export const H1 = styled.h1`
  font-size: 1.5rem;
  font-weight: bold;
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  margin: 0;
  padding: 0;
  margin-bottom: 2.5rem;
  min-width: 80%;
  text-align: center;
  max-width: 80%;
`

export const LoadingImage = styled.img`
  width: 6.25rem;
  height: auto;
`

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  min-width: 100%;
`

export const ContentWrapper = styled.div`
  opacity: 0;
  transition: all .25s ease-in;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 100%;
  min-width: 100%;

  &.motion {
    opacity: 1;
  }

  > div.social {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    min-width: 100%;
    margin-bottom: 1.875rem;
    border-bottom: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textFourthColor) || '#000' } .063rem solid;
    padding-bottom: 1.875rem;
    display: none;
  }
`

export const CloseButton = styled.button`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.primaryColor) || '#000' };
  background: none;
  border: 0 none;
  padding: 0;
  margin: 0;
`

export const Paragraph = styled.p`
  color: ${ props => (props.theme && props.theme.main && props.theme.main.general && props.theme.main.general.textPrimaryColor) || '#000' };
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;
`

export const Div = styled.div``

export const OverlayWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 3;
  opacity: 0;
  transition: all .25s ease-in;

  &.motion {
    opacity: 1;
    pointer-events: unset;
  }

  > div:not(.background) {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0);
    z-index: 2;
  }

  > div.background {
    background: rgba(150, 150, 150, .5);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`
