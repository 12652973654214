import React, {
  useEffect,
  useState,
} from 'react'
import { connect } from 'react-redux'
import {
  withTheme
} from 'styled-components'
import {
  Dialog,
  Slide
} from '@material-ui/core'
import {Helmet} from 'react-helmet'

import {
	useStoreSettings
} from '../hooks'

import {
  pipe,
  GetContent,
  caculateProductsQuantity,
  maskCpf
} from '../../domain/helpers'

import history from '../../history'

import { ReactComponent as LocationIcon } from './../assets/icons/icon_checkout_address.svg'
import { ReactComponent as EditIcon } from './../assets/icons/icon_edit_fill.svg'

// import {
//   PWAAnalytics
// } from '../../domain/helpers/ipiranga/PWA'

import {
  CustomIcon,
  CustomLoading
} from '../components'

import { Icon } from '../components/common'

import {
  useCustomLayout,
  useDeviceLayout
} from '../hooks'

import {
  handleGTM,
  setQuantity,
  setUserScheduling,
  postOrder,
  setCart,
  setUserHistory,
  setNotification,
  setCVV,
  setScheduling,
  setProductsRemoved
} from '../../redux/actions/main'

import {
  CVV
} from '../payment'

import { ReactComponent as BasketIcon } from '../assets/icons/icon_basket.svg'

import {
  List,
  Card,
  MinimumValue,
  Coupon
} from '.'

import {
  loading,
  cart, coupon
} from '../../state'

import {
  CouponsList
} from '../coupons/simple'

import {
  Notification
} from '../'

import { calculateCartTotal } from '../../domain/helpers'

import {
  Wrapper,
  AddressWrapper,
  Paragraph,
  H1,
  CloseButton,
  ContentWrapper,
  Div,
  H3,
  OverlayWrapper,
  CartHeaderWrapper,
  CartFooterWrapper,
  ButtonIcon,
  IconButtonContainer,
  ButtonIconLabel,
  IconButtonCartsItemsAmount,
  EditButton,
  ContainerCoupon
} from './styles'
import { Scheduling } from '../scheduling';

export const Context = React.createContext({})

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function CartPage(props) {
  const {
    loading,
    setLoading,
    accessToken,
    userName,
    className,
    storeName,
    storeAddress,
    userAddress,
    userCard,
    products,
    deliveryFee,
    postOrder,
    setQuantity,
    modalityId,
    pickupMinimumValue,
    deliveryMinimumValue,
    login,
    setCart,
    setUserHistory,
    handleGTM,
    setNotification,
    setCVV,
    storeStatus,
    params,
    handleClose,
    theme,
    coupon,
    device,
    isQuantityEditable,
    setViewCards,
    viewCards,
    pathName = "",
    handleCloseDialogCart,
    mode,
    cartCoupon,
    handleNoToken,
    addCoupon,
    setCoupon,
    setCartCoupon,
    scheduling,
    userScheduling,
    setScheduling,
    userCpf,
    userId,
    setProductsRemoved,
    URLParameters
  } = props

  // const {
  //   main
  // } = theme

  // const {
  //   primaryColor
  // } = main && main.general

  const {
    brandId,
    brand,
    data: number,
    paymentType
  } = userCard

  const {
    cvv
  } = userCard

  const [orderLoading, setOrderLoading] = useState(false)
  const [contentMotion, setContentMotion] = useState(false)
  const [contentOverlayMotion, setContentOverlayMotion] = useState(false)
  const [CVVReady, setCVVReady] = useState(false)
  const [cartTotal, setCartTotal] = useState(0)
  const [visibleCoupons, setVisibleCoupons] = useState(false)
  const [visibleScheduling, setVisibleScheduling] = useState(false)

	const {
		url,
		title,
		description
	} = useStoreSettings()

  useCustomLayout({
    device,
    backgroundColor: theme.main.general.primaryColor
  })

  const isMobile = useDeviceLayout({
    isMobile: true
  })

  useEffect(() => {
    const url = window.location.href;
    if (isMobile) {
      const access = {
        event: 'PageView',
        page_path: url,
        page_title: 'Carrinho'
      }

      handleGTM(access)
    }

    if (userId) {
      handleGTM({ user_id: userId })
    }
  }, [
    userId,
    handleGTM,
    isMobile
  ])

  // useEffect(() => {
  //   if (products && products.length) {
  //     const checkoutData = {
  //       action: 'analytics',
  //       data: {
  //         eventType: 'open_checkout',
  //         parameters: {
  //           store_name: storeName,
  //           modality: modalityId,
  //           product_list: products && products.map(function (item) {
  //             return {
  //               product_name: item.name,
  //               product_value: item.valorPromocional ? item.valorPromocional : item.price,
  //               quantity: item.quantity
  //             }
  //           })
  //         }
  //       }
  //     }

  //     handleGTM(checkoutData)

  //     PWAAnalytics(checkoutData)
  //   }

  //   return () => { }
  // }, [
  //   handleGTM,
  //   modalityId,
  //   products,
  //   storeName,
  //   primaryColor
  // ])

  useEffect(() => {
    if (products && products.length && !contentMotion) {
      setContentMotion(true)
    }

    setCartTotal(calculateCartTotal(products))

    if (CVVReady) {
      setContentOverlayMotion(true)
    }
  }, [
    CVVReady,
    products,
    contentMotion
  ])

  function postCoupon() {
    addCoupon([() => {
      setCartCoupon({
        error: true
      })
      setCoupon('')
    }, (data) => {
      setCartCoupon({
        code: coupon,
        ...data
      })
      setCoupon('')
    }])
  }

  function handleCoupon(data) {
    setCartCoupon({
      ...data
    })

    setVisibleCoupons(false)
  }

  function handleAddCoupon() {
    if (!accessToken) {
      if (isMobile) {
        history.push(`/login${URLParameters}`)

        return
      }
    }
    
    postCoupon()
  }

  function handleRemoveCoupon() {
    setCartCoupon('')
    setCoupon('')
  }

  function handleViewCoupons() {
    setVisibleCoupons(true)
  }

  function handleCloseDialogSecheduling() {
    setVisibleScheduling(false)
  }

  function handleCloseDialogCoupons() {
    setVisibleCoupons(false)
  }

  function handleCVV(args) {
    postCVV(args)
  }

  function handleCards() {
    if (isMobile) {
      setUserHistory({
        back: 'cart'
      })
    } else {
      setUserHistory({
        back: 'store'
      })
    }

    history.push(`/cards${URLParameters}`)
  }

  function handleCVVNext() {
    setContentOverlayMotion(false)
    setCVVReady(false)
  }

  function postCVV(args) {
    setCVV(args)
  }

  async function handleFinish() {
    setLoading('payment')
    if (storeStatus && storeStatus !== 1) {
      setNotification({
        type: 'warning',
        message: 'Ops! A loja está fechada. Tente em outro horário!!'
      })

      setLoading('')
      return
    }

    setOrderLoading(true)

    await postOrder([{
      name: 'order',
      type: 'error',
      callback: () => {
        setOrderLoading(false)
      }
    }, {
      name: 'order',
      type: 'success',
      callback: (payload) => {
        const {
          total,
          vqpOrderId
        } = payload

        const {
          name,
          value,
          minValue,
          typeValueModalityDelivery,
          //valueModalityDelivery
        } = coupon

        // const checkoutDataFinish = {
        //   action: 'analytics',
        //   data: {
        //     eventType: 'begin_purchase',
        //     parameters: {
        //       store_name: storeName,
        //       modality: modalityId,
        //       product_list: products.map(function (item) {
        //         return {
        //           product_name: item.name,
        //           product_value: item.valorPromocional ? item.valorPromocional : item.price,
        //           quantity: item.quantity
        //         }
        //       }),
        //       payment_value: coupon && (total > minValue) && !typeValueModalityDelivery ? total - value : total,
        //       coupon_value: coupon && !typeValueModalityDelivery ? value : valueModalityDelivery,
        //       coupon_cod: coupon ? name : '',
        //       order_id: orderId
        //     }
        //   }
        // }

        const checkoutData = {
          event: 'purchase',
          ecommerce: {
            purchase: {
              actionField: {
                id: vqpOrderId,
                affiliation: 'Online Store',
                revenue: coupon && (total > minValue) && !typeValueModalityDelivery ? total - value : total,
                tax: '',
                shipping: deliveryFee,
                coupon: coupon ? name : '',
              },
              products: products ? products.map((product) => {
                return {
                  name: product.name,
                  id: product.id,
                  price: product.price,
                  brand: 'Seara',
                  category: product.category.name,
                  variant: '',
                  quantity: product.quantity
                }
              }) : []
            }
          }
        }

        const finishDataReceipt = {
          event: 'pedido finalizado',
          transaction_id: '',
          natureza_pedido: 'Delivery',
          localidade: '',
          data_agendada: userScheduling ? userScheduling.date : '',
          ata_entregue: userScheduling ? userScheduling.date : ''
        }

        handleGTM(checkoutData)
        handleGTM(finishDataReceipt)

        setCart({
          products: null
        })

        setScheduling(null)

        history.push(`/receipt${URLParameters}`)
      }
    }, {
      name: 'address',
      type: 'error',
      callback: () => {
        setUserHistory({
          next: 'cart',
          back: 'cart'
        })

        setNotification({
          type: 'warning',
          message: 'Não foi possível cadastrar seu endereço. Tente novamente.'
        })

        history.push(`/new-address${URLParameters}`)
        setLoading('')
      }
    }, {
      name: 'address',
      type: 'success',
      callback: () => {
        console.warn('Address created')
        setLoading('')
      }
    }], {
      update: (params && !!params.showCart) || false
    })
  }

  function handleQuantity(args) {
    setQuantity(args)

    if (!args.quantity && products.length === 1 && products[0].quantity === 1 && handleClose && typeof handleClose === 'function') {
      handleClose()
    }
  }

  const cartMinimumValue = ({
    cartTotal, pickupMinimumValue, deliveryMinimumValue, modalityId
  }) => {
    if (modalityId === 2 && cartTotal < pickupMinimumValue) {
      return false
    }

    if (modalityId === 4 && cartTotal < deliveryMinimumValue) {
      return false
    }

    return true
  }

  const handleGoToNext = (products) => {
    if (products.length) {
      if (!accessToken) {
        handleNoToken()

        return
      }
      if (isMobile && handleCloseDialogCart && typeof handleCloseDialogCart === 'function') {
        handleCloseDialogCart()
        history.push(`/cards${URLParameters}`)

        return
      }

      setViewCards(true)
    }

    return
  }

  const goToConfirmation = () => {
    const datacheckout = {
      event: 'eec.checkout',
      ecommerce: {
        checkout: {
          actionField: {
            step: 1,
            option: userCard ? `Cartao de Crédito ${userCard.brand}` : ''
          }
        }
      }
    }

    handleGTM(datacheckout)
    history.push(`/order-confirmation${URLParameters}`)
  }
  const schedulingValidation = () => {
    if(scheduling){
      if(userScheduling){
        return true
      }

      return false
    }

    return true
  }

  const finishButtonDisabled = () => {
    if (accessToken && cartTotal && modalityId && schedulingValidation() && userCard.data && cartMinimumValue({
      modalityId,
      cartTotal,
      pickupMinimumValue,
      deliveryMinimumValue
    })) {
      return false
    } else {
      return true
    }
  }

  return <Wrapper id='cart' className={className}>
		<Helmet>
			<title>{`${title} - Carrinho de compras`}</title>
			<meta name='description' content={description} />
			<link rel='canonical' href={typeof handleCloseDialogCart === 'function' ? `${url}/cart` : `${url}/store`} />
		</Helmet>

    {mode === 'simple' ? null : <CartHeaderWrapper>
      <CloseButton className='close' onClick={() => {
        history.push(`store${URLParameters}`)
      }}>
        <CustomIcon name='AngleDown' />
      </CloseButton>

      {login && login.status === true && userAddress ? <AddressWrapper>
        <Icon name='localizationWhite' width='11px' />
        <Paragraph>{userAddress.main}</Paragraph>
      </AddressWrapper> : !orderLoading && <H1>Sacola</H1>}
    </CartHeaderWrapper>}

    <ContentWrapper id='cart-content' className={'motion'}>
      <Notification id='cart-notification' />

      <List
        mode={mode}
        coupon={cartCoupon || coupon}
        storeName={storeName}
        storeAddress={storeAddress}
        data={products}
        deliveryFee={deliveryFee}
        userName={userName}
        handleQuantity={handleQuantity}
        modalityId={modalityId}
        handleGTM={handleGTM}
        isQuantityEditable={isQuantityEditable}
        setProductsRemoved={setProductsRemoved}
      />

      <ContainerCoupon>
        <Coupon
          cartCoupon={cartCoupon}
          coupon={coupon}
          setCoupon={setCoupon}
          handleAddCoupon={handleAddCoupon}
          handleRemoveCoupon={handleRemoveCoupon}
          handleViewCoupons={handleViewCoupons}
        /></ContainerCoupon>

      <CartFooterWrapper>
        {scheduling && (userScheduling ?
          <Div id='cart-scheduling'>
            <div className="content">
              <H3>Data agendada</H3>
              <Paragraph>
                {`${userScheduling.week}, ${userScheduling ? `${userScheduling.date} de ${userScheduling.month}` : ''}`}
              </Paragraph>
            </div>
            <div className="edit">
              <EditButton onClick={() => setVisibleScheduling(true)}>
                <EditIcon />
              </EditButton>
            </div>
          </Div> :
          <Div id='cart-scheduling-select'>
            <h4>Data agendada</h4>
            <Paragraph onClick={() => setVisibleScheduling(true)}>Selecione</Paragraph>
          </Div>)}

        {mode === 'simple' ? null : (modalityId === 4 ?
          <Div id='cart-address'>
            <div className="content">
              <H3>Local de entrega</H3>
              <Paragraph>
                <LocationIcon />
                {userAddress ?
                  `${userAddress.main}${(userAddress.number ? `, ${userAddress.number}` : ``)}${(userAddress.complement ? `, ${userAddress.complement}` : ``)}${(userAddress.neighborhood ? `, ${userAddress.neighborhood}` : ``)}` : ''}
              </Paragraph>
            </div>
            <div className="edit">
              <EditButton onClick={() => {
                setUserHistory({
                  back: 'cart'
                })

                history.push(`addresses${URLParameters}`)
              }}>
                <EditIcon />
              </EditButton>
            </div>
          </Div> :
          <Div id='cart-address'>
            <H3>Lembrando que você vai retirar em:</H3>
            <Paragraph>
              <LocationIcon />
              {storeAddress}
            </Paragraph>
          </Div>)}

        {mode === 'simple' ? null :
          (!userCard || !userCard.brand ? <Div className='cart-cards'>
            <Paragraph onClick={() => handleCards()}>
              Selecione uma forma de pagamento
              <EditButton onClick={() => handleCards()}>
                <EditIcon />
              </EditButton>
            </Paragraph>
          </Div> : <Card
            brand={brand}
            number={number}
            paymentType={paymentType}
            brandId={brandId}
            handlePayment={handleCards}
          />)}

        {mode === 'simple' ? null : <Div id='cart-address'>
          <div className="content">
            <H3>CPF na nota</H3>
            <Paragraph>
              {userCpf ? maskCpf(userCpf) : ''}
            </Paragraph>
          </div>
        </Div>}

        {cartTotal && modalityId ? <MinimumValue
          cartTotal={cartTotal}
          modalityId={modalityId}
          minimumValue={modalityId === 2 ? pickupMinimumValue : deliveryMinimumValue}
        /> : null}

        {!viewCards && !pathName && !isMobile && <ButtonIcon disabled={!products.length} onClick={() => handleGoToNext(products)}>
          <IconButtonContainer>
            <IconButtonCartsItemsAmount>{caculateProductsQuantity(products)}</IconButtonCartsItemsAmount>
            <BasketIcon />
          </IconButtonContainer>
          <ButtonIconLabel>Formas de pagamento</ButtonIconLabel>
        </ButtonIcon>}

        {viewCards && !pathName && !isMobile && <ButtonIcon disabled={userCard && userCard.data ? false : true} onClick={() => goToConfirmation()}>
          <ButtonIconLabel >Continuar</ButtonIconLabel>
        </ButtonIcon>}

        {(pathName && pathName === '/order-confirmation') && <ButtonIcon id='finish-purchase-button' disabled={finishButtonDisabled()} onClick={() => {
          handleFinish()
        }}>
          <ButtonIconLabel >{loading.includes('payment') ?
            <CustomLoading
              color={'#fff'}
              type={'spin'}
              id='default-loading'
              height={30}
              width={30} /> :
            'Finalizar Compra'}</ButtonIconLabel>
        </ButtonIcon>}

        {isMobile && <ButtonIcon disabled={finishButtonDisabled()} onClick={() => {
          handleFinish()
        }}>
          <ButtonIconLabel>{loading.includes('payment') ?
            <CustomLoading
              color={'#fff'}
              type={'spin'}
              id='default-loading'
              height={30}
              width={30} /> :
            'Finalizar Compra'}</ButtonIconLabel>
        </ButtonIcon>}
      </CartFooterWrapper>

      <Dialog
        onClose={() => handleCloseDialogCoupons()}
        aria-labelledby="coupons-list-dialog"
        open={visibleCoupons}
        TransitionComponent={Transition}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <CouponsList theme={theme} handleCoupon={handleCoupon} />
      </Dialog>

      <Dialog
        onClose={() => handleCloseDialogSecheduling()}
        aria-labelledby="scheduling-dialog"
        open={visibleScheduling}
        TransitionComponent={Transition}
        maxWidth={'xs'}
        fullWidth={true}
        fullScreen={true}
      >
        <Scheduling handleCloseDialogSecheduling={handleCloseDialogSecheduling} />
      </Dialog>

      <OverlayWrapper className={contentOverlayMotion ? 'motion' : ''}>
        <Div className='background' onClick={() => {
          if (contentOverlayMotion) {
            setContentOverlayMotion(false)
            setCVVReady(false)
          }
        }} />

        {CVVReady ? <CVV cvv={cvv} handleCVV={handleCVV} handleNext={handleCVVNext} /> : null}
      </OverlayWrapper>
    </ContentWrapper>
  </Wrapper>
}

const mapStateToProps = (state) => {
  return {
    accessToken: state.main.accessToken,
    card: state.main.card || '',
    userName: state.main.userName || '',
    storeName: state.main.store && state.main.store.name,
    storeAddress: state.main.store && state.main.store.address,
    userAddress: state.main.userAddress || {},
    products: (state.main.cart && state.main.cart.products) || [],
    deliveryFee: (state.main.store && state.main.store.deliveryFee) || 0,
    deliveryMinimumValue: (state.main.store && state.main.store.deliveryMinimumValue) || 0,
    pickupMinimumValue: (state.main.store && state.main.store.pickupMinimumValue) || 0,
    adHoc: state.main.store && state.main.store.adHoc,
    modalityId: state.main.modality && state.main.modality.id,
    userCard: (state.main.userCard) || {},
    login: state.main.login || {},
    params: state.main.params || {},
    storeStatus: state.main.store && state.main.store.status,
    coupon: state.main.coupon || '',
    cartCoupon: (state.main.cart && state.main.cart.coupon) || '',
    loading: (state.main.store && state.main.loading) || [],
    device: (state.main.params && state.main.params.device) || '',
    scheduling: state.main.store && state.main.store.scheduling,
    userScheduling: state.main.scheduling,
    userCpf: state.main.userCpf || '',
    userId: state.main.userId,
    URLParameters: (state.main.URLParameters) || ''
  }
}

const GetConnection = connect(mapStateToProps, {
  addCoupon: coupon.addCoupon,
  setCoupon: coupon.setCoupon,
  setCartCoupon: cart.setCartCoupon,
  handleGTM,
  setQuantity,
  setUserScheduling,
  postOrder,
  setCart,
  setUserHistory,
  setNotification,
  setCVV,
  setLoading: loading.setLoading,
  setScheduling,
  setProductsRemoved
})

export const Cart = React.memo(pipe(
  GetConnection,
  GetContent({ context: Context, id: 'cart' }),
  withTheme
)(CartPage))
