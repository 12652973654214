interface UserAddressesList  {
  id: number
  address: string
  number: string | number
  cep: string
  neighborhood: string
  city: string
  state: string
  country: string
  complement: string
  reference: string
  category: string | number
  latitude: string | number
  longitude: string | number
}

export const mapUserAddressesList = (data: any) : UserAddressesList[] | [] => {
  const addresses = (data['Enderecos'] && data['Enderecos'].length && data['Enderecos'].map((mappedItem: any) => {
    return {
      id: mappedItem['Id'],
      address: mappedItem['Endereco'],
      number: mappedItem['Numero'],
      cep: mappedItem['Cep'],
      neighborhood: mappedItem['Bairro'],
      city: mappedItem['Cidade'],
      state: mappedItem['Estado'],
      country: mappedItem['Pais'],
      complement: mappedItem['Complemento'],
      reference: mappedItem['Referencia'],
      type: mappedItem['TipoEndereco'],
      latitude: mappedItem['Latitude'],
      longitude: mappedItem['Longitude']
    }
 })) || []

  return addresses
}
